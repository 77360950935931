import Compras from './../views/compras/index.vue';
import Compra from './../views/compras/compra.vue';
import Detalles from './../views/compras/detalles.vue';
const routes = [
	{
		path: '/Compras',
		name: 'compras',
		component: Compras,
		meta: {
			name: 'Control de Producto Comprado'
		}
	},
	{
		path: '/Compras/Nueva',
		name: 'compras.new',
		component: Compra,
		meta: {
			name: 'Nueva Compra'
		}
	},
	{
		path: '/Compras/Detalles/:id',
		name: 'compras.details',
		component: Detalles,
		meta: {
			name: 'Nueva Compra'
		}
	}
];
export default routes;
