<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Summary
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-form @submit.prevent="updateResource" :disabled="loader">
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <v-text-field
                dense
                outlined
                clearable
                label="Overall Pack Dates"
                v-model="resource.pack_dates"
                :error-messages="errors.pack_dates"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                outlined
                clearable
                label="Overall Appearance"
                v-model="resource.appearance"
                :error-messages="errors.appearance"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                outlined
                clearable
                label="Overall PLU Placement"
                v-model="resource.plu_placement"
                :error-messages="errors.plu_placement"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                outlined
                clearable
                label="Overall Box Condition"
                v-model="resource.box_condition"
                :error-messages="errors.box_condition"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-data-table
                dense
                :headers="headers"
                :items="items"
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:[`item.shrivel_grade`]="{ item }">
                  <v-text-field
                    dense
                    outlined
                    type="number"
                    v-model="item.shrivel_grade"
                    hide-details="auto"
                    :disabled="loader"
                    :error-messages="error_shrivel_grade(item)"
                  />
                </template>
                <template v-slot:[`item.color_skin_grade`]="{ item }">
                  <v-text-field
                    dense
                    outlined
                    type="number"
                    v-model="item.color_skin_grade"
                    hide-details="auto"
                    :disabled="loader"
                    :error-messages="color_skin_grade_error(item)"
                  />
                </template>
                <template v-slot:[`item.firmness_grade`]="{ item }">
                  <v-text-field
                    dense
                    outlined
                    type="number"
                    v-model="item.firmness_grade"
                    hide-details="auto"
                    :disabled="loader"
                    :error-messages="firmness_grade_error(item)"
                  />
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-if="modificado">
          <v-btn color="success" type="submit" :disabled="loader">
            {{ titleButton }}
          </v-btn>
          <v-btn color="error" @click="getResource($route.params.id)"
            >cancel</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  export default {
    name: "Summary",
    data: () => ({
      original: {},
      headers: [
        { text: "Product", value: "product" },
        { text: "Shrivel Grade", value: "shrivel_grade" },
        { text: "Color Skin Grade", value: "color_skin_grade" },
        { text: "Firmness Grade", value: "firmness_grade" },
      ],
      productos: undefined,
    }),
    computed: {
      ...mapState({
        resource: (state) => state.Qc.resource,
        loader: (state) => state.Qc.loader,
        errors: (state) => state.errors,
        products: (state) => state.Qc.resource.products,
      }),
      modificado() {
        if (!this.original.pack_dates) {
          return true;
        }
        if (JSON.stringify(this.original) !== JSON.stringify(this.resource)) {
          return true;
        }
        if (JSON.stringify(this.products) !== JSON.stringify(this.productos)) {
          return true;
        }
        return false;
      },
      titleButton() {
        return this.original.pack_dates ? "update" : "save";
      },
      items(){
        return this.resource.products.filter(x=> x.taken)
      }
    },
    mounted() {
      this.original = Object.assign({}, this.resource);
      this.productos = Object.assign({}, this.resource.products);
    },
    watch: {
      resource(val) {
        this.original = Object.assign({}, val);
      },
    },
    methods: {
      ...mapActions("Qc", ["getResource", "updateResource"]),
      error_shrivel_grade(item) {
        let index = this.resource.products.findIndex(
          (x) => x.product == item.product
        );
        let algo = `prods.${index}.shrivel_grade`;
        return this.errors[algo];
      },
      color_skin_grade_error(item) {
        let index = this.resource.products.findIndex(
          (x) => x.product == item.product
        );
        let algo = `prods.${index}.color_skin_grade`;
        return this.errors[algo];
      },
      firmness_grade_error(item) {
        let index = this.resource.products.findIndex(
          (x) => x.product == item.product
        );
        let algo = `prods.${index}.firmness_grade`;
        return this.errors[algo];
      },
    },
  };
</script>
