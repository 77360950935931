var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"blue lighten-4"}},[_vm._v(" Invoices ")]),_c('v-expansion-panel-content',[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","items":_vm.items,"headers":_vm.headers,"mobile-breakpoint":"0","items-per-page":_vm.items.length,"hide-default-footer":"","item-class":_vm.rowColor},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("folio")(item.id))+" ")]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fecha))+" ")]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}},{key:"item.resta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.resta))+" ")]}},{key:"item.pagos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")((item.importe - item.resta)))+" ")]}},{key:"item.fecha_pago",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fecha_pago))+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }