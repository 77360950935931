<!-- eslint-disable -->
<template>
	<v-card height="100%">
		<v-card-text v-if="resource.id">
			<v-row class="mt-3 mb-1">
				<v-col md="4"> Folio: {{ resource.folio }} </v-col>
				<v-col md="4"> Estatus: {{ resource.status }} </v-col>
				<v-col md="4"> Fecha: {{ resource.created_at | dateTime }} </v-col>
			</v-row>
			<v-divider></v-divider>
			<v-row class="mt-1">
				<v-col cols="12" md="4">
					Usuario que reporta: <br />
					{{ user.full_name }}
				</v-col>
				<v-col cols="12" md="4">
					Falla Reportada: <br />
					{{ resource.falla }}
				</v-col>
				<v-col v-if="resource.observaciones" cols="12" md="4">
					Observaciones: <br />
					{{ resource.observaciones }}
				</v-col>
			</v-row>
		</v-card-text>

		<v-card-text v-if="resource.updated_at != resource.created_at">
			<v-divider></v-divider>
			<h3 class="mt-1">Acciones Realizadas</h3>
			<v-row class="mt-3">
				<v-col>
					{{ resource.acciones }}
				</v-col>
				<v-col v-if="resource.updated_at != resource.created_at && !resource.fecha_conclusion">
					Fecha de Actualización: {{ resource.updated_at | dateTime }}
				</v-col>
				<v-col v-else>
					Fecha de Conclusión: {{ resource.fecha_conclusion | dateTime }}
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-btn color="primary" @click="$router.push({ name: 'reportes' })">
				regresar
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Historial',
	computed: {
		...mapState({
			resource: state => state.Reportes.resource
		}),
		user() {
			return this.resource.user;
		}
	},
	mounted() {
		if (!this.resource.id) {
			let id = this.$route.params.id;
			this.$store.dispatch('Reportes/historial', this.$route.params.id);
		}
	}
};
</script>
