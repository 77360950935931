<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-card height="100%" v-if="resource">
    <v-expansion-panels v-model="panel" multiple>
      <lot />
      <template v-if="$route.params.id">
        <template v-if="resource.species == 'AVOCADO'">
          <avocados />
          <samples v-if="resource.products.length" />
          <template v-if="resource.samples.length">
            <Summary />
            <photos />
          </template>
        </template>
      </template>
    </v-expansion-panels>
    <v-card-actions v-if="$route.params.id">
      <v-btn color="primary" @click="$router.push({ name: 'qc' })">
        back to List
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import Lot from "./PanelLot.vue";
  import Avocados from "./PanelAvocados.vue";
  import Samples from "./PanelSamples.vue";
  import Summary from "./PanelSummary.vue";
  import Photos from "./PanelPhotos.vue";
  export default {
    name: "Show",
    components: { Lot, Avocados, Samples, Summary, Photos },
    computed: {
      ...mapState({
        resource: (state) => state.Qc.resource,
      }),
      panel: {
        get() {
          return this.$store.state.Qc.panel;
        },
        set(val) {
          return this.$store.commit("Qc/setPanel", val);
        },
      },
    },
    methods: {
      ...mapActions("Qc", ["nuevo", "getResource"]),
    },
    beforeMount() {
      if (!this.resource) {
        if (this.$route.params.id) {
          this.getResource(this.$route.params.id);
        } else {
          this.nuevo();
        }
      }
    },
  };
</script>
