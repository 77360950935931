<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-card class="mt-5">
    <v-card-title primary-title>
      Inspection Photos
    </v-card-title>
    <v-form @submit.prevent="uploadPhoto" :disabled="loader">
      <v-card-text>
        <v-row>
          <v-col cols="12" xl="6">
            <v-file-input
              v-model="file"
              dense
              outlined
              prepend-icon="mdi-camera"
              show-size
              label="Photo"
              accept="image/png, image/jpeg, image/bmp"
              :error-messages="errors.image"
            />
          </v-col>
          <v-col v-if="file">
            <v-btn color="success" type="submit">
              <v-icon>mdi-cloud-upload</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4" v-for="item in items" :key="item.id">
          <v-card class="mb-3">
            <v-card-text>
              <v-img
                max-height="205"
                max-width="498"
                :src="`${item.url}`"
                contain
              />
            </v-card-text>
            <v-card-actions>
              <v-btn small color="error" @click="eliminar(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapMutations, mapState } from "vuex";
  export default {
    name: "Photos",
    data: () => ({
      items: [],
      file: undefined,
    }),
    computed: {
      ...mapState({
        loader: (state) => state.Embarques.loader,
        errors: (state) => state.errors,
      }),
    },
    methods: {
      ...mapMutations("Embarques", ["setLoader"]),
      getData() {
        let url = `/FR/embarques/photos/${this.$route.params.id}`;
        this.setLoader();
        window.axios
          .get(url)
          .then((resp) => {
            this.items = resp.data.collection;
          })
          .catch((e) => this.$store.dispatch("errores", e))
          .finally(() => this.setLoader(false));
      },
      uploadPhoto() {
        let formData = new FormData(),
          url = `/FR/embarques/photos/${this.$route.params.id}`;
        formData.append("image", this.file);
        this.setLoader();
        this.$store.commit("setErrors");
        window.axios
          .post(url, formData)
          .then((resp) => {
            if (resp.data.message) {
              this.$store.dispatch("message", resp.data.message);
              this.file = undefined;
              this.getData();
            }
          })
          .catch((e) => this.$store.dispatch("errores", e))
          .finally(() => this.setLoader(false));
      },
      eliminar(item) {
        this.$swal({
          title: "¿ Eliminar la Foto ?",
          text: item.name,
          showCancelButton: true,
        }).then((resp) => {
          if (resp.isConfirmed) {
            let url = `/deletePhoto/${item.id}`;
            window.axios
              .delete(url)
              .then((resp) => {
                this.$store.dispatch("message", resp.data.message);
                this.file = undefined;
                this.getData();
              })
              .catch((e) => this.$store.dispatch("message", e));
          }
        });
      },
    },
    beforeMount() {
      this.$store.commit("setErrors");
      this.getData();
    },
  };
</script>
