<!-- eslint-disable -->
<template>
	<v-row v-if="!loader && items " class="mt-2">
		<v-col class="mt-5">
			Registros Encontrados: {{ paginado.registros | numero }}
			<template v-if="paginado.importe">
				<br>
				Importe: {{ paginado.importe | moneda }}
			</template>
		</v-col>
		<v-col cols="12" md="8">
			<v-pagination
				class="mt-3"
				v-model="paginado.currentPage"
				:length="paginado.lastPage"
				total-visible="8"
			/>
		</v-col>
		<v-col></v-col>
	</v-row>
</template>

<script>
export default {
	name: 'Pagination',
	//props: ['loader', 'items', 'paginado']
	props: {
		loader: Boolean,
		items: {
			type: Array,
			default() {
				return [];
			}
		},
		paginado: {
			type: Object,
			default() {
				return {};
			}
		}
	}
};
</script>
