<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Datos Venta {{ sale.folio }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="mt-2 mb-4">
        <v-col
          >Fecha: <strong>{{ sale.date | date }}</strong></v-col
        >
        <v-col
          >Cliente: <strong>{{ sale.customer }}</strong></v-col
        >
        <v-col
          >PO: <strong>{{ sale.po }}</strong></v-col
        >
        <v-col
          >PU: <strong>{{ sale.pu }}</strong></v-col
        >
        <v-col
          >Importe Productos:
          <strong>{{ sale.importe | moneda }}</strong></v-col
        >
        <v-col v-if="sale.charges"
          >Importe Cargos Extra:
          <strong>{{ sale.charges | moneda }}</strong></v-col
        >
        <v-col
          >Importe Total: <strong>{{ sale.total | moneda }}</strong></v-col
        >
      </v-row>
      <v-row class="mb-2">
        <v-col v-if="sale.bol">
          <v-text-field
            outlined
            dense
            disabled
            label="Archivo Bol"
            v-model="sale.bol"
            hide-details="auto"
          />
        </v-col>
        <v-col cols="12" md="6" v-else>
          <v-file-input
            v-model="file"
            outlined
            dense
            label="Subir BOL"
            small-chips
            clearable
            counter
            show-size
            truncate-length="50"
            accept="image/*,.pdf"
            :error-messages="errors.file"
            max="20"
            :disabled="loader"
            hide-details="auto"
          />
        </v-col>
        <v-col>
          <v-btn color="primary" :disabled="loader" @click="customMethod">
            {{ !sale.bol ? "subir" : "descargar" }}
          </v-btn>
		  <v-btn class="ml-2" color="error" @click="deleteBol" v-if="sale.bol">
			  eliminar bol
		  </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  export default {
    name: "Sale",
    computed: {
      ...mapState({
        sale: (state) => state.Sucursales.sale,
        errors: (state) => state.errors,
        loader: (state) => state.Sucursales.loader,
      }),
    },
    data: () => ({
      file: undefined,
    }),
    methods: {
      ...mapActions("Sucursales", ["uploadBol", "deleteBol"]),
      customMethod() {
        if (!this.sale.bol) {
          this.uploadBol(this.file);
        } else {
          window.open(this.sale.downloadBol, "_blank");
        }
      },
    },
  };
</script>
