<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card height="100%" v-if="items">
    <v-card-text>
      <v-form @submit.prevent="getAll">
        <v-row class="mt-3">
          <v-col>
            <v-text-field
              dense
              outlined
              clearable
              label="Buscar por Expediente u Orden de Embarque"
              v-model="paginado.search"
            />
          </v-col>
          <v-col>
            <v-btn color="primary" type="submit">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn color="info" type="submit" class="ml-3">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        dense
        :items="items"
        :headers="headers"
        class="elevation-1"
        mobile-breakpoint="0"
        :items-per-page="paginado.perPage"
        hide-default-footer
      >
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
        <template v-slot:[`item.cajas`]="{ item }">
          {{ item.cajas | numero }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="success"
            @click="getEmbarque(item.id)"
            :disabled="loader"
            v-if="userCan('fr-embarques-edit')"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            x-small
            color="success"
            v-if="item.procesar && userCan('fr-embarques-procesar')"
            @click="preguntar(item)"
            :disabled="loader"
          >
            <v-icon small>mdi-cogs</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <Pagination :loader="loader" :items="items" :paginado="paginado" />
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapState, mapActions, mapMutations } from "vuex";
  export default {
    name: "Embarques",
    data: () => ({
      filtros: [],
    }),
    computed: {
      ...mapState({
        headers: (state) => state.Embarques.headers,
        items: (state) => state.Embarques.collection,
        loader: (state) => state.Embarques.loader,
        paginado: (state) => state.Embarques.paginado,
      }),
      actual() {
        return this.paginado.currentPage;
      },
      search() {
        return this.paginado.search;
      },
    },
    mounted() {
      if (!this.items.length) {
        this.getAll();
      }
    },
    methods: {
      ...mapActions("Embarques", ["getAll", "getEmbarque", "procesar"]),
      preguntar(item) {
        let pregunta = `¿Deseas procesar el expediente ${item.expediente}?`;
        window
          .swal({
            title: pregunta,
            text: `Se van a procesar ${item.pallets} pallets`,
            icon: "warning",
            buttons: ["Cancelar", "Confirmar"],
            dangerMode: true,
          })
          .then((confirmed) => {
            if (confirmed) {
              this.procesar(item.id);
            }
          });
      },
    },
    watch: {
      actual: function() {
        this.getAll();
      },
    },
  };
</script>
