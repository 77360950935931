<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-form @submit.prevent="$store.dispatch('Abilities/getAll')">
        <v-row class="mt-3">
          <v-col>
            <v-autocomplete
              dense
              outlined
              clearable
              label="Filtrar por Sistema"
              v-model="paginado.sistema"
              :items="sistemas"
            />
          </v-col>
          <v-col>
            <v-autocomplete
              dense
              outlined
              clearable
              label="Filtrar por Módulo"
              v-model="paginado.modulo"
              :items="modulos"
            />
          </v-col>
          <v-col>
            <v-text-field
              dense
              outlined
              clearable
              label="Buscar"
              v-model="paginado.search"
            />
          </v-col>
          <v-col>
            <v-btn color="primary" type="submit" :disabled="loader">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              class="ml-3"
              color="success"
              :disabled="loader"
              @click="$router.push({ name: 'abilities.create' })"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-text>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        class="elevation-1"
        :items-per-page="paginado.perPage"
        hide-default-footer
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | dateTime }}
        </template>
      </v-data-table>
      <v-row v-if="!loader && items && items.length">
        <v-col class="mt-5">
          Registros Encontrados: {{ paginado.registros | numero }}
        </v-col>
        <v-col cols="12" md="8">
          <v-pagination
            class="mt-3"
            v-model="paginado.currentPage"
            :length="paginado.lastPage"
            total-visible="8"
          />
        </v-col>
        <v-col></v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    name: "Abilities",
    mounted() {
      this.$store.dispatch("Abilities/getAll");
    },
    computed: {
      ...mapState({
        loader: (state) => state.Abilities.loader,
        paginado: (state) => state.Abilities.paginado,
        sistemas: (state) => state.Abilities.sistemas,
        modulos: (state) => state.Abilities.modulos,
        items: (state) => state.Abilities.collection,
        headers: (state) => state.Abilities.headers,
      }),
      actual() {
        return this.paginado.currentPage;
      },
    },
    watch: {
      actual: function() {
        this.$store.dispatch("Abilities/getAll");
      },
    },
  };
</script>
