/** @format */

const routes = [
  {
    path: "/Safe-Food/auditorias",
    name: "safe.audits",
    component: require("@/views/safe-food/audits/Index.vue").default,
    meta: {
      name: "Control de Auditorias",
    },
  },
  {
    path: "/Safe-Food/certificados",
    name: "safe.certifications",
    component: require("@/views/safe-food/certifications/Index.vue").default,
    meta: {
      name: "Control de Certificados",
    },
  },
  {
    path: "/Safe-Food/seguros",
    name: "safe.secures",
    component: require("@/views/safe-food/secures/Index.vue").default,
    meta: {
      name: "Control de Seguros",
    },
  },
];
export default routes;
