import Proveedores from './../views/proveedores/index.vue';
import Formulario from './../views/proveedores/form.vue';
import Historial from './../views/proveedores/historial.vue';
const routes = [
	{
		path: '/Proveedores',
		name: 'proveedores',
		component: Proveedores,
		meta: {
			name: 'Control de Proveedores'
		}
	},
	{
		path: '/Proveedores/nuevo',
		name: 'proveedores.create',
		component: Formulario,
		meta: {
			name: 'Nuevo Proveedor'
		}
	},
	{
		path: '/Proveedores/editar',
		name: 'proveedores.edit',
		component: Formulario,
		meta: {
			name: 'Editando Proveedor'
		}
	},
	{
		path: '/Proveedores/historial',
		name: 'proveedores.history',
		component: Historial,
		meta: {
			name: 'Historial del Proveedor'
		}
	}
];
export default routes;
