<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-card class="my-2">
    <v-card-text>
      <v-data-table
        dense
        :items="items"
        :headers="headers"
        class="elevation-1"
        mobile-breakpoint="0"
        :loading="loader"
        hide-default-footer
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | dateTime }}
        </template>
        <template v-slot:[`item.cc`]="{ item }">
          <span v-html="item.cc" />
        </template>
        <template v-slot:[`item.to`]="{ item }">
          <span v-html="item.to" />
        </template>
      </v-data-table>
      <Pagination :loader="loader" :items="items" :paginado="paginado" />
    </v-card-text>
    <v-card-actions>
      <v-btn small color="primary" @click="getAll" :disabled="loader"
        >actualizar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: "Correos",
    data: () => ({
      items: [],
      headers: [],
      loader: false,
      paginado: { currentPage: 1 },
    }),
    methods: {
      getAll() {
        this.loader = true;
        this.items = [];
        window.axios
          .get("FR/dashboard/getCorreos", {
            params: { page: this.paginado.currentPage },
          })
          .then((resp) => {
            this.items = resp.data.collection;
            this.paginado = resp.data.paginado;
            this.headers = resp.data.headers;
          })
          .catch((e) => this.$store.dispatch("errores", e))
          .finally(() => (this.loader = false));
      },
      nl2br(str, is_xhtml) {
        if (typeof str === "undefined" || str === null) {
          return "";
        }
        var breakTag =
          is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
        return (str + "").replace(
          /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
          "$1" + breakTag + "$2"
        );
      },
    },
    computed: {
      currentPage() {
        return this.paginado.currentPage;
      },
    },
    mounted() {
      this.getAll();
    },
    watch: {
      currentPage() {
        this.getAll();
      },
    },
  };
</script>
