var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[_c('v-form',{attrs:{"disabled":_vm.loader},on:{"submit":function($event){$event.preventDefault();return _vm.enviar($event)}}},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","items-per-page":_vm.items.length},scopedSlots:_vm._u([{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")((item.cantidad * item.precio_unitario)))+" ")]}},{key:"item.precio_unitario",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"my-1",attrs:{"outlined":"","dense":"","hide-details":"auto"},model:{value:(item.precio_unitario),callback:function ($$v) {_vm.$set(item, "precio_unitario", _vm._n($$v))},expression:"item.precio_unitario"}})]}},{key:"item.tipo_caja",fn:function(ref){
var item = ref.item;
return [_c('v-combobox',{attrs:{"items":_vm.labels,"outlined":"","dense":"","hide-details":"auto"},model:{value:(item.tipo_caja),callback:function ($$v) {_vm.$set(item, "tipo_caja", $$v)},expression:"item.tipo_caja"}})]}},{key:"item.pallet_id",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"my-1",attrs:{"outlined":"","dense":"","hide-details":"auto"},model:{value:(item.pallet_id),callback:function ($$v) {_vm.$set(item, "pallet_id", $$v)},expression:"item.pallet_id"}})]}},{key:"item.fecha_corte",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"date","hide-details":"auto"},model:{value:(item.fecha_corte),callback:function ($$v) {_vm.$set(item, "fecha_corte", $$v)},expression:"item.fecha_corte"}})]}}],null,true)})],1),_c('v-divider'),_c('v-card-text',{staticClass:"mt-2"},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Fecha de Compra","outlined":"","dense":"","type":"date","error-messages":_vm.errors.fecha},model:{value:(_vm.purchase.fecha),callback:function ($$v) {_vm.$set(_vm.purchase, "fecha", $$v)},expression:"purchase.fecha"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"PU (Pick Up Order)","outlined":"","dense":"","error-messages":_vm.errors.pu},model:{value:(_vm.purchase.pu),callback:function ($$v) {_vm.$set(_vm.purchase, "pu", $$v)},expression:"purchase.pu"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","label":"Observaciones"},model:{value:(_vm.purchase.observaciones),callback:function ($$v) {_vm.$set(_vm.purchase, "observaciones", $$v)},expression:"purchase.observaciones"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"success","type":"submit","disabled":_vm.loader}},[_vm._v(" guardar ")]),_c('v-btn',{attrs:{"color":"error","disabled":_vm.loader},on:{"click":function($event){return _vm.$router.push({
            name: 'orders.edit',
            params: { id: _vm.$route.params.id },
          })}}},[_vm._v(" cancelar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }