/** @format */

import router from "../../router";
const url = "FR/purchaseOrders";
const initialState = () => ({
  headers: [],
  collection: undefined,
  resource: undefined,
  loader: false,
  search: undefined,
  details: undefined,
  almacenes: undefined,
  proveedores: undefined,
  abiertos: [],
  categorias: undefined,
  paginado: { currentPage: 1, search: null },
  supplies: undefined,
  labels: undefined,
  pallets: undefined
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setPallets(state, payload) {
      state.pallets = payload;
    },
    setLabels(state, payload) {
      state.labels = payload;
    },
    setSupplies(state, payload) {
      state.supplies = payload;
    },
    setInitialState(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
    setHeaders(state, payload) {
      state.headers = payload;
    },
    setCollection(state, payload) {
      state.collection = payload;
    },
    setResource(state, payload = undefined) {
      state.resource = payload;
    },
    setLoader(state, payload) {
      state.loader = payload;
    },
    setSearch(state, payload) {
      state.search = payload;
    },
    setDetails(state, payload = []) {
      state.details = payload;
    },
    setAlmacenes(state, payload) {
      state.almacenes = payload;
    },
    setProveedores(state, payload) {
      state.proveedores = payload;
    },
    setAbiertos(state, payload = [0]) {
      state.abiertos = payload;
    },
    setCategorias(state, payload) {
      state.categorias = payload;
    },
    setPaginado(state, payload) {
      let paginado = payload;
      if (paginado.currentPage > paginado.lastPage) paginado.currentPage = 1;
      state.paginado = paginado;
    },
  },
  actions: {
    async getAll({ commit, dispatch, state }) {
      commit("setCollection", []);
      commit("setLoader", true);
      window.axios
        .get(url, {
          params: {
            page: state.paginado.currentPage,
            search: state.paginado.search,
            proveedor_id: state.paginado.proveedor_id,
          },
        })
        .then((resp) => {
          commit("setCollection", resp.data.collection);
          commit("setHeaders", resp.data.headers);
          commit("setAlmacenes", resp.data.almacenes);
          commit("setProveedores", resp.data.proveedores);
          commit("setCategorias", resp.data.categorias);
          commit("setPaginado", resp.data.paginado);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async getResource({ commit, dispatch, state }, item) {
      if (!state.collection) dispatch("getAll");
      commit("setLoader", true);
      commit("setResource");
      commit("setDetails");
      await window.axios
        .get(`${url}/${item.id}`)
        .then((resp) => {
          let id = item.id,
            nombre = `Editando Orden de Compra (Purchase Order) ${item.po}`;
          commit("setResource", resp.data.resource);
          commit("setDetails", resp.data.details);
          commit("setAbiertos", [1]);
          commit("setSupplies", resp.data.supplies);
          commit("setLabels", resp.data.labels);
          commit("setPallets",resp.data.pallets);
          router
            .push({ name: "orders.edit", params: { id, nombre } })
            .catch(() => {});
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },

    nueva({ commit, dispatch, state }) {
      if (!state.collection) dispatch("getAll");
      commit("setResource", {
        ship_terms: "FOB McAllen",
        pay_terms: "30 Days",
      });
      commit("setAbiertos");
      console.log("nueva orden");
      router.push({ name: "orders.create" }).catch(() => {});
    },
    async sendResource({ commit, dispatch, state }) {
      commit("setErrors", [], { root: true });
      commit("setLoader", true);
      let resource = state.resource;
      if (!resource.id) {
        await window.axios
          .post(`${url}`, resource)
          .then((resp) => {
            let id = resp.data.resource.id,
              nombre = `Editando Orden de Compra (Purchase Order) ${resp.data.resource.po}`;
            commit("setResource", resp.data.resource);
            commit("setDetails", resp.data.details);
            commit("setAbiertos", [1]);
            dispatch("getAll");
            dispatch("message", resp.data.message, { root: true });
            router
              .push({ name: "orders.edit", params: { id, nombre } })
              .catch(() => {});
          })
          .catch((e) => dispatch("errores", e, { root: true }))
          .finally(() => commit("setLoader", false));
      } else {
        await window.axios
          .put(`${url}/${resource.id}`, resource)
          .then((resp) => {
            commit("setResource", resp.data.resource);
            dispatch("message", resp.data.message, { root: true });
          })
          .catch((e) => dispatch("errores", e, { root: true }))
          .finally(() => commit("setLoader", false));
      }
    },
    async makePurchase({ commit, dispatch }, id) {
      commit("setLoader", true);
      await window.axios
        .get(`${url}/${id}`)
        .then((resp) => {
          commit("setResource", resp.data.resource);
          commit("setDetails", resp.data.details);
          commit("setSupplies", resp.data.supplies);
          commit("setLabels", resp.data.labels);
          commit("setPallets", resp.data.pallets);
          router
            .push({ name: "orders.purchase", params: { id } })
            .catch(() => {});
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
  },
};
