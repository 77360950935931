<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-form @submit.prevent="getSales" :disabled="loader">
        <v-row class="mt-2">
          <v-col cols="12" md="6" xl="4">
            <v-text-field
              dense
              outlined
              clearable
              label="Busqueda por PO o PU"
              v-model="paginado.search"
            />
          </v-col>
          <v-col cols="12" md="6" xl="4">
            <v-autocomplete
              dense
              outlined
              clearable
              label="Filtrar por Cliente"
              :items="paginado.customers"
              v-model="paginado.customer_id"
            />
          </v-col>
          <v-col cols="12" md="6" xl="4">
            <v-btn color="info" type="submit" :disabled="loader">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        dense
        :items="items"
        :headers="headers"
        class="elevation-1"
        mobile-breakpoint="0"
        :items-per-page="paginado.perPage"
        hide-default-footer
        :item-class="row_classes"
        :loading="loader"
      >
        <!-- prettier-ignore -->
        <template v-slot:[`item.actions`]="{ item }">
					<v-btn icon color="primary" @click="getSale(item.id)" :disabled="loader">
						<v-icon>mdi-information</v-icon>
					</v-btn>
					<v-btn icon color="error" v-if="item.actions.pdf2" :href="item.actions.pdf2" target="_blank">
						<v-icon>mdi-file-pdf</v-icon>
					</v-btn>
				</template>
        <!-- prettier-ignore -->
        <template v-slot:[`item.date`]="{ item }">{{ item.date | date }}</template>
        <!-- prettier-ignore -->
        <template v-slot:[`item.importe`]="{ item }">{{ item.importe | moneda }}</template>
        <!-- prettier-ignore -->
        <template v-slot:[`item.total`]="{ item }">{{ item.total | moneda }}</template>
        <!-- prettier-ignore -->
        <template v-slot:[`item.charges`]="{ item }">{{ item.charges | moneda }}</template>
        <!-- prettier-ignore -->
        <template v-slot:[`item.cajas`]="{ item }">{{ item.cajas | numero }}</template>
      </v-data-table>
      <Pagination :loader="loader" :items="items" :paginado="paginado" />
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  export default {
    name: "Sales",
    data: () => ({
      headers: [
        { text: "Folio", value: "folio" },
        { text: "Fecha", value: "date" },
        { text: "Cliente", value: "customer" },
        { text: "PO", value: "po" },
        { text: "PU", value: "pu" },
        { text: "Cajas", value: "cajas", align: "end" },
        { text: "Importe", value: "importe", align: "end" },
        { text: "Cargos", value: "charges", align: "end" },
        { text: "Total", value: "total", align: "end" },
        { text: "Factura", value: "factura" },
        { text: "Acciones", value: "actions", align: "end" },
      ],
    }),
    computed: {
      ...mapState({
        loader: (state) => state.Sucursales.loader,
        items: (state) => state.Sucursales.sales,
        paginado: (state) => state.Sucursales.paginadoSales,
      }),
      search() {
        return this.paginado.search;
      },
      currentPage() {
        return this.paginado.currentPage;
      },
      customer_id() {
        return this.paginado.customer_id;
      },
    },
    mounted() {
      this.getSales();
    },
    methods: {
      ...mapActions("Sucursales", ["getSales", "getSale"]),
    },
    watch: {
      currentPage() {
        this.getSales();
      },
    },
  };
</script>
