<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-expansion-panels multiple>
      <Datos />
      <Detalles />
      <Pagos v-if="userCan('fr-facturas-pagos')" />
    </v-expansion-panels>
    <v-card-actions>
      <v-btn color="primary" @click="$router.push({ name: 'facturas' })">
        regresar a facturas
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapState, mapActions, mapMutations } from "vuex";
  import Datos from "./panelFactura";
  import Detalles from "./panelDetalles";
  import Pagos from "./panelPagos";
  export default {
    components: { Datos, Detalles, Pagos },
    computed: {
      ...mapState({
        factura: (state) => state.Facturas.resource,
      }),
      cargado() {
        return Object.keys(this.factura).length > 0;
      },
    },
    mounted() {
      if (!this.cargado) {
        this.getFactura(this.$route.params.id);
      }
    },
    methods: {
      ...mapActions("Facturas", ["getFactura"]),
      cancelar() {
        this.$router.push({ name: "facturas" });
      },
    },
  };
</script>
