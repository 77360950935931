/** @format */

import router from "../../router";
const url = "FR/budgets";
const initialState = () => ({
  headers: [],
  collection: [],
  resource: {},
  loader: false,
  clientes: [],
  warehouses: [],
  domicilios: [],
  compradores: [],
  paginado: { currentPage: 1 },
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setInitialState(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
    setHeaders(state, payload) {
      state.headers = payload;
    },
    setCollection(state, payload) {
      state.collection = payload;
    },
    setResource(state, payload) {
      state.resource = payload;
    },
    setLoader(state, payload) {
      state.loader = payload;
    },
    SET_CLIENTES(state, payload) {
      state.clientes = payload;
    },
    SET_WAREHOUSES(state, payload) {
      state.warehouses = payload;
    },
    SET_DOMICILIOS(state, payload) {
      state.domicilios = payload;
    },
    setCompradores(state, payload) {
      state.compradores = payload;
    },
    setPaginado(state, payload) {
      state.paginado = payload;
    },
    setUsers(state, payload) {
      state.users = payload;
    },
  },
  actions: {
    async getAll({ commit, dispatch, state }) {
      commit("setCollection", []);
      window.axios
        .get(url, {
          params: {
            page: state.paginado.currentPage,
            search: state.paginado.search,
            cliente_id: state.paginado.cliente_id,
            estado: state.paginado.estado,
          },
        })
        .then((resp) => {
          commit("setCollection", resp.data.collection);
          commit("setHeaders", resp.data.headers);
          commit("SET_CLIENTES", resp.data.clientes);
          commit("SET_WAREHOUSES", resp.data.almacenes);
          commit("SET_DOMICILIOS", resp.data.domicilios);
          commit("setCompradores", resp.data.compradores);
          let paginado = resp.data.paginado;
          if (paginado.currentPage > paginado.lastPage)
            paginado.currentPage = 1;
          commit("setPaginado", paginado);
        })
        .catch((e) => dispatch("errores", e, { root: true }));
    },
    async detalles({ commit, dispatch }, id) {
      await window.axios
        .get(`${url}/${id}`)
        .then((resp) => {
          commit("setResource", resp.data);
          router
            .push({ name: "budget.details", params: { id } })
            .catch(() => {});
        })
        .catch((e) => dispatch("errores", e, { root: true }));
    },
    async addProducto({ commit, dispatch, state }, data) {
      let id = state.resource.budget.id;
      commit("setLoader", true);
      commit("setErrors", [], { root: true });
      await window.axios
        .post(`FR/addProduct/${id}`, data)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          commit("setResource", resp.data.resource);
          let index = state.collection.findIndex((x) => x.id == id);
          state.collection.splice(index, 1, resp.data.reemplazar);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async removeDetail({ commit, dispatch, state }, id) {
      commit("setLoader", true);
      await window.axios
        .delete(`FR/removeDetail/${id}`)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          commit("setResource", resp.data.resource);
          let index = state.collection.findIndex(
            (x) => x.id == state.resource.budget.id
          );
          state.collection.splice(index, 1, resp.data.reemplazar);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async updateBudget({ commit, dispatch, state }) {
      commit("setLoader", true);
      let budget = state.resource.budget;
      await window.axios
        .put(`${url}/${budget.id}`, budget)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          commit("setResource", resp.data.resource);
          let index = state.collection.findIndex((x) => x.id == budget.id);
          state.collection.splice(index, 1, resp.data.reemplazar);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async finalizar({ commit, dispatch, state, rootState }, id) {
      commit("setLoader", true);
      await window.axios
        .post(`FR/finalizar/${id}`)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          dispatch("getAll");
          let index = state.collection.findIndex((x) => x.id == id);
          state.collection.splice(index, 1);
          if (resp.data.venta) {
            rootState.Ventas.collection.unshift(resp.data.venta);
          }
          router.push({ name: resp.data.redirigir }).catch(() => {});
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async storeBudget({ commit, dispatch, state }, data) {
      commit("setLoader", true);
      commit("setErrors", [], { root: true });
      await window.axios
        .post(url, data)
        .then((resp) => {
          state.collection.unshift(resp.data.budget);
          dispatch("detalles", resp.data.id);
          dispatch("message", resp.data.message, { root: true });
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async pushExterno({ commit, dispatch, state }, data) {
      let id = state.resource.budget.id;
      commit("setLoader", true);
      commit("setErrors", [], { root: true });
      await window.axios
        .post(`FR/addExternos/${id}`, data)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          commit("setResource", resp.data.datos);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async removeExterno({ commit, dispatch }, id) {
      commit("setLoader", true);
      commit("setErrors", [], { root: true });
      await window.axios
        .delete(`FR/removeExterno/${id}`)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          commit("setResource", resp.data.datos);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
  },
};
