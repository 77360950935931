<!-- eslint-disable -->
<template>
	<v-card height="100%">
		<v-card-text>
			<v-expansion-panels multiple>
				<Venta />
				<Productos />
				<Externos v-if="externos.length" />
				<Facturas v-if="cargado && cargado.bol && userCan('fr-facturas')" />
				<Pagos v-if="facturas.length && userCan('fr-facturas-pagos')" />
				<v-expansion-panel v-if="cargado && cargado.nota">
					<v-expansion-panel-header color="green lighten-4">
						Nota
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-card class="mt-3">
							<v-card-text>
								<v-row>
									<v-col>
										Folio: {{ nota.folio }} <br />
										Fecha: {{ nota.created_at | dateTime }} <br />
										Usuario: {{ nota.usuario }}
									</v-col>
									<v-col>
										Observaciones: <br />
										{{ nota.observaciones }}
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-btn color="error" :href="nota.pdf" target="_blank">
											<v-icon>mdi-file-pdf</v-icon>
										</v-btn>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-expansion-panel-content>
				</v-expansion-panel>
				
			</v-expansion-panels>
		</v-card-text>
		<v-card-actions class="mt-2">
			<v-btn color="primary" @click="regresar">regresar a ventas</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Venta from './panelVenta';
import Productos from './panelProductos';
import Facturas from './panelFacturas';
import Pagos from './panelPagos';
import Externos from './panelExternos';
export default {
	name: 'Detalles',
	components: { Venta, Productos, Facturas, Pagos, Externos },
	computed: {
		...mapState({
			cargado: state => state.Ventas.resource,
			facturas: state => state.Ventas.facturas,
			externos: state => state.Ventas.externos
		}),
		nota() {
			return this.cargado.nota;
		}
	},
	mounted() {
		if (!this.cargado) {
			this.getVenta(this.$route.params.id);
		}
	},
	methods: {
		...mapActions('Ventas', ['getVenta']),
		regresar() {
			this.$router.push({ name: 'ventas' });
		}
	}
};
</script>
