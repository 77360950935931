<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-expansion-panels multiple v-if="resource">
      <Resource />
      <Compras />
    </v-expansion-panels>
    <v-card-actions>
      <v-btn color="primary" @click="$router.push({ name: 'supplies' })"
        >regresar a artículos</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import Resource from "./PanelResource.vue";
  import Compras from "./PanelCompras.vue";
  export default {
    name: "Details",
    components: { Resource, Compras },
    computed: {
      ...mapState({
        resource: (state) => state.Supplies.resource,
      }),
    },
    methods: {
      ...mapActions("Supplies", ["getResource"]),
    },
    mounted() {
      if (!this.resource) this.getResource(this.$route.params.id);
    },
  };
</script>
