<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Agregar Producto
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="6" md="3" xl="2" v-if="supplies.length">
          <v-switch v-model="articulos" label="Artículos" hide-details="auto" />
        </v-col>
        <v-col cols="6" md="3" xl="2">
          <v-switch
            v-model="propios"
            label="Aguacates FR"
            hide-details="auto"
          />
        </v-col>
        <v-col cols="6" md="3" xl="2">
          <v-switch
            v-model="externos"
            label="Aguacates Externos"
            hide-details="auto"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="articulos">
          <CardSupplies @cancelar="articulos=false" />
        </v-col>
        <v-col cols="12" v-if="propios">
          <CardFR />
        </v-col>
        <v-col cols="12" v-if="externos">
          <CardExternos />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import CardSupplies from "./CardSupplies.vue";
  import CardFR from "./CardFR.vue";
  import CardExternos from "./CardExternos.vue";
  export default {
    name: "Agregar",
    components: { CardSupplies, CardFR, CardExternos },
    data: () => ({
      articulos: false,
      propios: false,
      externos: false,
      perPage: 50,
    }),
    computed: {
      ...mapState({
        supplies: (state) => state.Budgets.resource.supplies,
        pallets: (state) => state.Budgets.resource.disponibles,
        loader: (state) => state.Budgets.loader,
      }),
      listos: function() {
        return this.pallets.filter((x) => {
          return (
            x.seleccionados &&
            x.precio &&
            x.seleccionados <= x.disponibles &&
            x.seleccionados > 0 &&
            x.precio >= 0
          );
        });
      },
      cajas: function() {
        return this.listos.reduce(function(accumulator, item) {
          return accumulator + parseFloat(item.seleccionados);
        }, 0);
      },
      palletsTotal: function() {
        let total = this.cajas / 80;
        return total;
      },
      importTotal: function() {
        return this.listos.reduce(function(accumulator, item) {
          let cantidad = parseFloat(item.seleccionados),
            precio = parseFloat(item.precio);
          return accumulator + precio * cantidad;
        }, 0);
      },
    },
    methods: {
      ...mapActions("Budgets", ["addProducto"]),
    },
  };
</script>
