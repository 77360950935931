<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card class="my-2">
    <v-card-text>
      <v-row>
        <v-col class="mt-2">
          <v-autocomplete
            clearable
            outlined
            dense
            small-chips
            label="Seleccionar Producto"
            :items="productos"
            v-model="producto"
          />
        </v-col>
      </v-row>
      <v-data-table
        mobile-breakpoint="0"
        :headers="headersExternos"
        :items="disponibles"
        class="elevation-1"
        hide-default-footer
        v-if="producto"
        :items-per-page="disponibles.length"
      >
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
        <template v-slot:[`item.seleccionados`]="{ item }">
          <v-text-field
            dense
            type="number"
            :max="item.disponibles"
            min="0"
            class="righted-input"
            v-model.number="item.seleccionados"
            hide-details="auto"
          />
        </template>
        <template v-slot:[`item.precio`]="{ item }">
          <v-text-field
            dense
            type="number"
            step="0.01"
            min="0"
            class="righted-input"
            v-model.number="item.precio"
            hide-details="auto"
          />
        </template>
        <template v-slot:[`item.fecha_minima`]="{ item }">
          {{ item.fecha_minima | date }}
        </template>
        <template v-slot:[`item.precio_compra`]="{ item }">
          {{ item.precio_compra | moneda }}
        </template>
      </v-data-table>
      <v-row class="mt-3">
        <v-col cols="12" v-if="listos.length">
          <v-card class="mt-3">
            <v-card-title>Aguacates Externos seleccionados</v-card-title>
            <v-card-text>
              <v-data-table
                dense
                hide-default-footer
                :headers="headerListosExternos"
                :items="listos"
                class="elevation-1"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn icon color="red" @click="eliminarExterno(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <template v-slot:[`item.precio`]="{ item }">
                  {{ item.precio | moneda }}
                </template>
                <template v-slot:[`item.importe`]="{ item }">
                  {{ importe(item) | moneda }}
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-text>
              Total de Pallets a agregar: {{ palletsTotal.toFixed(2) }} <br />
              Importe: {{ importTotal | moneda }}
            </v-card-text>
            <v-card-actions>
              <v-btn color="success" @click="addExternos" :disabled="loader"
                >Agregar Externos</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

  </v-card>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  export default {
    name: "CardExternos",
    data: () => ({
      producto: undefined,
      headersExternos: [
        { text: "Compra", value: "compra", hide: "smAndDown" },
        { text: "Pallet", value: "pallet_id" },
        { text: "Fecha Corte", value: "fecha" },
        { text: "Disponibles", value: "disponibles", align: "end" },
        { text: "Cajas a Vender", value: "seleccionados", align: "end" },
        { text: "Precio Compra", value: "precio_compra", align: "end" },
        { text: "Precio", value: "precio", align: "end" },
      ],
      headerListosExternos: [
        { text: "Producto", value: "etiqueta" },
        { text: "Pallet", value: "pallet_id" },
        { text: "Cantidad", value: "seleccionados", align: "end" },
        { text: "Precio", value: "precio", align: "end" },
        { text: "Importe", value: "importe", align: "end" },
        { text: "", value: "actions", align: "end" },
      ],
    }),
    computed: {
      ...mapState({
        externos: (state) => state.Budgets.resource.externos,
        loader: (state) => state.Budgets.loader,
      }),
      productos() {
        return [...new Set(this.externos.map((x) => x.etiqueta))].sort();
      },
      disponibles() {
        return this.externos.filter((x) => x.etiqueta == this.producto);
      },
      listos() {
        return this.externos.filter((x) => {
          return (
            x.seleccionados &&
            x.precio &&
            x.seleccionados <= x.disponibles &&
            x.seleccionados > 0 &&
            x.precio >= 0
          );
        });
      },
      cajas: function() {
        return this.listos.reduce(function(accumulator, item) {
          return accumulator + parseFloat(item.seleccionados);
        }, 0);
      },
      palletsTotal: function() {
        let total = this.cajas / 80;
        return total;
      },
      importTotal: function() {
        return this.listos.reduce(function(accumulator, item) {
          let cantidad = parseFloat(item.seleccionados),
            precio = parseFloat(item.precio);
          return accumulator + precio * cantidad;
        }, 0);
      },
    },
    methods: {
      ...mapActions("Budgets", ["pushExterno"]),
      eliminarExterno(item) {
        item.seleccionados = undefined;
        item.precio = undefined;
      },
      addExternos() {
        let data = { externos: this.listos };
        this.pushExterno(data);
      },
      importe(item) {
        let cantidad = parseFloat(item.seleccionados),
          precio = parseFloat(item.precio);
        return cantidad * precio;
      },
    },
  };
</script>
