<!-- eslint-disable -->
<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Pagos al Proveedor
		</v-expansion-panel-header>
		<Nuevo v-if="nuevo" :metodos="metodos" @cancelar="nuevo = false" />
		<v-expansion-panel-content v-else>
			<v-row class="mt-3">
				<v-col>
					<v-btn
						small
						color="success"
						v-if="puedeNuevo"
						@click="crear"
						:disabled="loader"
						class="mb-2"
					>
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</v-col>
			</v-row>
			<v-data-table
				dense
				hide-default-footer
				:headers="headers"
				:items="items"
				class="elevation-1 mt-4"
				mobile-breakpoint="0"
				:items-per-page="items.length"
			>
				<template v-slot:[`item.fecha`]="{ item }">
					{{ item.fecha | date }}
				</template>
				<template v-slot:[`item.importe`]="{ item }">
					{{ item.importe | moneda }}
				</template>
				<template v-slot:[`item.archivo`]="{ item }">
					{{ item.archivo }}
					<v-btn icon color="success" :href="item.href" target="_blank" v-if="item.href">
						<v-icon>mdi-cloud-download</v-icon>
					</v-btn>
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-btn icon color="error" :disabled="loader" @click="eliminar(item)">
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</template>
			</v-data-table>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
import Nuevo from './Pago';
export default {
	name: 'Pagos',
	components: { Nuevo },
	data: () => ({
		nuevo: false,
		headers: [
			{ text: 'Folio', value: 'folio' },
			{ text: 'Fecha', value: 'fecha' },
			{ text: 'Forma de Pago', value: 'metodo_pago' },
			{ text: 'Observaciones', value: 'observaciones' },
			{ text: 'Archivo', value: 'archivo' },
			{ text: 'Usuario', value: 'user' },
			{ text: 'Importe', value: 'importe', align: 'end' },
			{ text: 'Acciones', value: 'actions', align: 'end' }
		],
		metodos: undefined
	}),
	computed: {
		...mapState({
			items: state => state.Compras.pagos,
			details: state => state.Compras.details,
			id: state => state.Compras.resource.id,
			loader: state => state.Compras.loader
		}),
		puedeNuevo() {
			return (
				window._.sumBy(this.items, 'importe') <
				window._.sumBy(this.details, 'importe')
			);
		},
		importeCompra() {
			return window._.sumBy(this.details, 'importe');
		}
	},
	methods: {
		crear() {
			this.$store.commit('Compras/setLoader', true);
			window.axios.get(`FR/compras/pagos/nuevo/${this.id}`).then(resp => {
				this.metodos = resp.data.metodos;
				this.nuevo = true;
			});
		},
		eliminar(item) {
			//compras/pagos/eliminar/{pago}
			let pregunta = `¿Deseas eliminar el pago ${item.folio}?`;
			window
				.swal({
					title: pregunta,
					icon: 'warning',
					buttons: ['Cancelar', 'Confirmar'],
					dangerMode: true
				})
				.then(confirmado => {
					if (confirmado) {
						this.$store.commit('Compras/setLoader', true);
						window.axios
							.delete(`FR/compras/pagos/eliminar/${item.id}`)
							.then(resp => {
								window.swal(resp.data.message, {
									icon: 'success',
									timer: 1500
								});
								this.$store.commit('Compras/setPagos', resp.data.pagos);
							})
							.finally(() => this.$store.commit('Compras/setLoader', false));
					}
				});
		}
	}
};
</script>
