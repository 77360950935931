<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Quality Control
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="6" sm="3" md="auto" v-for="item in datos" :key="item.name">
          <v-switch
            v-model="item.visible"
            :label="item.label"
            hide-details="auto"
          />
        </v-col>
      </v-row>
      <card-muestras v-if="muestras.visible" />
      <card-summary v-if="summary.visible" />
      <card-photos v-if="photos.visible"/>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import CardMuestras from "./CardMuestras.vue";
  import CardSummary from "./CardSummary.vue";
  import CardPhotos from './CardPhotos.vue'
  export default {
    name: "CQ",
    components: { CardMuestras, CardSummary,CardPhotos },
    data: () => ({
      datos: [
        { name: "muestras", visible: false, label: "Samples" },
        { name: "summary", visible: false, label: "General Data" },
        { name: "photos", visible: false, label: "Photos" },
      ],
    }),
    computed: {
      muestras() {
        return this.datos.find((x) => x.name == "muestras");
      },
      summary() {
        return this.datos.find((x) => x.name == "summary");
      },
      photos() {
        return this.datos.find((x) => x.name == "photos");
      },
    },
  };
</script>
