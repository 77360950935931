<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-dialog persistent v-model="dialog" max-width="600">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Reporte de Facturas en Excel
      </v-card-title>
      <v-form :disabled="loader" @submit.prevent="download">
        <v-card-text class="mt-2">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                outlined
                clearable
                label="Fecha Inicial"
                v-model="resource.inicio"
                :error-messages="errors.inicio"
                type="date"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                outlined
                clearable
                label="Fecha Final"
                v-model="resource.fin"
                :error-messages="errors.fin"
                type="date"
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
              dense
              outlined
              clearable
              label="Filtrar por Cliente"
              :items="clientes"
              v-model="resource.cliente_id"
            />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
              dense
              outlined
              clearable
              label="Filtrar por Método de Pago"
              :items="metodos"
              v-model="resource.metodo"
            />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" type="submit" :disabled="loader">
            descargar
          </v-btn>
          <v-btn color="error" @click="$emit('cancelar')" :disabled="loader">
            cancelar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  import fileDownload from "js-file-download";
  export default {
    name: "Excel",
    props: {
      dialog: Boolean,
      clientes: {
        type: Array,
        required: true,
      },
      metodos: {
        type: Array,
        required: true,
      },
    },
    data: () => ({
      resource: {
        inicio: undefined,
        fin: undefined,
      },
      loader: false,
      filtros: ["Método de Pago", "Cliente"],
      sucursales: ["Texas", "Califorrnia", "Todos"],
    }),
    computed: {
      ...mapState({
        errors: (state) => state.errors,
      }),
    },
    methods: {
      ...mapMutations(["setErrors"]),
      ...mapActions(["errores", "message"]),
      download() {
        this.loader = true;
        let url = "FR/downloadExcel/excel";
        this.setErrors();
        window.axios
          .post(url, this.resource, {
            responseType: "blob",
          })
          .then((resp) => {
            let filename = "Invoices Report.xlsx";
            fileDownload(resp.data, filename);
            this.$emit("cancelar");
            this.message("Reporte descargado");
          })
          .catch((e) => this.$store.dispatch('errores',e))
          .finally(() => (this.loader = false));
      },
    },
    mounted() {
      this.setErrors();
    },
  };
</script>
