<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Producto Solicitado
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card class="my-2">
        <v-card-text>
          <v-row class="mb-5">
            <v-col
              cols="6"
              sm="3"
              xl="2"
              v-for="item in botones"
              :key="item.name"
            >
              <v-switch
                v-model="item.visible"
                :label="item.label"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <Avocados v-if="aguacates && aguacates.visible" />
          <FormSupply v-if="otros && otros.visible" />
          <v-data-table
            dense
            :headers="headers"
            :items="items"
            class="elevation-1"
            mobile-breakpoint="0"
            hide-default-footer
          >
            <template v-slot:[`item.importe`]="{ item }">
              {{ item.importe | moneda }}
            </template>
            <template v-slot:[`item.precio_unitario`]="{ item }">
              {{ item.precio_unitario | moneda }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                icon
                color="red"
                @click="eliminar(item)"
                :disabled="loader"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-row class="mt-3">
            <v-col cols="12" md="6"> Total de Cajas: {{ cajas }} </v-col>
            <v-col cols="12" md="6"> Importe {{ total | moneda }} </v-col>
          </v-row>
        </v-card-text>
        
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import { mapState } from "vuex";
  import FormSupply from "./FormSupplies.vue";
  import Avocados from "./FormAvocados.vue";
  export default {
    name: "Productos",
    components: { FormSupply, Avocados },
    data: () => ({
      headers: [
        { text: "Producto", value: "producto" },
        { text: "Cantidad", value: "cantidad", align: "end" },
        { text: "Precio Unitario", value: "precio_unitario", align: "end" },
        { text: "Importe", value: "importe", align: "end" },
        { text: "Acciones", value: "actions", align: "end" },
      ],
      botones: [
        { name: "aguacates", visible: false, label: "Aguacates" },
        { name: "articulos", visible: false, label: "Artículos" },
      ],
    }),
    computed: {
      ...mapState({
        details: (state) => state.Orders.details,
        calibres: (state) => state.Orders.categorias,
        loader: (state) => state.Orders.loader,
        errors: (state) => state.errors,
        resource: (state) => state.Orders.resource,
        supplies: (state) => state.Orders.supplies,
      }),
      organicos() {
        let avocados = this.details.filter((x) =>
          x.url.includes("removeDetail")
        );
        return avocados.filter((x) => x.producto.includes("ORGANIC"));
      },
      convencionales() {
        let avocados = this.details.filter((x) =>
          x.url.includes("removeDetail")
        );
        return avocados.filter((x) => x.producto.includes("CONVENTIONAL"));
      },
      items() {
        return this.organicos.concat(this.convencionales, this.articulos);
      },
      total() {
        return window._.sumBy(this.items, "importe");
      },
      cajas() {
        return window._.sumBy(this.items, "cantidad");
      },
      aguacates() {
        return this.botones.find((x) => x.name == "aguacates");
      },
      otros() {
        return this.botones.find((x) => x.name == "articulos");
      },
      articulos() {
        return this.details.filter((x) => x.supply_id);
      },
    },
    beforeMount() {
      this.$store.commit("setErrors", []);
    },
    methods: {
      eliminar(item) {
        let pregunta = `¿Deseas eliminar el producto ${item.producto} de la orden?`,
          text = "Esta acción no puede deshacerse";
        this.$swal({
          title: pregunta,
          text,
          icon: "question",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.confirmado(item);
          }
        });
      },
      confirmado(item) {
        this.$store.commit("Orders/setLoader", true);
        window.axios
          .delete(`${item.url}`)
          .then((resp) => {
            this.$store.commit("Orders/setDetails", resp.data.details);
            this.$store.dispatch("Orders/getAll");
            this.$store.dispatch("message", resp.data.message);
          })
          .catch((e) => this.$store.dispatch("errores", e))
          .finally(() => this.$store.commit("Orders/setLoader", false));
      },
      getPallets() {
        this.$store.dispatch("Orders/makePurchase", this.$route.params.id);
      },
    },
  };
</script>
