var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',[_c('v-form',{attrs:{"disabled":_vm.loader},on:{"submit":function($event){$event.preventDefault();return _vm.getAll($event)}}},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Search","append-icon":"mdi-magnify"},model:{value:(_vm.paginado.search),callback:function ($$v) {_vm.$set(_vm.paginado, "search", $$v)},expression:"paginado.search"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"info","type":"submit","disabled":_vm.loader}},[_c('v-icon',[_vm._v("mdi-magnify")])],1),(_vm.userCan('schedules-create'))?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"success","disabled":_vm.loader},on:{"click":_vm.nueva}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","items-per-page":_vm.paginado.perPage},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.date))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.total))+" ")]}},{key:"item.fees",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"red--text font-weight-bold"},[_vm._v(_vm._s(_vm._f("moneda")(item.fees)))])]}},{key:"item.reserve",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"red--text font-weight-bold"},[_vm._v(_vm._s(_vm._f("moneda")(item.reserve)))])]}},{key:"item.deducted",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"red--text font-weight-bold"},[_vm._v(_vm._s(_vm._f("moneda")(item.deducted)))])]}},{key:"item.paid",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("moneda")(item.paid)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('Actions',{attrs:{"item":item,"loader":_vm.loader},on:{"eliminar":function($event){return _vm.eliminar(item)}}},[_c('v-btn',{attrs:{"icon":"","color":"secondary"},on:{"click":function($event){return _vm.showResource(item.id)}}},[_c('v-icon',[_vm._v("mdi-view-list")])],1)],1)]}}],null,true)}),_c('Pagination',{attrs:{"items":_vm.items,"loader":_vm.loader,"paginado":_vm.paginado}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }