import router from '../../router';
const url = 'tickets';
const initialState = () => ({
	headers: [],
	collection: [],
	resource: {},
	loader: false,
	paginado: { currentPage: 1 }
});
export default {
	namespaced: true,
	state: initialState(),
	mutations: {
		setInitialState(state) {
			const newState = initialState();
			Object.keys(newState).forEach(key => {
				state[key] = newState[key];
			});
		},
		setHeaders(state, payload) {
			state.headers = payload;
		},
		setCollection(state, payload) {
			state.collection = payload;
		},
		setResource(state, payload) {
			state.resource = payload;
		},
		setLoader(state, payload = true) {
			state.loader = payload;
		},
		setPaginado(state, payload) {
			let paginado = payload;
			if (paginado.currentPage > paginado.lastPage) paginado.currentPage = 1;
			state.paginado = paginado;
		}
	},
	actions: {
		async getAll({ commit, dispatch, state }) {
			commit('setLoader');
			commit('setCollection', []);
			window.axios
				.get(url, {
					params: {
						page: state.paginado.currentPage,
						user_id: state.paginado.user_id,
						status: state.paginado.status
					}
				})
				.then(resp => {
					commit('setCollection', resp.data.collection);
					commit('setHeaders', resp.data.headers);
					commit('setPaginado', resp.data.paginado);
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		nuevo({ commit }) {
			commit('setErrors', [], { root: true });
			commit('setResource', {});
			router.push({ name: 'reportes.nuevo' }).catch(() => {});
		},
		async guardarResource({ commit, dispatch, state }) {
			commit('setLoader');
			commit('setErrors', [], { root: true });
			await window.axios
				.post(url, state.resource)
				.then(resp => {
					dispatch('getAll');
					router.push({ name: 'reportes' });
					window.swal(resp.data.message, { icon: 'success', timer: 1500 });
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async historial({ commit, dispatch }, id) {
			commit('setLoader');
			await window.axios
				.get(`${url}/${id}`)
				.then(resp => {
					commit('setResource', resp.data);
					router
						.push({ name: 'reportes.historial', params: { id } })
						.catch(() => {});
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async editar({ commit, dispatch }, id) {
			commit('setLoader');
			await window.axios
				.get(`${url}/${id}`)
				.then(resp => {
					commit('setResource', resp.data);
					router
						.push({ name: 'reportes.editar', params: { id } })
						.catch(() => {});
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async actualizar({ commit, dispatch, state }) {
			commit('setLoader');
			commit('setErrors', [], { root: true });
			await window.axios
				.put(`${url}/${state.resource.id}`, state.resource)
				.then(resp => {
					dispatch('getAll');
					router.push({ name: 'reportes' });
					window.swal(resp.data.message, { icon: 'success', timer: 1500 });
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async eliminar({ commit, dispatch }, id) {
			commit('setLoader');
			commit('setErrors', [], { root: true });
			await window.axios
				.delete(`${url}/${id}`)
				.then(resp => {
					dispatch('getAll');
					router.push({ name: 'reportes' });
					window.swal(resp.data.message, { icon: 'success', timer: 1500 });
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		}
	}
};
