import router from '../../router';
const initialState = () => ({
	collection: undefined,
	resource: undefined,
	loader: false,
	headers: [],
	detalles: undefined,
	paginado: { currentPage: 1 }
});
export default {
	namespaced: true,
	state: initialState(),
	mutations: {
		setInitialState(state) {
			const newState = initialState();
			Object.keys(newState).forEach(key => {
				state[key] = newState[key];
			});
		},

		setCollection(state, payload) {
			state.collection = payload;
		},
		setResource(state, payload) {
			state.resource = payload;
		},
		setLoader(state, payload = true) {
			state.loader = payload;
		},
		setHeaders(state, payload) {
			state.headers = payload;
		},
		setDetalles(state, payload) {
			state.detalles = payload;
		},
		setPaginado(state, payload) {
			let paginado = payload;
			if (paginado.currentPage > paginado.lastPage) paginado.currentPage = 1;
			state.paginado = paginado;
		}
	},
	actions: {
		async getAll({ commit, dispatch, state }) {
			let url = 'FR/pagos';
			commit('setCollection', []);
			commit('setLoader');
			window.axios
				.get(url, {
					params: {
						page: state.paginado.currentPage,
						search: state.paginado.search,
						cliente_id: state.paginado.cliente_id
					}
				})
				.then(resp => {
					commit('setCollection', resp.data.collection);
					commit('setHeaders', resp.data.headers);
					commit('setPaginado', resp.data.paginado);
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async getPago({ commit, dispatch }, id) {
			await window.axios
				.get(`/FR/pagos/${id}`)
				.then(resp => {
					commit('setResource', resp.data.resource);
					commit('setDetalles', resp.data.detalles);
					router.push({ name: 'pago.detalle', params: { id } }).catch(() => {});
				})
				.catch(e => dispatch('errores', e, { root: true }));
		}
	}
};
