<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Gastos: <span class="mover">{{ importe | moneda }}</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content v-if="!gasto">
      <v-row>
        <v-col cols="12" md="4">
          <v-btn small color="success" class="mt-5" @click="nuevo">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :items="items"
        :headers="headers"
        class="elevation-1"
        :search.sync="search"
        mobile-breakpoint="0"
      >
        <template v-slot:[`item.archivo`]="{ item }">
          <div v-if="item.archivo.nombre">
            {{ item.archivo.nombre }}
            <v-btn
              icon
              color="success"
              :href="item.archivo.link"
              target="_blank"
            >
              <v-icon>mdi-cloud-download</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="success" @click="editar(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon color="red" @click="eliminar(item)" :disabled="isLoading">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
      </v-data-table>
    </v-expansion-panel-content>
    <v-expansion-panel-content v-else>
      <v-card class="mt-2">
        <v-card-title primary-title>
          {{
            gasto.id
              ? `Editando Gasto ${$options.filters.folio(gasto.id)}`
              : "Nuevo Gasto"
          }}
        </v-card-title>
        <v-form @submit.prevent="submitGasto">
          <v-card-text>
            <v-row>
              <v-col cols="12" xl="2">
                <v-text-field
                  type="date"
                  label="Fecha del Gasto"
                  outlined
                  dense
                  v-model="gasto.fecha"
                  :error-messages="errors.fecha"
                />
              </v-col>
              <v-col cols="12" xl="6">
                <v-file-input
                  v-model="file"
                  dense
                  outlined
                  placeholder="Seleccionar archivo"
                  prepend-icon="mdi-attachment"
                  label="Archivo del gasto"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xl="8">
                <v-text-field
                  type="text"
                  label="Concepto del Gasto"
                  outlined
                  dense
                  v-model="gasto.concepto"
                  :error-messages="errors.concepto"
                />
              </v-col>
              <v-col cols="12" xl="2">
                <v-text-field
                  type="number"
                  step="0.01"
                  label="Importe"
                  outlined
                  dense
                  v-model.number="gasto.importe"
                  :error-messages="errors.importe"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xl="10">
                <v-textarea
                  outlined
                  clearable
                  label="Observaciones"
                  v-model="gasto.observaciones"
                  :error-messages="errors.observaciones"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              type="submit"
              color="success"
              small
              class="mr-3"
              :disabled="isLoading"
            >
              {{ gasto.id ? "actualizar" : "guardar" }}
            </v-btn>
            <v-btn color="error" small @click="cancelar">
              cancelar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
  import { mapActions, mapState, mapMutations } from "vuex";
  export default {
    name: "Gastos",
    data: () => ({
      headers: [
        { text: "Folio", value: "folio" },
        { text: "Fecha", value: "fecha" },
        { text: "Concepto", value: "concepto" },
        { text: "Observaciones", value: "observaciones" },
        { text: "Archivo", value: "archivo" },
        { text: "Importe", value: "importe", align: "end" },
        { text: "Acciones", value: "actions", align: "end" },
      ],
      search: undefined,
      gasto: undefined,
      file: undefined,
    }),
    computed: {
      ...mapState({
        errors: (state) => state.errors,
        isLoading: (state) => state.Embarques.loader,
        items: (state) => state.Embarques.gastos,
      }),
      importe() {
        return _.sumBy(this.items, "importe");
      },
    },
    watch: {
      items: function() {
        this.cancelar();
      },
    },
    methods: {
      ...mapActions("Embarques", ["addGasto", "deleteGasto", "updateGasto"]),
      ...mapMutations(["setErrors"]),
      nuevo() {
        this.gasto = {};
      },
      cancelar() {
        this.gasto = this.file = undefined;
        this.setErrors([]);
      },
      eliminar(item) {
        let pregunta = `¿Deseas eliminar el concepto ${item.concepto}?`;
        window
          .swal({
            title: pregunta,
            icon: "warning",
            buttons: ["Cancelar", "Confirmar"],
            dangerMode: true,
          })
          .then((confirmado) => {
            if (confirmado) {
              this.deleteGasto(item.id);
            }
          });
      },
      submitGasto() {
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("concepto", this.gasto.concepto);
        formData.append("importe", this.gasto.importe);
        if (this.gasto.observaciones)
          formData.append("observaciones", this.gasto.observaciones);
        formData.append("fecha", this.gasto.fecha);
        if (!this.gasto.id) {
          this.addGasto(formData);
        } else {
          let data = { id: this.gasto.id, data: formData };
          this.updateGasto(data);
        }
      },
      editar(item) {
        this.file = undefined;

        this.gasto = {
          id: item.id,
          concepto: item.concepto,
          importe: item.importe,
          fecha: item.fecha,
        };
        if (item.observaciones) this.gasto.observaciones = item.observaciones;
      },
    },
  };
</script>
