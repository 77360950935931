<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-form @submit.prevent="getAll" :disabled="loader">
        <v-row class="mt-3">
          <v-col cols="12" md="6" xl="4">
            <v-text-field
              dense
              outlined
              label="Buscar"
              v-model="paginado.search"
            />
          </v-col>
          <v-col>
            <v-btn color="info" type="submit" :disabled="loader">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              class="ml-2"
              color="success"
              v-if="userCan('credits-create')"
              :disabled="loader"
              @click="nuevo"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        hide-default-footer
        class="elevation-1"
        :loading="loader"
      >
        <template v-slot:[`item.date`]="{ item }">
          {{ item.date | date }}
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          {{ item.amount | moneda }}
        </template>
        <template v-slot:[`item.applied`]="{ item }">
          {{ item.applied | moneda }}
        </template>
        <template v-slot:[`item.saldo`]="{ item }">
          {{ item.saldo | moneda }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="primary"
            v-if="item.actions.observations"
            @click="information(item)"
          >
            <v-icon>mdi-information</v-icon>
          </v-btn>
          <v-btn icon color="success" v-if="item.actions.info">
            <v-icon>mdi-clipboard-list</v-icon>
          </v-btn>
          <v-btn
            icon
            color="red"
            :disabled="loader"
            v-if="item.actions.delete && userCan('credits-delete')"
            @click="eliminar(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <Pagination :loader="loader" :items="items" :paginado="paginado" />
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  export default {
    name: "Index",
    computed: {
      ...mapState({
        items: (state) => state.Credits.collection,
        loader: (state) => state.Credits.loader,
        paginado: (state) => state.Credits.paginado,
        headers: (state) => state.Credits.headers,
      }),
    },
    methods: {
      ...mapActions("Credits", ["getAll", "nuevo", "disableResource"]),
      information(item) {
        this.$swal.fire({
          title: "Observaciones",
          html: item.observations,
          confirmButtonText: "Ok",
        });
      },
      eliminar(item) {
        let fecha = this.$options.filters.date(item.date),
          importe = this.$options.filters.moneda(item.amount);
        this.$swal({
          title: `¿ Eliminar el pago de ${item.customer} ${fecha} ?`,
          text: `Importe a eliminar ${importe} `,
          icon: "question",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.disableResource(item.id);
          }
        });
      },
    },
    mounted() {
      this.getAll();
    },
  };
</script>
