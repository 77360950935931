import Index from '../views/sucursales/Index.vue';
import Formulario from '../views/sucursales/Form.vue';
import Budgets from '../views/sucursales/Budgets.vue';
import Budget from '../views/sucursales/Budget.vue';
import Sales from '../views/sucursales/Sales.vue';
import Sale from '../views/sucursales/Sale.vue';
const routes = [
	{
		path: '/Sucursales',
		name: 'sucursales',
		component: Index,
		meta: {
			name: 'Control de Sucursales'
		}
	},
	{
		path: '/Sucursales/nueva',
		name: 'sucursales.create',
		component: Formulario,
		meta: {
			name: 'Nueva Sucursal'
		}
	},
	{
		path: '/Sucursales/editar/:id',
		name: 'sucursales.edit',
		component: Formulario,
		meta: {
			name: 'Editando Sucursal'
		}
	},
	{
		path: '/California/presupuestos',
		name: 'california.budgets',
		component: Budgets,
		meta: {
			name: 'Presupuestos California'
		}
	},
	{
		path: '/California/presupuestos/detalles/:id',
		name: 'california.budgets.show',
		component: Budget,
		meta: {
			name: 'Detalle Presupuesto'
		}
	},
	{
		path: '/California/ventas',
		name: 'california.sales',
		component: Sales,
		meta: {
			name: 'Ventas California'
		}
	},
	{
		path: '/California/ventas/detalle/:id',
		name: 'california.sales.show',
		component: Sale,
		meta: {
			name: 'Detalle Venta California'
		}
	}
];
export default routes;
