<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Domicilios del Cliente
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card>
        <v-card-text v-if="action" class="mt-3">
          <v-form @submit.prevent="enviar">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  label="Nombre"
                  v-model="domicilio.name"
                  :error-messages="errors.name"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  dense
                  type="text"
                  label="Domicilio"
                  v-model="domicilio.address"
                  :error-messages="errors.address"
                />
              </v-col>
              <v-col cols="12" md="6" xl="4">
                <v-btn color="primary" :disabled="sending" type="submit">{{
                  domicilio.id ? "actualizar" : "guardar"
                }}</v-btn>
                <v-btn color="error" @click="cancelar" class="ml-3"
                  >cancelar</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-text v-else class="mt-3">
          <v-row>
            <v-col cols="12" md="4">
              <v-btn small color="success" class="mt-5" @click="nuevo">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" xl="4" offset-xl="4">
              <v-container>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Busqueda"
                  single-line
                  hide-details
                />
              </v-container>
            </v-col>
          </v-row>
          <v-data-table
            dense
            hide-default-footer
            :search.sync="search"
            :headers="headers"
            :items="domicilios"
            class="elevation-1"
            mobile-breakpoint="0"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon color="success" @click="editar(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon color="red" @click="eliminar(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import { mapState, mapMutations } from "vuex";
  export default {
    name: "Domicilios",
    data: () => ({
      headers: [
        { text: "Nombre", value: "name" },
        { text: "Domicilio", value: "address" },
        { text: "Acciones", value: "actions", align: "end" },
      ],
      action: false,
      domicilio: undefined,
      search: undefined,
      errors: [],
      sending: false,
    }),
    computed: {
      ...mapState({
        domicilios: (state) => state.Clientes.domicilios,
        cliente_id: (state) => state.Clientes.resource.id,
      }),
    },
    methods: {
      ...mapMutations("Clientes", ["SET_DOMICILIOS"]),
      nuevo() {
        this.action = true;
        this.domicilio = { cliente_id: this.cliente_id };
      },
      editar(item) {
        this.action = true;
        this.domicilio = item;
      },
      cancelar() {
        this.action = false;
        this.domicilio = undefined;
        this.errors = [];
      },
      enviar() {
        this.errors = [];
        this.sending = true;
        window.axios
          .post("FR/domicilio", this.domicilio)
          .then((resp) => {
            this.action = false;
            this.domicilio = undefined;
            this.errors = [];
            this.SET_DOMICILIOS(resp.data.domicilios);
            window.swal(resp.data.message, { icon: "success", timer: 1500 });
          })
          .catch((e) => {
            if (e.response.data.errors) {
              this.errors = e.response.data.errors;
            } else {
              window.swal("Error al procesar", { icon: "error", timer: 1500 });
            }
          })
          .finally(() => (this.sending = false));
      },
      eliminar(item) {
        let pregunta = `¿Deseas eliminar el domicilio ${item.name}?`;
        window
          .swal({
            title: pregunta,
            icon: "warning",
            buttons: ["Cancelar", "Confirmar"],
            dangerMode: true,
          })
          .then((confirmado) => {
            if (confirmado) {
              window.axios
                .delete(`FR/eliminar-domicilio/${item.id}`)
                .then((resp) => {
                  this.SET_DOMICILIOS(resp.data.domicilios);
                  window.swal(resp.data.message, {
                    icon: "success",
                    timer: 1500,
                  });
                })
                .catch((e) => {
                  if (e.response.data.errors) {
                    this.errors = e.response.data.errors;
                  } else {
                    window.swal("Error al procesar", {
                      icon: "error",
                      timer: 1500,
                    });
                  }
                });
            }
          });
      },
    },
  };
</script>
