<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-form :disabled="loader" @submit.prevent="enviar">
    <v-card-text>
      <v-row class="mt-2">
        <v-col cols="12" md="8">
          <v-text-field
            dense
            outlined
            clearable
            label="Product"
            v-model="resource.product"
            v-if="resource.id"
            disabled
          />
          <v-autocomplete
            v-else
            dense
            outlined
            clearable
            label="Product"
            v-model="resource.product"
            :error-messages="errors.product"
            :items="posibles"
            item-text="product"
            item-value="product"
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            dense
            outlined
            clearable
            label="Pallet id"
            v-model="resource.pallet_id"
            :error-messages="errors.pallet_id"
          />
        </v-col>
        <template v-if="resource.product">
          <v-col cols="12" md="6">
            <h3 class="mb-5">QUALITY DEFECTS</h3>
            <quality :resource="resource" class="mb-4" />
          </v-col>
          <v-col cols="12" md="6">
            <h3 class="mb-5">CONDITION DEFECTS</h3>
            <condition :resource="resource" class="mb-4" />
          </v-col>
          <v-col cols="12">
            <v-textarea
              outlined
              label="Observations"
              v-model="resource.observations"
            />
          </v-col>
          <v-col cols="12" md="auto">
            <v-text-field
              type="number"
              dense
              outlined
              clearable
              label="Firmness"
              v-model="resource.firmness"
              :error-messages="errors.firmness"
              hint="Firmeza"
            />
          </v-col>
        </template>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        v-if="resource.product"
        color="success"
        type="submit"
        :disabled="loader"
      >
        {{ textBtn }}
      </v-btn>
      <v-btn color="error" @click="$emit('cerrar')" :disabled="loader">
        cancel
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  import Quality from "./../embarques/QualityFields.vue";
  import Condition from "./../embarques/ConditionFields.vue";
  export default {
    name: "AddSample",
    components: { Quality, Condition },
    props: {
      resource: {
        type: Object,
        required: true,
      },
    },
    emits: ["cerrar"],
    computed: {
      ...mapState({
        control: (state) => state.Qc.resource,
        loader: (state) => state.loader,
        errors: (state) => state.errors,
      }),
      posibles() {
        return this.control.products.filter((x) => x.samples > x.taken);
      },
      textBtn() {
        return this.resource.id ? "update" : "save";
      },
      desabilitar() {
        return this.resource.id ? true : false;
      },
    },
    methods: {
      ...mapMutations(["setLoader", "setErrors"]),
      ...mapActions(["errores", "message"]),
      enviar() {
        this.setErrors();
        this.setLoader();
        if (!this.resource.id) {
          let url = `FR/quality_controls/addSample/${this.$route.params.id}`;
          window.axios
            .post(url, this.resource)
            .then((resp) => {
              this.message(resp.data.message);
              this.control.samples = resp.data.samples;
              this.control.products = resp.data.products;
              this.$emit("cerrar");
            })
            .catch((e) => this.errores(e))
            .finally(() => this.setLoader(false));
        } else {
          let url = `FR/quality_controls/updateSample/${this.resource.id}`;
          window.axios
            .put(url, this.resource)
            .then((resp) => {
              this.message(resp.data.message);
              this.control.samples = resp.data.samples;
              this.control.products = resp.data.products;
              this.$emit("cerrar");
            })
            .catch((e) => this.errores(e))
            .finally(() => this.setLoader(false));
        }
      },
    },
  };
</script>
