<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card-text>
    <v-row>
      <v-col cols="12" md="4"> </v-col>
      <v-col cols="12" xl="4" offset-xl="4">
        <v-container>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Busqueda"
            single-line
            hide-details
          />
        </v-container>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :items="items"
      :headers="headers"
      class="elevation-1"
      :search.sync="search"
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.id`]="{ item }">
        {{ item.id | folio }}
      </template>
      <template v-slot:[`item.fecha`]="{ item }">
        {{ item.fecha | date }}
      </template>
      <template v-slot:[`item.importe`]="{ item }">
        {{ item.importe | moneda }}
      </template>
      <template v-slot:[`item.cajas`]="{ item }">
        {{ item.cajas | numero }}
      </template>
    </v-data-table>
  </v-card-text>
</template>

<script>
  export default {
    name: "Pagos",
    props: {
      items: {
        type: Array,
        default() {
          return [];
        },
      },
    },
    data: () => ({
      headers: [
        { text: "Folio", value: "folio" },
        { text: "Fecha", value: "fecha" },
        { text: "PU", value: "pu" },
        { text: "Forma Pago", value: "metodo" },
        { text: "Importe", value: "importe", align: "end" },
      ],
      search: undefined,
    }),
  };
</script>
