import router from '../../router';
const url = 'abilities';
const initialState = () => ({
	loader: false,
	collection: [],
	paginado: { currentPage: 1 },
	headers: [],
	sistemas: [],
	modulos: [],
	all: undefined
});
export default {
	namespaced: true,
	state: initialState(),
	mutations: {
		setInitialState(state) {
			const newState = initialState();
			Object.keys(newState).forEach(key => {
				state[key] = newState[key];
			});
		},
		setLoader(state, payload = true) {
			state.loader = payload;
		},
		setCollection(state, payload) {
			state.collection = payload;
		},
		setPaginado(state, payload) {
			state.paginado = payload;
		},
		setHeaders(state, payload) {
			state.headers = payload;
		},
		setSistemas(state, payload) {
			state.sistemas = payload;
		},
		setModulos(state, payload) {
			state.modulos = payload;
		},
		setAll(state, payload) {
			state.all = payload;
		}
	},
	actions: {
		async getAll({ commit, dispatch, state }) {
			commit('setLoader');
			commit('setCollection', []);
			await window.axios
				.get(url, {
					params: {
						page: state.paginado.currentPage,
						sistema: state.paginado.sistema,
						modulo: state.paginado.modulo,
						search: state.paginado.search
					}
				})
				.then(resp => {
					commit('setCollection', resp.data.collection);
					commit('setHeaders', resp.data.headers);
					commit('setSistemas', resp.data.sistemas);
					commit('setModulos', resp.data.modulos);
					let paginado = resp.data.paginado;
					if (paginado.lastPage < paginado.currentPage)
						paginado.currentPage = 1;
					commit('setPaginado', paginado);
					commit('setAll', resp.data.all);
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async sendAbility({ commit, dispatch }, ability) {
			commit('setLoader');
			commit('setErrors', [], { root: true });
			window.axios
				.post(`${url}`, ability)
				.then(resp => {
					window.swal(resp.data.message, { icon: 'success', timer: 1500 });
					router.push({ name: 'abilities' }).catch(() => {});
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		}
	}
};
