<!-- eslint-disable -->
<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			PDF's de Inventario
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-card class="mt-3">
				<v-card-text>
					<v-row>
						<v-col>
							<v-autocomplete
								dense
								outlined
								small-chips
								:items="warehouses"
								label="Seleccione Almacen"
								v-model="warehouse"
							/>
						</v-col>
						<v-col>
							<v-btn
								color="primary"
								v-if="!personalizar"
								:href="`${url_pdf}?warehouse=${warehouse}`"
								target="_blank"
							>
								<v-icon>mdi-file-pdf</v-icon>
							</v-btn>
							<v-btn :color="color" class="ml-3" @click="seleccionar">
								<v-icon>{{ texto }}</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-text v-if="personalizar">
					<v-row>
						<v-col cols="12" xl="8">
							<span style="margin-right:55px">
								Productos Seleccionados: {{ seleccionados.length }} <br />
								Cajas Seleccionadas: {{ cajas }}
							</span>
							<v-btn
								color="primary"
								v-if="seleccionados.length"
								class="mb-4"
								@click="pruebas"
							>
								<v-icon>mdi-file-pdf</v-icon>
							</v-btn>
						</v-col>
						<v-col cols="12" xl="4">
							<v-container>
								<v-text-field
									v-model="search"
									append-icon="mdi-magnify"
									label="Busqueda"
									single-line
									hide-details
								/>
							</v-container>
						</v-col>
					</v-row>
					<v-data-table
						dense
						:headers="headers"
						:items="items"
						:search.sync="search"
						class="elevation-1"
						mobile-breakpoint="0"
					>
						<template v-slot:[`item.disponibles`]="{ item }">
							<input
								type="number"
								v-model.number="item.disponibles"
								class="righted-input"
							/>
						</template>
						<template v-slot:[`item.precio`]="{ item }">
							<input
								type="number"
								step="0.01"
								v-model.number="item.precio"
								class="righted-input"
							/>
						</template>
					</v-data-table>
					<a
						href="/pdf-personalizado"
						target="_blank"
						style="display:none;"
						id="btn-descarga"
					></a>
				</v-card-text>
			</v-card>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex';
export default {
	name: 'PDF',
	data: () => ({
		personalizar: false,
		headers: [
			{ text: 'Producto', value: 'producto' },
			{ text: 'Disponibles', value: 'disp', align: 'end' },
			{ text: 'Cantidad', value: 'disponibles', align: 'end' }
			/* { text: 'Precio', value: 'precio', align: 'end' } */
		],
		search: undefined,
		warehouse: 4,
		items: []
	}),
	computed: {
		...mapState({
			warehouses: state => state.Pallets.warehouses,
			productos: state => state.Pallets.productos,
			disponibles: state => state.Pallets.disponibles,
			url_pdf: state => state.Pallets.url_pdf
		}),
		texto() {
			return this.personalizar ? 'mdi-cancel' : 'mdi-pencil';
		},
		color() {
			return this.personalizar ? 'error' : 'success';
		},
		seleccionados() {
			return this.items.filter(x => x.disponibles);
		},
		cajas() {
			return _.sumBy(this.seleccionados, 'disponibles');
		}
	},
	mounted() {},
	methods: {
		armar() {
			this.items = [];
			this.productos.forEach(producto => {
				let filtrados = this.disponibles.filter(x => x.referencia === producto),
					objeto = {
						producto: producto,
						disp: null,
						precio: null,
						disponibles: null
					};
				if (filtrados.length) {
					objeto.disp = _.sumBy(filtrados, 'disponibles');
				}
				this.items.push(objeto);
			});
		},
		seleccionar() {
			this.armar();
			this.personalizar = !this.personalizar;
		},
		pruebas() {
			let disponibles = this.items.filter(x => x.disponibles);
			disponibles.forEach(x => {
				x.calibre = x.producto.substr(0, 2);
				x.categoria = x.producto.includes('CAT 1') ? 1 : 2;
				x.tipo = x.producto.includes('ORGANIC') ? 'Organic' : 'Conventional';
			});
			let precio = _.sumBy(disponibles, 'precio') > 0 ? true : false;
			let data = {
				warehouse: this.warehouse,
				listos: disponibles,
				precio
			};
			window.axios
				.post('pdf-personalizado', data)
				.then(resp => {
					let link = document.getElementById('btn-descarga');
					link.href = resp.data.link;
					link.click();
				})
				.catch(e => {
					window.swal('No se encuentra el archivo en este servidor', {
						icon: 'error',
						timer: 1500
					});
				});
		}
	}
};
</script>
