<!-- eslint-disable -->
<template>
	<v-card height="100%" v-if="pago">
		<v-card-text>
			<span class="enlinea">Folio: {{ pago.id | folio }}</span>
			<span class="enlinea">Fecha: {{ pago.fecha | date }}</span> <br />
			Cliente: {{ pago.cliente }} <br />
			<span class="enlinea">Factura: {{ pago.factura }}</span>
			<span class="enlinea">PU: {{ pago.pu }}</span> <br />
			Importe del Pago: {{ pago.importe | moneda }}
			<v-data-table
				dense
				:headers="headers"
				:items="detalles"
				class="elevation-1 mt-3"
				mobile-breakpoint="0"
			>
				<template v-slot:[`item.precio`]="{ item }">
					{{ item.precio | moneda }}
				</template>
				<template v-slot:[`item.importe`]="{ item }">
					{{ item.importe | moneda }}
				</template>
			</v-data-table>
		</v-card-text>
		<v-card-actions>
			<v-btn color="primary" @click="$router.push({ name: 'pagos' })"
				>regresar a pagos</v-btn
			>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
	name: 'Detalle',
	data: () => ({
		headers: [
			{ text: 'Pallet', value: 'pallet' },
			{ text: 'Embarque', value: 'embarque' },
			{ text: 'Producto', value: 'producto' },
			{ text: 'Cantidad', value: 'cantidad', align: 'end' },
			{ text: 'Precio U', value: 'precio', align: 'end' },
			{ text: 'Importe', value: 'importe', align: 'end' }
		]
	}),
	computed: {
		...mapState({
			pago: state => state.Pagos.resource,
			detalles: state => state.Pagos.detalles
		})
	},
	mounted() {
		if (!this.pago) {
			this.getPago(this.$route.params.id);
		}
	},
	methods: {
		...mapActions('Pagos', ['getPago'])
	}
};
</script>
<style lang="css" scoped>
span.enlinea {
	margin-right: 25px;
}
</style>
