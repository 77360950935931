<!-- eslint-disable -->
<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Pagos a Facturas
		</v-expansion-panel-header>
		<v-expansion-panel-content v-if="!nuevo">
			<v-row>
				<v-col cols="12" xl="8" md="6">
					<v-btn
						small
						color="success"
						class="mt-5"
						@click="nuevoPago"
						v-if="puedeNueva && userCan('fr-facturas-pagos-add')"
					>
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="12" xl="4" md="6">
					<v-container>
						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="Busqueda"
							single-line
							hide-details
						/>
					</v-container>
				</v-col>
			</v-row>
			<v-data-table
				dense
				:headers="headers"
				:items="pagos"
				class="elevation-1"
				:search.sync="search"
				v-if="!nuevo"
				mobile-breakpoint="0"
			>
				<template v-slot:[`item.importe`]="{ item }">
					{{ item.importe | moneda }}
				</template>
				<template v-slot:[`item.fecha`]="{ item }">
					{{ item.fecha | date }}
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-btn icon color="red" @click="eliminar(item)" :disabled="loader" v-if="userCan('fr-facturas-pagos-remove')">
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</template>
			</v-data-table>
		</v-expansion-panel-content>
		<v-expansion-panel-content v-else>
			<FormularioPago @cancelar="nuevo = !nuevo" />
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import FormularioPago from './formularioPago';
export default {
	name: 'Pagos',
	components: { FormularioPago },
	data: () => ({
		perPage: 10,
		headers: [
			{ text: 'Folio', value: 'folio' },
			{ text: 'Fecha Elaboración', value: 'fecha' },
			{ text: 'Método Pago', value: 'metodo_pago' },
			{ text: 'Referencia', value: 'referencia' },
			{ text: 'Importe', value: 'importe', align: 'end' },
			{ text: 'Usuario', value: 'usuario' },
			{ text: 'Acciones', value: 'actions', align: 'end' }
		],
		search: undefined,
		nuevo: false
	}),
	computed: {
		...mapState({
			facturas: state => state.Ventas.facturas,
			pagos: state => state.Ventas.pagos,
			loader: state => state.Ventas.loader
		}),
		puedeNueva() {
			let importeFacturas = _.sumBy(this.facturas, 'importe'),
				importePagos = _.sumBy(this.pagos, 'importe');
			return importeFacturas > importePagos;
		}
	},
	watch: {
		pagos: function() {
			this.nuevo = false;
		}
	},
	methods: {
		...mapMutations(['setErrors']),
		...mapActions('Ventas', ['eliminarPago']),
		nuevoPago() {
			this.setErrors([]);
			this.nuevo = !this.nuevo;
		},
		eliminar(item) {
			let pregunta = `¿Deseas eliminar el pago ${item.folio}?`;
			window
				.swal({
					title: pregunta,
					icon: 'warning',
					buttons: ['Cancelar', 'Confirmar'],
					dangerMode: true
				})
				.then(confirmado => {
					if (confirmado) {
						this.eliminarPago(item.id);
					}
				});
		}
	}
};
</script>
