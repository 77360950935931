/** @format */

import router from "../../router";
const url = "FR/facturas";
const initialState = () => ({
  headers: [],
  collection: [],
  resource: {},
  loader: false,
  detalles: [],
  pagos: [],
  metodos: [],
  pago: undefined,
  paginado: { currentPage: 1 },
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setInitialState(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
    setHeaders(state, payload) {
      state.headers = payload;
    },
    setCollection(state, payload) {
      state.collection = payload;
    },
    setResource(state, payload) {
      state.resource = payload;
    },
    setLoader(state, payload) {
      state.loader = payload;
    },
    setDetalles(state, payload) {
      state.detalles = payload;
    },
    setPagos(state, payload) {
      state.pagos = payload;
    },
    setMetodos(state, payload) {
      state.metodos = payload;
    },
    SET_PAGO(state, payload) {
      state.pago = payload;
    },
    setPaginado(state, payload) {
      let paginado = payload;
      if (paginado.currentPage > paginado.lastPage) paginado.currentPage = 1;
      state.paginado = paginado;
    },
  },
  actions: {
    async getAll({ commit, dispatch, state }) {
      commit("setCollection", []);
      window.axios
        .get(url, {
          params: {
            page: state.paginado.currentPage,
            search: state.paginado.search,
            cliente_id: state.paginado.cliente_id,
            metodo: state.paginado.metodo,
            status: state.paginado.status,
          },
        })
        .then((resp) => {
          commit("setCollection", resp.data.collection);
          commit("setHeaders", resp.data.headers);
          commit("setPaginado", resp.data.paginado);
        })
        .catch((e) => dispatch("errores", e, { root: true }));
    },
    async getFactura({ commit, dispatch }, id) {
      commit("setErrors", [], { root: true });
      await window.axios
        .get(`${url}/${id}`)
        .then((resp) => {
          commit("setResource", resp.data.resource);
          commit("setDetalles", resp.data.detalles);
          commit("setPagos", resp.data.pagos);
          commit("setMetodos", resp.data.metodos);
          router
            .push({ name: "facturas.details", params: { id: id } })
            .catch(() => {});
        })
        .catch((e) => dispatch("errores", e, { root: true }));
    },
    async updateFactura({ commit, dispatch, state }) {
      commit("setErrors", [], { root: true });
      commit("setLoader", true);
      let factura = state.resource;
      await window.axios
        .put(`${url}/${factura.id}`, factura)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          commit("setResource", resp.data.resource);
          let index = state.collection.findIndex((x) => x.id == factura.id);
          state.collection.splice(index, 1, resp.data.resource);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async updateDetail({ commit, dispatch, state }, detail) {
      commit("setErrors", [], { root: true });
      commit("setLoader", true);
      if (!detail.id) {
        window.axios
          .post(`FR/factura-addDetail/${state.resource.id}`, detail)
          .then((resp) => {
            commit("setDetalles", resp.data.detalles);
            commit("setResource", resp.data.resource);
            let index = state.collection.findIndex(
              (x) => x.id == state.resource.id
            );
            state.collection.splice(index, 1, resp.data.resource);
            dispatch("message", resp.data.message, { root: true });
          })
          .catch((e) => dispatch("errores", e, { root: true }))
          .finally(() => commit("setLoader", false));
      } else {
        window.swal("Funcion aun no implementada", {
          icon: "success",
          timer: 1500,
        });
        commit("setLoader", false);
      }
    },
    async deleteDetail({ commit, dispatch, state }, id) {
      await window.axios
        .delete(`FR/factura-eliminarConcepto/${id}`)
        .then((resp) => {
          commit("setDetalles", resp.data.detalles);
          commit("setResource", resp.data.resource);
          let index = state.collection.findIndex(
            (x) => x.id == state.resource.id
          );
          state.collection.splice(index, 1, resp.data.resource);
          dispatch("message", resp.data.message, { root: true });
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async createPago({ commit, dispatch, state }) {
      let factura = state.resource;
      commit("setErrors", [], { root: true });
      commit("setLoader", true);
      await window.axios
        .post(`FR/createPago/${factura.id}`, state.pago)
        .then((resp) => {
          commit("setResource", resp.data.resource);
          let index = state.collection.findIndex((x) => x.id == factura.id);
          state.collection.splice(index, 1, resp.data.resource);
          commit("setDetalles", resp.data.detalles);
          commit("setPagos", resp.data.pagos);
          commit("setMetodos", resp.data.metodos);
          commit("SET_PAGO", undefined);
          dispatch("message", resp.data.message, { root: true });
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async deletePago({ commit, dispatch, state }, id) {
      commit("setErrors", [], { root: true });
      commit("setLoader", true);
      let factura = state.resource;
      await window.axios
        .delete(`FR/deletePago/${id}`)
        .then((resp) => {
          commit("setResource", resp.data.resource);
          let index = state.collection.findIndex((x) => x.id == factura.id);
          state.collection.splice(index, 1, resp.data.resource);
          commit("setDetalles", resp.data.detalles);
          commit("setPagos", resp.data.pagos);
          commit("setMetodos", resp.data.metodos);
          commit("SET_PAGO", undefined);
          dispatch("message", resp.data.message, { root: true });
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async deleteResource({ commit, dispatch }, id) {
      commit("setLoader", true);
      window.axios
        .delete(`${url}/${id}`)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          dispatch("getAll");
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
  },
};
