<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card-text>
    <v-row class="mt-3">
      <v-col cols="12" md="8">
        <v-text-field
          dense
          outlined
          label="Nombre (asi aparecerá en los PU)"
          v-model="resource.name"
          :error-messages="errors.name"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-combobox
          dense
          outlined
          label="Unidad de medida (asi aparecerá en los PU)"
          v-model="resource.unit"
          :error-messages="errors.unit"
          :items="units"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          dense
          outlined
          label="Unidades por Pallet"
          v-model.number="resource.units_per_pallet"
          :error-messages="errors.units_per_pallet"
          type="number"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          dense
          outlined
          label="Libras por Caja"
          v-model.number="resource.pounds"
          :error-messages="errors.pounds"
          type="number"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          dense
          outlined
          label="Piezas por Caja"
          v-model.number="resource.pieces"
          :error-messages="errors.pieces"
          type="number"
        />
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    name: "Fields",
    computed: {
      ...mapState({
        errors: (state) => state.errors,
        resource: (state) => state.Supplies.resource,
      }),
    },
  };
</script>
