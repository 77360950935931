<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-form @submit.prevent="addSupply" :disabled="loader">
    <v-row>
      <v-col cols="12" md="4">
        <v-autocomplete
          dense
          outlined
          label="Artículo"
          :items="supplies"
          v-model="supply.supply_id"
          :error-messages="errors.supply_id"
        />
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          dense
          outlined
          label="Cantidad"
          v-model.number="supply.cantidad"
          :error-messages="errors.cantidad"
          type="number"
        />
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          dense
          outlined
          clearable
          label="Precio Unitario"
          step="0.01"
          type="number"
          v-model.number="supply.precio_unitario"
          :error-messages="errors.precio_unitario"
        />
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          dense
          outlined
          clearable
          label="Importe"
          type="number"
          disabled
          v-model="importe"
        />
      </v-col>
      <v-col cols="12" md="1">
        <v-btn color="success" type="submit" :disabled="loader">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    name: "Supplies",
    data: () => ({
      supply: { cantidad: null, precio_unitario: null, supply_id: null },
    }),
    computed: {
      ...mapState({
        loader: (state) => state.Orders.loader,
        errors: (state) => state.errors,
        supplies: (state) => state.Orders.supplies,
      }),
      importe() {
        return this.supply.cantidad * this.supply.precio_unitario;
      },
    },
    methods: {
      addSupply() {
        let url = `FR/purchaseOrders/addSupply/${this.$route.params.id}`;
        this.$store.commit("setErrors", []);
        this.$store.commit("Orders/setLoader", true);
        window.axios
          .post(url, this.supply)
          .then((resp) => {
            this.$store.commit("Orders/setDetails", resp.data.details);
            this.supply = {
              cantidad: null,
              precio_unitario: null,
              supply_id: null,
            };
            this.$store.dispatch("Orders/getAll");
            this.$store.dispatch("message", resp.data.message);
          })
          .catch((e) => this.$store.dispatch("errores", e))
          .finally(() => this.$store.commit("Orders/setLoader", false));
      },
    },
  };
</script>
