<!-- eslint-disable -->
<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Datos Presupuesto {{ budget.folio }}
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-row class="mt-3">
				<v-col>
					Fecha: <strong>{{ budget.date | date }}</strong>
				</v-col>
				<v-col>
					Cliente: <strong>{{ budget.cliente }}</strong>
				</v-col>
				<v-col>
					Purchase Order (PO): <strong>{{budget.po}}</strong>
				</v-col>
				<v-col>
					Comprador: <strong>{{ budget.buyer }}</strong>
				</v-col>
				<v-col>
					Vendedor: <strong>{{budget.user}}</strong>
				</v-col>
			</v-row>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'DataBudget',
	computed: {
		...mapState({
			budget: state => state.Sucursales.budget
		})
	}
};
</script>
