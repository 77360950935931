/** @format */

import router from "../../router";
const url = "FR/sucursals";
const initialState = () => ({
  headers: [],
  collection: [],
  resource: undefined,
  loader: false,
  paginado: { currentPage: 1 },
  clientes: undefined,
  budgets: undefined,
  headersBudgets: undefined,
  paginadoBudgets: { currentPage: 1 },
  budget: undefined,
  products: undefined,
  pu: undefined,
  sales: undefined,
  paginadoSales: { currentPage: 1, search: undefined, customer_id: undefined },
  sale: undefined,
  charges: undefined,
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setInitialState(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
    setHeaders(state, payload) {
      state.headers = payload;
    },
    setCollection(state, payload) {
      state.collection = payload;
    },
    setResource(state, payload = {}) {
      state.resource = payload;
    },
    setLoader(state, payload = true) {
      state.loader = payload;
    },
    setPaginado(state, payload) {
      let paginado = payload;
      if (paginado.currentPage > paginado.lastPage) paginado.currentPage = 1;
      state.paginado = paginado;
    },
    setClientes(state, payload) {
      state.clientes = payload;
    },
    setBudgets(state, payload = undefined) {
      state.budgets = payload;
    },
    setHeadersBudgets(state, payload) {
      state.headersBudgets = payload;
    },
    setPaginadoBudgets(state, payload) {
      state.paginadoBudgets = payload;
    },
    setBudget(state, payload = undefined) {
      state.budget = payload;
    },
    setProducts(state, payload = undefined) {
      if (!payload) {
        state.products = undefined;
      }
      if (payload && payload.length) {
        state.products = payload;
      }
    },
    setPu(state, payload = undefined) {
      state.pu = payload;
    },
    setSales(state, payload = undefined) {
      state.sales = payload;
    },
    setPaginadoSales(state, payload) {
      state.paginadoSales = payload;
    },
    setSale(state, payload = undefined) {
      state.sale = payload;
    },
    setCharges(state, payload = undefined) {
      state.charges = payload;
    },
  },
  actions: {
    async getAll({ commit, dispatch, state }) {
      commit("setLoader");
      commit("setCollection", []);
      window.axios
        .get(url, {
          params: {
            page: state.paginado.currentPage,
          },
        })
        .then((resp) => {
          commit("setCollection", resp.data.collection);
          commit("setHeaders", resp.data.headers);
          commit("setPaginado", resp.data.paginado);
          commit("setClientes", resp.data.clientes);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    nuevo({ commit, dispatch, state }) {
      if (!state.clientes) dispatch("getAll");
      commit("setResource");
      router.push({ name: "sucursales.create" }).catch(() => {});
    },
    async editar({ commit, dispatch, state }, id) {
      if (!state.clientes) dispatch("getAll");
      commit("setLoader");
      await window.axios
        .get(`${url}/${id}`)
        .then((resp) => {
          commit("setResource", resp.data);
          router
            .push({ name: "sucursales.edit", params: { id } })
            .catch(() => {});
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async enviar({ commit, dispatch, state }) {
      let sucursal = state.resource;
      commit("setLoader");
      commit("setErrors", [], { root: true });
      if (sucursal.id) {
        window.axios
          .put(`${url}/${sucursal.id}`, sucursal)
          .then((resp) => {
            dispatch("message", resp.data.message, { root: true });
            router.push({ name: "sucursales" });
          })
          .catch((e) => dispatch("errores", e, { root: true }))
          .finally(() => commit("setLoader", false));
      } else {
        window.axios
          .post(url, sucursal)
          .then((resp) => {
            dispatch("message", resp.data.message, { root: true });
            router.push({ name: "sucursales" });
          })
          .catch((e) => dispatch("errores", e, { root: true }))
          .finally(() => commit("setLoader", false));
      }
    },
    async getBudgets({ commit, dispatch, state }) {
      commit("setLoader");
      commit("setBudgets");
      await window.axios
        .get(`${url}/budgets`, {
          params: {
            page: state.paginadoBudgets.currentPage,
            search: state.paginadoBudgets.search,
            customer_id: state.paginadoBudgets.customer_id,
          },
        })
        .then((resp) => {
          commit("setBudgets", resp.data.collection);
          commit("setPaginadoBudgets", resp.data.paginado);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async showBudget({ commit, dispatch }, id) {
      commit("setLoader");
      commit("setBudget");
      commit("setProducts");
      commit("setPu");
      await window.axios
        .get(`${url}/getBudget/${id}`)
        .then((resp) => {
          commit("setBudget", resp.data.budget);
          commit("setProducts", resp.data.details);
          commit("setPu", resp.data.pu);
          router
            .push({ name: "california.budgets.show", params: { id } })
            .catch(() => {});
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async getSales({ commit, dispatch, state }) {
      commit("setLoader");
      commit("setSales");
      await window.axios
        .get(`${url}/sales`, {
          params: {
            page: state.paginadoSales.currentPage,
            search: state.paginadoSales.search,
            customer_id: state.paginadoSales.customer_id,
          },
        })
        .then((resp) => {
          commit("setSales", resp.data.collection);
          commit("setPaginadoSales", resp.data.paginado);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async getSale({ commit, dispatch }, id) {
      commit("setLoader");
      commit("setSale");
      await window.axios
        .get(`${url}/getSale/${id}`)
        .then((resp) => {
          commit("setSale", resp.data.sale);
          commit("setProducts", resp.data.products);
          commit("setCharges", resp.data.charges);
          router
            .push({ name: "california.sales.show", params: { id } })
            .catch(() => {});
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async uploadBol({ commit, dispatch, state }, file) {
      let formData = new FormData();
      formData.append("file", file);
      commit("setLoader");
      commit("setErrors", [], { root: true });
      window.axios
        .post(`${url}/uploadBol/${state.sale.id}`, formData)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          dispatch("getSale", state.sale.id);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async deleteBol({ commit, dispatch, state }) {
      commit("setLoader");
      window.axios
        .post(`${url}/deleteBol/${state.sale.id}`)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          dispatch("getSale", state.sale.id);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async invoiceSale({ commit, dispatch, state }) {
      commit("setLoader");
      await window.axios
        .post(`${url}/invoiceSale/${state.sale.id}`)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          router.push({ name: "facturas" });
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
  },
};
