<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="deep-purple lighten-4" @click="alExpandir">
      Inspection Photos
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-form @submit.prevent="uploadPhoto" :disabled="loader">
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12" xl="6">
              <v-file-input
                v-model="file"
                dense
                outlined
                prepend-icon="mdi-camera"
                show-size
                label="Photo"
                accept="image/png, image/jpeg, image/bmp"
                :error-messages="errors.image"
              />
            </v-col>
            <v-col v-if="file">
              <v-btn color="success" type="submit">
                <v-icon>mdi-cloud-upload</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-text v-if="items && items.length">
        <v-row>
          <v-col cols="12" md="4" v-for="item in items" :key="item.id">
            <v-card class="mb-3">
              <v-card-text>
                <v-img
                  max-height="205"
                  max-width="498"
                  :src="`${item.url}`"
                  contain
                />
              </v-card-text>
              <v-card-actions>
                <v-btn small color="error" @click="eliminar(item)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  export default {
    name: "Photos",
    data: () => ({
      items: undefined,
      file: undefined,
    }),
    computed: {
      ...mapState({
        loader: (state) => state.loader,
        errors: (state) => state.errors,
      }),
    },
    methods: {
      ...mapMutations(["setLoader", "setErrors"]),
      ...mapActions(["errores", "message"]),
      alExpandir(event) {
        if (
          !event.currentTarget.classList.contains(
            "v-expansion-panel-header--active"
          )
        ) {
          this.getData();
        }
      },
      getData() {
        let url = `FR/quality_controls/getPhotos/${this.$route.params.id}`;
        this.setLoader();
        window.axios
          .get(url)
          .then((resp) => {
            this.items = resp.data.collection;
          })
          .catch((e) => this.errores(e))
          .finally(() => this.setLoader(false));
      },
      uploadPhoto() {
        let url = `FR/quality_controls/uploadPhoto/${this.$route.params.id}`,
          formData = new FormData();
        formData.append("image", this.file);
        this.setLoader();
        this.setErrors();
        window.axios
          .post(url, formData)
          .then((resp) => {
            if (resp.data.message) {
              this.message(resp.data.message);
              this.file = undefined;
              this.getData();
            }
          })
          .catch((e) => this.$store.dispatch("errores", e))
          .finally(() => this.setLoader(false));
      },
    },
  };
</script>
