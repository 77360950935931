<!-- eslint-disable -->
<template>
	<v-card height="100%" v-if="abilities">
		<v-form @submit.prevent="$store.dispatch('Abilities/sendAbility', ability)">
			<v-card-text>
				<v-row class="mt-3">
					<v-col cols="12" md="6">
						<v-combobox
							dense
							outlined
							clearable
							label="Sistema"
							v-model="sistema"
							:items="sistemas"
							:error-messages="errors.sistema"
						/>
					</v-col>
					<v-col cols="12" md="6">
						<v-combobox
							dense
							outlined
							clearable
							label="Módulo"
							v-model="modulo"
							:items="modulos"
							:error-messages="errors.modulo"
						/>
					</v-col>
					<v-col cols="12" md="3">
						<v-text-field
							dense
							outlined
							clearable
							label="Nombre"
							v-model="ability.name"
							:error-messages="errors.name"
						/>
					</v-col>
					<v-col>
						<v-text-field
							dense
							outlined
							clearable
							label="Descripción"
							v-model="ability.description"
							:error-messages="errors.description"
						/>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-btn color="primary" type="submit" :disabled="loader">
					guardar
				</v-btn>
				<v-btn
					color="error"
					@click="$router.push({ name: 'abilities' })"
					:disabled="loader"
				>
					cancelar
				</v-btn>
			</v-card-actions>
		</v-form>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Form',
	data: () => ({
		ability: {},
		sistema: undefined,
		modulo: undefined
	}),
	computed: {
		...mapState({
			abilities: state => state.Abilities.all,
			loader: state => state.Abilities.loader,
			errors: state => state.errors
		}),
		sistemas() {
			return [...new Set(this.abilities.map(x => x.sistema))].sort();
		},
		modulos() {
			let filtrados = this.abilities.filter(x => x.sistema == this.sistema);
			return [...new Set(filtrados.map(x => x.modulo))].sort();
		}
	},
	beforeMount() {
		this.$store.commit('setErrors', []);
		if (!this.abilities) {
			this.$store.dispatch('Abilities/getAll');
		}
	},
	watch: {
		sistema: function(val) {
			this.ability.sistema = val;
			this.modulo = undefined;
		},
		modulo: function(val) {
			this.ability.modulo = val;
			this.ability.name = this.ability.description = undefined;
		}
	}
};
</script>
