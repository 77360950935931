var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',[_c('v-form',{attrs:{"disabled":_vm.loader},on:{"submit":function($event){$event.preventDefault();return _vm.getSales($event)}}},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Busqueda por PO o PU"},model:{value:(_vm.paginado.search),callback:function ($$v) {_vm.$set(_vm.paginado, "search", $$v)},expression:"paginado.search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","clearable":"","label":"Filtrar por Cliente","items":_vm.paginado.customers},model:{value:(_vm.paginado.customer_id),callback:function ($$v) {_vm.$set(_vm.paginado, "customer_id", $$v)},expression:"paginado.customer_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('v-btn',{attrs:{"color":"info","type":"submit","disabled":_vm.loader}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","items":_vm.items,"headers":_vm.headers,"mobile-breakpoint":"0","items-per-page":_vm.paginado.perPage,"hide-default-footer":"","item-class":_vm.row_classes,"loading":_vm.loader},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary","disabled":_vm.loader},on:{"click":function($event){return _vm.getSale(item.id)}}},[_c('v-icon',[_vm._v("mdi-information")])],1),(item.actions.pdf2)?_c('v-btn',{attrs:{"icon":"","color":"error","href":item.actions.pdf2,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-file-pdf")])],1):_vm._e()]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(item.date)))]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moneda")(item.importe)))]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moneda")(item.total)))]}},{key:"item.charges",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moneda")(item.charges)))]}},{key:"item.cajas",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("numero")(item.cajas)))]}}],null,true)}),_c('Pagination',{attrs:{"loader":_vm.loader,"items":_vm.items,"paginado":_vm.paginado}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }