<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-form @submit.prevent="addDetail">
    <v-row>
      <v-col cols="12" md="4">
        <v-select
          dense
          outlined
          label="Calibre"
          :items="calibres"
          v-model="producto.calibre"
          :error-messages="errors.calibre"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          dense
          outlined
          label="Categoría"
          :items="categorias"
          v-model="producto.categoria"
          :error-messages="errors.categoria"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          dense
          outlined
          label="Tipo"
          :items="tipos"
          v-model="producto.tipo"
          :error-messages="errors.tipo"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          dense
          outlined
          clearable
          label="Cantidad"
          type="number"
          v-model.number="cantidad"
          :error-messages="errors.cantidad"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          dense
          outlined
          clearable
          label="Precio Unitario"
          step="0.01"
          type="number"
          v-model.number="precioUnitario"
          :error-messages="errors.precioUnitario"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          dense
          outlined
          clearable
          label="Importe"
          type="number"
          disabled
          v-model="importe"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-btn color="success" type="submit" :disabled="loader">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    name: "Avocados",
    data: () => ({
      producto: {},
      cantidad: undefined,
      precioUnitario: undefined,
      categorias: ["CAT 1", "CAT 2"],
      tipos: ["ORGANIC", "CONVENTIONAL"],
    }),
    computed: {
      ...mapState({
        calibres: (state) => state.Orders.categorias,
        loader: (state) => state.Orders.loader,
        errors: (state) => state.errors,
      }),
      importe() {
        if (!this.precioUnitario || !this.cantidad) return null;
        return this.precioUnitario * this.cantidad;
      },
    },
    methods: {
      addDetail() {
        this.$store.commit("setErrors", []);
        this.$store.commit("Orders/setLoader", true);
        window.axios
          .post(
            `FR/purchaseOrders/addDetail/${this.$route.params.id}`,
            this.producto
          )
          .then((resp) => {
            this.$store.commit("Orders/setDetails", resp.data.details);
            this.cantidad = this.precioUnitario = undefined;
            this.producto = {};
            this.$store.dispatch("Orders/getAll");
            this.$store.dispatch("message", resp.data.message);
          })
          .catch((e) => this.$store.dispatch("errores", e))
          .finally(() => this.$store.commit("Orders/setLoader", false));
      },
    },
    watch: {
      precioUnitario: function(val) {
        this.producto.precioUnitario = val;
      },
      cantidad: function(val) {
        this.producto.cantidad = val;
      },
    },
  };
</script>
