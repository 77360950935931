/** @format */

import router from "../../router";
const url = "FR/compras";
const initialState = () => ({
  collection: undefined,
  resource: undefined,
  loader: false,
  proveedores: undefined,
  details: undefined,
  productos: undefined,
  cajas: undefined,
  files: undefined,
  gastos: undefined,
  apartados: undefined,
  paginado: { currentPage: 1, proveedor_id: undefined },
  ventas: undefined,
  pagos: undefined,
  supplies: undefined,
  compraSupplies: undefined,
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setInitialState(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
    setCollection(state, payload) {
      state.collection = payload;
    },
    setResource(state, payload) {
      state.resource = payload;
    },
    setLoader(state, payload) {
      state.loader = payload;
    },
    SET_PROVEEDORES(state, payload) {
      state.proveedores = payload;
    },
    SET_DETAILS(state, payload) {
      state.details = payload;
    },
    SET_PRODUCTOS(state, payload) {
      state.productos = payload;
    },
    SET_CAJAS(state, payload) {
      state.cajas = payload;
    },
    setFiles(state, payload) {
      state.files = payload;
    },
    setGastos(state, payload) {
      state.gastos = payload;
    },
    setApartados(state, payload = undefined) {
      state.apartados = payload;
    },
    setPaginado(state, payload) {
      let paginado = payload;
      if (paginado.currentPage > paginado.lastPage) paginado.currentPage = 1;
      state.paginado = paginado;
    },
    setVentas(state, payload = undefined) {
      state.ventas = payload;
    },
    setPagos(state, payload = undefined) {
      state.pagos = payload;
    },
    setSupplies(state, payload) {
      state.supplies = payload;
    },
    setCompraSupplies(state, payload) {
      state.compraSupplies = payload;
    },
  },
  actions: {
    async getAll({ commit, dispatch, state }) {
      commit("setCollection", []);
      commit("setLoader", true);
      window.axios
        .get(url, {
          params: {
            page: state.paginado.currentPage,
            proveedor_id: state.paginado.proveedor_id,
          },
        })
        .then((resp) => {
          commit("setCollection", resp.data.collection);
          commit("SET_PROVEEDORES", resp.data.proveedores);
          commit("setPaginado", resp.data.paginado);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    nuevo({ commit }) {
      this.commit("setErrors", [], { root: true });
      commit("setResource", {});
      router.push({ name: "compras.new" }).catch(() => {});
    },
    async send({ commit, dispatch, state }) {
      let compra = state.resource;
      commit("setErrors", [], { root: true });
      commit("setLoader", true);
      if (!compra.id) {
        await window.axios
          .post(url, compra)
          .then((resp) => {
            dispatch("message", resp.data.message, { root: true });
            commit("setCollection", resp.data.collection);
            router.push({ name: "compras" }).catch(() => {});
          })
          .catch((e) => dispatch("errores", e, { root: true }))
          .finally(() => commit("setLoader", false));
      }
    },
    async detalles({ commit, dispatch }, id) {
      commit("setErrors", [], { root: true });
      commit("setLoader", true);
      commit("setFiles", []);
      commit("setGastos", []);
      window.axios
        .get(`${url}/${id}`)
        .then((resp) => {
          commit("setResource", resp.data.resource);
          commit("SET_DETAILS", resp.data.details);
          commit("SET_PRODUCTOS", resp.data.productos);
          commit("SET_CAJAS", resp.data.cajas);
          commit("setFiles", resp.data.files);
          commit("setGastos", resp.data.gastos);
          commit("setApartados", resp.data.apartados);
          commit("setVentas", resp.data.ventas);
          commit("setPagos", resp.data.pagos);
          commit("setSupplies", resp.data.supplies);
          commit("setCompraSupplies", resp.data.compraSupplies);
          let nombre = `Detalles de la compra ${resp.data.resource.folio}`;
          router
            .push({ name: "compras.details", params: { nombre, id } })
            .catch(() => {});
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async eliminarCompra({ commit, dispatch }, id) {
      commit("setLoader", true);
      await window.axios
        .delete(`${url}/${id}`)
        .then((resp) => {
          commit("setCollection", resp.data.collection);
          commit("SET_PROVEEDORES", resp.data.proveedores);
          dispatch("message", resp.data.message, { root: true });
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async uploadFile({ commit, dispatch, state }, form) {
      commit("setLoader", true);
      await window.axios
        .post(`${url}/uploadFile/${state.resource.id}`, form)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          commit("setFiles", resp.data.files);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async deleteFile({ commit, dispatch }, id) {
      commit("setLoader", true);
      await window.axios
        .delete(`${url}/deleteFile/${id}`)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          commit("setFiles", resp.data.files);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async uploadGasto({ commit, dispatch, state }, data) {
      commit("setErrors", [], { root: true });
      commit("setLoader", true);
      let action = data.id ? "" : `${url}/uploadGasto/${state.resource.id}`;
      await window.axios
        .post(action, data)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          commit("setGastos", resp.data.gastos);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async deleteGasto({ commit, dispatch }, id) {
      commit("setLoader", true);
      await window.axios
        .delete(`${url}/deleteGasto/${id}`)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          commit("setGastos", resp.data.gastos);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
  },
};
