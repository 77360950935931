/** @format */

import router from "../../router";
const url = "FR/schedules";
const base = "goodman";
const initialState = () => ({
  headers: [],
  collection: undefined,
  resource: undefined,
  loader: false,
  invoices: undefined,
  paginado: { currentPage: 1 },
  ProcessingFees: undefined,
  RequiredReserve: undefined,
  WireTransferFee: undefined,
  files: undefined,
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setFiles(state, payload) {
      state.files = payload;
    },
    setInitialState(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
    setHeaders(state, payload) {
      state.headers = payload;
    },
    setCollection(state, payload) {
      state.collection = payload;
    },
    setResource(state, payload) {
      state.resource = payload;
    },
    setLoader(state, payload = true) {
      state.loader = payload;
    },
    setInvoices(state, payload) {
      state.invoices = payload;
    },
    setPaginado(state, payload) {
      let paginado = payload;
      if (paginado.currentPage > paginado.lastPage) paginado.currentPage = 1;
      state.paginado = paginado;
    },
    setProcessingFees(state, payload) {
      state.ProcessingFees = payload;
    },
    setRequiredReserve(state, payload) {
      state.RequiredReserve = payload;
    },
    setWireTransferFee(state, payload) {
      state.WireTransferFee = payload;
    },
  },
  actions: {
    async getAll({ commit, dispatch, state }) {
      commit("setCollection", []);
      commit("setLoader", true);
      window.axios
        .get(url, {
          params: {
            page: state.paginado.currentPage,
            search: state.paginado.search,
          },
        })
        .then((resp) => {
          commit("setCollection", resp.data.collection);
          commit("setHeaders", resp.data.headers);
          commit("setPaginado", resp.data.paginado);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async nueva({ commit, dispatch }) {
      commit("setLoader");
      await window.axios
        .get(`${url}/create`)
        .then((resp) => {
          commit("setInvoices", resp.data.invoices);
          commit("setProcessingFees", resp.data.ProcessingFees);
          commit("setRequiredReserve", resp.data.RequiredReserve);
          commit("setWireTransferFee", resp.data.WireTransferFee);
          router.push({ name: `${base}.create` }).catch(() => {});
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async showResource({ commit, dispatch }, id) {
      commit("setLoader");
      await window.axios
        .get(`${url}/${id}`)
        .then((resp) => {
          commit("setInvoices", resp.data.invoices);
          commit("setResource", resp.data.resource);
          commit("setFiles", resp.data.files);
          router.push({ name: `${base}.show`, params: { id } }).catch(() => {});
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async storeResource({ commit, dispatch }, schedule) {
      commit("setLoader");
      commit("setErrors", {}, { root: true });
      await window.axios
        .post(url, schedule)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          router.push({ name: base }).catch(() => {});
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async deleteResource({ commit, dispatch }, id) {
      commit("setLoader");
      await window.axios
        .delete(`${url}/${id}`)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          dispatch("getAll");
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async updateResource({ commit, dispatch, state }) {
      commit("setErrors", {}, { root: true });
      commit("setLoader");
      await window.axios
        .put(`${url}/${state.resource.id}`, state.resource)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          dispatch("showResource", state.resource.id);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
  },
};
