<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="orange lighten-4">
      Productos
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-data-table
        dense
        :headers="headers"
        :items="propios"
        class="elevation-1 mt-3"
        hide-default-footer
        :items-per-page="items.length"
        mobile-breakpoint="0"
        :loading="loader"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="red" @click="eliminar(item)" :disabled="loader">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.price`]="{ item }">
          {{ item.price | moneda }}
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          {{ item.amount | moneda }}
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  export default {
    name: "Products",
    data: () => ({
      loader: false,
      headers: [
        { text: "Producto", value: "description" },
        { text: "Pallet", value: "pallet_id" },
        { text: "Expediente", value: "exp" },
        { text: "Cajas", value: "qty", align: "end" },
        { text: "Precio", value: "price", align: "end" },
        { text: "Importe", value: "amount", align: "end" },
        { text: "", value: "actions", align: "end" },
      ],
      propios: [],
    }),
    computed: {
      ...mapState({
        items: (state) => state.Budgets.resource.PuItems,
      }),
      
    },
    mounted() {
      this.propios = [...this.items];
    },
    methods: {
      ...mapActions("Budgets", ["detalles"]),
      eliminar(item) {
        let pregunta = `¿Deseas eliminar ${item.description}?`,
          text = "Esta acción no puede deshacerse";
        this.$swal({
          title: pregunta,
          text: text,
          icon: "warning",
          showCancelButton: true,
        }).then((resp) => {
          if (resp.isConfirmed) {
            this.loader = true;
            this.propios = [];
            window.axios
              .post("FR/Budgets/removeItem", item)
              .then((resp) => {
                this.$store.dispatch("message", resp.data.message);
                this.detalles(this.$route.params.id);
              })
              .catch((e) => {
                this.$store.dispatch("errores", e);
                this.propios = [...this.items];
              })
              .finally(() => (this.loader = false));
          }
        });
      },
    },
    watch: {
      items(val) {
        this.propios = [...val];
      },
    },
  };
</script>
