<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Productos (Aguacates)
    </v-expansion-panel-header>
    <Historial :item="detalle" v-if="history" @regresar="regresar" />
    <v-expansion-panel-content v-else>
      <v-row class="mt-3">
        <v-col> Total de cajas: {{ cajasTotal | numero }} </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-btn small color="success" class="mt-5" @click="agregar">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        :search.sync="search"
        class="elevation-1 mt-3"
        mobile-breakpoint="0"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="green"
            v-if="item.cantidad !== item.disponibles"
            @click="historial(item)"
          >
            <v-icon>mdi-history</v-icon>
          </v-btn>
          <v-btn icon color="red" @click="eliminar(item)" v-if="item.cantidad == item.disponibles">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.precio_unitario`]="{ item }">
          {{ item.precio_unitario | moneda }}
        </template>
        <template v-slot:[`item.fecha_corte`]="{ item }">
          {{ item.fecha_corte | date }}
        </template>
      </v-data-table>
    </v-expansion-panel-content>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-form @submit.prevent="enviar">
          <v-card-title class="headline grey lighten-2">
            Agregar Producto
          </v-card-title>
          <v-card-text class="mt-3">
            <v-row>
              <v-col cols="12">
                <v-combobox
                  type="text"
                  label="Producto"
                  outlined
                  dense
                  v-model="producto.producto"
                  :error-messages="errors.producto"
                  :items="productos"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  type="date"
                  label="Fecha de Corte"
                  outlined
                  dense
                  v-model="producto.fecha_corte"
                  :error-messages="errors.fecha_corte"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  type="text"
                  label="Pallet ID"
                  outlined
                  dense
                  v-model="producto.pallet_id"
                  :error-messages="errors.pallet_id"
                />
              </v-col>
              <v-col cols="12">
                <v-combobox
                  type="text"
                  label="Tipo de Caja"
                  outlined
                  dense
                  v-model="producto.tipo_caja"
                  :error-messages="errors.tipo_caja"
                  :items="cajas"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  type="number"
                  label="Cantidad"
                  outlined
                  dense
                  v-model.number="producto.cantidad"
                  :error-messages="errors.cantidad"
                  step="0.01"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  type="number"
                  label="Precio de Compra Unitario"
                  outlined
                  dense
                  v-model.number="producto.precio_unitario"
                  :error-messages="errors.precio_unitario"
                  step="0.01"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  type="number"
                  label="Importe"
                  outlined
                  dense
                  v-model.number="importe"
                  disabled
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn small color="primary" type="submit" :disabled="loader">
              guardar
            </v-btn>
            <v-btn small color="error" @click="cancelar" class="ml-3">
              cancelar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-expansion-panel>
</template>

<script>
  import Historial from "./historial";
  import { mapActions, mapMutations, mapState } from "vuex";
  export default {
    name: "Productos",
    components: { Historial },
    data: () => ({
      dialog: undefined,
      search: undefined,
      headers: [
        { text: "Producto", value: "producto" },
        { text: "Pallet ID", value: "pallet_id" },
        { text: "Fecha Corte", value: "fecha_corte" },
        { text: "Tipo Caja", value: "tipo_caja" },
        { text: "Cantidad", value: "cantidad", align: "end" },
        { text: "Disponibles", value: "disponibles", align: "end" },
        { text: "Precio U", value: "precio_unitario", align: "end" },
        { text: "Importe", value: "importe", align: "end" },
        { text: "Acciones", value: "actions", align: "end" },
      ],
      producto: {},
      history: false,
      detalle: {},
    }),
    computed: {
      ...mapState({
        errors: (state) => state.errors,
        loader: (state) => state.Compras.loader,
        compra: (state) => state.Compras.resource,
        items: (state) => state.Compras.details,
        productos: (state) => state.Compras.productos,
        cajas: (state) => state.Compras.cajas,
        collection: (state) => state.Compras.collection,
      }),
      importe() {
        return this.producto.cantidad * this.producto.precio_unitario;
      },
      compraActual() {
        return this.collection.find((x) => x.id == this.compra.id);
      },
      cajasTotal() {
        return window._.sumBy(this.items, "cantidad");
      },
    },
    methods: {
      ...mapActions(["errores"]),
      ...mapMutations(["setErrors"]),
      ...mapMutations("Compras", [
        "setLoader",
        "SET_DETAILS",
        "SET_PRODUCTOS",
        "SET_CAJAS",
      ]),
      ...mapActions("Compras", ["deleteDetail"]),
      agregar() {
        this.setErrors([]);
        this.producto = {
          precio_unitario: 0,
          cantidad: 0,
        };
        this.dialog = true;
      },
      cancelar() {
        this.dialog = undefined;
      },
      enviar() {
        //
        this.setErrors([]);
        this.setLoader(true);
        window.axios
          .post(`FR/compras/addProducto/${this.compra.id}`, this.producto)
          .then((resp) => {
            this.SET_DETAILS(resp.data.details);
            this.SET_PRODUCTOS(resp.data.productos);
            this.SET_CAJAS(resp.data.cajas);
            this.cancelar();
            this.actualizar();
          })
          .catch((e) => this.errores(e))
          .finally(() => {
            this.setLoader(false);
          });
      },
      eliminar(item) {
        let pregunta = `¿Deseas eliminar el producto ${item.producto}?`,
          text = "Esta acción no puede deshacerse";
        window
          .swal({
            title: pregunta,
            text: text,
            icon: "warning",
            buttons: ["Cancelar", "Confirmar"],
            dangerMode: true,
          })
          .then((confirmado) => {
            if (confirmado) {
              this.setLoader(true);
              window.axios
                .delete(`FR/compras/deleteDetail/${item.id}`)
                .then((resp) => {
                  this.SET_DETAILS(resp.data.details);
                  this.SET_PRODUCTOS(resp.data.productos);
                  this.SET_CAJAS(resp.data.cajas);
                  this.cancelar();
                  this.actualizar();
                })
                .catch((e) => this.errores(e))
                .finally(() => {
                  this.setLoader(false);
                });
            }
          });
      },
      actualizar() {
        if (!this.items.length) {
          this.compraActual.importe = null;
          return;
        }
        this.compraActual.importe = window._.sumBy(this.items, "importe");
      },
      historial(item) {
        this.history = true;
        this.detalle = item;
      },
      regresar() {
        this.history = false;
        this.detalle = {};
      },
    },
  };
</script>
