<!-- eslint-disable -->
<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Apartados
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-row>
				<v-col cols="12" xl="4" offset-xl="8">
					<v-container>
						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="Busqueda"
							single-line
							hide-details
						/>
					</v-container>
				</v-col>
			</v-row>
			<v-data-table
				:headers="headers"
				:items="items"
				class="elevation-1 mt-3"
				dense
				mobile-breakpoint="0"
				:search.sync="search"
			>
					<template v-slot:[`item.importe`]="{ item }">
					{{ item.importe | moneda }}
				</template>
					<template v-slot:[`item.precio`]="{ item }">
					{{ item.precio | moneda }}
				</template>
			</v-data-table>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>
<script>
import { mapState } from 'vuex';
export default {
	name: 'Apartados',
	data: () => ({
		headers: [
			{ text: 'Pallet', value: 'pallet_id' },
			{ text: 'Producto', value: 'producto' },
			{ text: 'Cliente', value: 'cliente' },
			{ text: 'Presupuesto', value: 'budget' },
			{ text: 'PU', value: 'pu' },
			{ text: 'PO', value: 'po' },
			{ text: 'Cantidad', value: 'cantidad', align: 'end' },
			{ text: 'Precio', value: 'precio', align: 'end' },
			{ text: 'Importe', value: 'importe', align: 'end' }
		],
		search: undefined
	}),
	computed: {
		...mapState({
			items: state => state.Compras.apartados
		})
	}
};
</script>
