/** @format */

import router from "../../router";
const url = "FR/dashboard";
const initialState = () => ({
  inventario: false,
  ventas: {},
  facturas: {},
  pagos: {},
  externos: [],
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setInitialState(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
    SET_INVENTARIO(state, payload) {
      state.inventario = payload;
    },
    setVentas(state, payload) {
      state.ventas = payload;
    },
    SET_FACTURAS(state, payload) {
      state.facturas = payload;
    },
    setPagos(state, payload) {
      state.pagos = payload;
    },
    SET_EXTERNOS(state, payload) {
      state.externos = payload;
    },
  },
  actions: {
    async getAll({ commit, dispatch }) {
      commit("SET_INVENTARIO", false);
      commit("setVentas", {});
      commit("SET_FACTURAS", {});
      commit("setPagos", {});
      commit("SET_EXTERNOS", undefined);
      window.axios
        .get(url)
        .then((resp) => {
          commit("SET_INVENTARIO", resp.data.inventario);
          commit("setVentas", resp.data.ventas);
          commit("SET_FACTURAS", resp.data.facturas);
          commit("setPagos", resp.data.pagos);
          commit("SET_EXTERNOS", resp.data.externos);
        })
        .catch((e) => dispatch("errores", e, { root: true }));
    },
  },
};
