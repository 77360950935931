<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-form @submit.prevent="getAll">
        <v-row class="my-3">
          <v-col cols="12" md="6">
            <v-text-field
              dense
              outlined
              clearable
              label="Buscar "
              v-model="paginado.search"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-btn color="primary" type="submit" :disabled="loader">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              color="success"
              class="ml-2"
              v-if="userCan('fr-supplies-create')"
              :disabled="loader"
              @click="nuevo"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        dense
        :items="items"
        :headers="headers"
        class="elevation-1"
        mobile-breakpoint="0"
        :items-per-page="paginado.perPage"
        hide-default-footer
        :loading="loader"
        :item-class="row_classes"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="success"
            v-if="item.actions.edit"
            @click="getResource(item.id)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            color="error"
            v-if="item.actions.delete"
            @click="eliminar(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn
            icon
            color="success"
            v-if="item.actions.restore"
            @click="restaurar(item.id)"
          >
            <v-icon>mdi-delete-restore</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <Pagination :loader="loader" :items="items" :paginado="paginado" />
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  export default {
    name: "Index",
    computed: {
      ...mapState({
        loader: (state) => state.Supplies.loader,
        headers: (state) => state.Supplies.headers,
        items: (state) => state.Supplies.collection,
        paginado: (state) => state.Supplies.paginado,
      }),
    },
    methods: {
      ...mapActions("Supplies", [
        "getAll",
        "nuevo",
        "getResource",
        "disableResource",
        "restaurar",
      ]),
      eliminar(item) {
        let pregunta = `¿Deseas deshabilitar el artículo ${item.name}?`,
          text = "No estará disponible para nuevas compras";
        this.$swal({
          title: pregunta,
          text: text,
          icon: "warning",
          showCancelButton: true,
        }).then((resp) => {
          if (resp.isConfirmed) {
            this.disableResource(item.id);
          }
        });
      },
    },
    mounted() {
      this.getAll();
    },
  };
</script>
