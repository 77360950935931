<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="orange lighten-4">
      {{ title }}
    </v-expansion-panel-header>
    <v-expansion-panel-content v-if="nuevo">
      <add-sample :resource="sample" @cerrar="nuevo = false" />
    </v-expansion-panel-content>
    <v-expansion-panel-content v-else>
      <v-card-text>
        <v-row class="my-2">
          <v-col>
            <v-btn
              small
              color="success"
              @click="createSample"
              v-if="posibles.length"
              :disabled="loader"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          dense
          :items="items"
          :headers="headers"
          class="elevation-1 "
          mobile-breakpoint="0"
          hide-default-footer
          :loading="loader"
        >
          <template v-slot:[`item.avg_quality`]="{ item }">
            {{ (item.quality / 11).toFixed(2) }}
          </template>
          <template v-slot:[`item.avg_condition`]="{ item }">
            {{ (item.condition / 11).toFixed(2) }}
          </template>
          <template v-slot:[`item.id`]="{ item }">
            {{ item.id | folio }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon color="success" @click="editar(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon color="error" @click="eliminar(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import { mapState } from "vuex";
  import AddSample from "./CardAddSample.vue";
  export default {
    name: "Samples",
    components: {
      AddSample,
    },
    computed: {
      ...mapState({
        resource: (state) => state.Qc.resource,
        loader: (state) => state.Qc.loader,
      }),
      items() {
        return this.resource.samples;
      },
      title() {
        if (!this.nuevo) return `${this.items.length} Samples Taken`;
        return this.sample.id ? "Editing Sample" : "New Sample";
      },
      posibles() {
        return this.resource.products.filter((x) => x.samples > x.taken);
      },
    },
    data: () => ({
      headers: [
        { text: "Id", value: "id", width: "5%" },
        { text: "Product", value: "product" },
        { text: "Pallet Id", value: "pallet_id" },
        { text: "Sum Quality", value: "quality", align: "end", width: "5%" },
        {
          text: "Avg Quality",
          value: "avg_quality",
          align: "end",
          width: "5%",
        },
        {
          text: "Sum Condition",
          value: "condition",
          align: "end",
          width: "5%",
        },
        {
          text: "Avg Condition",
          value: "avg_condition",
          align: "end",
          width: "5%",
        },
        { text: "Firmness", value: "firmness", align: "end", width: "5%" },
        {
          text: "Classification",
          value: "classification",
          align: "center",
          width: "5%",
        },
        { text: "Actions", value: "actions", align: "end", width: "5%" },
      ],
      nuevo: false,
      sample: {},
    }),
    methods: {
      createSample() {
        this.sample = {};
        this.nuevo = true;
      },
      editar(item) {
        this.sample = Object.assign({}, item);
        this.nuevo = true;
      },
      eliminar(item) {
        this.$swal({
          title: `¿ Delete Sample ${this.$options.filters.folio(item.id)}  ?`,
          text: "this action cannot be undone",
          icon: "question",
          showCancelButton: true,
        }).then((resp) => {
          if (resp.isConfirmed) {
            let url = `FR/quality_controls/deleteSample/${item.id}`;
            this.$store.commit("Qc/setLoader");
            window.axios
              .delete(url)
              .then((resp) => {
                this.$store.dispatch("message", resp.data.message);
                this.resource.products = resp.data.products;
                this.resource.samples = resp.data.samples;
              })
              .catch((e) => this.$store.dispatch("message", e))
              .finally(() => this.$store.commit("Qc/setLoader", false));
          }
        });
      },
    },
  };
</script>
