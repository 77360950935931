<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-form @submit.prevent="getAll" :disabled="loader">
        <v-row class="mt-2">
          <v-col>
            <v-text-field
              dense
              outlined
              clearable
              label="Search"
              append-icon="mdi-magnify"
              v-model="paginado.search"
            />
          </v-col>
          <v-col>
            <v-btn color="info" type="submit" :disabled="loader">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              color="success"
              class="ml-2"
              :disabled="loader"
              @click="nueva"
              v-if="userCan('schedules-create')"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        hide-default-footer
        class="elevation-1"
        :items-per-page="paginado.perPage"
      >
        <template v-slot:[`item.date`]="{ item }">
          {{ item.date | date }}
        </template>
        <template v-slot:[`item.total`]="{ item }">
          {{ item.total | moneda }}
        </template>
        <template v-slot:[`item.fees`]="{ item }">
          <span class="red--text font-weight-bold">{{
            item.fees | moneda
          }}</span>
        </template>
        <template v-slot:[`item.reserve`]="{ item }">
          <span class="red--text font-weight-bold">{{
            item.reserve | moneda
          }}</span>
        </template>
        <template v-slot:[`item.deducted`]="{ item }">
          <span class="red--text font-weight-bold">{{
            item.deducted | moneda
          }}</span>
        </template>
        <template v-slot:[`item.paid`]="{ item }">
          <span class="font-weight-bold">{{ item.paid | moneda }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <Actions :item="item" :loader="loader" @eliminar="eliminar(item)">
            <v-btn icon color="secondary" @click="showResource(item.id)">
              <v-icon>mdi-view-list</v-icon>
            </v-btn>
          </Actions>
        </template>
      </v-data-table>
      <Pagination :items="items" :loader="loader" :paginado="paginado" />
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  export default {
    name: "Index",
    data: () => ({
      //
    }),
    computed: {
      ...mapState({
        loader: (state) => state.Goodman.loader,
        items: (state) => state.Goodman.collection,
        headers: (state) => state.Goodman.headers,
        paginado: (state) => state.Goodman.paginado,
      }),
      actual() {
        return this.paginado.currentPage;
      },
      search() {
        return this.paginado.search;
      },
    },
    methods: {
      ...mapActions("Goodman", ["getAll", "nueva", "showResource",'deleteResource']),
      eliminar(item) {
        this.$swal({
          title: `¿ Eliminar el schedule #${item.schedule} ?`,
          text: "Esta acción no puede deshacerse",
          icon: "question",
          showCancelButton: true,
        }).then((resp) => {
          if (resp.isConfirmed) {
            this.deleteResource(item.id);
          }
        });
      },
    },
    mounted() {
      this.getAll();
    },
    watch: {
      actual: function() {
        this.getAll();
      },
      search(val) {
        if (!val) this.getAll();
      },
    },
  };
</script>
