<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-card class="mt-5">
    <v-card-title primary-title>
      {{ title }}
    </v-card-title>
    <v-card-text v-if="!nuevo && !editing">
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar Pallet"
            outlined
            dense
          />
        </v-col>
        <v-col cols="12" xl="4">
          <v-btn color="success" @click="nuevo = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            class="ml-2"
            color="primary"
            v-if="items.length"
            @click="imprimir"
          >
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :items="items"
        :headers="headers"
        class="elevation-1 mt-2"
        mobile-breakpoint="0"
        :loading="loader"
        :search.sync="search"
      >
        <template v-slot:[`item.date`]="{ item }">
          {{ item.date | date }}
        </template>
        <template v-slot:[`item.avg_quality`]="{ item }">
          {{ (item.quality / 11).toFixed(2) }}
        </template>
        <template v-slot:[`item.avg_condition`]="{ item }">
          {{ (item.condition / 11).toFixed(2) }}
        </template>
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | folio }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="primary"
            v-if="item.observations"
            @click="mensaje(item)"
          >
            <v-icon>mdi-information</v-icon>
          </v-btn>
          <v-btn icon color="success" @click="editar(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon color="error">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <formulario v-if="nuevo" @cancelar="nuevo = false" />
    <edit-muestreo
      v-if="editing"
      @cancelar="editing = false"
      :resource="resource"
    />
  </v-card>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import Formulario from "./FormMuestreo.vue";
  import EditMuestreo from "./EditMuestreo.vue";
  export default {
    name: "Muestras",
    components: { Formulario, EditMuestreo },
    data: () => ({
      headers: [
        { text: "Id", value: "id", width: "5%" },
        { text: "Date", value: "date", width: "5%" },
        { text: "Pallet ID", value: "pallet_id", width: "5%" },
        { text: "Product", value: "product" },
        { text: "Sum Quality", value: "quality", align: "end", width: "5%" },
        {
          text: "Avg Quality",
          value: "avg_quality",
          align: "end",
          width: "5%",
        },
        {
          text: "Sum Condition",
          value: "condition",
          align: "end",
          width: "5%",
        },
        {
          text: "Avg Condition",
          value: "avg_condition",
          align: "end",
          width: "5%",
        },
        { text: "Firmness", value: "firmness", align: "end", width: "5%" },
        {
          text: "Classification",
          value: "classification",
          align: "center",
          width: "5%",
        },
        { text: "Actions", value: "actions", align: "end", width: "5%" },
      ],
      search: undefined,
      nuevo: false,
      editing: false,
      resource: {},
    }),
    computed: {
      ...mapState({
        loader: (state) => state.Embarques.loader,
        items: (state) => state.Embarques.samples,
        url: (state) => state.Embarques.printQC,
        isMobile: (state) => state.isMobile,
      }),
      title() {
        let title = "Quality Control Samples";
        if (this.nuevo) title = "New Quality Control Sample";
        if (this.editing)
          title = `Editing Sample ${this.$options.filters.folio(
            this.resource.id
          )}`;
        return title;
      },
    },
    methods: {
      ...mapActions("Embarques", ["getSamples"]),
      refrescar() {
        this.getSamples();
      },
      mensaje(item) {
        this.$swal({
          title: "Observations",
          html: this.nl2br(item.observations),
          confirmButtonText: "OK",
        });
      },
      editar(item) {
        this.resource = Object.assign({}, item);
        this.editing = true;
      },
      nl2br(str, is_xhtml) {
        if (typeof str === "undefined" || str === null) {
          return "";
        }
        var breakTag =
          is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
        return (str + "").replace(
          /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
          "$1" + breakTag + "$2"
        );
      },
      imprimir() {        
        window.open(this.url);
      },
    },
    mounted() {
      this.getSamples();
    },
  };
</script>
