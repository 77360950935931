<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-row>
    <v-col cols="12" md="auto">
      <v-text-field
        dense
        outlined
        clearable
        label="Poor Cleanliness"
        v-model.number="resource.q_poor"
        type="number"
        min="0"
        hint="Limpieza"
        :error-messages="errors.q_poor"
      />
    </v-col>
    <v-col cols="12" md="auto">
      <v-text-field
        dense
        outlined
        clearable
        label="Turning Color"
        v-model.number="resource.q_color"
        type="number"
        min="0"
        hint="Coloración"
        :error-messages="errors.q_color"
      />
    </v-col>
    <v-col cols="12" md="auto">
      <v-text-field
        dense
        outlined
        clearable
        label="Mishhapped"
        v-model.number="resource.q_mishhapped"
        type="number"
        min="0"
        hint="Deforme"
        :error-messages="errors.q_mishhapped"
      />
    </v-col>
    <v-col cols="12" md="auto">
      <v-text-field
        dense
        outlined
        clearable
        label="Missize"
        v-model.number="resource.q_missize"
        type="number"
        min="0"
        hint="Diferencia de Tamaño"
        :error-messages="errors.q_missize"
      />
    </v-col>
    <v-col cols="12" md="auto">
      <v-text-field
        dense
        outlined
        clearable
        label="Pulled Stems"
        v-model.number="resource.q_pulled"
        type="number"
        min="0"
        hint="Sin tallo"
        :error-messages="errors.q_pulled"
      />
    </v-col>

    <v-col cols="12" md="auto">
      <v-text-field
        dense
        outlined
        clearable
        label="Scars"
        v-model.number="resource.q_scars"
        type="number"
        min="0"
        hint="Cicatrices"
        :error-messages="errors.q_scars"
      />
    </v-col>
    <v-col cols="12" md="auto">
      <v-text-field
        dense
        outlined
        clearable
        label="Sun Burns"
        v-model.number="resource.q_sun"
        type="number"
        min="0"
        hint="Quemaduras de Sol"
        :error-messages="errors.q_sun"
      />
    </v-col>

    <v-col cols="12" md="auto">
      <v-text-field
        dense
        outlined
        clearable
        label="Lenticels"
        v-model.number="resource.q_lenticels"
        type="number"
        min="0"
        hint="Lenticelas"
        :error-messages="errors.q_lenticels"
      />
    </v-col>
    <v-col cols="12" md="auto">
      <v-text-field
        dense
        outlined
        clearable
        label="Shrivel"
        v-model.number="resource.q_shrivel"
        type="number"
        min="0"
        hint="Marchites"
        :error-messages="errors.q_shrivel"
      />
    </v-col>
    <v-col cols="12">
      <h4>Sum Quality: {{ score }}</h4>
    </v-col>
  </v-row>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    name: "Quality",
    props: {
      resource: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapState({
        errors: (state) => state.errors,
      }),
      score() {
        let total = 0;
        if (this.resource.q_poor) total += this.resource.q_poor;
        if (this.resource.q_color) total += this.resource.q_color;
        if (this.resource.q_mishhapped) total += this.resource.q_mishhapped;
        if (this.resource.q_missize) total += this.resource.q_missize;
        if (this.resource.q_pulled) total += this.resource.q_pulled;
        if (this.resource.q_surface) total += this.resource.q_surface;
        if (this.resource.q_russet) total += this.resource.q_russet;
        if (this.resource.q_scars) total += this.resource.q_scars;
        if (this.resource.q_sun) total += this.resource.q_sun;
        if (this.resource.q_black) total += this.resource.q_black;
        if (this.resource.q_lenticels) total += this.resource.q_lenticels;
        if (this.resource.q_shrivel) total += this.resource.q_shrivel;
        return total;
      },
    },
  };
</script>
