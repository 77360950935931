<!-- eslint-disable -->
<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Datos de la Factura {{ resource.folio }}
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-card class="mt-3">
				<v-form @submit.prevent="updateFactura">
					<v-card-text>
						<p class="negrita" v-html="generales" />
						<v-row>
							<v-col cols="12" xl="3">
								<v-text-field
									type="date"
									label="Fecha  de Elaboración"
									outlined
									dense
									v-model="resource.fecha_elaboracion"
									:error-messages="errors.fecha_elaboracion"
								/>
							</v-col>
							<v-col cols="12" xl="3">
								<v-text-field
									type="date"
									label="Fecha de Pago"
									outlined
									dense
									v-model="resource.fecha_pago"
									:error-messages="errors.fecha_pago"
								/>
							</v-col>
							<v-col cols="12" xl="3">
								<v-text-field
									type="text"
									label="Términos"
									outlined
									dense
									v-model="resource.terminos"
									:error-messages="errors.terminos"
								/>
							</v-col>
							<v-col cols="12" xl="3">
								<v-select
									outlined
									dense
									small-chips
									:items="metodos"
									label="Método de Pago"
									v-model="resource.metodo_pago"
									:error-messages="errors.metodo_pago"
								></v-select>
							</v-col>
							<v-col cols="12">
								<v-textarea
									clearable
									label="Observaciones"
									outlined
									v-model="resource.observaciones"
								/>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-btn
							color="success"
							v-if="modificado"
							:disabled="loader"
							type="submit"
							>Actualizar</v-btn
						>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
	name: 'Factura',
	data: () => ({
		original: undefined,
		metodos: [
			'Cheque',
			'Efectivo',
			'Electrónico',
			'Factoraje',
			'No especificado'
		]
	}),
	computed: {
		...mapState({
			resource: state => state.Facturas.resource,
			loader: state => state.Facturas.loader,
			errors: state => state.errors
		}),
		modificado() {
			return JSON.stringify(this.resource) !== JSON.stringify(this.original);
		},
		generales() {
			return `Cliente: ${this.resource.cliente}&emsp; PU: ${this.resource.pu}&emsp; PO: ${this.resource.po}`;
		}
	},
	mounted() {
		this.original = Object.assign({}, this.resource);
	},
	watch: {
		resource: function(val) {
			this.original = Object.assign({}, val);
		}
	},
	methods: {
		...mapActions('Facturas', ['updateFactura'])
	}
};
</script>
