var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-5"},[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(!_vm.nuevo && !_vm.editing)?_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar Pallet","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","xl":"4"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){_vm.nuevo = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),(_vm.items.length)?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":_vm.imprimir}},[_c('v-icon',[_vm._v("mdi-printer")])],1):_vm._e()],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-2",attrs:{"dense":"","items":_vm.items,"headers":_vm.headers,"mobile-breakpoint":"0","loading":_vm.loader,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.date))+" ")]}},{key:"item.avg_quality",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.quality / 11).toFixed(2))+" ")]}},{key:"item.avg_condition",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.condition / 11).toFixed(2))+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("folio")(item.id))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.observations)?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.mensaje(item)}}},[_c('v-icon',[_vm._v("mdi-information")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.editar(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"error"}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})],1):_vm._e(),(_vm.nuevo)?_c('formulario',{on:{"cancelar":function($event){_vm.nuevo = false}}}):_vm._e(),(_vm.editing)?_c('edit-muestreo',{attrs:{"resource":_vm.resource},on:{"cancelar":function($event){_vm.editing = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }