<!-- eslint-disable -->
<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Ventas
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-row>
				<v-col cols="12" md="8">
					<p class="mt-5 negrita">
						<br />
						Ventas: {{ ventas.length | numero }}
						<span class="mover">Importe Total: {{ importe | moneda }}</span>
						<span class="mover"
							>Importe Comisión: {{ (importe * 0.06) | moneda }}</span
						>
						<span class="mover"
							>Importe Factoraje: {{ factoraje | moneda }}</span
						>
					</p>
				</v-col>
				<v-col cols="12" xl="4">
					<v-container>
						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="Busqueda"
							single-line
							hide-details
						/>
					</v-container>
				</v-col>
			</v-row>
			<v-data-table
				dense
				:headers="headers"
				:items="items"
				class="elevation-1"
				:search.sync="search"
				mobile-breakpoint="0"
			>
				<template v-slot:[`item.fecha`]="{ item }">
					{{ item.fecha | date }}
				</template>
				<template v-slot:[`item.importe`]="{ item }">
					{{ item.importe | moneda }}
				</template>
				<template v-slot:[`item.precio_unitario`]="{ item }">
					{{ item.precio_unitario | moneda }}
				</template>
			</v-data-table>
			<v-btn small color="primary" class="mt-3" @click="descargar">
				<v-icon small>mdi-file-excel</v-icon>
			</v-btn>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
	name: 'Ventas',
	data: () => ({
		headers: [
			{ text: 'Pallet', value: 'pallet' },
			{ text: 'Producto', value: 'producto' },
			{ text: 'Fecha', value: 'fecha' },
			{ text: 'Cliente', value: 'cliente' },
			{ text: 'Metodo de Pago', value: 'metodo' },
			{ text: 'Invoice ID', value: 'factura_id' },
			{ text: 'PU', value: 'pu' },
			{ text: 'Cantidad', value: 'cantidad', align: 'end' },
			{ text: 'Precio U', value: 'precio_unitario', align: 'end' },
			{ text: 'Importe', value: 'importe', align: 'end' }
		],
		search: undefined
	}),
	computed: {
		...mapState({
			items: state => state.Embarques.ventas,
			embarque: state => state.Embarques.resource
		}),
		totalCajas() {
			return _.sumBy(this.items, 'cantidad');
		},
		importe() {
			return _.sumBy(this.items, 'importe');
		},
		ventas() {
			return [...new Set(this.items.map(x => x.pu))].sort();
		},
		factoraje() {
			let filtrados = this.items.filter(x => x.metodo == 'Factoraje'),
				importe = _.sumBy(filtrados, 'importe');
			return importe * 0.019;
		}
	},
	methods: {
		descargar() {
			this.printEmbarque(this.embarque.id);
		},
		printEmbarque(id) {
			window
				.axios({
					url: `/FR/printEmbarque/${id}`,
					method: 'GET',
					responseType: 'blob'
				})
				.then(resp => {
					let fileName = `Embarque ${this.embarque.expediente}.xlsx`,
						fileURL = window.URL.createObjectURL(new Blob([resp.data])),
						fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute('download', fileName);
					document.body.appendChild(fileLink);
					fileLink.click();
				})
				.catch(() => {
					window.swal('Error al procesar,volver a intentar', {
						icon: 'error',
						timer: 1500
					});
				});
		}
	}
};
</script>
