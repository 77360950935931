import Index from './../views/abilities/Index.vue';
import Form from './../views/abilities/Form.vue';
const routes = [
	{
		path: '/Abilities',
		name: 'abilities',
		component: Index,
		meta: {
			name: 'Catálogo de Permisos'
		}
	},
	{
		path: '/Abilities/create',
		name: 'abilities.create',
		component: Form,
		meta: {
			name: 'Nuevo Permiso'
		}
	}
];
export default routes;
