<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="blue lighten-4">
      {{ items.length }} Avocado Products
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card-text>
        <v-row class="my-2">
          <v-col>
            <v-btn small color="success" @click="probar" :disabled="loader">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          dense
          :items="items"
          :headers="headers"
          class="elevation-1 "
          mobile-breakpoint="0"
          hide-default-footer
          :loading="loader"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              icon
              color="error"
              @click="eliminar(item)"
              v-if="item.taken == 0"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-expansion-panel-content>
    <add-avocado v-if="dialog" :dialog="dialog" @cerrar="dialog = false" />
  </v-expansion-panel>
</template>
<script>
  import { mapState } from "vuex";
  import AddAvocado from "./DialogAddAvocado.vue";
  export default {
    name: "Products",
    components: { AddAvocado },
    data: () => ({
      headers: [
        { text: "Product", value: "product" },
        { text: "Boxes", value: "boxes", align: "end", width: "5%" },
        { text: "Pallets", value: "pallets", align: "end", width: "5%" },
        {
          text: "Samples to Take",
          value: "samples",
          align: "end",
          width: "5%",
        },
        { text: "Samples taken", value: "taken", align: "end", width: "5%" },
        { text: "Actions", value: "actions", align: "end", width: "5%" },
      ],
      dialog: false,
    }),
    computed: {
      ...mapState({
        resource: (state) => state.Qc.resource,
        loader: (state) => state.Qc.loader,
      }),
      items() {
        return this.resource.products;
      },
    },
    methods: {
      probar() {
        this.dialog = true;
      },
      eliminar(item) {
        this.$swal({
          title: `¿ Delete Product ${item.product}  ?`,
          text: "this action cannot be undone",
          icon: "question",
          showCancelButton: true,
        }).then((resp) => {
          if (resp.isConfirmed) {
            let url = `FR/quality_controls/deleteProduct/${item.id}`;
            this.$store.commit("Qc/setLoader");
            window.axios
              .delete(url)
              .then((resp) => {
                this.$store.dispatch("message", resp.data.message);
                this.resource.products = resp.data.products;
                this.resource.samples = resp.data.samples;
              })
              .catch((e) => this.$store.dispatch("message", e))
              .finally(() => this.$store.commit("Qc/setLoader", false));
          }
        });
      },
    },
  };
</script>
