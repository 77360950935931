var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"green lighten-4"}},[_vm._v(" Facturas Relacionadas ")]),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[(_vm.userCan('fr-ventas-factura'))?[(!_vm.items.length)?_c('v-btn',{staticClass:"mt-5 ml-3",attrs:{"small":"","color":"success","disabled":_vm.loader},on:{"click":_vm.preguntar}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]:_vm._e(),(_vm.userCan('fr-ventas-nota') && !_vm.items.length)?[(!_vm.sale.nota)?_c('v-btn',{staticClass:"mt-5 ml-3",attrs:{"small":"","color":"primary"},on:{"click":_vm.preguntaNota}},[_c('v-icon',[_vm._v("mdi-card-text")])],1):_vm._e()]:_vm._e()],2),_c('v-col',{attrs:{"cols":"12","xl":"4","offset-xl":"4"}},[_c('v-container',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Busqueda","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"mobile-breakpoint":"0","items-per-page":_vm.items.length,"hide-default-footer":""},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}},{key:"item.pagos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(_vm.importe(item)))+" ")]}},{key:"item.fecha_elaboracion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fecha_elaboracion))+" ")]}},{key:"item.fecha_pago",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fecha_pago))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.getFactura(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"primary"}},[_c('v-icon',[_vm._v("mdi-file-pdf")])],1),_c('v-btn',{attrs:{"icon":"","color":"red"}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.titleModal))])]),_c('v-card-text',[_vm._v(" Cliente: "+_vm._s(_vm.nota.cliente)),_c('br'),_vm._v(" Importe: "+_vm._s(_vm._f("moneda")(_vm.nota.importe))+" ")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","label":"Observaciones"},model:{value:(_vm.nota.observaciones),callback:function ($$v) {_vm.$set(_vm.nota, "observaciones", $$v)},expression:"nota.observaciones"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.cancelar}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"success","disabled":_vm.loader},on:{"click":_vm.sendNota}},[_vm._v("Guardar")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }