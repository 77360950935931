<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-form
      @submit.prevent="$store.dispatch('Sucursales/enviar')"
      v-if="resource"
      :disabled="loader"
    >
      <v-card-text>
        <v-row class="mt-3">
          <v-col>
            <v-text-field
              dense
              outlined
              label="Nombre"
              v-model="resource.name"
              :error-messages="errors.name"
            />
          </v-col>
          <v-col>
            <v-text-field
              dense
              outlined
              label="Prefijo"
              v-model="resource.prefijo"
              :error-messages="errors.prefijo"
            />
          </v-col>
          <v-col>
            <v-autocomplete
              dense
              outlined
              clearable
              label="Cliente Asignado"
              v-model="resource.cliente_id"
              :items="clientes"
              :error-messages="errors.cliente_id"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" type="submit" :disabled="loader">
          {{ text }}
        </v-btn>
        <v-btn color="error" @click="$router.push({ name: 'sucursales' })">
          cancelar
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    name: "Formulario",
    data: () => ({
      original: undefined,
    }),
    computed: {
      ...mapState({
        resource: (state) => state.Sucursales.resource,
        loader: (state) => state.Sucursales.loader,
        clientes: (state) => state.Sucursales.clientes,
        errors: (state) => state.errors,
      }),
      text() {
        return this.resource.id ? "actualizar" : "guardar";
      },
      modificado() {
        return JSON.stringify(this.original) !== JSON.stringify(this.resource);
      },
    },
    mounted() {
      this.$store.commit("setErrors");
      if (!this.resource) {
        if (this.$route.params.id) {
          this.$store.dispatch("Sucursales/editar", this.$route.params.id);
        } else {
          this.$store.dispatch("Sucursales/nuevo");
        }
      } else {
        this.original = Object.assign({}, this.resource);
      }
    },
    watch: {
      resource: function(val) {
        this.original = Object.assign({}, val);
      },
    },
  };
</script>
