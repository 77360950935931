<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-card class="my-2">
    <v-card-title primary-title>
      Nueva Lista de Precios
    </v-card-title>
    <v-card-text>
      <v-form @submit.prevent="getData" :disabled="loader">
        <v-row class="my-2">
          <v-col cols="12" md="6">
            <v-text-field
              dense
              outlined
              clearable
              label="Buscar"
              append-icon="mdi-magnify"
              :disabled="loader"
              v-model="search"
            />
          </v-col>
          <v-col>
            <v-btn color="success" :disabled="loader" @click="nuevo">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn
              color="error"
              :disabled="loader"
              class="ml-2"
              v-if="selected.length"
              @click="pdf"
            >
              <v-icon>mdi-file-pdf-box</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        class="elevation-1"
        v-model="selected"
        show-select
        :loading="loader"
        :search="search"
        :items-per-page="perPage"
        hide-default-footer
      >
        <template v-slot:[`item.price`]="{ item }">{{
          item.price | moneda
        }}</template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="success" @click="editar(item)" :disabled="loader">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon color="error" @click="eliminar(item)" :disabled="loader">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog persistent v-model="dialog" max-width="600" v-if="dialog">
      <price-list
        :resource="resource"
        :categories="categories"
        @cerrar="dialog = false"
        @actualizar="getData"
      />
    </v-dialog>
  </v-card>
</template>

<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  import PriceList from "./CardPriceList.vue";
  import fileDownload from "js-file-download";

  export default {
    name: "ListaPrecios",
    components: { PriceList },
    data: () => ({
      items: undefined,
      search: undefined,
      headers: [
        { text: "Categoría", value: "category", width: "10%" },
        { text: "Descripción", value: "description" },
        {
          text: "Peso Unidad",
          value: "unit_weight",
          width: "5%",
        },
        {
          text: "Unidades X Pallet",
          value: "units_per_pallet",
          align: "end",
          width: "5%",
        },
        { text: "Cantidad", value: "quantity", align: "end", width: "5%" },
        { text: "Precio", value: "price", align: "end", width: "5%" },
        { text: "Acciones", value: "actions", align: "end", width: "5%" },
      ],
      selected: [],
      resource: undefined,
      dialog: false,
      paginado: {
        currentPage: 1,
        categories: [],
        category: null,
        search: undefined,
      },
    }),
    computed: {
      ...mapState({
        loader: (state) => state.loader,
      }),
      categories() {
        return this.paginado.categories;
      },
      currentPage() {
        return this.paginado.currentPage;
      },
      perPage() {
        return this.items ? this.items.length : 0;
      },
    },
    methods: {
      ...mapMutations(["setLoader"]),
      ...mapActions(["errores", "message"]),
      getData() {
        this.dialog = false;
        this.items = undefined;
        this.setLoader();
        window.axios
          .get("price_lists")
          .then((resp) => {
            this.items = resp.data.collection;
            this.paginado = resp.data.paginado;
          })
          .catch((e) => this.errores(e))
          .finally(() => this.setLoader(false));
      },
      nuevo() {
        this.resource = {};
        this.dialog = true;
      },
      editar(item) {
        this.resource = Object.assign({}, item);
        this.dialog = true;
      },
      eliminar(item) {
        let pregunta = `¿Deseas eliminar ${item.description}?`,
          text = "Esta acción no puede deshacerce";
        window
          .swal({
            title: pregunta,
            text: text,
            icon: "warning",
            buttons: ["Cancelar", "Confirmar"],
            dangerMode: true,
          })
          .then((confirmado) => {
            if (confirmado) {
              this.setLoader();
              let url = `price_lists/${item.id}`;
              window.axios
                .delete(url)
                .then((resp) => {
                  this.message(resp.data.message);
                  this.getData();
                })
                .catch((e) => this.errores(e))
                .finally(() => this.setLoader(false));
            }
          });
      },
      pdf() {
        let items = [...new Set(this.selected.map((x) => x.id))],
          url = "FR/dashboard/printLista",
          data = { items };
        window.axios.post(url, data).then((resp) => {
          window.axios
            .get(url, {
              responseType: "blob",
            })
            .then((response) => {
              fileDownload(response.data, "Price List.pdf");
            });
        });
      },
    },
    mounted() {
      this.getData();
    },
  };
</script>
