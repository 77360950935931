<!-- eslint-disable -->
<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Datos Generales Embarque {{ embarque.id | folio }}
			<span style="margin-left:30px"
				>Expediente: {{ embarque.expediente }}</span
			>
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-row class="mt-3">
				<v-col cols="12" md="6" xl="4">
					Fecha: {{ embarque.fecha | date }}<br />
					Factura / Expediente: {{ embarque.expediente }}<br />
					Purchase Order: {{ embarque.po }}<br />
					N° Certificado: {{ embarque.certificado }} <br />
				</v-col>
				<v-col cols="12" md="6" xl="4">
					Agente Aduanal Mexicano: {{ embarque.agenteMexicano }}<br />
					Agente Aduanal Americano: {{ embarque.agenteAmericano }} <br />
					<v-btn small color="error" class="mt-3" :href="embarque.pdfManifiesto" target="_blank">
						<v-icon small>mdi-file-pdf</v-icon>
						manifiesto
					</v-btn>
				</v-col>
				<v-col cols="12" md="6" xl="4">
					Transporte: {{ embarque.transporte }}<br />
					Operador: {{ embarque.operador }}<br />
				</v-col>
			</v-row>
			<v-row class="mt-3">
				<template v-if="!embarque.importe">
					<v-col cols="12" xl="6">
						<v-file-input
							class="mt-5"
							v-model="file"
							dense
							outlined
							placeholder="Seleccionar archivo para subir"
							prepend-icon="mdi-attachment"
							accept="application/xml"
							label="Subir Archivo XML de factura"
						/>
					</v-col>
					<v-col cols="12" xl="2">
						<v-btn
							class="mt-5"
							color="success"
							v-if="esXml"
							:disabled="isLoading"
							@click="enviar"
							>Subir</v-btn
						>
					</v-col>
				</template>
				<v-col v-else>
					Importe de la Factura : {{ embarque.importe | moneda }}
				</v-col>
			</v-row>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
	name: 'Generales',
	data: () => ({
		original: {},
		file: undefined,
		isLoading: false
	}),
	computed: {
		...mapState({
			embarque: state => state.Embarques.resource
		}),
		esXml() {
			return this.file && this.file.name.includes('.xml');
		}
	},
	mounted() {
		this.original = Object.assign({}, this.embarque);
	},
	methods: {
		...mapActions('Embarques', ['getEmbarque']),
		enviar() {
			let formData = new FormData();
			formData.append('file', this.file);
			this.isLoading = true;
			window.axios
				.post(`/FR/Subir-Factura/${this.embarque.id}`, formData)
				.then(resp => {
					window.swal(resp.data.message, { icon: 'success', timer: 1500 });
					this.getEmbarque(this.embarque.id);
				})
				.catch(e => {
					window.swal(e.response.data.message, { icon: 'error', timer: 5000 });
				})
				.finally(() => (this.isLoading = false));
		}
	}
};
</script>
