<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card class="my-2">
    <v-card-text>
      <v-data-table
        dense
        :items="items"
        :headers="headers"
        class="elevation-1"
        mobile-breakpoint="0"
        :loading="loader"
        hide-default-footer
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | folio }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.cajas`]="{ item }">
          {{ item.cajas | numero }}
        </template>
      </v-data-table>
      <Pagination :loader="loader" :items="items" :paginado="paginado" />
    </v-card-text>
    <v-card-actions>
      <v-btn small color="primary" @click="getAll" :disabled="loader">
        actualizar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: "Pagos",
    data: () => ({
      items: [],
      headers: [
        { text: "Folio", value: "folio" },
        { text: "Usuario", value: "usuario" },
        { text: "Fecha", value: "fecha" },
        { text: "PU", value: "pu" },
        { text: "Cliente", value: "cliente" },
        { text: "Forma Pago", value: "metodo" },
        { text: "Importe", value: "importe", align: "end" },
      ],
      loader: false,
      paginado: { currentPage: 1 },
    }),
    computed: {
      actual() {
        return this.paginado.currentPage;
      },
    },
    mounted() {
      this.getAll();
    },
    watch: {
      actual() {
        this.getAll();
      },
    },
    methods: {
      getAll() {
        let url = "FR/dashboard/getPagos";
        this.loader = true;
        this.items = [];
        window.axios
          .get(url, {
            params: { page: this.paginado.currentPage },
          })
          .then((resp) => {
            this.items = resp.data.collection;
            this.paginado = resp.data.paginado;
          })
          .catch((e) => this.$store.dispatch("errores", e))
          .finally(() => (this.loader = false));
      },
    },
  };
</script>
