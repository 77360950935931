var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"my-2"},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"mt-2"},[_c('v-autocomplete',{attrs:{"clearable":"","outlined":"","dense":"","small-chips":"","label":"Seleccionar Producto","items":_vm.productos},model:{value:(_vm.producto),callback:function ($$v) {_vm.producto=$$v},expression:"producto"}})],1)],1),(_vm.producto)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"mobile-breakpoint":"0","headers":_vm.headersExternos,"items":_vm.disponibles,"hide-default-footer":"","items-per-page":_vm.disponibles.length},scopedSlots:_vm._u([{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fecha))+" ")]}},{key:"item.seleccionados",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"righted-input",attrs:{"dense":"","type":"number","max":item.disponibles,"min":"0","hide-details":"auto"},model:{value:(item.seleccionados),callback:function ($$v) {_vm.$set(item, "seleccionados", _vm._n($$v))},expression:"item.seleccionados"}})]}},{key:"item.precio",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"righted-input",attrs:{"dense":"","type":"number","step":"0.01","min":"0","hide-details":"auto"},model:{value:(item.precio),callback:function ($$v) {_vm.$set(item, "precio", _vm._n($$v))},expression:"item.precio"}})]}},{key:"item.fecha_minima",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fecha_minima))+" ")]}},{key:"item.precio_compra",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.precio_compra))+" ")]}}],null,true)}):_vm._e(),_c('v-row',{staticClass:"mt-3"},[(_vm.listos.length)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mt-3"},[_c('v-card-title',[_vm._v("Aguacates Externos seleccionados")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","hide-default-footer":"","headers":_vm.headerListosExternos,"items":_vm.listos},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.eliminarExterno(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"item.precio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.precio))+" ")]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(_vm.importe(item)))+" ")]}}],null,true)})],1),_c('v-card-text',[_vm._v(" Total de Pallets a agregar: "+_vm._s(_vm.palletsTotal.toFixed(2))+" "),_c('br'),_vm._v(" Importe: "+_vm._s(_vm._f("moneda")(_vm.importTotal))+" ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"success","disabled":_vm.loader},on:{"click":_vm.addExternos}},[_vm._v("Agregar Externos")])],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }