<!-- @format -->

<!-- eslint-disable -->
<template>
  <div class="text-center">
    <v-dialog persistent v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Agregar Articulos a Compra
        </v-card-title>
        <v-form :disabled="loader" @submit.prevent="enviar">
          <v-card-text class="mt-2">
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  outlined
                  label="Seleccione Artículo"
                  :items="supplies"
                  item-text="name"
                  item-value="id"
                  v-model="resource.supply_id"
                  :error-messages="errors.supply_id"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  label="Cantidad"
                  v-model.number="resource.quantity"
                  :error-messages="errors.quantity"
                  type="number"
                  step="0.01"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  label="Precio de Compra Unitario"
                  v-model.number="resource.price"
                  :error-messages="errors.price"
                  type="number"
                  step="0.01"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  label="Importe"
                  v-model.number="importe"
                  type="number"
                  step="0.01"
                  disabled
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  label="Pallet ID "
                  v-model.number="resource.pallet_id"
                  :error-messages="errors.pallet_id"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  type="date"
                  label="Fecha de Corte"
                  outlined
                  dense
                  v-model="resource.fecha_corte"
                  :error-messages="errors.fecha_corte"
                />
              </v-col>
              <v-col cols="12">
                <v-combobox
                  type="text"
                  label="Tipo de Caja"
                  outlined
                  dense
                  v-model="resource.tipo_caja"
                  :error-messages="errors.tipo_caja"
                  :items="cajas"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn small color="primary" type="submit" :disabled="loader">
              guardar
            </v-btn>
            <v-btn
              small
              color="error"
              @click="$emit('cancelar')"
              :disabled="loader"
            >
              cancelar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  export default {
    name: "AddSupply",
    props: {
      dialog: Boolean,
    },
    data: () => ({
      resource: {
        pallet_id: undefined,
        price: undefined,
        quantity: undefined,
      },
    }),
    computed: {
      ...mapState({
        errors: (state) => state.errors,
        loader: (state) => state.Compras.loader,
        supplies: (state) => state.Compras.supplies,
        compra: (state) => state.Compras.resource,
        cajas: (state) => state.Compras.cajas,
      }),
      importe() {
        if (!this.resource.price || !this.resource.quantity) return null;
        let importe = this.resource.quantity * this.resource.price;
        return importe.toFixed(2);
      },
    },
    methods: {
      ...mapMutations(["setErrors"]),
      ...mapActions(["errores", "message"]),
      ...mapMutations("Compras", ["setLoader", "setCompraSupplies"]),
      enviar() {
        let url = `FR/compras/addSupply/${this.compra.id}`;
        this.setErrors();
        this.setLoader();
        window.axios
          .post(url, this.resource)
          .then((resp) => {
            this.message(resp.data.message);
            this.setCompraSupplies(resp.data.compraSupplies);
            this.$emit("cancelar");
          })
          .catch((e) => this.errores(e))
          .finally(() => this.setLoader(false));
      },
    },
    mounted() {
      this.setErrors();
    },
  };
</script>
