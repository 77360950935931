<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Producto
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="mt-2">
        <v-col> Cantidad de Cajas: {{ cajas | numero }} </v-col>
        <v-col> Importe: {{ importe | moneda }} </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        class="elevation-1 mt-2"
      >
        <!-- prettier-ignore -->
        <template v-slot:[`item.importe`]="{ item }">{{ item.importe | moneda }}</template>
        <!-- prettier-ignore -->
        <template v-slot:[`item.precio`]="{ item }">{{ item.precio | moneda }}</template>
      </v-data-table>
      <v-row class="my-2">
        <v-col>
          <v-btn color="success" @click="dialog = true"
            >enviar precios a correo</v-btn
          >
        </v-col>
      </v-row>
    </v-expansion-panel-content>
    <Modal v-if="dialog" :budget="budget" :dialog="dialog" @cancelar="dialog = false" />
  </v-expansion-panel>
</template>

<script>
  import { mapState } from "vuex";
  import Modal from "./ModalEmail.vue";
  export default {
    name: "Products",
    components: { Modal },
    computed: {
      ...mapState({
        items: (state) => state.Sucursales.products,
        budget: (state) => state.Sucursales.budget,
      }),
      cajas() {
        return window._.sumBy(this.items, "cantidad");
      },
      importe() {
        return window._.sumBy(this.items, "importe");
      },
    },
    data: () => ({
      headers: [
        { text: "Producto", value: "producto" },
        { text: "Pallet Id", value: "pallet_id" },
        { text: "Cantidad", value: "cantidad", align: "end" },
        { text: "Precio U", value: "precio", align: "end" },
        { text: "Importe", value: "importe", align: "end" },
      ],
      dialog: false,
    }),
  };
</script>
