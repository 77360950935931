<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Pagos Registrados
    </v-expansion-panel-header>
    <v-expansion-panel-content v-if="!nuevo">
      <v-row>
        <v-col cols="12" xl="8" md="6">
          <v-btn
            small
            color="success"
            class="mt-5"
            v-if="puedeNuevo && userCan('fr-facturas-pagos-add')"
            @click="hacerPago"
            :disabled="loader"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" md="6">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :headers="headers"
        :items="pagos"
        class="elevation-1"
        :search.sync="search"
        v-if="!nuevo"
        mobile-breakpoint="0"
      >
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="primary"
            @click="information(item)"
            v-if="item.observaciones"
            :disabled="loader"
          >
            <v-icon>mdi-information</v-icon>
          </v-btn>
          <v-btn
            icon
            color="red"
            @click="eliminar(item)"
            :disabled="loader"
            v-if="userCan('fr-facturas-pagos-remove') && !item.credito_id"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-expansion-panel-content>
    <v-expansion-panel-content v-else>
      <v-card class="mt-3">
        <v-form @submit.prevent="createPago">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  outlined
                  dense
                  type="date"
                  label="Fecha de Elaboracion"
                  v-model="pago.fecha"
                  :error-messages="errors.fecha"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  outlined
                  dense
                  type="number"
                  step="0.01"
                  label="Importe"
                  v-model.number="pago.importe"
                  :error-messages="errors.importe"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-combobox
                  outlined
                  dense
                  small-chips
                  label="Método de Pago"
                  :items="metodos"
                  v-model="pago.metodo_pago"
                  :error-messages="errors.metodo_pago"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  label="Referencia"
                  v-model="pago.referencia"
                  :error-messages="errors.referencia"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  type="text"
                  label="Observaciones"
                  v-model="pago.observaciones"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="success" type="submit" :disabled="loader">
              guardar
            </v-btn>
            <v-btn color="error" @click="cancelar">
              cancelar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
  import { mapState, mapActions, mapMutations } from "vuex";
  export default {
    name: "Pagos",
    data: () => ({
      perPage: 10,
      headers: [
        { text: "Folio", value: "folio" },
        { text: "Fecha Elaboración", value: "fecha" },
        { text: "Método Pago", value: "metodo_pago" },
        { text: "Referencia", value: "referencia" },
        { text: "Importe", value: "importe", align: "end" },
        { text: "Usuario", value: "usuario" },
        { text: "Acciones", value: "actions", align: "end" },
      ],
      search: undefined,
      nuevo: false,
    }),
    computed: {
      ...mapState({
        pagos: (state) => state.Facturas.pagos,
        detalles: (state) => state.Facturas.detalles,
        loader: (state) => state.Facturas.loader,
        metodos: (state) => state.Facturas.metodos,
        pago: (state) => state.Facturas.pago,
        errors: (state) => state.errors,
      }),
      puedeNuevo() {
        return (
          _.sumBy(this.detalles, "importe") > _.sumBy(this.pagos, "importe")
        );
      },
    },
    watch: {
      pagos: function() {
        this.cancelar();
      },
    },
    methods: {
      ...mapMutations(["setErrors"]),
      ...mapMutations("Facturas", ["SET_PAGO"]),
      ...mapActions("Facturas", ["createPago", "deletePago"]),
      eliminar(item) {
        let pregunta = `¿Deseas eliminar el pago ${item.folio}?`;
        window
          .swal({
            title: pregunta,
            icon: "warning",
            buttons: ["Cancelar", "Confirmar"],
            dangerMode: true,
          })
          .then((confirmado) => {
            if (confirmado) {
              this.deletePago(item.id);
            }
          });
      },
      hacerPago() {
        this.nuevo = true;
        this.SET_PAGO({});
      },
      cancelar() {
        this.nuevo = false;
        this.SET_PAGO(undefined);
        this.setErrors([]);
      },
      information(item) {
        this.$swal({
          title: "Observaciones",
          html: `${item.observaciones}`,
          confirmButtonColor: "#d33",
          confirmButtonText: "Cerrar",
        });
      },
    },
  };
</script>

