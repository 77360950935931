<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      {{ title }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-form :disabled="loader" @submit.prevent="sendResource">
        <v-card-text>
          <v-row class="mt-2 mb-1">
            <v-col cols="12" md="auto">
              <v-text-field
                dense
                outlined
                clearable
                label="Date"
                type="date"
                v-model="resource.date"
                :error-messages="errors.date"
              />
            </v-col>
            <v-col cols="12" md="auto">
              <v-text-field
                dense
                outlined
                clearable
                label="Lot"
                v-model="resource.lot"
                :error-messages="errors.lot"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                dense
                outlined
                clearable
                label="Supplier"
                type="search"
                :items="suppliers"
                v-model="resource.supplier"
                :error-messages="errors.supplier"
              />
            </v-col>
            <v-col cols="12" md="auto">
              <v-autocomplete
                dense
                outlined
                clearable
                label="Species"
                type="search"
                :items="species"
                v-model="resource.species"
                :error-messages="errors.species"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-combobox
                dense
                outlined
                clearable
                label="Type of Inspect"
                type="search"
                :items="types"
                v-model="resource.type"
                :error-messages="errors.type"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-combobox
                dense
                outlined
                clearable
                label="Inspect Site"
                type="search"
                :items="sites"
                v-model="resource.site"
                :error-messages="errors.site"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                dense
                outlined
                clearable
                label="Labels"
                v-model="resource.label"
                :error-messages="errors.label"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                dense
                outlined
                clearable
                label="Observations"
                v-model="resource.observations"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-if="modificado || !$route.params.id">
          <v-btn color="success" type="submit" :disabled="loader">{{
            btntitle
          }}</v-btn>
          <v-btn color="error" @click="cancelar">cancel</v-btn>
        </v-card-actions>
      </v-form>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  export default {
    name: "Lot",
    data: () => ({
      original: {},
    }),
    computed: {
      ...mapState({
        resource: (state) => state.Qc.resource,
        loader: (state) => state.Qc.loader,
        suppliers: (state) => state.Qc.suppliers,
        species: (state) => state.Qc.species,
        types: (state) => state.Qc.types,
        sites: (state) => state.Qc.sites,
        errors: (state) => state.errors,
      }),
      title() {
        return this.$route.params.id ? `General Data Lot ${this.original.lot}` : "Please fill the form";
      },
      btntitle() {
        return this.$route.params.id ? "update" : "save";
      },
      modificado() {
        return JSON.stringify(this.original) !== JSON.stringify(this.resource);
      },
    },
    beforeMount() {
      this.$store.commit("setErrors");
    },
    mounted() {
      this.original = Object.assign({}, this.resource);
    },
    watch: {
      resource(val) {
        this.original = Object.assign({}, val);
      },
    },
    methods: {
      ...mapActions("Qc", ["sendResource"]),
      cancelar() {
        this.$store.commit("setErrors");
        if (this.$route.params.id) {
          this.$store.commit("Qc/setResource", this.original);
          this.$store.commit("Qc/setPanel", []);
        } else {
          this.$router.push({ name: "qc" }).catch(() => {});
        }
      },
    },
  };
</script>
