<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-form @submit.prevent="getBudgets" :disabled="loader">
        <v-row class="mt-2">
          <v-col cols="12" md="6" xl="4">
            <v-text-field
              dense
              outlined
              clearable
              label="Busqueda por PO o PU"
              v-model="paginado.search"
            />
          </v-col>
          <v-col cols="12" md="6" xl="4">
            <v-autocomplete
              dense
              outlined
              clearable
              label="Filtrar por Cliente"
              :items="paginado.customers"
              v-model="paginado.customer_id"
            />
          </v-col>
          <v-col cols="12" md="6" xl="4">
            <v-btn color="info" type="submit">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        dense
        :items="items"
        :headers="headers"
        class="elevation-1"
        mobile-breakpoint="0"
        :items-per-page="paginado.perPage"
        hide-default-footer
        :item-class="row_classes"
        :loading="loader"
      >
        <!-- prettier-ignore -->
        <template v-slot:[`item.actions`]="{ item }">
					<v-btn icon color="primary" @click="mostrar(item)">
						<v-icon>mdi-information</v-icon>
					</v-btn>
					<v-btn icon color="error" v-if="item.actions.pdf1" :href="item.pdfPu" target="_blank">
						<v-icon>mdi-file-pdf</v-icon>
					</v-btn>
					<v-btn icon color="info" @click="email(item)">
						<v-icon>mdi-email</v-icon>
					</v-btn>
				</template>
        <!-- prettier-ignore -->
        <template v-slot:[`item.date`]="{ item }">{{ item.date | date }}</template>
        <!-- prettier-ignore -->
        <template v-slot:[`item.importe`]="{ item }">{{ item.importe | moneda }}</template>
      </v-data-table>
      <Pagination :loader="loader" :items="items" :paginado="paginado" />
    </v-card-text>
	<Modal v-if="dialog" :budget="budget" :dialog="dialog" @cancelar="dialog = false" />
  </v-card>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import Modal from './budget/ModalEmail.vue'
  export default {
    name: "Budgets",
	components: { Modal },
    beforeMount() {
      this.getBudgets();
    },
    computed: {
      ...mapState({
        items: (state) => state.Sucursales.budgets,
        loader: (state) => state.Sucursales.loader,
        paginado: (state) => state.Sucursales.paginadoBudgets,
      }),
      actual() {
        return this.paginado.currentPage;
      },
      customer_id() {
        return this.paginado.customer_id;
      },
    },
    data: () => ({
      headers: [
        { text: "Folio", value: "folio" },
        { text: "Usuario", value: "user" },
        { text: "Fecha", value: "date" },
        { text: "Cliente", value: "cliente" },
        { text: "PO", value: "po" },
        { text: "PU", value: "pu" },
        { text: "Comprador", value: "buyer" },
        { text: "Cajas", value: "cajas", align: "end" },
        { text: "Importe", value: "importe", align: "end" },
        { text: "Acciones", value: "actions", align: "end" },
      ],
      dialog: false,
      budget: undefined,
    }),
    methods: {
      ...mapActions("Sucursales", ["getBudgets", "showBudget"]),
      mostrar(item) {
        this.showBudget(item.id);
      },
      email(item) {
        this.budget = item;
        this.dialog = true;
      },
    },
    watch: {
      customer_id() {
        this.getBudgets();
      },
    },
  };
</script>
