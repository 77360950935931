<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-row class="my-2">
        <v-col cols="12" md="6">
          <v-autocomplete
            dense
            outlined
            clearable
            label="Filter by Supplier"
            v-model="paginado.supplier"
            :items="paginado.suppliers"
          />
        </v-col>
        <v-col>
          <v-btn color="success" @click="nuevo">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :items="items"
        :headers="headers"
        class="elevation-1"
        mobile-breakpoint="0"
        :items-per-page="paginado.perPage"
        hide-default-footer
        :loading="loader"
      >
        <template v-slot:[`item.date`]="{ item }">
          {{ item.date | date }}
        </template>
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | folio }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="secondary" v-if="item.print" :href="item.print" target="_blank">
            <v-icon>mdi-printer</v-icon>
          </v-btn>

          <v-btn
            icon
            color="primary"
            @click="information(item)"
            v-if="item.observations"
            :disabled="loader"
          >
            <v-icon>mdi-information</v-icon>
          </v-btn>
          <v-btn icon color="success" @click="getResource(item.id)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <Pagination :loader="loader" :items="items" :paginado="paginado" />
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  export default {
    name: "Index",
    computed: {
      ...mapState({
        loader: (state) => state.Qc.loader,
        items: (state) => state.Qc.collection,
        headers: (state) => state.Qc.headers,
        paginado: (state) => state.Qc.paginado,
      }),
      currentPage() {
        return this.paginado.currentPage;
      },
    },
    methods: {
      ...mapActions("Qc", ["getAll", "nuevo", "getResource"]),
      information(item) {
        this.$swal({
          title: "Observations",
          html: `${item.observations}`,
          confirmButtonColor: "#d33",
          confirmButtonText: "Cerrar",
        });
      },
    },
    mounted() {
      this.getAll();
    },
    watch: {
      currentPage() {
        this.getAll();
      },
    },
  };
</script>
