<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text v-if="nuevo">
      <v-form @submit.prevent="guardar">
        <v-row>
          <v-col cols="12" xl="4">
            <v-autocomplete
              clearable
              small-chips
              dense
              outlined
              v-model="cliente_id"
              label="Cliente"
              :items="clientes"
              :error-messages="errors.cliente_id"
            />
          </v-col>
          <v-col cols="12" xl="4">
            <v-combobox
              dense
              outlined
              v-model="comprador"
              :items="buyers"
              label="Comprador"
            />
          </v-col>
          <v-col cols="12" xl="4">
            <v-select
              outlined
              clearable
              small-chips
              dense
              v-model="customer_address_id"
              label="Domicilio"
              :items="domicilios2"
              :error-messages="errors.customer_address_id"
            />
          </v-col>
          <v-col cols="12" xl="4">
            <v-select
              outlined
              clearable
              small-chips
              dense
              v-model="warehouse_address_id"
              label="Warehouse"
              :items="warehouses"
              :error-messages="errors.warehouse_address_id"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="primary" type="submit" :disabled="loader">
              Guardar
            </v-btn>
            <v-btn color="error" class="ml-3" @click="cancelar">cancelar</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-text v-else>
      <v-form @submit.prevent="getAll">
        <v-row class="mt-3">
          <v-col cols="12" md="3">
            <v-autocomplete
              dense
              outlined
              clearable
              label="Filtrar por cliente"
              :items="clientes"
              v-model="paginado.cliente_id"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              outlined
              clearable
              label="Buscar por PU o PO"
              v-model="paginado.search"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              dense
              outlined
              clearable
              label="Filtrar por estado"
              v-model="paginado.estado"
              :items="estados"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-btn color="primary" type="submit">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              color="success"
              @click="nuevo = true"
              class="ml-3"
              v-if="userCan('fr-budgets-create')"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        dense
        :items="items"
        :headers="headers"
        class="elevation-1 mt-3"
        mobile-breakpoint="0"
        hide-default-footer
        :items-per-page="paginado.perPage"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | folio }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.finalizado`]="{ item }">
          {{ item.finalizado ? "SI" : "" }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <Actions
            :item="item"
            :loader="loader"
            @editar="detalles(item.id)"
            @eliminar="eliminar"
          >
            <v-btn
              icon
              color="primary"
              :href="item.shipper"
              target="_blank"
              v-if="userCan('fr-budgets-pdfshipper') && item.importe > 0"
            >
              <v-icon>mdi-file-pdf</v-icon>
            </v-btn>
            <v-btn
              icon
              color="red"
              :href="item.pdfPU"
              target="_blank"
              v-if="userCan('fr-budgets-pdfpu') && item.importe > 0"
            >
              <v-icon>mdi-file-pdf</v-icon>
            </v-btn>
            <v-btn
              icon
              color="info"
              v-if="item.actions.email"
              @click="email(item)"
            >
              <v-icon>mdi-email</v-icon>
            </v-btn>
          </Actions>
        </template>
      </v-data-table>
      <v-row v-if="!loader && items && items.length">
        <v-col class="mt-5">
          Registros Encontrados: {{ paginado.registros | numero }}
        </v-col>
        <v-col cols="12" md="8">
          <v-pagination
            class="mt-3"
            v-model="paginado.currentPage"
            :length="paginado.lastPage"
            total-visible="8"
          />
        </v-col>
        <v-col></v-col>
      </v-row>
    </v-card-text>
    <modal-email
      v-if="dialog"
      :dialog="dialog"
      :item="budget"
      :pdfTypes="pdfTypes"
      :url="url"
      @cancelar="dialog = false"
    />
  </v-card>
</template>

<script>
  import { mapState, mapActions, mapMutations } from "vuex";
import ModalEmail from '../../components/ModalEmail.vue';
  export default {
  components: { ModalEmail },
    name: "Budgets",
    data: () => ({
      search: undefined,
      nuevo: false,
      cliente_id: undefined,
      customer_address_id: undefined,
      warehouse_address_id: 4,
      comprador: undefined,
      estados: [
        { text: "Todos", value: null },
        { text: "Pendiente", value: "pendiente" },
        { text: "Finalizado", value: "finalizado" },
        { text: "Cancelada", value: "cancelada" },
      ],
      budget: {},
      dialog: false,
      pdfTypes: ["PU", "Shipper Sales"],
      url: undefined,
    }),
    computed: {
      ...mapState({
        headers: (state) => state.Budgets.headers,
        items: (state) => state.Budgets.collection,
        clientes: (state) => state.Budgets.clientes,
        warehouses: (state) => state.Budgets.warehouses,
        domicilios: (state) => state.Budgets.domicilios,
        loader: (state) => state.Budgets.loader,
        errors: (state) => state.errors,
        compradores: (state) => state.Budgets.compradores,
        paginado: (state) => state.Budgets.paginado,
      }),
      domicilios2() {
        return this.domicilios.filter((x) => x.cliente_id == this.cliente_id);
      },
      buyers() {
        let compradores = this.compradores.filter(
          (x) => x.cliente_id == this.cliente_id
        );
        return [...new Set(compradores.map((x) => x.comprador))].sort();
      },
      actual() {
        return this.paginado.currentPage;
      },
      cliente() {
        return this.paginado.cliente_id;
      },
      estado() {
        return this.paginado.estado;
      },
    },
    mounted() {
      this.getAll();
    },
    watch: {
      cliente_id: function() {
        this.customer_address_id = undefined;
        this.comprador = undefined;
      },
      actual: function() {
        this.getAll();
      },
      cliente: function() {
        this.getAll();
      },
      estado: function() {
        this.getAll();
      },
    },
    methods: {
      ...mapActions("Budgets", [
        "getAll",
        "detalles",
        "finalizar",
        "storeBudget",
      ]),
      ...mapMutations("Budgets", ["setLoader"]),
      ...mapMutations(["setErrors"]),
      eliminar(item) {
        let pregunta = `¿Deseas cancelar el folio ${item.folio}?`,
          text = "El folio ya no podrá ser utilizado";
        window
          .swal({
            title: pregunta,
            text: text,
            icon: "warning",
            buttons: ["Cancelar", "Confirmar"],
            dangerMode: true,
          })
          .then((confirmed) => {
            if (confirmed) {
              this.finalizar(item.id);
            }
          });
      },
      cancelar() {
        this.nuevo = false;
        this.cliente_id = this.customer_address_id = this.comprador = undefined;
        this.warehouse_address_id = 4;
        this.setErrors([]);
        this.setLoader(false);
      },
      guardar() {
        let budget = {
          cliente_id: this.cliente_id,
          customer_address_id: this.customer_address_id,
          warehouse_address_id: this.warehouse_address_id,
          comprador: this.comprador,
        };
        this.storeBudget(budget);
      },
      email(item) {
        this.url = item.urlEmail;
        this.budget = Object.assign({}, item);
        this.dialog = true;
      },
    },
  };
</script>
