<!-- eslint-disable -->
<template>
	<v-card class="mt-3">
		<v-form @submit.prevent="enviar">
			<v-card-text>
				<v-row>
					<v-col cols="12" md="3">
						<v-select
							outlined
							dense
							label="Factura"
							:items="facturas"
							item-value="id"
							item-text="folio"
							v-model="pago.factura_id"
						></v-select>
					</v-col>
					<v-col cols="12" md="3">
						<v-text-field
							outlined
							dense
							type="date"
							label="Fecha de Elaboracion"
							v-model="pago.fecha"
							:error-messages="errors.fecha"
						/>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" md="3">
						<v-text-field
							outlined
							dense
							type="number"
							step="0.01"
							label="Importe"
							v-model.number="pago.importe"
							:error-messages="errors.importe"
						/>
					</v-col>
					<v-col cols="12" md="3">
						<v-combobox
							outlined
							dense
							small-chips
							label="Método de Pago"
							:items="metodos"
							v-model="pago.metodo_pago"
							:error-messages="errors.metodo_pago"
						/>
					</v-col>
					<v-col cols="12" md="3">
						<v-text-field
							outlined
							dense
							type="text"
							label="Referencia"
							v-model="pago.referencia"
							:error-messages="errors.referencia"
						/>
					</v-col>
					<v-col cols="12">
						<v-textarea
							outlined
							type="text"
							label="Observaciones"
							v-model="pago.observaciones"
						/>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-btn
					color="success"
					type="submit"
					:disabled="isLoading"
					v-if="pago.factura_id"
				>
					guardar
				</v-btn>
				<v-btn color="error" @click="cancelar">
					cancelar
				</v-btn>
			</v-card-actions>
		</v-form>
	</v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
	name: 'FormularioPago',
	data: () => ({
		pago: {}
	}),
	computed: {
		...mapState({
			errors: state => state.errors,
			facturas: state => state.Ventas.facturas,
			metodos: state => state.Ventas.metodos,
			isLoading: state => state.Ventas.loader
		})
	},
	methods: {
		...mapActions('Ventas', ['nuevoPago']),
		...mapMutations('Ventas', ['setLoader']),
		cancelar() {
			this.setLoader(false);
			this.$emit('cancelar');
		},
		enviar() {
			this.nuevoPago(this.pago);
		}
	}
};
</script>
