<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Archivos
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" xl="6">
          <v-file-input
            class="mt-5"
            v-model="file"
            dense
            outlined
            placeholder="Seleccionar archivo para subir"
            prepend-icon="mdi-attachment"
            label="Subir Archivo"
          />
        </v-col>
        <v-col cols="12" xl="2">
          <v-btn
            class="mt-5"
            color="success"
            v-if="file"
            :disabled="loader"
            @click="enviar()"
            >Subir</v-btn
          >
        </v-col>
        <v-col cols="12" xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        class="elevation-1"
        :search.sync="search"
        mobile-breakpoint="0"
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | dateTime }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="success"
            :href="item.href"
            target="_blank"
            v-if="item.actions.download"
          >
            <v-icon>mdi-cloud-download</v-icon>
          </v-btn>
          <v-btn icon color="error" @click="eliminar(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  export default {
    name: "Archivos",
    data: () => ({
      file: undefined,
      search: undefined,
      headers: [
        { text: "Archivo", value: "name" },
        { text: "Fecha / Hora", value: "created_at" },
        { text: "Usuario", value: "user" },
        { text: "Acciones", value: "actions", align: "end" },
      ],
    }),
    computed: {
      ...mapState({
        items: (state) => state.Compras.files,
        loader: (state) => state.Compras.loader,
      }),
    },
    watch: {
      items: function() {
        this.file = undefined;
      },
    },
    methods: {
      ...mapActions("Compras", ["uploadFile", "deleteFile"]),
      enviar() {
        let formData = new FormData();
        formData.append("file", this.file);
        this.uploadFile(formData);
      },
      eliminar(item) {
        let pregunta = `¿Eliminar el archivo ${item.name}?`;
        window
          .swal({
            title: pregunta,
            icon: "warning",
            buttons: ["Cancelar", "Confirmar"],
            dangerMode: true,
          })
          .then((confirmed) => {
            if (confirmed) {
              this.deleteFile(item.id);
            }
          });
      },
    },
  };
</script>
