<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-row class="my-2">
        <v-col cols="12" md="6">
          <v-text-field
            dense
            outlined
            clearable
            label="Buscar"
            v-model="search"
          />
        </v-col>
        <v-col>
          <v-btn color="primary" :href="this.url" target="_blank">
            <v-icon>mdi-file-pdf</v-icon>
          </v-btn>
          <v-btn color="info" @click="dialog2 = true" class="ml-2">
            <v-icon>mdi-email</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="prices"
            class="elevation-1 my-2"
            dense
            mobile-breakpoint="0"
            hide-default-footer
            :items-per-page="prices.length"
            :search.sync="search"
            :loading="loader"
          >
            <template v-slot:[`item.updated_at`]="{ item }">
              {{ item.updated_at | dateTime }}
            </template>
            <template v-slot:[`item.price`]="{ item }">
              {{ item.price | moneda }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                icon
                color="success"
                @click="editar(item)"
                v-if="userCan('actualizar-precios')"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <ModalPrice
      v-if="dialog"
      :dialog="dialog"
      :price="price"
      @actualizar="actualizar"
      @cancelar="cancelar"
    />
    <ModaleEmail v-if="dialog2" :dialog="dialog2" @cancelar="dialog2 = false" />
  </v-card>
</template>

<script>
  import ModalPrice from "./ModalPrice.vue";
  import ModaleEmail from "./SendPricesByEmail.vue";
  export default {
    name: "Prices",
    components: { ModalPrice, ModaleEmail },
    data: () => ({
      headers: [
        { text: "Descripcion", value: "description" },
        { text: "Actualizado", value: "updated_at" },
        { text: "Precio", value: "price", align: "end" },
        { text: "Acciones", value: "actions", align: "end" },
      ],
      prices: [],
      loader: false,
      search: undefined,
      dialog: false,
      price: {},
      url: undefined,
      dialog2: false,
    }),
    mounted() {
      this.solicitar();
    },
    methods: {
      editar(item) {
        this.price = Object.assign({}, item);
        this.dialog = true;
      },
      solicitar() {
        this.loader = true;
        this.prices = [];
        window.axios
          .get("FR/prices")
          .then((resp) => {
            this.prices = resp.data.prices;
            this.url = resp.data.url;
          })
          .catch((e) => this.$store.dispatch("errores", e))
          .finally(() => (this.loader = false));
      },
      cancelar() {
        this.dialog = false;
      },
      actualizar() {
        this.dialog = false;
        this.solicitar();
      },
    },
  };
</script>
