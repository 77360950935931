<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text v-if="user">
      Bienvenido {{ user.nombre }}
      <h2>Estas en el servidor {{ servidor }}</h2>
      <v-row>
        <v-col cols="6" sm="3" md="auto" v-for="item in items" :key="item.name">
          <v-switch
            v-model="item.visible"
            :label="item.label"
            hide-details="auto"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col cols="12" v-if="inventario && inventario.visible">
          <NewPanelInventario />
        </v-col>
        <v-col cols="12" v-if="ventas && ventas.visible">
          <Ventas />
        </v-col>
        <v-col cols="12" v-if="facturas && facturas.visible">
          <Facturas />
        </v-col>
        <v-col cols="12" v-if="pagos && pagos.visible">
          <Pagos />
        </v-col>
        <v-col cols="12" v-if="statements && statements.visible">
          <v-card-actions>
            <v-btn color="primary" @click="getAll" v-if="ventas.collection">
              actualizar datos
            </v-btn>
            <v-btn color="success" @click="downloadExcel">
              <v-icon>mdi-file-excel</v-icon>
              Statement Texas
            </v-btn>
            <v-btn color="success" @click="downloadCa">
              <v-icon>mdi-file-excel</v-icon>
              Statement California
            </v-btn>
          </v-card-actions>
        </v-col>
        <v-col cols="12" v-if="lista && lista.visible">
          <Lista />
        </v-col>
        <v-col cols="12" v-if="correos && correos.visible">
          <Correos />
        </v-col>
        <v-col cols="12" v-if="bols && bols.visible">
          <Bols />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapState } from "vuex";
  import fileDownload from "js-file-download";
  import NewPanelInventario from "./Tablas/Inventario.vue";
  import Ventas from "./Tablas/VentasMes.vue";
  import Facturas from "./Tablas/Facturas.vue";
  import Pagos from "./Tablas/Pagos.vue";
  import Lista from "./Tablas/NewList.vue";
  import Correos from "./Tablas/Correos.vue";
  import Bols from "./Bols.vue";
  export default {
    name: "Dashboard",
    components: {
      NewPanelInventario,
      Ventas,
      Facturas,
      Pagos,
      Lista,
      Correos,
      Bols,
    },
    data: () => ({
      items: [],
      servidor: undefined,
    }),
    mounted() {
      this.servidor = process.env.VUE_APP_TITLE;
      window.axios
        .get("FR/dashboard/getPanels")
        .then((resp) => (this.items = resp.data));
    },
    computed: {
      ...mapState({
        user: (state) => state.Auth.user,
      }),
      inventario() {
        return this.items.find((x) => x.name == "inventario");
      },
      ventas() {
        return this.items.find((x) => x.name == "ventas");
      },
      facturas() {
        return this.items.find((x) => x.name == "facturas");
      },
      pagos() {
        return this.items.find((x) => x.name == "pagos");
      },
      statements() {
        return this.items.find((x) => x.name == "statements");
      },
      lista() {
        return this.items.find((x) => x.name == "lista");
      },
      correos() {
        return this.items.find((x) => x.name == "correos");
      },
      bols() {
        return this.items.find((x) => x.name == "bols");
      },
    },
    methods: {
      downloadExcel() {
        let url = `FR/getStatementAll`;
        let name = `Statement Texas.xlsx`;
        this.$swal({
          title: `¿ Descargar el Statement Texas ?`,
          text: "Esto puede tardar algunos minutos",
          icon: "warning",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            window.axios
              .get(url, {
                responseType: "blob",
              })
              .then((response) => {
                console.log(response.headers);
                fileDownload(response.data, name);
              });
          }
        });
      },
      downloadCa() {
        let url = "FR/getStatementCa";
        let name = `Statement California.xlsx`;
        this.$swal({
          title: `¿ Descargar el Statement California ?`,
          text: "Esto puede tardar algunos minutos",
          icon: "warning",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            window.axios
              .get(url, {
                responseType: "blob",
              })
              .then((response) => {
                console.log(response.headers);
                fileDownload(response.data, name);
              });
          }
        });
      },
    },
  };
</script>
