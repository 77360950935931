<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card>
    <v-card-title primary-title>
      Subir Archivos Bol
    </v-card-title>
    <v-form :disabled="loader" @submit.prevent="enviar">
      <v-card-text>
        <v-row>
          <v-col>
            <v-file-input
              multiple
              v-model="files"
              outlined
              dense
              label="Seleccione los Archivos PDF"
              small-chips
              clearable
              counter
              show-size
              truncate-length="50"
              accept=".pdf"
              :error-messages="errors.files"
              max="20"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" :disabled="loader" type="submit">
          enviar
        </v-btn>
      </v-card-actions>
    </v-form>
    <v-card-text v-if="pendientes.length || encontrados.length || no_validos.length">
      <v-row>
        <v-col cols="12" md="auto" v-if="encontrados.length">
          <h3>Archivos Procesados</h3>
          <ul>
            <li v-for="item in encontrados" :key="item.name">
              {{ item.name }}
            </li>
          </ul>
        </v-col>
        <v-col cols="12" md="auto" v-if="pendientes.length">
          <h3>Presupuestos pendientes de Cerrar</h3>
          <ul>
            <li v-for="item in pendientes" :key="item.name">{{ item.name }}</li>
          </ul>
        </v-col>
        <v-col cols="12" md="auto" v-if="no_validos.length">
          <h3>Archivos no válidos</h3>
          <ul>
            <li v-for="item in no_validos" :key="item.name">{{ item.name }}</li>
          </ul>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  export default {
    name: "Upload",
    data: () => ({
      files: [],
      encontrados: [],
      pendientes: [],
      no_validos: [],
    }),
    computed: {
      ...mapState({
        loader: (state) => state.loader,
        errors: (state) => state.errors,
      }),
    },
    methods: {
      ...mapMutations(["setLoader", "setErrors"]),
      ...mapActions(["errores", "message"]),
      enviar() {
        this.setErrors();
        this.encontrados = this.pendientes = this.no_validos = [];
        if (!this.files.length) {
          this.$store.commit("setErrors", {
            files: ["Necesario"],
          });
          return;
        }

        let formData = new FormData();
        for (var i = 0; i < this.files.length; i++) {
          formData.append("files[]", this.files[i]);
        }

        this.setLoader();
        window.axios
          .post("uploadBols", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((resp) => {
            this.message(resp.data.message);
            this.pendientes = resp.data.pendientes;
            this.encontrados = resp.data.encontrados;
            this.no_validos = resp.data.no_validos;
            this.files = [];
          })
          .catch((e) => {
            if (e.response.status == 422) {
              let prueba = Object.assign({}, e.response.data.errors);
              if (
                JSON.stringify(prueba) !==
                JSON.stringify({ files: ["Necesario"] })
              ) {
                this.$store.commit("setErrors", {
                  files: ["Solo se aceptan archivos PDF", "Necesario"],
                });
              }
            } else {
              this.errores(e);
            }
          })
          .finally(() => this.setLoader(false));
      },
    },
  };
</script>
