<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Precio {{ price.description }}
      </v-card-title>
      <v-form @submit.prevent="actualizar" :disabled="loader">
        <v-card-text>
          <v-text-field
            dense
            outlined
            label="Precio"
            v-model.number="price.price"
            type="number"
            step="0.01"
            class="mt-4"
            :error-messages="errors.price"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="loader" type="submit">actualizar</v-btn>
          <v-btn color="error" @click="$emit('cancelar')" :disabled="loader">
            cancelar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    name: "Modal",
    data: () => ({
      loader: false,
    }),
    props: {
      dialog: { type: Boolean },
      price: { type: Object, required: true },
    },
    computed: {
      ...mapState({
        errors: (state) => state.errors,
      }),
    },
    mounted() {
      this.$store.commit("setErrors");
    },
    methods: {
      actualizar() {
        this.loader = true;
        this.$store.commit("setErrors");
        window.axios
          .put(`FR/prices/${this.price.id}`, this.price)
          .then((resp) => {
              this.$store.dispatch('message',resp.data.message);
              this.$emit('actualizar')
          })
          .catch((e) => this.$store.dispatch("errores", e))
          .finally(() => (this.loader = false));
      },
    },
  };
</script>
