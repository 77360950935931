import router from '../../router';
const url = 'FR/pallets';
const initialState = () => ({
	loader: false,
	warehouses: undefined,
	disponibles: undefined,
	productos: undefined,
	url_pdf: undefined,
	pallet: undefined
});
export default {
	namespaced: true,
	state: initialState(),
	mutations: {
		setInitialState(state) {
			const newState = initialState();
			Object.keys(newState).forEach(key => {
				state[key] = newState[key];
			});
		},
		setLoader(state, payload) {
			state.loader = payload;
		},
		SET_WAREHOUSES(state, payload) {
			state.warehouses = payload;
		},
		SET_PRODUCTOS(state, payload) {
			state.productos = payload;
		},
		SET_DISPONIBLES(state, payload) {
			state.disponibles = payload;
		},
		SET_URL_PDF(state, payload) {
			state.url_pdf = payload;
		},
		SET_PALLET(state, payload) {
			state.pallet = payload;
		}
	},
	actions: {
		async getAll({ commit, dispatch }) {
			commit('SET_WAREHOUSES', undefined);
			window.axios
				.get(url)
				.then(resp => {
					commit('SET_WAREHOUSES', resp.data.warehouses);
					commit('SET_PRODUCTOS', resp.data.productos);
					commit('SET_DISPONIBLES', resp.data.disponibles);
					commit('SET_URL_PDF', resp.data.url_pdf);
				})
				.catch(e => dispatch('errores', e, { root: true }));
		},
		async searchPallet({ commit, dispatch }, pallet) {
			commit('setLoader', true);
			commit('SET_PALLET', undefined);
			await window.axios
				.post(`FR/searchPallet`, pallet)
				.then(resp => {
					commit('SET_PALLET', resp.data);
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		}
	}
};
