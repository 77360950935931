<!-- eslint-disable -->
<template>
	<v-card height="100%" v-if="proveedor">
		<v-form @submit.prevent="sendProveedor">
			<v-card-text class="mt-2">
				<v-row>
					<v-col cols="12" md="6">
						<v-text-field
							outlined
							dense
							type="text"
							label="Nombre"
							v-model="proveedor.nombre"
							:error-messages="errors.nombre"
						/>
					</v-col>
					<v-col cols="12" md="6">
						<v-text-field
							outlined
							dense
							type="text"
							label="Contacto"
							v-model="proveedor.contacto"
							:error-messages="errors.contacto"
						/>
					</v-col>
					<v-col cols="12" md="6">
						<v-text-field
							outlined
							dense
							type="text"
							label="Teléfono"
							v-model="proveedor.telefono"
							:error-messages="errors.telefono"
						/>
					</v-col>
					<v-col cols="12" md="6">
						<v-text-field
							outlined
							dense
							type="text"
							label="Correo"
							v-model="proveedor.email"
							:error-messages="errors.email"
						/>
					</v-col>
					<v-col cols="12" md="6">
						<v-textarea
							outlined
							rows="3"
							label="Domicilio"
							v-model="proveedor.domicilio"
							:error-messages="errors.domicilio"
						/>
					</v-col>
					<v-col cols="12" md="6">
						<v-textarea
							outlined
							rows="3"
							label="Observaciones"
							v-model="proveedor.observaciones"
							:error-messages="errors.observaciones"
						/>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-btn small color="primary" type="submit" :disabled="loader">
					{{ title }}
				</v-btn>
				<v-btn small color="error" @click="cancelar" class="ml-3">
					cancelar
				</v-btn>
			</v-card-actions>
		</v-form>
	</v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
	name: 'Formulario',
	computed: {
		...mapState({
			proveedor: state => state.Proveedores.resource,
			errors: state => state.errors,
			loader: state => state.Proveedores.loader
		}),
		title() {
			return this.proveedor.id ? 'actualizar' : 'guardar';
		}
	},
	mounted() {
		if (!this.proveedor) {
			this.$router.push({ name: 'proveedores' }).catch(() => {});
		}
	},
	methods: {
		...mapActions('Proveedores', ['sendProveedor']),
		cancelar() {
			this.$router.push({ name: 'proveedores' }).catch(() => {});
		}
	}
};
</script>
