<!-- eslint-disable -->
<template>
	<v-card height="100%">
		<v-form @submit.prevent="sendUser" class="formulario">
			<v-card-text>
				<v-row class="mt-3">
					<v-col cols="12" sm="6" md="4">
						<v-text-field
							outlined
							dense
							type="text"
							label="Nombre"
							v-model="user.nombre"
							:error-messages="errors.nombre"
						/>
					</v-col>
					<v-col cols="12" sm="6" md="4">
						<v-text-field
							outlined
							dense
							type="text"
							label="Apellidos"
							v-model="user.apellidos"
							:error-messages="errors.apellidos"
						/>
					</v-col>
					<v-col cols="12" sm="6" md="4">
						<v-text-field
							outlined
							dense
							type="email"
							label="Correo"
							v-model="user.email"
							:error-messages="errors.email"
						/>
					</v-col>
					<v-col cols="12" sm="6" md="4">
						<v-text-field
							outlined
							dense
							type="text"
							label="Teléfono"
							v-model="user.telefono"
							:error-messages="errors.telefono"
						/>
					</v-col>
					<v-col cols="12" sm="6" md="4">
						<v-select
							outlined
							dense
							clearable
							label="Sucursal"
							:items="sucursales"
							v-model="user.sucursal_id"
							:error-messages="errors.sucursal_id"
						/>
					</v-col>
					<v-col cols="12">
						<v-btn color="success" type="submit" :disabled="loader">
							{{ btnTitle }}
						</v-btn>
						<v-btn
							class="ml-3"
							color="error"
							@click="$router.push({ name: 'users' })"
						>
							cancelar
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-form>
	</v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
	name: 'Formulario',
	computed: {
		...mapState({
			user: state => state.Users.resource,
			errors: state => state.errors,
			loader: state => state.Users.loader,
			sucursales: state => state.Users.sucursales
		}),
		btnTitle() {
			return this.user.id ? 'Actualizar' : 'guardar';
		},
		test() {
			return document.getElementById('nprogress');
		}
	},
	mounted() {
		const id = this.$route.params.id;
		if (id) {
			this.edit(id);
		}
	},
	beforeDestroy() {
		this.setResource({});
		this.setErrors([]);
	},
	methods: {
		...mapActions('Users', ['edit', 'sendUser']),
		...mapMutations('Users', ['setResource']),
		...mapMutations(['setErrors'])
	}
};
</script>
