/** @format */

import router from "../../router";
const url = "FR/proveedors";
const initialState = () => ({
  headers: [],
  collection: undefined,
  resource: undefined,
  loader: false,
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setInitialState(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
    setHeaders(state, payload) {
      state.headers = payload;
    },
    setCollection(state, payload) {
      state.collection = payload;
    },
    setResource(state, payload) {
      state.resource = payload;
    },
    setLoader(state, payload) {
      state.loader = payload;
    },
  },
  actions: {
    async getAll({ commit, dispatch }) {
      commit("setCollection", []);
      commit("setLoader", true);
      window.axios
        .get(url)
        .then((resp) => {
          commit("setCollection", resp.data.collection);
          commit("setHeaders", resp.data.headers);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async nuevo({ commit }) {
      commit("setResource", {});
      commit("setErrors", [], { root: true });
      router.push({ name: "proveedores.create" }).catch(() => {});
    },
    async editar({ commit, dispatch }, id) {
      commit("setLoader", true);
      commit("setErrors", [], { root: true });
      await window.axios
        .get(`${url}/${id}`)
        .then((resp) => {
          commit("setResource", resp.data);
          let nombre = "Editando a " + resp.data.nombre;
          router
            .push({ name: "proveedores.edit", params: { nombre } })
            .catch(() => {});
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async sendProveedor({ commit, dispatch, state }) {
      let proveedor = state.resource;
      commit("setLoader", true);
      if (!proveedor.id) {
        window.axios
          .post(url, proveedor)
          .then((resp) => {
            commit("setCollection", resp.data.collection);
            dispatch("message", resp.data.message, { root: true });
            router.push({ name: "proveedores" }).catch(() => {});
          })
          .catch((e) => dispatch("errores", e, { root: true }))
          .finally(() => commit("setLoader", false));
      } else {
        window.axios
          .put(`${url}/${proveedor.id}`, proveedor)
          .then((resp) => {
            commit("setCollection", resp.data.collection);
            dispatch("message", resp.data.message, { root: true });
            router.push({ name: "proveedores" }).catch(() => {});
          })
          .catch((e) => dispatch("errores", e, { root: true }))
          .finally(() => commit("setLoader", false));
      }
    },
    async historial({ commit, dispatch, state }, id) {
      let proveedor = state.collection.find((x) => x.id == id),
        nombre = "Historial " + proveedor.nombre;
      commit("setResource", {});
      router
        .push({ name: "proveedores.history", params: { nombre } })
        .catch(() => {});
    },
    async deshabilitar({ commit, dispatch }, id) {
      commit("setLoader", true);
      await window.axios
        .delete(`${url}/${id}`)
        .then((resp) => {
          commit("setCollection", resp.data.collection);
          dispatch("message", resp.data.message, { root: true });
          router.push({ name: "proveedores" }).catch(() => {});
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async habilitar({ commit, dispatch }, id) {
      commit("setLoader", true);
      await window.axios
        .post(`${url}/habilitar/${id}`)
        .then((resp) => {
          commit("setCollection", resp.data.collection);
          dispatch("message", resp.data.message, { root: true });
          router.push({ name: "proveedores" }).catch(() => {});
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
  },
};
