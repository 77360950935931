/** @format */

import router from "../../router";
const url = "FR/supplies";
const initialState = () => ({
  headers: undefined,
  collection: undefined,
  resource: undefined,
  loader: false,
  paginado: { currentPage: 1 },
  units: undefined,
  compras: undefined,
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setInitialState(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
    setHeaders(state, payload) {
      state.headers = payload;
    },
    setCollection(state, payload = []) {
      state.collection = payload;
    },
    setResource(state, payload = {}) {
      state.resource = payload;
    },
    setLoader(state, payload = true) {
      state.loader = payload;
    },
    setPaginado(state, payload) {
      let paginado = payload;
      if (paginado.currentPage > paginado.lastPage) paginado.currentPage = 1;
      state.paginado = paginado;
    },
    setUnits(state, payload) {
      state.units = payload;
    },
    setCompras(state, payload) {
      state.compras = payload;
    },
  },
  actions: {
    async getAll({ commit, dispatch, state }) {
      commit("setCollection");
      commit("setLoader");
      window.axios
        .get(url, {
          params: {
            page: state.paginado.currentPage,
            search: state.paginado.search,
          },
        })
        .then((resp) => {
          commit("setCollection", resp.data.collection);
          commit("setHeaders", resp.data.headers);
          commit("setPaginado", resp.data.paginado);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async nuevo({ commit }) {
      window.axios
        .get(`${url}/create`)
        .then((resp) => {
          commit("setErrors", {}, { root: true });
          commit("setResource");
          commit("setUnits", resp.data.units);
          router.push({ name: "supplies.create" }).catch(() => {});
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async getResource({ commit, dispatch }, id) {
      commit("setErrors", {}, { root: true });
      await window.axios
        .get(`${url}/${id}`)
        .then((resp) => {
          commit("setResource", resp.data.resource);
          commit("setUnits", resp.data.units);
          commit("setCompras", resp.data.compras);
          router
            .push({ name: "supplies.edit", params: { id } })
            .catch(() => {});
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async sendResource({ commit, dispatch, state }) {
      commit("setErrors", {}, { root: true });
      commit("setLoader");
      let resource = state.resource;
      if (!resource.id) {
        await window.axios
          .post(url, resource)
          .then((resp) => {
            dispatch("message", resp.data.message, { root: true });
            router.push({ name: "supplies" });
          })
          .catch((e) => dispatch("errores", e, { root: true }))
          .finally(() => commit("setLoader", false));
      } else {
        await window.axios
          .put(`${url}/${resource.id}`, resource)
          .then((resp) => {
            dispatch("message", resp.data.message, { root: true });
            dispatch("getResource", resource.id);
          })
          .catch((e) => dispatch("errores", e, { root: true }))
          .finally(() => commit("setLoader", false));
      }
    },
    async restaurar({ commit, dispatch }, id) {
      commit("setErrors", {}, { root: true });
      commit("setLoader");
      await window.axios
        .post(`${url}/restaurar/${id}`)
        .then((resp) => {
          dispatch("getAll");
          dispatch("message", resp.data.message, { root: true });
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async disableResource({ commit, dispatch }, id) {
      commit("setErrors", {}, { root: true });
      commit("setLoader");
      await window.axios
        .delete(`${url}/${id}`)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          dispatch("getAll");
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
  },
};
