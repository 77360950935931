<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card height="100%" v-if="resource">
    <v-form @submit.prevent="sendResource" :disabled="loader">
      <Fields />
      <v-card-actions>
        <v-btn color="success" type="submit" :disabled="loader">
          {{ text }}
        </v-btn>
        <v-btn color="error" @click="$router.push({ name: 'supplies' })">
          cancelar
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import Fields from "./Fields.vue";
  export default {
    name: "Formulario",
    components: { Fields },
    computed: {
      ...mapState({
        errors: (state) => state.errors,
        resource: (state) => state.Supplies.resource,
        loader: (state) => state.Supplies.loader,
        units: (state) => state.Supplies.units,
      }),
      text() {
        return this.resource.id ? "actualizar" : "guardar";
      },
    },
    methods: {
      ...mapActions("Supplies", ["nuevo", "getResource", "sendResource"]),
    },
    mounted() {
      if (!this.resource) {
        if (this.$route.params.id) {
          this.getResource(this.$route.params.id);
        } else {
          this.nuevo();
        }
      }
    },
  };
</script>
