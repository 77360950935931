<!-- eslint-disable -->
<template>
	<v-card height="100%">
		<v-card-text>
			<v-btn small color="success" @click="$store.dispatch('Sucursales/nuevo')">
				<v-icon>mdi-plus</v-icon>
			</v-btn>
			<v-btn
				small
				color="info"
				class="ml-3"
				@click="$store.dispatch('Sucursales/getAll')"
			>
				<v-icon>mdi-refresh</v-icon>
			</v-btn>
		</v-card-text>
		<v-card-text>
			<v-data-table
				dense
				:items="items"
				:headers="headers"
				class="elevation-1"
				mobile-breakpoint="0"
				:items-per-page="paginado.perPage"
				hide-default-footer
			>
				<template v-slot:[`item.actions`]="{ item }">
					<v-btn
						icon
						color="success"
						@click="$store.dispatch('Sucursales/editar', item.id)"
					>
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
				</template>
			</v-data-table>
			<Pagination :loader="loader" :items="items" :paginado="paginado" />
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Index',
	mounted() {
		this.$store.dispatch('Sucursales/getAll');
	},
	computed: {
		...mapState({
			headers: state => state.Sucursales.headers,
			items: state => state.Sucursales.collection,
			loader: state => state.Sucursales.loader,
			paginado: state => state.Sucursales.paginado
		})
	}
};
</script>
