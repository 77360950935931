<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-expansion-panels multiple v-if="compra">
        <Infor />
        <Productos />
        <Supplies />
        <Archivos />
        <Gastos />
        <Apartados v-if="apartados" />
        <Ventas v-if="ventas" />
        <Pagos />
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="regresar">Regresar a compras</v-btn>
      <v-btn color="success" @click="actualizar">Actualizar compra</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import Infor from "./panelInfo";
  import Productos from "./panelProducto";
  import Pagos from "./panelPagos";
  import Archivos from "./panelArchivos";
  import Gastos from "./panelGastos";
  import Apartados from "./panelApartados";
  import Ventas from "./panelVentas";
  import Supplies from "./PanelSupplies.vue";
  import { mapActions, mapMutations, mapState } from "vuex";
  export default {
    name: "Detalles",
    components: {
      Productos,
      Infor,
      Archivos,
      Gastos,
      Apartados,
      Pagos,
      Ventas,
      Supplies,
    },
    computed: {
      ...mapState({
        compra: (state) => state.Compras.resource,
        ventas: (state) => state.Compras.ventas,
        apartados: (state) => state.Compras.apartados,
        pagos: (state) => state.Compras.pagos,
      }),
    },
    mounted() {
      if (!this.compra) {
        this.detalles(this.$route.params.id);
      }
    },
    methods: {
      ...mapMutations("Compras", ["setLoader"]),
      ...mapActions("Compras", ["detalles"]),
      regresar() {
        this.$router.push({ name: "compras" }).catch(() => {});
      },
      actualizar() {
        this.setLoader();
        this.detalles(this.compra.id);
      },
    },
    beforeDestroy() {
      this.$store.commit("Compras/setVentas");
      this.$store.commit("Compras/setApartados");
    },
  };
</script>
