<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-expansion-panel v-if="resource">
    <v-expansion-panel-header color="blue lighten-4">
      Datos Generales
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-form @submit.prevent="$store.dispatch('Orders/sendResource')">
        <v-row class="mt-3">
          <v-col cols="12" md="3">
            <v-select
              dense
              outlined
              small-chips
              clearable
              label="Proveedor"
              :items="proveedores"
              v-model="resource.proveedor_id"
              :error-messages="errors.proveedor_id"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              dense
              outlined
              small-chips
              clearable
              :items="almacenes"
              v-model="resource.warehouse_address_id"
              label="Almacen que recibe"
              :error-messages="errors.warehouse_address_id"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              outlined
              label="Fecha de Solicitud"
              v-model="resource.order_date"
              type="date"
              :error-messages="errors.order_date"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              outlined
              label="Fecha de Entrega"
              v-model="resource.date_req"
              type="date"
              :error-messages="errors.date_req"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              outlined
              label="Condiciones de Envío"
              v-model="resource.ship_terms"
              :error-messages="errors.ship_terms"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              outlined
              label="Condiciones de Pago"
              v-model="resource.pay_terms"
              :error-messages="errors.pay_terms"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-textarea
              outlined
              label="Términos"
              v-model="resource.terms"
              :error-messages="errors.terms"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-textarea
              outlined
              label="Condiciones"
              v-model="resource.conditions"
              :error-messages="errors.conditions"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-textarea
              outlined
              label="Observaciones"
              v-model="resource.observations"
              :error-messages="errors.observations"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="success" :disabled="deshabilitado" type="submit">
              {{ btnLabel }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    name: "Datos",
    data: () => ({
      original: undefined,
    }),
    computed: {
      ...mapState({
        resource: (state) => state.Orders.resource,
        loader: (state) => state.Orders.loader,
        proveedores: (state) => state.Orders.proveedores,
        almacenes: (state) => state.Orders.almacenes,
        errors: (state) => state.errors,
      }),
      modificado: function() {
        return JSON.stringify(this.resource) !== JSON.stringify(this.original);
      },
      btnLabel: function() {
        return this.resource.id ? "actualizar" : "guardar";
      },
      deshabilitado: function() {
        if (!this.$route.params.id) return false;
        return !this.modificado || this.loader;
      },
    },
    mounted() {
      this.$store.commit("setErrors", []);
      this.original = Object.assign({}, this.resource);
    },
    watch: {
      resource: function(val) {
        if (!this.$route.params.id) this.original = Object.assign({}, val);
      },
    },
  };
</script>
