<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="orange lighten-4">
      Artículos (No Aguacates)
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" md="4">
          <v-btn small color="success" class="mt-5" @click="dialog = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        :search.sync="search"
        class="elevation-1 mt-3"
        mobile-breakpoint="0"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="error"
            v-if="item.actions.delete"
            @click="eliminar(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.price`]="{ item }">
          {{ item.price | moneda }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
      </v-data-table>
    </v-expansion-panel-content>
    <AddSupply v-if="dialog" :dialog="dialog" @cancelar="dialog = false" />
  </v-expansion-panel>
</template>

<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  import AddSupply from "./AddSuply.vue";
  export default {
    name: "Supplies",
    components: { AddSupply },
    data: () => ({
      search: undefined,
      headers: [
        { text: "Articulo", value: "supply.name" },
        { text: "Pallet ID", value: "pallet_id" },
        { text: "Cantidad", value: "quantity", align: "end" },
        { text: "Disponibles", value: "disponibles", align: "end" },
        { text: "Precio U", value: "price", align: "end" },
        { text: "Importe", value: "importe", align: "end" },
        { text: "Acciones", value: "actions", align: "end" },
      ],
      dialog: false,
    }),
    computed: {
      ...mapState({
        loader: (state) => state.Compras.loader,
        items: (state) => state.Compras.compraSupplies,
      }),
    },
    methods: {
      ...mapMutations(["setErrors"]),
      ...mapMutations("Compras", ["setLoader", "setCompraSupplies"]),
      ...mapActions(["errores", "message"]),
      eliminar(item) {
        let pregunta = `¿Deseas eliminar el artículo ${item.supply.name}?`,
          text = "Esta acción no puede deshacerse";
        this.$swal({
          title: pregunta,
          text: text,
          icon: "warning",
          showCancelButton: true,
        }).then((resp) => {
          if (resp.isConfirmed) {
            let url = `FR/compras/removeSupply/${item.id}`;
            this.setLoader();
            window.axios
              .delete(url)
              .then((resp) => {
                this.$store.dispatch("message", resp.data.message);
                this.setCompraSupplies(resp.data.compraSupplies);
              })
              .catch((e) => this.errores(e))
              .finally(() => this.setLoader(false));
          }
        });
      },
    },
  };
</script>
