var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"orange lighten-4"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.nuevo)?_c('v-expansion-panel-content',[_c('add-sample',{attrs:{"resource":_vm.sample},on:{"cerrar":function($event){_vm.nuevo = false}}})],1):_c('v-expansion-panel-content',[_c('v-card-text',[_c('v-row',{staticClass:"my-2"},[_c('v-col',[(_vm.posibles.length)?_c('v-btn',{attrs:{"small":"","color":"success","disabled":_vm.loader},on:{"click":_vm.createSample}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)],1),_c('v-data-table',{staticClass:"elevation-1 ",attrs:{"dense":"","items":_vm.items,"headers":_vm.headers,"mobile-breakpoint":"0","hide-default-footer":"","loading":_vm.loader},scopedSlots:_vm._u([{key:"item.avg_quality",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.quality / 11).toFixed(2))+" ")]}},{key:"item.avg_condition",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.condition / 11).toFixed(2))+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("folio")(item.id))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.editar(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.eliminar(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }