/** @format */

import router from "../../router";
const url = "FR/creditos";
const initialState = () => ({
  headers: [],
  collection: [],
  resource: {},
  loader: false,
  paginado: { currentPage: 1 },
  customers: undefined,
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setInitialState(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
    setHeaders(state, payload) {
      state.headers = payload;
    },
    setCollection(state, payload = []) {
      state.collection = payload;
    },
    setResource(state, payload) {
      state.resource = payload;
    },
    setLoader(state, payload = true) {
      state.loader = payload;
    },
    setPaginado(state, payload) {
      state.paginado = payload;
    },
    setCustomers(state, payload) {
      state.customers = payload;
    },
  },
  actions: {
    async getAll({ commit, dispatch, state }) {
      commit("setCollection");
      commit("setLoader");
      window.axios
        .get(url, {
          params: {
            page: state.paginado.currentPage,
            search: state.paginado.search,
          },
        })
        .then((resp) => {
          commit("setCollection", resp.data.collection);
          commit("setHeaders", resp.data.headers);
          let paginado = resp.data.paginado;
          if (paginado.currentPage > paginado.lastPage)
            paginado.currentPage = 1;
          commit("setPaginado", paginado);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async nuevo({ commit, dispatch }) {
      commit("setLoader");
      await window.axios
        .get(`${url}/create`)
        .then((resp) => {
          commit("setCustomers", resp.data.customers);
          router.push({ name: "credits.create" }).catch(() => {});
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async guardar({ commit, dispatch }, data) {
      commit("setErrors", {}, { root: true });
      commit("setLoader");
      await window.axios
        .post(url, data)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          router.push({ name: "credits" }).catch(() => {});
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async disableResource({ commit, dispatch }, id) {
      commit("setLoader");
      await window.axios
        .delete(`${url}/${id}`)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          dispatch("getAll");
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
  },
};
