<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="orange lighten-4">
      Productos Venta
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="my-3">
        <v-col style="padding-top: 20px!important;">
          Total de Cajas: {{ cajas }}
        </v-col>
        <v-col style="padding-top: 20px!important;">
          Total de Pallets: {{ pallets }}
        </v-col>
        <v-col style="padding-top: 20px!important;">
          Importe Total: {{ total | moneda }}
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            dense
            outlined
            clearable
            v-model="search"
            append-icon="mdi-magnify"
            label="Busqueda"
            single-line
            hide-details
          />
        </v-col>
      </v-row>
      <v-data-table
        dense
        :headers="headers"
        :items="details"
        class="elevation-1"
        :search.sync="search"
        mobile-breakpoint="0"
        hide-default-footer
        :items-per-page="details.length"
      >
        <template v-slot:[`item.amount`]="{ item }">
          {{ item.amount | moneda }}
        </template>
        <template v-slot:[`item.price`]="{ item }">
          {{ item.price | moneda }}
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import { mapState, mapActions, mapMutations } from "vuex";
  export default {
    name: "Productos",
    data: () => ({
      perPage: 10,
      headers: [
        { text: "Producto", value: "description" },
        { text: "Pallet ID", value: "pallet_id" },
        { text: "Expediente", value: "exp" },
        { text: "Cajas", value: "qty", align: "end" },
        { text: "Pallets", value: "pallets", align: "end" },
        { text: "Precio", value: "price", align: "end" },
        { text: "Importe", value: "amount", align: "end" },
      ],
      search: undefined,
    }),
    computed: {
      ...mapState({
        details: (state) => state.Ventas.detalles,
      }),
      pallets() {
        let pallets = _.sumBy(this.details, "pallets");
        return pallets.toFixed(3);
      },
      cajas() {
        return window._.sumBy(this.details, "qty");
      },
      total() {
        return window._.sumBy(this.details, "amount");
      },
    },
    methods: {
      cantidad(items) {
        return items.reduce(function(accumulator, item) {
          return accumulator + item.cajas;
        }, 0);
      },
      precio(items) {
        return parseFloat(items[0].precio);
      },
      importe(items) {
        return items.reduce(function(accumulator, item) {
          return accumulator + item.cajas * parseFloat(item.precio);
        }, 0);
      },
    },
  };
</script>
