<!-- eslint-disable -->
<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Pagos de Facturas del mes
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-row>
				<v-col xl="4" md="6">
					<p class="mt-5 negrita">
						Total Pagos: {{ total | numero }} <br />
						Importe: {{ importe | moneda }}
					</p>
				</v-col>
				<v-col cols="12" xl="4" offset-xl="4" md="6" offset-md="6">
					<v-container>
						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="Busqueda"
							single-line
							hide-details
						/>
					</v-container>
				</v-col>
			</v-row>
			<v-data-table
				dense
				:items="items"
				:headers="headers"
				class="elevation-1"
				:search.sync="search"
				mobile-breakpoint="0"
			>
				<template v-slot:[`item.id`]="{ item }">
					{{ item.id | folio }}
				</template>
				<template v-slot:[`item.fecha`]="{ item }">
					{{ item.fecha | date }}
				</template>
				<template v-slot:[`item.importe`]="{ item }">
					{{ item.importe | moneda }}
				</template>
				<template v-slot:[`item.cajas`]="{ item }">
					{{ item.cajas | numero }}
				</template>
			</v-data-table>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
	name: 'Pagos',
	data: () => ({
		search: undefined
	}),
	computed: {
		...mapState({
			headers: state => state.Dashboard.pagos.headers,
			items: state => state.Dashboard.pagos.collection
		}),
		total() {
			return this.items ? this.items.length : 0;
		},
		importe() {
			return _.sumBy(this.items, 'importe');
		}
	}
};
</script>
