import Index from './../views/reportes/Index.vue';
import Nuevo from './../views/reportes/Nuevo.vue';
import Historial from './../views/reportes/Historial.vue';
import Editar from './../views/reportes/Editar.vue';

const routes = [
	{
		path: '/Soporte',
		name: 'reportes',
		component: Index,
		meta: {
			name: 'Soporte Técnico'
		}
	},
	{
		path: '/Soporte/nuevo',
		name: 'reportes.nuevo',
		component: Nuevo,
		meta: {
			name: 'Nuevo Ticket de Soporte'
		}
	},
	{
		path: '/Soporte/historial/:id',
		name: 'reportes.historial',
		component: Historial,
		meta: {
			name: 'Historial del Ticket'
		}
	},
	{
		path: '/Soporte/editar/:id',
		name: 'reportes.editar',
		component: Editar,
		meta: {
			name: 'Actualizar Ticket'
		}
	}
];
export default routes;
