<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        class="elevation-1 mt-3"
        hide-default-footer
      >
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: "Index",
    data: () => ({
      headers: [
        { text: "Empresa", value: "empresa" },
        { text: "Tipo de Certificado", value: "empresa" },
        { text: "Emitido por", value: "emitido" },
        { text: "Fecha Emisión", value: "puntaje" },
        { text: "Válido hasta", value: "files", align: "end" },
        { text: "Acciones", value: "actions", align: "end" },
      ],
      items: [],
    }),
  };
</script>
