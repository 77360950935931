<!-- eslint-disable -->
<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="blue lighten-4">
			Disponibles con fechas
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-card class="mt-3">
				<v-card-text>
					<v-row>
						<v-checkbox
							class="mr-5"
							v-for="(item, k) in productos"
							hide-details
							v-model="selected"
							:label="item"
							:value="item"
							:key="k"
						/>
					</v-row>
				</v-card-text>
				<v-card-text v-if="selected.length">
					<v-row>
						<v-col cols="12" md="6" v-for="(item, k) in ordenados" :key="k">
							<v-card class="mb-3" height="98%">
								<v-card-title primary-title>
									{{ item }}
								</v-card-title>
								<v-card-text>
									<v-data-table
										dense
										mobile-breakpoint="0"
										:headers="headers"
										:items="buscados(item)"
									>
										<template v-slot:[`item.fecha_minima`]="{ item }">
											{{ item.fecha_minima | date }}
										</template>
									</v-data-table>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Fechas',
	data: () => ({
		selected: [],
		headers: [
			{ text: 'Pallet', value: 'pallet' },
			{ text: 'Date', value: 'fecha_minima' },
			{ text: 'Qty', value: 'disponibles' }
		]
	}),
	computed: {
		...mapState({
			collection: state => state.Pallets.disponibles
		}),
		disponibles() {
			return this.collection.filter(x => x.disponibles > 0);
		},
		productos() {
			return [...new Set(this.disponibles.map(x => x.referencia))].sort();
		},
		ordenados() {
			let pruebas = this.selected;
			return pruebas.sort();
		}
	},
	methods: {
		buscados(item) {
			return this.disponibles.filter(x => x.referencia == item);
		}
	}
};
</script>
<style lang="scss" scoped>
.v-label {
	font-size: 0.75em;
	color: rgba(0, 0, 0, 0.85) !important;
}
</style>
