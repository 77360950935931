<!-- eslint-disable -->
<template>
	<v-card height="100%">
		<v-card-text>
			<v-form @submit.prevent="getAll">
				<v-row class="mt-3">
					<v-col cols="12" md="4">
						<v-autocomplete
							dense
							outlined
							clearable
							label="Filtrar por Cliente"
							:items="paginado.clientes"
							v-model="paginado.cliente_id"
						/>
					</v-col>
					<v-col>
						<v-text-field
							dense
							outlined
							clearable
							label="Buscar PU"
							v-model="paginado.search"
						/>
					</v-col>
					<v-col>
						<v-btn color="primary" type="submit" :disabled="loader">
							<v-icon>mdi-magnify</v-icon>
						</v-btn>
						<v-btn color="info" class="ml-3" @click="getAll">
						<v-icon>mdi-refresh</v-icon>
					</v-btn>
					</v-col>
				</v-row>
			</v-form>
			<v-data-table
				dense
				:items="items"
				:headers="headers"
				class="elevation-1"
				mobile-breakpoint="0"
				:items-per-page="paginado.perPage"
				hide-default-footer
			>
				<template v-slot:[`item.id`]="{ item }">
					{{ item.id | folio }}
				</template>
				<template v-slot:[`item.fecha`]="{ item }">
					{{ item.fecha | date }}
				</template>
				<template v-slot:[`item.importe`]="{ item }">
					{{ item.importe | moneda }}
				</template>
				<template v-slot:[`item.cajas`]="{ item }">
					{{ item.cajas | numero }}
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-btn icon color="success" @click="getVenta(item.id)" v-if="userCan('fr-ventas-edit')">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
					<v-btn
						icon
						color="primary"
						v-if="item.cliente == 'VENTA LOCAL' && !item.nota && userCan('fr-ventas-nota')"
						@click="preguntar(item)"
						
					>
						<v-icon>mdi-card-text</v-icon>
					</v-btn>
				</template>
			</v-data-table>
			<Pagination :loader="loader" :items="items" :paginado="paginado" />
		</v-card-text>
		<v-row justify="center">
			<v-dialog v-model="dialog" persistent max-width="600px">
				<v-card>
					<v-card-title>
						<span class="headline">{{ titleModal }}</span>
					</v-card-title>
					<v-card-text>
						Cliente: {{ nota.cliente }}<br />
						Importe: {{ nota.importe | moneda }}
					</v-card-text>
					<v-card-text>
						<v-container>
							<v-row>
								<v-col cols="12">
									<v-textarea
										outlined
										label="Observaciones"
										v-model="nota.observaciones"
									/>
								</v-col>
							</v-row>
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="error" @click="cancelar">Cancelar</v-btn>
						<v-btn color="success" @click="sendNota" :disabled="loader"
							>Guardar</v-btn
						>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>
	</v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
	name: 'Ventas',
	data: () => ({
		search: undefined,
		filtros: [],
		dialog: false,
		nota: {},
		titleModal: undefined
	}),
	computed: {
		...mapState({
			headers: state => state.Ventas.headers,
			items: state => state.Ventas.collection,
			loader: state => state.Ventas.loader,
			paginado: state => state.Ventas.paginado
		}),
		actual() {
			return this.paginado.currentPage;
		},
		cliente_id() {
			return this.paginado.cliente_id;
		}
	},
	mounted() {
		this.getAll();
	},
	watch: {
		items: function() {
			this.cancelar();
		},
		actual: function() {
			this.getAll();
		},
		cliente_id: function() {
			this.getAll();
		}
	},
	methods: {
		...mapActions('Ventas', ['getAll', 'getVenta', 'hacerNota']),
		preguntar(item) {
			this.titleModal = `Nota para la venta ${item.folio}`;
			this.nota = item;
			this.dialog = true;
		},
		sendNota() {
			this.hacerNota(this.nota);
		},
		cancelar() {
			this.nota = {};
			this.dialog = false;
		}
	}
};
</script>
