<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Pallets
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="mt-3">
        <v-col cols="12" md="4">
          <p class="mt-5 negrita">
            Total de Pallets: {{ pallets.length | numero }} <br />
            Total de Cajas: {{ cajas | numero }}
          </p>
        </v-col>
        <v-col cols="12" md="4">
          <p class="negrita">
            Total Vendidos:{{ vendidos | numero }} <br />
            Total Apartados: {{ apartados | numero }}<br />
            Total Disponibles: {{ disponibles | numero }}
          </p>
        </v-col>
        <v-col cols="12" xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        class="elevation-1"
        :search.sync="search"
        mobile-breakpoint="0"
      >
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import { mapState, mapActions, mapMutations } from "vuex";
  export default {
    name: "Pallets",
    data: () => ({
      headers: [
        { text: "Pallet", value: "pallet" },
        { text: "Producto", value: "producto" },
        { text: "Cantidad", value: "cantidad", align: "end" },
        { text: "Vendidos", value: "vendidos", align: "end" },
        { text: "Apartados", value: "apartados", align: "end" },
        { text: "Disponibles", value: "disponibles", align: "end" },
      ],
      items: [],
      search: undefined,
    }),
    computed: {
      ...mapState({
        detalles: (state) => state.Embarques.detalles,
      }),
      pallets() {
        return [...new Set(this.detalles.map((x) => x.pallet))].sort();
      },
      cajas() {
        return _.sumBy(this.detalles, "cantidad");
      },
      apartados() {
        return _.sumBy(this.detalles, "apartados");
      },
      vendidos() {
        return _.sumBy(this.detalles, "vendidos");
      },
      disponibles() {
        return _.sumBy(this.detalles, "disponibles");
      },
    },
    mounted() {
      let pallets = [...new Set(this.detalles.map((x) => x.pallet))].sort();
      pallets.forEach((pallet) => {
        let filtrados = this.detalles.filter((x) => x.pallet == pallet),
          productos = [...new Set(filtrados.map((x) => x.producto))].sort();
        productos.forEach((producto) => {
          let agrupados = filtrados.filter((x) => x.producto == producto),
            obj = {
              pallet: pallet,
              producto: producto,
              cantidad: _.sumBy(agrupados, "cantidad"),
              vendidos: _.sumBy(agrupados, "vendidos"),
              apartados: _.sumBy(agrupados, "apartados"),
              disponibles: _.sumBy(agrupados, "disponibles"),
            };
          this.items.push(obj);
        });
      });
    },
    methods: {
      //
    },
  };
</script>
