/** @format */

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import logout from "./../components/base/logout.vue";
import Users from "./../views/users/index.vue";
import UserForm from "./../views/users/form.vue";
import Clientes from "./../views/clientes/index.vue";
import ClienteDetalles from "./../views/clientes/detalles.vue";
import ClienteNuevo from "./../views/clientes/nuevo.vue";
import Warehouses from "./../views/warehouses/index.vue";
import Budgets from "./../views/budgets/index.vue";
import BudgetForm from "./../views/budgets/detalles.vue";
import Ventas from "./../views/ventas/index.vue";
import DetalleVenta from "./../views/ventas/detalles.vue";
import Facturas from "./../views/facturas/index.vue";
import DetalleFactura from "./../views/facturas/detalles";
import Embarques from "./../views/embarques/index.vue";
import DetalleEmbarque from "./../views/embarques/detalles.vue";
import Notas from "./../views/notas/index.vue";
import Pallets from "./../views/pallets/index.vue";
import Pagos from "./../views/pagos/index.vue";
import DetallePago from "./../views/pagos/show.vue";
import EditMe from "./../views/editMe.vue";
import ComprasRoutes from "./comprasRoutes";
import ProveedoresRoutes from "./proveedoresRoutes";
import OrdersRoutes from "./orders";
import UserPermisos from "./../views/users/Permisos.vue";
import Abilities from "./abilities";
import Reportes from "./reportes";
import Sucursales from "./sucursales";
import SafeFood from "./safe";
import Supplies from "./Supplies";
import Credits from "./credits";
import Factoraje from "./factorajeRoutes";
import Qc from './qc'

Vue.use(VueRouter);

const routes = [
  ...Factoraje,
  ...OrdersRoutes,
  ...ComprasRoutes,
  ...ProveedoresRoutes,
  ...Abilities,
  ...Reportes,
  ...Sucursales,
  ...SafeFood,
  ...Supplies,
  ...Credits,
  ...Qc,
  {
    path: "/test",
    name: "test",
    component: require("@/views/dashboard/Home.vue").default,
    meta: {
      name: "Componente de pruebas",
    },
  },
  {
    path: "/prices",
    name: "prices",
    component: require("@/views/Prices.vue").default,
    meta: {
      name: "Lista de Precios",
    },
  },

  {
    path: "/Pagos/detalle/:id",
    name: "pago.detalle",
    component: DetallePago,
    meta: {
      name: "Detalle del Pago",
    },
  },
  {
    path: "/Pagos",
    name: "pagos",
    component: Pagos,
    meta: {
      name: "Control de Pagos",
    },
  },
  {
    path: "/Pallets",
    name: "pallets",
    component: Pallets,
    meta: {
      name: "Control de Pallets",
    },
  },
  {
    path: "/Notas",
    name: "notas",
    component: Notas,
    meta: {
      name: "Control de Notas",
    },
  },
  {
    path: "/Embarques",
    name: "embarques",
    component: Embarques,
    meta: {
      name: "Control de Embarques",
    },
  },
  {
    path: "/Embarques/detalles/:id",
    name: "embarque.details",
    component: DetalleEmbarque,
    meta: {
      name: "Detalle del Embarque",
    },
  },
  {
    path: "/Facturas",
    name: "facturas",
    component: Facturas,
    meta: {
      name: "Control de Facturas",
    },
  },
  {
    path: "/Facturas/detalles/:id",
    name: "facturas.details",
    component: DetalleFactura,
    meta: {
      name: "Detalle de la Factura",
    },
  },
  {
    path: "/Ventas/detalles/:id",
    name: "ventas.details",
    component: DetalleVenta,
    meta: {
      name: "Detalles de la venta",
    },
  },
  {
    path: "/Ventas",
    name: "ventas",
    component: Ventas,
    meta: {
      name: "Control de Ventas",
    },
  },
  {
    path: "/Presupuestos/detalles/:id",
    name: "budget.details",
    component: BudgetForm,
    meta: {
      name: "Detalles del Presupuesto",
    },
  },
  {
    path: "/Presupuestos",
    name: "budgets",
    component: Budgets,
    meta: {
      name: "Control de  Presupuestos",
    },
  },
  {
    path: "/Almacenes",
    name: "warehouses",
    component: Warehouses,
    meta: {
      name: "Control de  Almacenes",
    },
  },
  {
    path: "/Inicio",
    name: "inicio",
    component: require("@/views/dashboard/Home.vue").default,
    meta: {
      name: "Dashboard",
    },
  },
  {
    path: "/Salir",
    name: "logout",
    component: logout,
  },
  {
    path: "/Usuarios",
    name: "users",
    component: Users,
    meta: {
      name: "Control de Usuarios",
    },
  },
  {
    path: "/Usuarios/editar/:id",
    name: "users.edit",
    component: UserForm,
    meta: {
      name: "Editando Usuario",
    },
  },
  {
    path: "/Usuarios/permisos/:id",
    name: "users.permisos",
    component: UserPermisos,
    meta: {
      name: "Permisos del Usuario",
    },
  },
  {
    path: "/Usuarios/nuevo",
    name: "users.create",
    component: UserForm,
    meta: {
      name: "Nuevo Usuario",
    },
  },
  {
    path: "/Clientes",
    name: "clientes",
    component: Clientes,
    meta: {
      name: "Control de Clientes",
    },
  },
  {
    path: "/Clientes/detalles/:id",
    name: "clientes.details",
    component: ClienteDetalles,
    meta: {
      name: "Detalles del Cliente",
    },
  },
  {
    path: "/Clientes/nuevo",
    name: "clientes.nuevo",
    component: ClienteNuevo,
    meta: {
      name: "Nuevo Cliente",
    },
  },
  {
    path: "/",
    component: require("@/views/dashboard/Home.vue").default,
    meta: {
      name: "Dashboard",
    },
  },
  {
    path: "/Editar-mis-datos",
    name: "editMe",
    component: EditMe,
    meta: {
      name: "Editar Mis Datos",
    },
  },
];

const router = new VueRouter({
  routes,
  linkExactActiveClass: "nav-item active",
  mode: "history",
});
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});
export default router;
