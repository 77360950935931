/** @format */

import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";
import router from "../router";
import fileDownload from "js-file-download";
import { isMobile } from "mobile-device-detect";
import { Printd } from "printd";
Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  state: {
    appName: "Zamgod Solutions",
    errors: [],
    loader: false,
    isMobile,
    d: new Printd(),
  },
  mutations: {
    setErrors(state, payload = {}) {
      state.errors = payload;
    },
    setLoader(state, payload = true) {
      state.loader = payload;
    },
  },
  actions: {
    reset({ commit }) {
      // resets state of all the modules
      Object.keys(modules).forEach((moduleName) => {
        commit(`${moduleName}/setInitialState`);
      });
    },
    errores({ commit, dispatch }, e) {
      const status = e.response.status;
      switch (status) {
        case 403:
          window.swal("No cuentas con los permisos suficientes", {
            icon: "error",
            timer: 1500,
          });
          router.push("/").catch(() => {});
          window.location.reload();
          break;

        case 404:
          window.swal("Ruta o recurso no encontrado", {
            icon: "error",
            timer: 1500,
          });
          break;

        case 401:
          window.swal("La sesion expiró", {
            icon: "error",
            timer: 1500,
          });
          dispatch("reset");
          localStorage.clear();
          window.axios.defaults.headers.common.Authorization =
            "Bearer token-falso";
          router.push("/").catch(() => {});
          break;

        case 422:
          commit("setErrors", e.response.data.errors);
          if (!e.response.data.errors) {
            window.swal(e.response.data.message, {
              icon: "error",
              timer: 2000,
            });
          }
          break;

        case 510: {
          window.swal("Credenciales Inválidas", {
            icon: "error",
            timer: 1500,
          });
          dispatch("Auth/logout");
          break;
        }
        default:
          window.swal(e.response.data.message, { icon: "error", timer: 1500 });
          break;
      }
    },
    /* jshint -W137 */
    message({}, string) {
      Vue.swal({
        icon: "success",
        title: string,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2500,
      });
    },
    /* jshint -W137 */
    download({}, url, name) {
      window.axios
        .get(url, {
          responseType: "blob",
        })
        .then((response) => {
          Vue.swal({
            title: `¿ Descargar el archivo ${name} ?`,
            text: "Se guardara en descargas",
            icon: "warning",
            showCancelButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              fileDownload(response.data, name);
            }
          });
        });
    },
    print({ commit, state }, url) {
      commit("setD");
      const el = document.createElement("p");
      window.axios.get(url).then((resp) => {
        el.innerHTML = resp.data;
        state.d.print(el);
      });
    },
  },
  strict: false,
});
