<!-- eslint-disable -->
<template>
	<v-expansion-panel >
		<v-expansion-panel-header color="green lighten-4">
			Información
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			Proveedor: {{compra.proveedor}} <br>
			Fecha de Compra: {{compra.fecha|date}} <br>
			PO: {{compra.po}} <br>
			PU: {{compra.pu}}
			<template v-if="compra.observaciones">
				<h4>Observaciones</h4>
				{{compra.observaciones}}
			</template>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Informacion',
	computed:{
		...mapState({
			compra: state=>state.Compras.resource
		})
	}
};
</script>
