<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-card class="mt-5" v-if="datos">
    <v-card-title primary-title>
      General Data
    </v-card-title>
    <v-form @submit.prevent="enviar" :disabled="loader">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <v-combobox
              dense
              outlined
              clearable
              label="Inspection Type"
              :items="datos.types"
              v-model="summary.type"
              :error-messages="errors.type"
            />
          </v-col>
          <v-col cols="12" md="8">
            <v-combobox
              dense
              outlined
              clearable
              label="Inspection Place"
              :items="sites"
              v-model="summary.site"
              :error-messages="errors.site"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              dense
              outlined
              clearable
              label="Labels"
              v-model="summary.labels"
              :error-messages="errors.labels"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              outlined
              clearable
              label="Overall Pack Dates"
              v-model="summary.pack_dates"
              :error-messages="errors.pack_dates"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              outlined
              clearable
              label="Overall Appearance"
              v-model="summary.appearance"
              :error-messages="errors.appearance"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              outlined
              clearable
              label="Overall PLU Placement"
              v-model="summary.plu_placement"
              :error-messages="errors.plu_placement"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              outlined
              clearable
              label="Overall Box Condition"
              v-model="summary.box_condition"
              :error-messages="errors.box_condition"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-data-table
              dense
              :headers="headers"
              :items="items"
              hide-default-footer
              class="elevation-1"
            >
              <template v-slot:[`item.shrivel_grade`]="{ item }">
                <v-text-field
                  dense
                  outlined
                  type="number"
                  v-model="item.shrivel_grade"
                  hide-details="auto"
                  :disabled="loader"
                  :error-messages="error_shrivel_grade(item)"
                />
              </template>
              <template v-slot:[`item.color_skin_grade`]="{ item }">
                <v-text-field
                  dense
                  outlined
                  type="number"
                  v-model="item.color_skin_grade"
                  hide-details="auto"
                  :disabled="loader"
                  :error-messages="color_skin_grade_error(item)"
                />
              </template>
              <template v-slot:[`item.firmness_grade`]="{ item }">
                <v-text-field
                  dense
                  outlined
                  type="number"
                  v-model="item.firmness_grade"
                  hide-details="auto"
                  :disabled="loader"
                  :error-messages="firmness_grade_error(item)"
                />
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" md="6">
            <v-textarea
              dense
              outlined
              clearable
              label="Observations"
              v-model="summary.observations"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" type="submit" :disabled="loader">
          {{ btnTitle }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  export default {
    name: "Summary",
    data: () => ({
      original: undefined,
      headers: [
        { text: "Product", value: "text" },
        { text: "Shrivel Grade", value: "shrivel_grade" },
        { text: "Color Skin Grade", value: "color_skin_grade" },
        { text: "Firmness Grade", value: "firmness_grade" },
      ],
    }),
    computed: {
      ...mapState({
        loader: (state) => state.Embarques.loader,
        datos: (state) => state.Embarques.summaryQC,
        errors: (state) => state.errors,
      }),
      summary() {
        if (!this.datos) return false;
        return this.datos.summary;
      },
      modificado() {
        return JSON.stringify(this.original) !== JSON.stringify(this.datos);
      },
      items() {
        return this.summary ? this.summary.prods : [];
      },
      btnTitle() {
        if (!this.summary.site) return "save";
        return "update";
      },
      sites() {
        return this.datos ? this.datos.sites : [];
      },
    },
    beforeMount() {
      this.$store.commit("setErrors");
      this.getSummaryQC();
    },
    methods: {
      ...mapActions("Embarques", ["getSummaryQC"]),
      ...mapMutations("Embarques", ["setLoader", "setsummaryQC"]),
      enviar() {
        let url = `/FR/embarques/summaryQC/${this.summary.id}`;
        this.setLoader();
        this.$store.commit("setErrors");
        window.axios
          .put(url, this.summary)
          .then((resp) => {
            this.getSummaryQC();
            this.$store.dispatch("message", resp.data.message);
          })
          .catch((e) => this.$store.dispatch("errores", e))
          .finally(() => this.setLoader(false));
      },
      cancelar() {
        this.$store.commit("setErrors");
        this.setsummaryQC(this.original);
      },
      error_shrivel_grade(item) {
        let index = this.datos.summary.prods.findIndex(
          (x) => x.product == item.product
        );
        let algo = `prods.${index}.shrivel_grade`;
        return this.errors[algo];
      },
      color_skin_grade_error(item) {
        let index = this.datos.summary.prods.findIndex(
          (x) => x.product == item.product
        );
        let algo = `prods.${index}.color_skin_grade`;
        return this.errors[algo];
      },
      firmness_grade_error(item) {
        let index = this.datos.summary.prods.findIndex(
          (x) => x.product == item.product
        );
        let algo = `prods.${index}.firmness_grade`;
        return this.errors[algo];
      },
    },
    watch: {
      datos(val) {
        this.original = Object.assign({}, val);
      },
    },
  };
</script>
