<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-dialog persistent v-model="dialog" max-width="800">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ title }}
      </v-card-title>
      <v-form @submit.prevent="enviar" :disabled="loader">
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-text-field
                dense
                outlined
                clearable
                label="Cargo"
                v-model="contacto.cargo"
                :error-messages="errors.cargo"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                outlined
                clearable
                label="Nombre"
                v-model="contacto.nombre"
                :error-messages="errors.nombre"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                outlined
                clearable
                label="Correo"
                v-model="contacto.email"
                :error-messages="errors.email"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                outlined
                clearable
                label="Teléfono"
                v-model="contacto.telefono"
                :error-messages="errors.telefono"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" type="submit" :disabled="loader">
            enviar
          </v-btn>
          <v-btn color="error" @click="$emit('cancelar')" :disabled="loader">
            cancelar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    name: "ContactoForm",
    props: {
      dialog: {
        type: Boolean,
      },
      contacto: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    computed: {
      ...mapState({
        errors: (state) => state.errors,
        loader: (state) => state.Clientes.loader,
      }),
      title() {
        return this.contacto.id ? "Editar Contacto" : "Nuevo Contacto";
      },
    },
    mounted() {
      this.contacto.cliente_id = parseInt(this.$route.params.id);
      this.$store.commit("setErrors");
    },
    methods: {
      enviar() {
        const url = this.contacto.id
          ? `FR/clientes/updateContacto/${this.contacto.id}`
          : `FR/clientes/addContacto/${this.$route.params.id}`;
        this.$store.commit("setErrors");
        this.$store.commit("Clientes/setLoader");
        window.axios
          .post(url, this.contacto)
          .then((resp) => {
            this.$store.dispatch("message", resp.data.message);
            this.$store.commit("Clientes/setContactos", resp.data.contactos);
            this.$emit("cancelar");
          })
          .catch((e) => this.$store.dispatch("errores", e))
          .finally(() => {
            this.$store.commit("Clientes/setLoader", false);
          });
      },
    },
  };
</script>
