<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-form @submit.prevent="getAll">
        <v-row class="mt-3">
          <v-col cols="12" md="6">
            <v-autocomplete
              dense
              outlined
              clearable
              label="Filtrar por Proveedor"
              v-model="paginado.proveedor_id"
              :items="proveedores"
            />
          </v-col>
          <v-col>
            <v-btn color="primary" type="submit" :disabled="loader">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              color="success"
              @click="nuevo"
              :disabled="sePuede"
              class="ml-3"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        dense
        :items="items"
        :headers="headers"
        class="elevation-1"
        mobile-breakpoint="0"
        :items-per-page="paginado.perPage"
        hide-default-footer
      >
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="success" @click="detalles(item.id)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon color="red" @click="eliminar(item)" v-if="item.actions.delete">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <Pagination :loader="loader" :items="items" :paginado="paginado" />
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  export default {
    name: "Compras",
    data: () => ({
      headers: [
        { text: "Folio", value: "folio" },
        { text: "Fecha", value: "fecha" },
        { text: "Proveedor", value: "proveedor" },
        { text: "PO", value: "po" },
        { text: "PU", value: "pu" },
        { text: "Importe", value: "importe", align: "end" },
        { text: "Acciones", value: "actions", align: "end" },
      ],
    }),
    computed: {
      ...mapState({
        items: (state) => state.Compras.collection,
        proveedores: (state) => state.Compras.proveedores,
        loader: (state) => state.Compras.loader,
        paginado: (state) => state.Compras.paginado,
      }),
      sePuede() {
        return this.proveedores ? false : true;
      },
      actual() {
        return this.paginado.currentPage;
      },
      proveedor_id() {
        return this.paginado.proveedor_id;
      },
    },
    mounted() {
      this.getAll();
    },
    methods: {
      ...mapActions("Compras", [
        "getAll",
        "nuevo",
        "detalles",
        "eliminarCompra",
      ]),
      eliminar(item) {
        let pregunta = `¿Deseas eliminar la compra ${item.folio}?`,
          text = "Esta acción no puede deshacerse";
        this.$swal({
          title: pregunta,
          text: text,
          icon: "warning",
          showCancelButton: true,
        }).then((resp) => {
          if (resp.isConfirmed) {
            this.eliminarCompra(item.id);
          }
        });
      },
    },
    watch: {
      actual: function() {
        this.getAll();
      },
    },
  };
</script>
