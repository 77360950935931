<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-form @submit.prevent="enviar" :disabled="loader">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="auto">
          <v-text-field
            dense
            outlined
            clearable
            label="Date"
            v-model="resource.date"
            type="date"
            :error-messages="errors.date"
            disabled
          />
        </v-col>
        <v-col cols="12" md="auto">
          <v-text-field
            dense
            outlined
            clearable
            label="Pallet ID"
            v-model="resource.pallet_id"
            :error-messages="errors.pallet_id"
            disabled
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            dense
            outlined
            clearable
            label="Product"
            v-model="resource.product"
            disabled
            :error-messages="errors.product"
          />
        </v-col>
        <v-col cols="12" md="auto">
          <v-text-field
            type="number"
            dense
            outlined
            clearable
            label="Quantity"
            v-model="resource.quantity"
            :error-messages="errors.quantity"
            disabled
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <h3 class="mb-5">QUALITY DEFECTS</h3>
          <quality :resource="resource" class="mb-4" />
        </v-col>
        <v-col cols="12" md="6">
          <h3 class="mb-5">CONDITION DEFECTS</h3>
          <condition :resource="resource" class="mb-4" />
        </v-col>
        <v-col cols="12">
          <v-textarea
            outlined
            clearable
            label="Observations"
            v-model="resource.observations"
          />
        </v-col>
        <v-col cols="12" md="auto">
          <v-text-field
            type="number"
            dense
            outlined
            clearable
            label="Firmness"
            v-model="resource.firmness"
            :error-messages="errors.firmness"
            hint="Firmeza"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn color="success" type="submit" v-if="modificado" :disabled="loader">
        save
      </v-btn>
      <v-btn color="error" @click="$emit('cancelar')" :disabled="loader">
        cancel
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
  import Quality from "./QualityFields.vue";
  import Condition from "./ConditionFields.vue";
  import { mapState } from "vuex";
  export default {
    name: "Edit",
    components: { Quality, Condition },
    props: {
      resource: {
        type: Object,
        required: true,
      },
    },
    emits: ["cancelar"],
    data: () => ({
      original: undefined,
    }),
    computed: {
      ...mapState({
        loader: (state) => state.Embarques.loader,
        errors: (state) => state.errors,
      }),
      modificado() {
        return JSON.stringify(this.original) !== JSON.stringify(this.resource);
      },
    },
    mounted() {
      this.$store.commit("setErrors");
      this.original = Object.assign({}, this.resource);
    },
    methods: {
      enviar() {
        this.$store.commit("Embarques/setLoader", true);
        window.axios
          .put(`FR/updateSample/${this.resource.id}`, this.resource)
          .then((resp) => {
            this.$store.dispatch("message", resp.data.message);
            this.$store.commit("Embarques/setSamples", resp.data.collection);
            this.$emit("cancelar");
          })
          .catch((e) => this.$store.dispatch("errores", e))
          .finally(() => this.$store.commit("Embarques/setLoader", false));
      },
    },
  };
</script>
