<!-- eslint-disable -->
<template>
	<v-card v-if="proveedor" height="100%">
		<v-card-text>
			<v-expansion-panels multiple v-model="panel">
				<v-expansion-panel>
					<v-expansion-panel-header color="green lighten-4">
						Compras
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-container>
              aqui va una tabla con las compras
            </v-container>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-card-text>
		<v-card-actions>
			<v-btn color="primary" @click="regresar">
				regresar a proveedores
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Historial',
	data: () => ({
		panel: [0]
	}),
	computed: {
		...mapState({
			proveedor: state => state.Proveedores.resource
		})
	},
	mounted() {
		if (!this.proveedor) {
			this.$router.push({ name: 'proveedores' }).catch(() => {});
		}
	},
	methods: {
		regresar() {
			this.$router.push({ name: 'proveedores' }).catch(() => {});
		}
	}
};
</script>
