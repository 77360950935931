<!-- eslint-disable -->
<template>
	<v-card height="100%">
		<v-card-text>
			<v-expansion-panels multiple v-if="budget">
				<DataBudget />
				<Product v-if="products" />
				<PU v-if="pu" />
			</v-expansion-panels>
		</v-card-text>
		<v-card-actions>
			<v-btn color="primary" @click="regresar">regresar</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DataBudget from './budget/PanelBudget.vue';
import Product from './budget/PanelProduct.vue';
import PU from './budget/PanelPu.vue';
export default {
	name: 'Budget',
	components: { DataBudget, Product, PU },
	computed: {
		...mapState({
			budget: state => state.Sucursales.budget,
			products: state => state.Sucursales.products,
			pu: state => state.Sucursales.pu
		})
	},
	methods: {
		...mapActions('Sucursales', ['showBudget']),
		regresar() {
			this.$router.push({ name: 'california.budgets' });
		}
	},
	beforeMount() {
		if (!this.budget) {
			this.showBudget(this.$route.params.id);
		}
	}
};
</script>
