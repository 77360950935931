<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4">
          <v-btn small color="success" class="mt-5" @click="nuevo" v-if="userCan('fr-users-create')">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn small color="info" class="mt-5 ml-3" @click="getAll">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :items="activos"
        :headers="headers"
        class="elevation-1"
        :search.sync="search"
        mobile-breakpoint="0"
      >
        <template v-slot:[`item.lastLogin`]="{ item }">
          {{ item.lastLogin | dateTime }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="success"
            v-if="item.actions.edit"
            @click="editar(item.id)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            color="red"
            v-if="item.actions.delete"
            @click="eliminar(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn
            icon
            color="secondary"
            v-if="item.actions.abilities && userCan('*')"
            @click="$store.dispatch('Users/getAbilities', item)"
          >
            <v-icon>mdi-lock</v-icon>
          </v-btn>
          <v-btn
            icon
            color="secondary"
            v-if="user.email == 'developer@zamgod.mx'"
            @click="$store.dispatch('Auth/tokenById', item.id)"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-text v-if="inactivos.length">
      <h3>Usuarios deshabilitados</h3>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers2"
        :items="inactivos"
        class="elevation-1 mt-3"
      >
        <template v-slot:[`item.trashed`]="{ item }">
          {{ item.trashed | dateTime }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="success" @click="restaurar(item)">
            <v-icon>mdi-delete-restore</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapState, mapActions, mapMutations } from "vuex";
  export default {
    name: "Users",
    data: () => ({
      headers2: [
        { text: "Nombre", value: "nombre" },
        { text: "Correo", value: "email" },
        { text: "Teléfono", value: "telefono" },
        { text: "Deshabilitado", value: "trashed" },
        { text: "Acciones", value: "actions", align: "end" },
      ],
    }),
    computed: {
      ...mapState({
        items: (state) => state.Users.collection,
        headers: (state) => state.Users.headers,
        loader: (state) => state.Users.loader,
        user: (state) => state.Auth.user,
        
      }),
      activos() {
        return this.items.filter((x) => !x.trashed);
      },
      inactivos() {
        return this.items.filter((x) => x.trashed);
      },
	  search: {
          get() {
            return this.$store.state.Users.search;
          },
          set(value) {
            this.$store.commit("Users/setSearch", value);
          },
        },
    },
    mounted() {
      this.getAll();
    },
    beforeDestroy() {},
    methods: {
      ...mapActions("Users", ["getAll", "edit", "deleteUser"]),
      ...mapMutations("Users", ["setCollection"]),
      editar(id) {
        this.$router
          .push({ name: "users.edit", params: { id } })
          .catch(() => {});
      },
      nuevo() {
        this.$router.push({ name: "users.create" }).catch(() => {});
      },
      eliminar(item) {
        let pregunta = `¿Deseas deshabilitar a ${item.nombre}?`,
          text =
            "El usuario no podrá usar el sistema. Los datos no se eliminan";
        window
          .swal({
            title: pregunta,
            text: text,
            icon: "warning",
            buttons: ["Cancelar", "Confirmar"],
            dangerMode: true,
          })
          .then((confirmado) => {
            if (confirmado) {
              this.deleteUser(item.id);
            }
          });
      },
      restaurar(item) {
        let pregunta = `¿Deseas habilitar a ${item.nombre}?`,
          text = "El usuario podrá usar el sistema de nuevo.";
        window
          .swal({
            title: pregunta,
            text: text,
            icon: "warning",
            buttons: ["Cancelar", "Confirmar"],
            dangerMode: true,
          })
          .then((confirmado) => {
            if (confirmado) {
              this.$store.dispatch("Users/restoreResource", item.id);
            }
          });
      },
    },
  };
</script>
