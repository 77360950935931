<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-card height="100%" v-if="resource">
    <v-expansion-panels multiple >
      <Information />
      <Invoices />
      <Archivos />
    </v-expansion-panels>
    <v-card-actions @click="$router.push({ name: 'goodman' })">
      <v-btn color="primary">back to schedules</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import Information from "./PanelInfo.vue";
  import Invoices from "./PanelInvoices.vue";
  import Archivos from "./PanelFiles.vue";
  export default {
    name: "Show",
    components: { Information, Invoices, Archivos },
    computed: {
      ...mapState({
        resource: (state) => state.Goodman.resource,
      }),
    },
    methods: {
      ...mapActions("Goodman", ["showResource"]),     
    },
    beforeMount() {
      if (!this.items) {
        this.showResource(this.$route.params.id);
      }
    },
  };
</script>
