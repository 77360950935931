<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Historial de Facturas y Pagos
    </v-expansion-panel-header>
    <v-expansion-panel-content v-if="datos">
      <v-card-text>
        <v-form @submit.prevent="buscar">
          <v-row class="mt-2">
            <v-col cols="12" md="3" lg="2">
              <v-text-field
                dense
                outlined
                label="Fecha Inicial"
                type="date"
                v-model="datos.inicio"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="12" md="3" lg="2">
              <v-text-field
                dense
                outlined
                label="Fecha Final"
                type="date"
                v-model="datos.fin"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="12" md="3" lg="3">
              <v-btn color="info" type="submit">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <template v-if="datos.resta > 0">
                <v-btn color="success" class="ml-1" @click="dialog = true">
                  <v-icon>mdi-email</v-icon>
                </v-btn>
                <v-btn color="error" class="ml-1" @click="downloadPdf">
                  <v-icon>mdi-file-pdf</v-icon>
                </v-btn>
                <v-btn color="primary" class="ml-1" @click="downloadExcel">
                  <v-icon>mdi-file-excel</v-icon>
                </v-btn>
              </template>
            </v-col>
            <v-col>
              <v-row>
                <v-col cols="6" md="2">
                  <v-switch v-model="tabla1" :label="`Facturas`"></v-switch>
                </v-col>
                <v-col cols="6" md="2">
                  <v-switch v-model="tabla2" :label="`Pagos`"></v-switch>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
        <v-row class="mt-3">
          <v-col cols="12" md="3" lg="2">
            Total de Facturas: {{ facturas.length }}
          </v-col>
          <v-col cols="12" md="3" lg="2">
            importe Facturas: {{ datos.importe | moneda }}
          </v-col>
          <v-col cols="12" md="3" lg="2">
            Total de Pagos: {{ pagos.length }}
          </v-col>
          <v-col cols="12" md="3" lg="2">
            Importe Pagos: {{ datos.pagado | moneda }}
          </v-col>
          <v-col cols="12" md="3" lg="2">
            Importe Pendiente: {{ datos.resta | moneda }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="tabla1">
        <Facturas :items="facturas" />
      </v-card-text>
      <v-card-text v-if="tabla2">
        <Pagos :items="pagos" />
      </v-card-text>
    </v-expansion-panel-content>
    <Email :dialog="dialog" v-if="dialog" @cancelar="dialog = false" />
  </v-expansion-panel>
</template>

<script>
  import { mapMutations, mapState } from "vuex";
  import fileDownload from "js-file-download";
  import Facturas from "./TablaFacturas.vue";
  import Pagos from "./TablaPagos.vue";
  import Email from "./ModalEmail.vue";
  export default {
    name: "Nuevo",
    components: {
      Facturas,
      Pagos,
      Email,
    },
    data: () => ({
      tabla1: false,
      tabla2: false,
      dialog: false,
    }),
    computed: {
      ...mapState({
        datos: (state) => state.Clientes.new,
        resource: (state) => state.Clientes.resource,
      }),
      facturas() {
        return this.datos.facturas;
      },
      pagos() {
        return this.datos.pagos;
      },
    },
    methods: {
      ...mapMutations("Clientes", ["setNew"]),
      buscar() {
        this.tabla1 = this.tabla2 = false;
        this.datos.facturas = this.datos.pagos = [];
        this.datos.importe = this.datos.pagado = this.datos.resta = 0;
        let url = `FR/getFacturas/${this.$route.params.id}`;
        window.axios
          .get(url, {
            params: {
              inicio: this.datos.inicio,
              fin: this.datos.fin,
            },
          })
          .then((resp) => {
            this.setNew(resp.data);
          });
      },
      downloadPdf() {
        let url = `FR/pdfStatement/${this.resource.id}`;
        let name = `Statement ${this.resource.nombre}.pdf`;
        this.$swal({
          title: `¿ Descargar el archivo ${name} ?`,
          icon: "warning",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            window.axios
              .get(url, {
                responseType: "blob",
              })
              .then((response) => {
                fileDownload(response.data, name);
              });
          }
        });
      },
      downloadExcel() {
        let url = `FR/getStatement/${this.resource.id}`;
        let name = `Statement ${this.resource.nombre}.xlsx`;
        this.$swal({
          title: `¿ Descargar el excel ?`,
          icon: "warning",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            window.axios
              .get(url, {
                responseType: "blob",
              })
              .then((response) => {
                console.log(response.headers);
                fileDownload(response.data, name);
              });
          }
        });
      },
    },
  };
</script>
