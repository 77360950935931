import router from '../../router';
const initialState = () => ({
	headers: [],
	collection: [],
	resource: {},
	loader: false,
	deshabilitados: undefined,
	sucursales: undefined,
	search: undefined
});
const url = '/users';
export default {
	namespaced: true,
	state: initialState(),
	mutations: {
		setInitialState(state) {
			const newState = initialState();
			Object.keys(newState).forEach(key => {
				state[key] = newState[key];
			});
		},
		setHeaders(state, payload) {
			state.headers = payload;
		},
		setCollection(state, payload) {
			state.collection = payload;
		},
		setResource(state, payload) {
			state.resource = payload;
		},
		setLoader(state, payload) {
			state.loader = payload;
		},
		setPermisos(state, payload) {
			state.permisos = payload;
		},
		deletePermisos(state, payload) {
			state.permisos.actuales = payload;
		},
		setAbilities(state, payload) {
			state.permisos.actuales = payload;
		},
		setDeshabilitados(state, payload) {
			state.deshabilitados = payload;
		},
		setSucursales(state, payload) {
			state.sucursales = payload;
		},
		setSearch(state,payload){
			state.search = payload;
		}
	},
	actions: {
		async getAll({ commit,dispatch }) {
			commit('setCollection', []);
			window.axios
				.get('FR/users', { params: { tipo: 'Frfresh' } })
				.then(resp => {
					commit('setCollection', resp.data.collection);
					commit('setHeaders', resp.data.headers);
					commit('setSucursales', resp.data.sucursales);
				})
				.catch(e => {
					dispatch('errores', e, { root: true });
					commit('setLoader', false);
				});
		},
		async edit({ commit }, id) {
			window.axios
				.get(`users/${id}`)
				.then(resp => {
					commit('setResource', resp.data);
				})
				.catch(e => {
					dispatch('errores', e, { root: true });
					commit('setLoader', false);
				});
		},
		async sendUser({ commit, state, dispatch }) {
			commit('setErrors', [], { root: true });
			commit('setLoader', true);
			state.resource.tipo = 'Frfresh';
			if (state.resource.id) {
				await window.axios
					.put(`FR/users/${state.resource.id}`, state.resource)
					.then(resp => {
						window.swal(resp.data.message, { icon: 'success', timer: 1500 });
						let index = state.collection.findIndex(
							x => x.id == state.resource.id
						);
						state.collection.splice(index, 1, resp.data.user);
						commit('setLoader', false);
						router.push({ name: 'users' });
					})
					.catch(e => {
						dispatch('errores', e, { root: true });
						commit('setLoader', false);
					});
			} else {
				await window.axios
					.post('FR/users', state.resource)
					.then(resp => {
						window.swal(resp.data.message, { icon: 'success', timer: 1500 });
						state.collection.unshift(resp.data.user);
						commit('setLoader', false);
						router.push({ name: 'users' });
					})
					.catch(e => {
						dispatch('errores', e, { root: true });
						commit('setLoader', false);
					});
			}
		},
		async deleteUser({ commit, dispatch, state }, id) {
			commit('setLoader', true);
			window.axios
				.delete(`FR/users/${id}`)
				.then(resp => {
					window.swal(resp.data.message, { icon: 'success', timer: 1500 });
					dispatch('getAll');
				})
				.catch(e => {
					dispatch('errores', e, { root: true });
					commit('setLoader', false);
				});
		},
		async getAbilities({ commit, dispatch }, item) {
			commit('setLoader', true);
			await window.axios
				.get(`${url}/abilities/${item.id}`)
				.then(resp => {
					commit('setPermisos', resp.data.permisos);
					commit('setResource', resp.data.resource);
					router
						.push({ name: 'users.permisos', params: { id: item.id } })
						.catch(() => {});
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async syncAbilities({ commit, dispatch, state }) {
			commit('setLoader', true);
			let id = state.resource.id;
			await window.axios
				.post(`${url}/abilities/${id}`, state.permisos)
				.then(resp => {
					window.swal(resp.data.message, { icon: 'success', timer: 1500 });
					router.push({ name: 'users' });
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async restoreResource({ commit, dispatch }, id) {
			commit('setLoader', true);
			await window.axios
				.post(`${url}/habilitar/${id}`)
				.then(resp => {
					window.swal(resp.data.message, { icon: 'success', timer: 1500 });
					dispatch('getAll');
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		}
	}
};
