/** @format */

import Index from "./../views/orders/Index.vue";
import Details from "./../views/orders/Detalles.vue";
const routes = [
  {
    path: "/Purchase-Orders",
    name: "orders",
    component: Index,
    meta: {
      name: "Control de Ordenes de Compra (Purchase Order)",
    },
  },
  {
    path: "/Purchase-Orders/nueva",
    name: "orders.create",
    component: Details,
    meta: {
      name: "Nueva Orden de Compra (Purchase Order)",
    },
  },
  {
    path: "/Purchase-Orders/details/:id",
    name: "orders.edit",
    component: Details,
    meta: {
      name: "Editando Orden de Compra (Purchase Order)",
    },
  },
  {
    path: "/Purchase-Orders/makePurchase/:id",
    name: "orders.purchase",
    component: require("./../views/orders/MakePurchase.vue").default,
    meta: {
      name: "Realizar Compra",
    },
  },
];
export default routes;
