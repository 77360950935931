<!-- eslint-disable -->
<template>
	<v-card height="100%">
		<v-form @submit.prevent="$store.dispatch('Reportes/guardarResource')">
			<v-card-text>
				<v-row class="mt-3">
					<v-col cols="12" md="6">
						<v-textarea
							dense
							outlined
							label="Falla Reportada"
							v-model="resource.falla"
							:error-messages="errors.falla"
						/>
					</v-col>
          <v-col cols="12" md="6">
						<v-textarea
							dense
							outlined
							label="Observaciones"
							v-model="resource.observaciones"
							:error-messages="errors.observaciones"
						/>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-btn color="success" type="submit">
					guardar
				</v-btn>
				<v-btn color="error" @click="$router.push({ name: 'reportes' })">
					cancelar
				</v-btn>
			</v-card-actions>
		</v-form>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Nuevo',
	computed: {
		...mapState({
			errors: state => state.errors,
			resource: state => state.Reportes.resource,
			loader: state => state.Reportes.loader
		})
	}
};
</script>
