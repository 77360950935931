<!-- eslint-disable -->
<template>
	<v-card height="100%" v-if="compra">
		<v-form @submit.prevent="send">
			<v-card-text>
				<v-row class="mt-3">
					<v-col cols="12" md="8">
						<v-autocomplete
							dense
							outlined
							small-chips
							label="Proveedor"
							:items="proveedores"
							v-model="compra.proveedor_id"
							:error-messages="errors.proveedor_id"
						/>
					</v-col>
					<v-col cols="12" md="4">
						<v-text-field
							type="date"
							label="Fecha de Compra"
							outlined
							dense
							v-model="compra.fecha"
							:error-messages="errors.fecha"
						/>
					</v-col>
					<v-col cols="12" md="6">
						<v-text-field
							type="text"
							label="PU"
							outlined
							dense
							v-model="compra.pu"
							:error-messages="errors.pu"
						/>
					</v-col>
					<v-col cols="12">
						<v-textarea
							outlined
							label="Observaciones"
							v-model="compra.observaciones"
							rows="3"
						/>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-btn small color="primary" type="submit" :disabled="loader">
					guardar
				</v-btn>
				<v-btn small color="error" @click="cancelar" class="ml-3">
					cancelar
				</v-btn>
			</v-card-actions>
		</v-form>
	</v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
	name: 'Compra',
	data: () => ({
		//
	}),
	computed: {
		...mapState({
			proveedores: state => state.Compras.proveedores,
			loader: state => state.Compras.loader,
			compra: state => state.Compras.resource,
			errors: state => state.errors
		})
	},
	mounted() {
		if (!this.compra) this.$router.push({ name: 'compras' }).catch(() => {});
	},
	methods: {
		cancelar() {
			this.$router.push({ name: 'compras' }).catch(() => {});
		},
		...mapActions('Compras', ['send'])
	}
};
</script>
