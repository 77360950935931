<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-form @submit.prevent="storeResource(schedule)" :disabled="loader">
      <v-card-text>
        <v-row class="mt-2">
          <v-col>
            <v-text-field
              dense
              outlined
              clearable
              label="Schedule #"
              v-model="schedule.schedule"
              :error-messages="errors.schedule"
              hide-details="auto"
            />
          </v-col>
          <v-col>
            <v-text-field
              dense
              outlined
              clearable
              label="Date"
              type="date"
              v-model="schedule.date"
              :error-messages="errors.date"
              hide-details="auto"
            />
          </v-col>
          <v-col>
            <v-text-field
              dense
              outlined
              clearable
              label="Wire Transfer Fee"
              type="number"
              v-model="schedule.wire_transfer_fee"
              :error-messages="errors.wire_transfer_fee"
              hide-details="auto"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row class="mt-2">
          <v-col cols="12" md="6">
            <v-card height="100%">
              <v-card-text>
                <h3>Select Invoices</h3>
                <v-row class="mt-2">
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      clearable
                      append-icon="mdi-magnify"
                      label="Buscar"
                      id="id"
                      v-model="search"
                    />
                  </v-col>
                </v-row>
                <v-data-table
                  dense
                  v-model="schedule.selected"
                  show-select
                  item-key="id"
                  :items="invoices"
                  :headers="headers"
                  class="elevation-1 mt-4"
                  mobile-breakpoint="0"
                  :loading="loader"
                  :search.sync="search"
                >
                  <template v-slot:[`item.amount`]="{ item }">
                    {{ item.amount | moneda }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card height="100%">
              <v-card-text>
                <h3>Invoices Selected</h3>
                <v-row class="mt-2">
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      clearable
                      append-icon="mdi-magnify"
                      label="Buscar"
                      id="id"
                      v-model="search2"
                    />
                  </v-col>
                </v-row>
                <v-data-table
                  dense
                  item-key="id"
                  :items="schedule.selected"
                  :headers="headers"
                  class="elevation-1 mt-4"
                  mobile-breakpoint="0"
                  :loading="loader"
                  :search.sync="search2"
                >
                  <template v-slot:[`item.amount`]="{ item }">
                    {{ item.amount | moneda }}
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-text>
                <v-row v-if="amount">
                  <v-col cols="12" class="text-right">
                    Total of Invoices Sold:
                    <strong>{{ amount | moneda }}</strong>
                  </v-col>
                  <v-col cols="12" class="text-right">
                    Processing Fees:
                    <strong class="red--text"
                      >-{{ (amount * ProcessingFees) | moneda }}</strong
                    >
                  </v-col>
                  <v-col cols="12" class="text-right">
                    Required Reserve:
                    <strong class="red--text"
                      >-{{ (amount * RequiredReserve) | moneda }}</strong
                    >
                  </v-col>
                  <v-col cols="12" class="text-right">
                    Expenses Deducted:
                    <strong class="red--text"
                      >-{{ schedule.wire_transfer_fee | moneda }}</strong
                    >
                  </v-col>
                  <v-col cols="12" class="text-right">
                    Proceeds Owed/Paid to Client:
                    <strong>{{
                      (amount -
                        amount * (ProcessingFees + RequiredReserve) -
                        schedule.wire_transfer_fee)
                        | moneda
                    }}</strong>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" :disabled="!listo || loader" type="submit">
          save
        </v-btn>
        <v-btn
          color="error"
          @click="$router.push({ name: 'goodman' })"
          :disabled="loader"
        >
          cancel
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from "vuex";

  export default {
    name: "Create",
    data: () => ({
      schedule: {
        schedule: undefined,
        wire_transfer_fee: undefined,
        date: undefined,
        selected: [],
      },
      search: undefined,
      search2: undefined,
      headers: [
        { text: "Invoice", value: "id" },
        { text: "Customer", value: "customer" },
        { text: "Amount", value: "amount", align: "end" },
      ],
    }),
    computed: {
      ...mapState({
        errors: (state) => state.errors,
        invoices: (state) => state.Goodman.invoices,
        loader: (state) => state.Goodman.loader,
        ProcessingFees: (state) => state.Goodman.ProcessingFees,
        RequiredReserve: (state) => state.Goodman.RequiredReserve,
        WireTransferFee: (state) => state.Goodman.WireTransferFee,
      }),
      listo() {
        return (
          this.schedule.selected.length &&
          this.schedule.schedule &&
          this.schedule.wire_transfer_fee &&
          this.schedule.date
        );
      },
      amount() {
        return window._.sumBy(this.schedule.selected, "amount");
      },
    },
    methods: {
      ...mapActions("Goodman", ["nueva", "storeResource"]),
    },
    beforeMount() {
      if (!this.invoices) this.nueva();
      this.$store.commit("setErrors");
    },
    mounted() {
      this.schedule.wire_transfer_fee = this.WireTransferFee;
    },
    watch: {
      WireTransferFee(val) {
        this.schedule.wire_transfer_fee = val;
      },
    },
  };
</script>
