<!-- eslint-disable -->
<template>
	<v-card height="100%">
		<v-card-text v-if="resource.id">
			<v-row class="mt-3 mb-1">
				<v-col md="4"> Folio: {{ resource.folio }} </v-col>
				<v-col md="4"> Estatus: {{ resource.status }} </v-col>
				<v-col md="4"> Fecha: {{ resource.created_at | dateTime }} </v-col>
			</v-row>
			<v-divider></v-divider>
			<v-row class="mt-1">
				<v-col cols="12" md="4">
					Usuario que reporta: <br />
					{{ user.full_name }}
				</v-col>
				<v-col cols="12" md="4">
					Falla Reportada: <br />
					{{ resource.falla }}
				</v-col>
				<v-col v-if="resource.observaciones" cols="12" md="4">
					Observaciones: <br />
					{{ resource.observaciones }}
				</v-col>
			</v-row>
		</v-card-text>
		<v-form @submit.prevent="$store.dispatch('Reportes/actualizar')">
			<v-card-text>
				<v-divider></v-divider>
				<v-row class="mt-3">
					<v-col cols="12" md="4">
						<v-combobox
							dense
							outlined
							clearable
							label="Estatus"
							v-model="resource.status"
							:items="resource.statuses"
							:error-messages="errors.status"
						/>
					</v-col>
					<v-col cols="12" md="4">
						<v-text-field
							dense
							outlined
							label="Última Actualización"
							v-model="resource.actualizado"
						/>
					</v-col>
					<v-col cols="12">
						<v-textarea
							outlined
							label="Acciones Realizadas"
							v-model="resource.acciones"
							:error-messages="errors.acciones"
						/>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-btn color="success" type="submit" :disabled="loader">
					guardar
				</v-btn>
				<v-btn
					color="error"
					@click="$router.push({ name: 'reportes' })"
					:disabled="loader"
				>
					cancelar
				</v-btn>
			</v-card-actions>
		</v-form>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Editar',
	computed: {
		...mapState({
			errors: state => state.errors,
			resource: state => state.Reportes.resource,
			loader: state => state.Reportes.loader
		}),
		user() {
			return this.resource.user;
		}
	},
	mounted() {
		if (!this.resource.id) {
			let id = this.$route.params.id;
			this.$store.dispatch('Reportes/editar', this.$route.params.id);
		}
	}
};
</script>
