<!-- eslint-disable -->
<template>
	<v-card height="100%">
		<v-card-text>
			<v-row>
				<v-col cols="12" md="4">
					<v-btn small color="info" class="mt-5 ml-3" @click="getAll">
						<v-icon>mdi-refresh</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="12" xl="4" offset-xl="4">
					<v-container>
						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="Busqueda"
							single-line
							hide-details
						/>
					</v-container>
				</v-col>
			</v-row>
			<v-data-table
				dense
				:items="items"
				:headers="headers"
				class="elevation-1"
				:search.sync="search"
				mobile-breakpoint="0"
			>
				<template v-slot:[`item.id`]="{ item }">
					{{ item.id | folio }}
				</template>
				<template v-slot:[`item.fecha`]="{ item }">
					{{ item.fecha | dateTime }}
				</template>
				<template v-slot:[`item.importe`]="{ item }">
					{{ item.importe | moneda }}
				</template>
				<template v-slot:[`item.resta`]="{ item }">
					{{ item.resta | moneda }}
				</template>
				<template v-slot:[`item.pagos`]="{ item }">
					{{ (item.importe - item.resta) | moneda }}
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<template v-if="!item.deleted_at">
						<v-btn icon color="primary" :href="item.pdf" target="_blank" v-if="userCan('fr-notas-pdf')">
							<v-icon>mdi-file-pdf</v-icon>
						</v-btn>
						<v-btn icon color="red" @click="eliminar(item)" v-if="userCan('fr-notas-delete')">
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</template>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex';
export default {
	name: 'Notas',
	data: () => ({
		items: [],
		headers: [],
		search: undefined,
		loader: false
	}),
	computed: {
		//
	},
	mounted() {
		this.getAll();
	},
	methods: {
		getAll() {
			this.items = [];
			window.axios.get('FR/notas').then(resp => {
				this.items = resp.data.collection;
				this.headers = resp.data.headers;
			});
		},
		eliminar(item) {
			this.loader = true;
			let pregunta = `¿Deseas cancelar la nota ${item.folio}?`;
			window
				.swal({
					title: pregunta,
					icon: 'warning',
					buttons: ['Cancelar', 'Confirmar'],
					dangerMode: true
				})
				.then(confirmed => {
					if (confirmed) {
						window.axios
							.post(`FR/eliminarNota/${item.id}`)
							.then(resp => {
								window.swal(resp.data.message, {
									icon: 'success',
									timer: 1500
								});
								let index = this.items.findIndex(x => x.id == item.id);
								this.items.splice(index, 1, resp.data.nota);
							})
							.catch(e =>
								window.swal(e.response, { icon: 'error', timer: 1500 })
							)
							.finally(() => (this.loader = false));
					}
				});
		}
	}
};
</script>
