import router from '../../router';
const url = 'FR/warehouses';
const initialState = () => ({
	headers: [],
	collection: [],
	resource: {},
	loader: false,
	action: false
});
export default {
	namespaced: true,
	state: initialState(),
	mutations: {
		setInitialState(state) {
			const newState = initialState();
			Object.keys(newState).forEach(key => {
				state[key] = newState[key];
			});
		},
		setHeaders(state, payload) {
			state.headers = payload;
		},
		setCollection(state, payload) {
			state.collection = payload;
		},
		setResource(state, payload) {
			state.resource = payload;
		},
		setLoader(state, payload) {
			state.loader = payload;
		},
		SET_ACTION(state, payload) {
			state.action = payload;
		}
	},
	actions: {
		async getAll({ commit, dispatch }) {
			commit('setCollection', []);
			window.axios
				.get(url)
				.then(resp => {
					commit('setCollection', resp.data.collection);
					commit('setHeaders', resp.data.headers);
				})
				.catch(e => dispatch('errores', e, { root: true }));
		},
		async sendWarehouse({ commit, dispatch, state }, almacen) {
			commit('setLoader', true);
			commit('setErrors', [], { root: true });
			if (!almacen.id) {
				window.axios
					.post(url, almacen)
					.then(resp => {
						window.swal(resp.data.message, { icon: 'success', timer: 1500 });
						state.collection.unshift(resp.data.resource);
						commit('SET_ACTION', false);
					})
					.catch(e => dispatch('errores', e, { root: true }))
					.finally(() => commit('setLoader', false));
			} else {
				window.axios
					.put(`${url}/${almacen.id}`, almacen)
					.then(resp => {
						window.swal(resp.data.message, { icon: 'success', timer: 1500 });
						let index = state.collection.findIndex(x => x.id == almacen.id);
						state.collection.splice(index, 1, resp.data.resource);
						commit('SET_ACTION', false);
					})
					.catch(e => dispatch('errores', e, { root: true }))
					.finally(() => commit('setLoader', false));
			}
		},
		async deleteWarehouse({ commit, dispatch, state }, id) {
			window.axios
				.delete(`${url}/${id}`)
				.then(resp => {
					window.swal(resp.data.message, { icon: 'success', timer: 1500 });
					let index = state.collection.findIndex(x => x.id == id);
					state.collection.splice(index, 1);
					commit('SET_ACTION', false);
				})
				.catch(e => dispatch('errores', e, { root: true }));
		}
	}
};
