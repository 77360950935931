<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Nuevo Inventario
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card-text>
        <v-row class="my-2">
          <v-col cols="12" md="3" xl="2" class="mio">
            <strong>Total apartados:{{ disponibles | numero }}</strong>
          </v-col>
          <v-col cols="12" md="3" xl="2" class="mio">
            <strong>Total disponibles: {{ apartados | numero }}</strong>
          </v-col>
          <v-col cols="12" md="3" xl="2">
            <v-switch v-model="todos" label="Mostrar todos "></v-switch>
          </v-col>
          <v-col cols="12" md="3" xl="2">
            <v-switch v-model="fechas" label="Disponibilidad pro Fechas"></v-switch>
          </v-col>
        </v-row>
        <v-data-table
          dense
          :items="items"
          :headers="headers"
          class="elevation-1"
          mobile-breakpoint="0"
          :loading="loader"
          :item-class="row_classes"
          :items-per-page="perPage"
          hide-default-footer
        >
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn small color="primary" @click="actualizar">actualizar</v-btn>
      </v-card-actions>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  export default {
    name: "Inventario",
    components: {},
    data: () => ({
      collection: [],
      headers: [],
      todos: false,
      loader: false,
      fechas: false,
    }),
    beforeMount() {
      this.actualizar();
    },
    methods: {
      actualizar() {
        this.loader = true;
        this.headers = this.collection = [];
        window.axios
          .get("FR/new-inventario")
          .then((resp) => {
            this.collection = resp.data.items;
            this.headers = resp.data.headers;
          })
          .finally(() => (this.loader = false));
      },
    },
    computed: {
      apartados() {
        return window._.sumBy(this.collection, "apartados");
      },
      disponibles() {
        return window._.sumBy(this.collection, "disponibles");
      },
      perPage() {
        return this.items.length;
      },
      items() {
        if (this.todos) return this.collection;
        return this.collection.filter((x) => x.disponibles > 0);
      },
    },
  };
</script>
<style scoped>
  .mio {
    margin-top: 16px;
    padding-top: 4px;
  }
</style>
