<!-- eslint-disable -->
<template>
	<v-expansion-panel v-if="original.nombre">
		<v-expansion-panel-header color="green lighten-4">
			Datos del cliente {{ original.nombre }}
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<Formulario/>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
import Formulario from './formulario'
export default {
  name: 'Datos',
  components:{Formulario},
	data: () => ({
		original: {}
	}),
	computed: {
		...mapState({
			cliente: state => state.Clientes.resource,
		}),
		title() {
			return this.original.nombre
				? `Datos del Cliente ${this.original.nombre}`
				: 'Cargando ....';
		}
	},
	watch: {
		cliente: function(val) {
			this.original = Object.assign({}, val);
		}
	},
	mounted(){
		this.original = Object.assign({}, this.cliente);
	}
};
</script>
