<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Compras
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        mobile-breakpoint="0"
        class="elevation-1 mt-3"
      >
        <template v-slot:[`item.fecha`]="{ item }">{{item.fecha|date}}</template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    name: "Compras",
    computed: {
      ...mapState({
        items: (state) => state.Supplies.compras,
      }),
    },
    data: () => ({
      headers: [
        { text: "Compra", value: "folio" },
        { text: "Fecha", value: "fecha" },
        { text: "PO", value: "po" },
        { text: "Proveedor", value: "supplier" },
        { text: "Cantidad", value: "cantidad", align: "end" },
      ],
    }),
  };
</script>
