<!-- eslint-disable -->
<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Datos PickUp Order (PU) {{ pu.folio }}
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-row class="mt-3">
				<v-col>
					Fecha: <strong>{{ pu.date | date }}</strong>
				</v-col>
				<v-col>
					Almacén: <strong>{{ pu.warehouse }}</strong>
				</v-col>
				<v-col>
					Termógrafos:
					<strong>{{ pu.thermographs ? pu.thermographs : 'Ninguno' }}</strong>
				</v-col>
				<v-col v-if="pu.observations" cols="12">
					<h4>Observaciones</h4>
					{{ pu.observations }}
				</v-col>
			</v-row>
			<v-divider class="my-4"></v-divider>
			<v-row>
				<v-col cols="12" md="6">
					<h3>Domicilio Almacén</h3>
					<v-row class="my-2">
						<v-col>{{ pu.address.address }}</v-col>
					</v-row>
					<h3>Horario Almacén</h3>
					<v-row class="my-2">
						<v-col>{{ pu.address.schedule }}</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" md="6">
					<h3>Domicilio de Envío</h3>
					<v-row class="my-2">
						<v-col>{{ pu.destination.address }}</v-col>
					</v-row>
					<h3>Horario</h3>
					<v-row class="my-2">
						<v-col>{{ pu.destination.schedule }}</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'PU',
	computed: {
		...mapState({
			pu: state => state.Sucursales.pu
		})
	}
};
</script>
