<!-- eslint-disable -->
<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="orange lighten-4">
			Productos externos
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-col cols="12" xl="4" offset-xl="8" md="6" offset-md="6">
				<v-container>
					<v-text-field
						v-model="search"
						append-icon="mdi-magnify"
						label="Busqueda"
						single-line
						hide-details
					/>
				</v-container>
			</v-col>
			<v-data-table
				dense
				:headers="headers"
				:items="items"
				class="elevation-1"
				:search.sync="search"
				mobile-breakpoint="0"
			>
				<template v-slot:[`item.importe`]="{ item }">
					{{ item.importe | moneda }}
				</template>
				<template v-slot:[`item.precio`]="{ item }">
					{{ item.precio | moneda }}
				</template>
			</v-data-table>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Externos',
	data: () => ({
		search: undefined,
		perPage: 10,
		headers: [
			{ text: 'Producto', value: 'producto' },
			{ text: 'Pallet', value: 'pallet' },
			{ text: 'Expediente', value: 'expediente' },
			{ text: 'Cajas', value: 'cajas', align: 'end' },
			{ text: 'Precio', value: 'precio', align: 'end' },
			{ text: 'Importe', value: 'importe', align: 'end' }
		]
	}),
	computed: {
		...mapState({
			items: state => state.Ventas.externos,
			loader: state => state.Ventas.loader
		})
	}
};
</script>
