<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-card height="100%" v-if="customers">
    <v-form @submit.prevent="guardar(resource)" :disabled="loader">
      <v-card-text>
        <v-row class="mt-3">
          <v-col cols="12" md="4">
            <v-autocomplete
              dense
              outlined
              small-chips
              clearable
              label="Sucursal"
              v-model="sucursal"
              :items="sucursales"
              type="search"
            />
          </v-col>
          <v-col cols="12" md="8">
            <v-autocomplete
              dense
              outlined
              small-chips
              clearable
              label="Cliente"
              v-model="resource.entity_id"
              :items="posibles"
              type="search"
              item-text="name"
              item-value="entity_id"
              :error-messages="errors.entity_id"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              outlined
              clearable
              label="Fecha"
              v-model="resource.date"
              type="date"
              :error-messages="errors.date"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              dense
              outlined
              clearable
              label="Forma de Pago"
              v-model="resource.forma_pago"
              :items="metodos"
              :error-messages="errors.forma_pago"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              outlined
              clearable
              label="Importe"
              v-model.number="resource.amount"
              type="number"
              :error-messages="errors.amount"
            />
          </v-col>
           <v-col cols="12" md="3">
            <v-text-field
              dense
              outlined
              clearable
              label="Referencia"
              v-model.number="resource.reference"              
              :error-messages="errors.reference"
            />
          </v-col>
          <v-col>
            <v-textarea
              dense
              outlined
              clearable
              label="Observaciones"
              v-model.number="resource.observations"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" :disabled="loader" type="submit">
          guardar
        </v-btn>
        <v-btn
          color="error"
          :disabled="loader"
          @click="$router.push({ name: 'credits' })"
        >
          cancelar
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  export default {
    name: "Create",
    data: () => ({
      sucursales: ["California", "Texas"],
      sucursal: undefined,
      metodos: ["Cheque", "Efectivo", "Electrónico"],
      resource: {
        entity_id: undefined,
        entity_type: undefined,
        date: undefined,
        forma_pago: undefined,
        amount: undefined,
      },
    }),
    computed: {
      ...mapState({
        customers: (state) => state.Credits.customers,
        loader: (state) => state.Credits.loader,
        errors: (state) => state.errors,
      }),
      posibles() {
        return this.customers.filter(
          (x) => x.entity_type == this.resource.entity_type
        );
      },
    },
    methods: {
      ...mapActions("Credits", ["nuevo", "guardar"]),
    },
    beforeMount() {
      if (!this.customers) this.nuevo();
    },
    watch: {
      sucursal(val) {
        this.resource.entity_type = this.resource.entity_id = undefined;
        if (val == "California") {
          this.resource.entity_type = "App\\Models\\Sucursales\\Customer";
        }
        if (val == "Texas") {
          this.resource.entity_type = "App\\Models\\FR\\Cliente";
        }
      },
    },
  };
</script>
