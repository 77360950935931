<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-form @submit.prevent="enviar" :disabled="loader">
      <v-card-text>
        <v-data-table
          dense
          mobile-breakpoint="0"
          :headers="headers"
          :items="items"
          hide-default-footer
          class="elevation-1"
          :items-per-page="items.length"
        >
          <template v-slot:[`item.importe`]="{ item }">
            {{ (item.cantidad * item.precio_unitario) | moneda }}
          </template>
          <template v-slot:[`item.precio_unitario`]="{ item }">
            <v-text-field
              outlined
              dense
              v-model.number="item.precio_unitario"
              hide-details="auto"
              class="my-1"
            />
          </template>
          <template v-slot:[`item.tipo_caja`]="{ item }">
            <v-combobox
              :items="labels"
              outlined
              dense
              v-model="item.tipo_caja"
              hide-details="auto"
            />
          </template>
          <template v-slot:[`item.pallet_id`]="{ item }">
            <v-text-field
              outlined
              dense
              v-model="item.pallet_id"
              hide-details="auto"
              class="my-1"
            />
          </template>
          <template v-slot:[`item.fecha_corte`]="{ item }">
            <v-text-field
              outlined
              dense
              type="date"
              v-model="item.fecha_corte"
              hide-details="auto"
            />
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider />
      <v-card-text class="mt-2">
        <v-row>
          <v-col>
            <v-text-field
              label="Fecha de Compra"
              outlined
              dense
              type="date"
              v-model="purchase.fecha"
              :error-messages="errors.fecha"
            />
          </v-col>
          <v-col>
            <v-text-field
              label="PU (Pick Up Order)"
              outlined
              dense
              v-model="purchase.pu"
              :error-messages="errors.pu"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              outlined
              label="Observaciones"
              v-model="purchase.observaciones"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" type="submit" :disabled="loader">
          guardar
        </v-btn>
        <v-btn
          color="error"
          @click="
            $router.push({
              name: 'orders.edit',
              params: { id: $route.params.id },
            })
          "
          :disabled="loader"
        >
          cancelar
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  export default {
    name: "Purchase",
    data: () => ({
      headers: [
        { text: "Producto", value: "producto" },
        { text: "Cantidad", value: "cantidad", align: "end" },
        { text: "Precio Unitario", value: "precio_unitario", align: "end" },
        { text: "Importe", value: "importe", align: "end" },
        { text: "Pallet ID", value: "pallet_id" },
        { text: "Label", value: "tipo_caja" },
        { text: "Fecha de Corte", value: "fecha_corte" },
      ],
      purchase: {
        fecha: undefined,
        pu: undefined,
        observaciones: undefined,
      },
      items: [],
    }),
    computed: {
      ...mapState({
        pallets: (state) => state.Orders.pallets,
        labels: (state) => state.Orders.labels,
        loader: (state) => state.loader,
        errors: (state) => state.errors,
      }),
      validar() {
        let pendientes = [];
        pendientes = this.items.filter((x) => !x.precio_unitario);
        if (pendientes.length) {
          this.$swal({
            title: "Hay articulos sin Precio",
            icon: "error",
            confirmButtonText: "OK",
            text: "No podemos continuar",
          });
          return false;
        }
        pendientes = this.items.filter((x) => !x.pallet_id);
        if (pendientes.length) {
          this.$swal({
            title: "Hay articulos sin Pallet id",
            icon: "error",
            confirmButtonText: "OK",
            text: "No podemos continuar",
          });
          return false;
        }
        pendientes = this.items.filter((x) => !x.tipo_caja);
        if (pendientes.length) {
          this.$swal({
            title: "Hay articulos sin Label",
            icon: "error",
            confirmButtonText: "OK",
            text: "No podemos continuar",
          });
          return false;
        }
        pendientes = this.items.filter((x) => !x.fecha_corte);
        if (pendientes.length) {
          this.$swal({
            title: "Hay articulos sin Fecha de Corte",
            icon: "error",
            confirmButtonText: "OK",
            text: "No podemos continuar",
          });
          return false;
        }
        return true;
      },
    },
    methods: {
      ...mapActions("Orders", ["makePurchase"]),
      ...mapMutations(["setLoader", "setErrors"]),
      ...mapActions(["errores", "message"]),
      enviar() {
        if (this.validar) {
          this.setLoader();
          this.setErrors();
          let url = `FR/purchaseOrders/makePurchase/${this.$route.params.id}`,
            data = {
              items: this.items,
              fecha: this.purchase.fecha,
              pu: this.purchase.pu,
              observaciones: this.purchase.observaciones,
            };
          window.axios
            .post(url, data)
            .then((resp) => {
              this.message(resp.data.message);
              this.$router.push({ name: "compras" });
            })
            .catch((e) => this.errores(e))
            .finally(() => this.setLoader(false));
        }
      },
    },
    beforeMount() {
      if (!this.pallets) {
        this.makePurchase(this.$route.params.id);
      }
    },
    mounted() {
      this.$store.commit("setErrors");
      if (this.pallets)
        this.pallets.forEach((x) => this.items.push(Object.assign({}, x)));
    },
    watch: {
      pallets(val) {
        if (!this.items.length)
          val.forEach((x) => this.items.push(Object.assign({}, x)));
      },
    },
  };
</script>
