var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.getAll($event)}}},[_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","clearable":"","label":"Filtrar por Cliente","items":_vm.paginado.clientes},model:{value:(_vm.paginado.cliente_id),callback:function ($$v) {_vm.$set(_vm.paginado, "cliente_id", $$v)},expression:"paginado.cliente_id"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Buscar PU"},model:{value:(_vm.paginado.search),callback:function ($$v) {_vm.$set(_vm.paginado, "search", $$v)},expression:"paginado.search"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":_vm.loader}},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"info"},on:{"click":_vm.getAll}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","items":_vm.items,"headers":_vm.headers,"mobile-breakpoint":"0","items-per-page":_vm.paginado.perPage,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("folio")(item.id))+" ")]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fecha))+" ")]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}},{key:"item.cajas",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numero")(item.cajas))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.userCan('fr-ventas-edit'))?_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.getVenta(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(item.cliente == 'VENTA LOCAL' && !item.nota && _vm.userCan('fr-ventas-nota'))?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.preguntar(item)}}},[_c('v-icon',[_vm._v("mdi-card-text")])],1):_vm._e()]}}],null,true)}),_c('Pagination',{attrs:{"loader":_vm.loader,"items":_vm.items,"paginado":_vm.paginado}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.titleModal))])]),_c('v-card-text',[_vm._v(" Cliente: "+_vm._s(_vm.nota.cliente)),_c('br'),_vm._v(" Importe: "+_vm._s(_vm._f("moneda")(_vm.nota.importe))+" ")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","label":"Observaciones"},model:{value:(_vm.nota.observaciones),callback:function ($$v) {_vm.$set(_vm.nota, "observaciones", $$v)},expression:"nota.observaciones"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.cancelar}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"success","disabled":_vm.loader},on:{"click":_vm.sendNota}},[_vm._v("Guardar")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }