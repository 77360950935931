/** @format */

import router from "../../router";
const url = "FR/ventas";
const initialState = () => ({
  headers: [
    { text: "Folio", value: "id" },
    { text: "Fecha", value: "fecha" },
    { text: "Cliente", value: "cliente" },
    { text: "PU", value: "pu" },
    { text: "Expedientes", value: "expedientes" },
    { text: "Cajas", value: "cajas", align: "end" },
    { text: "Importe Producto", value: "importe", align: "end" },
    { text: "Facturado", value: "facturas", align: "center" },
    { text: "Nota", value: "nota" },
    { text: "Acciones", value: "actions", align: "end" },
  ],
  collection: [],
  resource: undefined,
  loader: false,
  detalles: [],
  facturas: [],
  pagos: [],
  metodos: [],
  externos: [],
  paginado: { currentPage: 1, search: undefined, cliente_id: undefined },
  products: undefined,
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setInitialState(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
    setHeaders(state, payload) {
      state.headers = payload;
    },
    setCollection(state, payload) {
      state.collection = payload;
    },
    setResource(state, payload) {
      state.resource = payload;
    },
    setLoader(state, payload) {
      state.loader = payload;
    },
    setDetalles(state, payload) {
      state.detalles = payload;
    },
    SET_FACTURAS(state, payload) {
      state.facturas = payload;
    },
    setPagos(state, payload) {
      state.pagos = payload;
    },
    setMetodos(state, payload) {
      state.metodos = payload;
    },
    SET_EXTERNOS(state, payload) {
      state.externos = payload;
    },
    setPaginado(state, payload) {
      let paginado = payload;
      if (paginado.currentPage > paginado.lastPage) paginado.currentPage = 1;
      state.paginado = paginado;
    },
    setProducts(state, payload) {
      state.products = payload;
    },
  },
  actions: {
    async getAll({ commit, dispatch, state }) {
      commit("setCollection", []);
      window.axios
        .get(url, {
          params: {
            page: state.paginado.currentPage,
            search: state.paginado.search,
            cliente_id: state.paginado.cliente_id,
          },
        })
        .then((resp) => {
          commit("setCollection", resp.data.collection);
          commit("setHeaders", resp.data.headers);
          commit("setPaginado", resp.data.paginado);
        })
        .catch((e) => dispatch("errores", e, { root: true }));
    },
    async getVenta({ commit, dispatch }, id) {
      window.axios
        .get(`${url}/${id}`)
        .then((resp) => {
          commit("setResource", resp.data.resource);
          commit("setDetalles", resp.data.detalles);
          commit("SET_FACTURAS", resp.data.facturas);
          commit("setPagos", resp.data.pagos);
          commit("setMetodos", resp.data.metodos);
          commit("SET_EXTERNOS", resp.data.externos);
          commit("setProducts", resp.data.products);
          router
            .push({ name: "ventas.details", params: { id } })
            .catch(() => {});
        })
        .catch((e) => dispatch("errores", e, { root: true }));
    },
    async nuevoPago({ commit, dispatch }, payload) {
      commit("setErrors", [], { root: true });
      commit("setLoader", true);
      await window.axios
        .post(`FR/nuevo-pago`, payload)
        .then((resp) => {
          commit("setResource", resp.data.resource);
          commit("setDetalles", resp.data.detalles);
          commit("SET_FACTURAS", resp.data.facturas);
          commit("setPagos", resp.data.pagos);
          commit("setMetodos", resp.data.metodos);
          dispatch("message", resp.data.message, { root: true });
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async eliminarPago({ commit, dispatch }, id) {
      commit("setLoader", true);
      await window.axios
        .post(`FR/eliminar-pago/${id}`)
        .then((resp) => {
          commit("setResource", resp.data.resource);
          commit("setDetalles", resp.data.detalles);
          commit("SET_FACTURAS", resp.data.facturas);
          commit("setPagos", resp.data.pagos);
          commit("setMetodos", resp.data.metodos);
          dispatch("message", resp.data.message, { root: true });
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async hacerFactura({ commit, dispatch, state }) {
      commit("setErrors", [], { root: true });
      commit("setLoader", true);
      await window.axios
        .post(`FR/hacer-factura/${state.resource.id}`)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          let index = state.collection.findIndex(
            (x) => x.id == state.resource.id
          );
          state.collection.splice(index, 1, resp.data.resource);
          dispatch("Facturas/getFactura", resp.data.id, { root: true });
        })
        .finally(() => commit("setLoader", false));
    },
    async hacerNota({ commit, dispatch, state }, item) {
      commit("setErrors", [], { root: true });
      commit("setLoader", true);
      await window.axios
        .post(`FR/hacerNota/${item.id}`, item)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          let index = state.collection.findIndex((x) => x.id == item.id);
          state.collection.splice(index, 1, resp.data.resource);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
  },
};
