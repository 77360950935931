<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-dialog persistent v-model="dialog" max-width="600">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Nueva Factura sin venta
      </v-card-title>
      <v-form @submit.prevent="sendData" v-if="customers" :disabled="loader">
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="auto">
              <v-text-field
                dense
                outlined
                label="Fecha de Elaboración"
                v-model="resource.fecha_elaboracion"
                :error-messages="errors.fecha_elaboracion"
                type="date"
              />
            </v-col>
            <v-col cols="auto">
              <v-text-field
                dense
                outlined
                label="Fecha de Pago"
                v-model="resource.fecha_pago"
                :error-messages="errors.fecha_pago"
                type="date"
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                dense
                outlined
                label="Cliente"
                v-model="resource.cliente_id"
                :error-messages="errors.cliente_id"
                :items="customers"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" type="submit" :disabled="loader">
            guardar
          </v-btn>
          <v-btn color="error" @click="$emit('cancelar')" :disabled="loader">
            cancelar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  export default {
    name: "NewInvoice",
    props: {
      dialog: Boolean,
    },
    emits: ["cancelar", "actualizar"],
    data: () => ({
      resource: {},
      customers: undefined,
      url: "/FR/newInvoice",
    }),
    computed: {
      ...mapState({
        errors: (state) => state.errors,
        loader: (state) => state.loader,
      }),
    },
    methods: {
      ...mapMutations(["setLoader", "setErrors"]),
      ...mapActions(["errores", "message"]),
      getData() {
        this.setLoader();
        window.axios
          .get(this.url)
          .then((resp) => {
            this.customers = resp.data.customers;
          })
          .catch((e) => this.errores(e))
          .finally(() => this.setLoader(false));
      },
      sendData() {
        this.setLoader();
        this.setErrors();
        window.axios
          .post(this.url, this.resource)
          .then((resp) => {
            this.message(resp.data.message);
            this.$emit("actualizar");
          })
          .catch((e) => this.errores(e))
          .finally(() => this.setLoader(false));
      },
    },
    mounted() {
      this.getData();
    },
  };
</script>
