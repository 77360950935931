<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4">
          <v-btn
            small
            color="success"
            class="mt-5"
            @click="nuevo"
            :disabled="loader"
            v-if="userCan('fr-customers-create')"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            small
            color="info"
            class="mt-5 ml-3"
            @click="getAll"
            :disabled="loader"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :items="items"
        :headers="headers"
        class="elevation-1"
        :search.sync="search"
        mobile-breakpoint="0"
        :item-class="row_classes"
        :items-per-page="perPage"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <template v-if="item.deleted_at">
            <v-btn
              icon
              color="success"
              @click="restaurar(item)"
              :disabled="loader"
            >
              <v-icon>mdi-delete-restore</v-icon>
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              icon
              color="success"
              v-if="item.actions.edit"
              @click="detalles(item.id)"
              :disabled="loader"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              color="red"
              v-if="item.actions.delete"
              @click="eliminar(item)"
              :disabled="loader"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapState, mapActions, mapMutations } from "vuex";
  export default {
    name: "Clientes",
    data: () => ({
      search: undefined,
      perPage: 15,
    }),
    computed: {
      ...mapState({
        headers: (state) => state.Clientes.headers,
        items: (state) => state.Clientes.collection,
        loader: (state) => state.Clientes.loader,
      }),
    },
    mounted() {
      if (!this.items.length) this.getAll();
    },
    methods: {
      ...mapActions("Clientes", [
        "getAll",
        "nuevo",
        "deleteCliente",
        "detalles",
      ]),
      ...mapMutations(["setErrors"]),
      eliminar(item) {
        let pregunta = `¿Deseas eliminar al cliente ${item.nombre}?`,
          text =
            "Los datos no se borran, pero no se podrán hacer nuevos presupuestos";
        window
          .swal({
            title: pregunta,
            text: text,
            icon: "warning",
            buttons: ["Cancelar", "Confirmar"],
            dangerMode: true,
          })
          .then((confirmado) => {
            if (confirmado) {
              this.deleteCliente(item.id);
            }
          });
      },
      restaurar(item) {
        let pregunta = `¿Deseas restaurar al cliente ${item.nombre}?`,
          text = "El cliente estará disponible para nuevos presupuestos";
        window
          .swal({
            title: pregunta,
            text: text,
            icon: "warning",
            buttons: ["Cancelar", "Confirmar"],
            dangerMode: true,
          })
          .then((confirmado) => {
            if (confirmado) {
              this.$store.dispatch("Clientes/restoreResource", item.id);
            }
          });
      },
    },
  };
</script>
