/** @format */

import router from "../../router";
const url = "FR/clientes";
const initialState = () => ({
  headers: [],
  collection: [],
  resource: undefined,
  loader: false,
  domicilios: [],
  ventas: {},
  facturas: [],
  pagos: [],
  new: undefined,
  contactos: undefined,
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setInitialState(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
    setContactos(state, payload) {
      state.contactos = payload;
    },
    setHeaders(state, payload) {
      state.headers = payload;
    },
    setCollection(state, payload) {
      state.collection = payload;
    },
    setResource(state, payload) {
      state.resource = payload;
    },
    setLoader(state, payload = true) {
      state.loader = payload;
    },
    SET_DOMICILIOS(state, payload) {
      state.domicilios = payload;
    },
    setVentas(state, payload) {
      state.ventas = payload;
    },
    SET_FACTURAS(state, payload) {
      state.facturas = payload;
    },
    setPagos(state, payload) {
      state.pagos = payload;
    },
    setNew(state, payload) {
      state.new = payload;
    },
  },
  actions: {
    async getAll({ commit, dispatch }) {
      commit("setCollection", []);
      commit("setLoader", true);
      window.axios
        .get(url)
        .then((resp) => {
          commit("setCollection", resp.data.collection);
          commit("setHeaders", resp.data.headers);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async detalles({ commit, dispatch }, id) {
      commit("setErrors", [], { root: true });
      commit("setResource", undefined);
      commit("SET_DOMICILIOS", []);
      commit("SET_FACTURAS", []);
      commit("setPagos", []);
      window.axios
        .get(`${url}/${id}`)
        .then((resp) => {
          commit("setResource", resp.data.cliente);
          commit("SET_DOMICILIOS", resp.data.domicilios);
          commit("SET_FACTURAS", resp.data.new.facturas);
          commit("setPagos", resp.data.new.pagos);
          commit("setVentas", resp.data.ventas);
          commit("setNew", resp.data.new);
          commit("setContactos", resp.data.contactos);
          router
            .push({ name: "clientes.details", params: { id } })
            .catch(() => {});
        })
        .catch((e) => dispatch("errores", e, { root: true }));
    },
    nuevo({ commit }) {
      commit("setErrors", [], { root: true });
      commit("setResource", {});
      router.push({ name: "clientes.nuevo" });
    },
    async sendCliente({ commit, state, dispatch }) {
      let cliente = state.resource;
      commit("setLoader", true);
      commit("setErrors", [], { root: true });
      if (!cliente.id) {
        await window.axios
          .post(url, cliente)
          .then((resp) => {
            dispatch("message", resp.data.message, { root: true });
            state.collection.unshift(resp.data.resource);
            router.push({ name: "clientes" });
            commit("setLoader", false);
          })
          .catch((e) => dispatch("errores", e, { root: true }))
          .finally(() => commit("setLoader", false));
      } else {
        await window.axios
          .put(`${url}/${cliente.id}`, cliente)
          .then((resp) => {
            dispatch("message", resp.data.message, { root: true });
            let index = state.collection.findIndex((x) => x.id == cliente.id);
            state.collection.splice(index, 1, resp.data.resource);
            commit("setResource", resp.data.cliente);
            commit("setLoader", false);
          })
          .catch((e) => dispatch("errores", e, { root: true }))
          .finally(() => commit("setLoader", false));
      }
    },
    async deleteCliente({ state, dispatch }, id) {
      await window.axios
        .delete(`${url}/${id}`)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          dispatch("getAll");
        })
        .catch((e) => dispatch("errores", e, { root: true }));
    },
    async restoreResource({ commit, dispatch }, id) {
      commit("setLoader", true);
      await window.axios
        .post(`${url}/restaurar/${id}`)
        .then((resp) => {
          dispatch("getAll");
          dispatch("message", resp.data.message, { root: true });
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async disableContacto({ commit, dispatch }, id) {
      commit("setLoader");
      await window.axios
        .delete(`${url}/disableContacto/${id}`)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          commit("setContactos", resp.data.contactos);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async enableContacto({ commit, dispatch }, id) {
      commit('setLoader')
      await window.axios
        .post(`${url}/enableContacto/${id}`)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          commit("setContactos", resp.data.contactos);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    }
  },
};
