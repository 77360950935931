var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.$store.dispatch('Orders/getAll')}}},[_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","clearable":"","label":"Filtrar por Proveedor","items":_vm.paginado.proveedores,"type":"search"},model:{value:(_vm.paginado.proveedor_id),callback:function ($$v) {_vm.$set(_vm.paginado, "proveedor_id", $$v)},expression:"paginado.proveedor_id"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Buscar PO","placeholder":"Solo colocar el número"},model:{value:(_vm.paginado.search),callback:function ($$v) {_vm.$set(_vm.paginado, "search", $$v)},expression:"paginado.search"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":_vm.loader}},[_c('v-icon',[_vm._v("mdi-magnify")])],1),(_vm.userCan('fr-purchases-create'))?_c('v-btn',{staticClass:"ml-3",attrs:{"color":"success","disabled":_vm.loader},on:{"click":function($event){return _vm.$store.dispatch('Orders/nueva')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","items":_vm.items,"headers":_vm.headers,"mobile-breakpoint":"0","items-per-page":_vm.paginado.perPage,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.date_req",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.date_req))+" ")]}},{key:"item.order_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.order_date))+" ")]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.userCan('fr-purchases-edit') && !item.compra_id)?_c('v-btn',{attrs:{"icon":"","color":"success","disabled":_vm.loader},on:{"click":function($event){return _vm.$store.dispatch('Orders/getResource', item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.userCan('fr-purchases-pdf'))?_c('v-btn',{attrs:{"icon":"","color":"error","disabled":_vm.loader,"href":item.pdf,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-file-pdf")])],1):_vm._e(),(!item.compra_id)?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.procesar(item)}}},[_c('v-icon',[_vm._v("mdi-cogs")])],1):_vm._e()]}}],null,true)}),_c('Pagination',{attrs:{"loader":_vm.loader,"items":_vm.items,"paginado":_vm.paginado}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }