/** @format */

const routes = [
  {
    path: "/Creditos",
    name: "credits",
    component: require("@/views/credits/Index.vue").default,
    meta: {
      name: "Control de Créditos/Abonos",
    },
  },
  {
    path: "/Creditos/nuevo",
    name: "credits.create",
    component: require("@/views/credits/Create.vue").default,
    meta: {
      name: "Nuevo Crédito o Abono",
    },
  },
];
export default routes;
