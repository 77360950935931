<!-- eslint-disable -->
<template>
	<v-form @submit.prevent="sendCliente">
		<v-row class="mt-3">
			<v-col cols="12" md="6">
				<v-text-field
					outlined
					dense
					type="text"
					label="Nombre"
					v-model="cliente.nombre"
					:error-messages="errors.nombre"
				/>
			</v-col>
      <v-col cols="12" md="6">
				<v-text-field
					outlined
					dense
					type="text"
					label="Representante"
					v-model="cliente.representante"
					:error-messages="errors.representante"
				/>
			</v-col>
      <v-col cols="12" sm="6">
				<v-text-field
					outlined
					dense
					type="email"
					label="Correo"
					v-model="cliente.email"
					:error-messages="errors.email"
				/>
			</v-col>
      <v-col cols="12" sm="6">
				<v-text-field
					outlined
					dense
					type="text"
					label="Teléfono"
					v-model="cliente.telefono"
					:error-messages="errors.telefono"
				/>
			</v-col>
      <v-col v-if="!cliente.id || modificado">
        <v-btn small color="primary" type="submit" :disabled="loader">{{title}}</v-btn>
				<v-btn small color="error" @click="cancelar" class="ml-3" >cancelar</v-btn>
      </v-col>
		</v-row>
	</v-form>
</template>

<script>
import { mapState, mapActions,mapMutations} from 'vuex';
export default {
	name: 'Formulario',
	data: () => ({
		original: {}
	}),
	computed: {
		...mapState({
			cliente: state => state.Clientes.resource,
			errors: state => state.errors,
			loader: state=> state.Clientes.loader
		}),
		modificado: function() {
			return JSON.stringify(this.cliente) !== JSON.stringify(this.original);
    },
    title(){
      return this.cliente.id?'actualizar':'guardar'
    }
  },
   watch: {
		cliente: function(val) {
			this.original = Object.assign({}, val);
		}
	},
	mounted() {
		this.original = Object.assign({}, this.cliente);
	},
 methods:{
	 ...mapActions('Clientes', ['sendCliente']),
	 cancelar(){
		 this.$router.push({ name: 'clientes'})
	 }
 }
};
</script>
