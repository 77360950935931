<!-- @format -->

<!-- eslint-disable -->
<template>
  <div class="text-center">
    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Enviar por correo los precios
        </v-card-title>
        <v-form :disabled="loader" @submit.prevent="enviar">
          <v-card-text>
            <v-text-field
              dense
              outlined
              label="Introducir Correos separados por punto y coma "
              v-model="correos"
              :error-messages="errors.correos"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit" :disabled="loader">
              enviar
            </v-btn>
            <v-btn color="error" @click="$emit('cancelar')" :disabled="loader">
              cancelar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    name: "Modal",
    props: {
      dialog: Boolean,
    },
    data: () => ({
      loader: false,
      correos: undefined,
    }),
    computed: {
      ...mapState({
        errors: (state) => state.errors,
      }),
    },
    methods: {
      enviar() {
        this.loader = true;
        this.$store.commit("setErrors");
        window.axios
          .post(`FR/sendPricesByEmail`, {
            correos: this.correos,
          })
          .then((resp) => {
            this.$emit("cancelar");
            this.$swal({
              icon: "success",
              title: resp.data.message,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2500,
            });
          })
          .catch((e) => this.$store.dispatch("errores", e))
          .finally(() => (this.loader = false));
      },
    },
    mounted() {
      this.$store.commit("setErrors");
    },
  };
</script>
