<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Facturas Relacionadas
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" md="4">
          <template v-if="userCan('fr-ventas-factura')">
            <v-btn
              small
              color="success"
              class="mt-5 ml-3"
              v-if="!items.length"
              @click="preguntar"
              :disabled="loader"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <template v-if="userCan('fr-ventas-nota') && !items.length">
            <v-btn
              small
              color="primary"
              class="mt-5 ml-3"
              @click="preguntaNota"
              v-if="!sale.nota"
            >
              <v-icon>mdi-card-text</v-icon>
            </v-btn>
          </template>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-card-text>
        <v-data-table
          dense
          :headers="headers"
          :items="items"
          class="elevation-1"
          :search.sync="search"
          mobile-breakpoint="0"
          :items-per-page="items.length"
          hide-default-footer
        >
          <template v-slot:[`item.importe`]="{ item }">
            {{ item.importe | moneda }}
          </template>
          <template v-slot:[`item.pagos`]="{ item }">
            {{ importe(item) | moneda }}
          </template>
          <template v-slot:[`item.fecha_elaboracion`]="{ item }">
            {{ item.fecha_elaboracion | date }}
          </template>
          <template v-slot:[`item.fecha_pago`]="{ item }">
            {{ item.fecha_pago | date }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon color="success" @click="getFactura(item.id)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon color="primary">
              <v-icon>mdi-file-pdf</v-icon>
            </v-btn>
            <v-btn icon color="red">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ titleModal }}</span>
            </v-card-title>
            <v-card-text>
              Cliente: {{ nota.cliente }}<br />
              Importe: {{ nota.importe | moneda }}
            </v-card-text>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      outlined
                      label="Observaciones"
                      v-model="nota.observaciones"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="cancelar">Cancelar</v-btn>
              <v-btn color="success" @click="sendNota" :disabled="loader"
                >Guardar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
  import { mapState, mapActions, mapMutations } from "vuex";
  export default {
    name: "Facturas",
    data: () => ({
      perPage: 10,
      headers: [
        { text: "Folio", value: "folio" },
        { text: "Fecha Elaboración", value: "fecha_elaboracion" },
        { text: "Fecha Pago", value: "fecha_pago" },
        { text: "Método de Pago", value: "metodo_pago" },
        { text: "Importe", value: "importe", align: "end" },
        { text: "Pagos", value: "pagos", align: "end" },
        { text: "Acciones", value: "actions", align: "end" },
      ],
      search: undefined,
      dialog: false,
      nota: {},
      titleModal: undefined,
    }),
    computed: {
      ...mapState({
        items: (state) => state.Ventas.facturas,
        pagos: (state) => state.Ventas.pagos,
        details: (state) => state.Ventas.detalles,
        loader: (state) => state.Ventas.loader,
        sale: (state) => state.Ventas.resource,
        externos: (state) => state.Ventas.externos,
      }),
      puedeFactura() {
        return this.details.filter((x) => {
          return x.cajas > x.facturados;
        });
      },
      facturarExternos() {
        return this.externos.filter((x) => x.pendientes_facturar > 0);
      },
    },
    methods: {
      ...mapActions("Ventas", ["hacerFactura", "hacerNota", "getVenta"]),
      ...mapActions("Facturas", ["getFactura"]),
      importe(item) {
        let relacionados = this.pagos.filter((x) => x.factura_id == item.id);
        return _.sumBy(relacionados, "importe");
      },
      preguntar() {
        let pregunta = `Se va a elaborar una factura con todo el producto aun no facturado`;
        this.$swal({
          title: pregunta,
          icon: "warning",
          showCancelButton: true,
        }).then((resp) => {
          if (resp.isConfirmed) {
            let url = `FR/Ventas/makeFactura/${this.$route.params.id}`;
            window.axios.post(url).then((resp) => {
              this.$store.dispatch("message", resp.data.message);
              this.$store.dispatch("Facturas/getFactura", resp.data.id);
            });
          }
        });
      },
      preguntaNota() {
        this.titleModal = `Nota para la venta ${this.sale.folio}`;
        this.nota = this.sale;
        this.dialog = true;
      },
      sendNota() {
        let id = this.$route.params.id;
        this.dialog = false;
        this.hacerNota(this.nota).then(() => {
          this.getVenta(id);
        });
      },
      cancelar() {
        this.nota = {};
      },
    },
  };
</script>
