<!-- eslint-disable -->
<template>
	<v-card height="100%">
		<v-card-text>
			<!-- <v-row>
				<v-col xl="4" md="6">
					<v-btn small color="info" class="mt-5 ml-3" @click="getAll">
						<v-icon>mdi-refresh</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="12" xl="4" offset-xl="4" md="6" offset-md="6">
					<v-container>
						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="Busqueda"
							single-line
							hide-details
						/>
					</v-container>
				</v-col>
			</v-row> -->
			<v-form @submit.prevent="getAll">
				<v-row class="mt-3">
					<v-col cols="12" md="6">
						<v-autocomplete
							dense
							outlined
							clearable
							label="Filtrar por Cliente"
							v-model="paginado.cliente_id"
							:items="paginado.clientes"
						/>
					</v-col>
					<v-col>
						<v-text-field
							dense
							outlined
							clearable
							label="Buscar"
							v-model="paginado.search"
						/>
					</v-col>
					<v-col>
						<v-btn color="primary" type="submit">
							<v-icon>mdi-magnify</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-form>

			<v-data-table
				dense
				:items="items"
				:headers="headers"
				class="elevation-1"
				mobile-breakpoint="0"
				:items-per-page="paginado.perPage"
				hide-default-footer
			>
				<template v-slot:[`item.id`]="{ item }">
					{{ item.id | folio }}
				</template>
				<template v-slot:[`item.fecha`]="{ item }">
					{{ item.fecha | date }}
				</template>
				<template v-slot:[`item.importe`]="{ item }">
					{{ item.importe | moneda }}
				</template>
				<template v-slot:[`item.cajas`]="{ item }">
					{{ item.cajas | numero }}
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-btn icon color="success" @click="getPago(item.id)">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
				</template>
			</v-data-table>
			<Pagination :loader="loader" :items="items" :paginado="paginado" />
		</v-card-text>
	</v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
	name: 'Pagos',

	mounted() {
		this.getAll();
	},
	computed: {
		...mapState({
			items: state => state.Pagos.collection,
			headers: state => state.Pagos.headers,
			paginado: state => state.Pagos.paginado,
			loader: state => state.Pagos.loader
		}),
		actual() {
			return this.paginado.currentPage;
		},
		cliente(){
			return this.paginado.cliente_id;
		}
	},
	methods: {
		...mapActions('Pagos', ['getAll', 'getPago'])
	},
	watch: {
		actual: function() {
			this.getAll();
		},
		cliente: function(){
			this.getAll();
		}
	}
};
</script>
