<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-dialog persistent v-model="dialog" max-width="800">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Enviar Pdf por Correo
      </v-card-title>
      <v-form @submit.prevent="enviar" :disabled="loader">
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-autocomplete
                multiple
                dense
                outlined
                clearable
                small-chips
                label="Seleccione Destinatarios"
                :items="item.contactos"
                :item-text="texto"
                v-model="destinatarios"
                :error-messages="errors.destinatarios"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                outlined
                clearable
                label="Correo NO Registrado"
                v-model="otro"
                :error-messages="errors.otro"
              />
            </v-col>
            <v-col v-if="!pdfTypes.includes('Invoice')">
              <v-autocomplete
                v-if="pdfTypes.length"
                multiple
                dense
                outlined
                clearable
                small-chips
                label="Tipo de PDF a enviar"
                :items="pdfTypes"
                v-model="types"
                :error-messages="errors.types"
              />
            </v-col>
            <v-col v-else>
              <v-autocomplete
                v-if="pdfTypes.length"
                dense
                outlined
                clearable
                small-chips
                label="Tipo de PDF a enviar"
                :items="pdfTypes"
                v-model="types"
                :error-messages="errors.types"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            type="submit"
            :disabled="loader"
            @click="enviar"
          >
            enviar
          </v-btn>
          <v-btn color="error" @click="$emit('cancelar')" :disabled="loader">
            cancelar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  export default {
    name: "ModalEmail",
    emits: ["cancelar"],
    props: {
      item: {
        type: Object,
        default() {
          return {};
        },
      },
      dialog: {
        type: Boolean,
      },

      pdfTypes: {
        type: Array,
        default() {
          return [];
        },
      },
      url: {
        type: String,
        default() {
          return undefined;
        },
      },
    },
    data: () => ({
      destinatarios: [],
      types: [],
      otro: undefined,
    }),
    computed: {
      ...mapState({
        loader: (state) => state.loader,
        errors: (state) => state.errors,
      }),
    },
    methods: {
      ...mapMutations(["setLoader", "setErrors"]),
      ...mapActions(["errores", "message"]),
      texto(item) {
        return `${item.nombre} (${item.email})`;
      },
      enviar() {
        if (this.url) {
          this.setLoader();
          this.setErrors();
          let data = {
            destinatarios: this.destinatarios,
            types: this.types,
            otro: this.otro,
          };
          window.axios
            .post(this.url, data)
            .then((resp) => {
              this.message(resp.data.message);
              this.$emit("cancelar");
            })
            .catch((e) => {
              this.errores(e);
            })
            .finally(() => this.setLoader(false));
        } else {
          this.$swal({
            title: "Envío aun no implementado",
            confirmButtonText: "OK",
          });
        }
      },
    },
    mounted() {
      this.setErrors();
    },
  };
</script>
