/** @format */

require("./bootstrap");
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import { loadProgressBar } from "axios-progress-bar";
import "axios-progress-bar/dist/nprogress.css";
loadProgressBar();
import "./sass/style.scss";
import "./registerServiceWorker";
import Paginado from "./components/Paginado.vue";

Vue.config.productionTip = false;
Vue.filter("date", function(value) {
  if (value)
    return window
      .moment(value)
      .locale("en")
      .format("MMM-DD-YYYY");
  return "";
});
Vue.filter("dateTime", function(value) {
  if (value)
    return window
      .moment(value)
      .locale("en")
      .format("MMM-DD-YYYY hh:mm A");
  return "";
});
Vue.filter("moneda", function(value) {
  if (!value) return "";
  if (isNaN(value)) value = parseFloat(value);
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  if (isNaN(value)) return "";
  var numberString = formatter
    .formatToParts(value)
    .map(({ type, value }) => {
      switch (type) {
        case "currency":
          return `${value} `;
        default:
          return value;
      }
    })
    .reduce((string, part) => string + part);
  return numberString;
});
Vue.filter("folio", function(value) {
  return String(value).padStart(5, "0");
});
Vue.filter("numero", function(value) {
  if (isNaN(value)) value = parseFloat(value);
  if (!value) return null;
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  if (isNaN(value)) return "";
  var numberString = formatter
    .formatToParts(value)
    .map(({ type, value }) => {
      switch (type) {
        case "currency":
          return ` `;
        case "fraction":
          return "";
        case "decimal":
          return "";
        default:
          return value;
      }
    })
    .reduce((string, part) => string + part);
  return numberString;
});
Vue.filter("telefono", function(val) {
  if (!val) return "";
  let code = val.substr(0, 3),
    first = val.substr(3, 3),
    last = val.substr(6, 4);
  return `(${code}) ${first} - ${last}`;
});
Vue.mixin({
  methods: {
    row_classes(item) {
      if (
        item.deleted_at ||
        item.trashed ||
        (item.actions && item.actions.restore)
      ) {
        return "red lighten-5"; //can also return multiple classes e.g ["orange","disabled"]
      }
      if (item.sale_id) {
        return "green lighten-5";
      }
    },
    userCan(ability) {
      let abilities = store.state.Auth.user.abilities;
      let encontrado = abilities.find((x) => {
        return x.name === "*" || x.name === ability;
      });
      if (encontrado) return true;
    },
  },
});

Vue.component("Pagination", Paginado);
Vue.component("Actions", require("./components/Actions.vue").default);
Vue.component("ModalEmail", require("./components/ModalEmail.vue").default);

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
const optSwa = {
  confirmButtonColor: "#4CAF50",
  cancelButtonColor: "#d33",
  confirmButtonText: "Si",
  cancelButtonText: "No",
};
Vue.use(VueSweetalert2, optSwa);

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
