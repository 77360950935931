<!-- eslint-disable -->
<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="light-blue lighten-4">
			Datos Presupuesto {{ presupuesto.id | folio }}
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-form @submit.prevent="updateBudget">
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<v-autocomplete
								label="Cliente"
								v-model="presupuesto.cliente_id"
								dense
								small-chips
								deletable-chips
								:error-messages="errors.cliente_id"
								outlined
								:items="clientes"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" xl="3">
							<v-text-field
								:error-messages="errors.fecha2"
								type="date"
								label="Pick Up Date"
								outlined
								dense
								v-model="presupuesto.fecha2"
							/>
						</v-col>
						<v-col cols="12" xl="3">
							<v-text-field
								disabled
								:error-messages="errors.pu"
								type="text"
								label="PU"
								outlined
								dense
								v-model="presupuesto.pu"
							/>
						</v-col>
						<v-col cols="12" xl="3">
							<v-text-field
								:error-messages="errors.po"
								type="text"
								label="Purchase Order"
								outlined
								dense
								v-model="presupuesto.po"
							/>
						</v-col>
						<v-col cols="12" xl="3">
							<v-text-field
								type="number"
								:error-messages="errors.thermographers"
								label="Thermographers"
								outlined
								dense
								v-model.number="presupuesto.thermographers"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" xl="4">
							<v-autocomplete
								label="Warehouse"
								v-model="presupuesto.warehouse_address_id"
								dense
								small-chips
								deletable-chips
								:error-messages="errors.warehouse_address_id"
								outlined
								:items="warehouses"
							/>
						</v-col>
						<v-col cols="12" xl="4">
							<v-autocomplete
								label="Customer Address"
								v-model="presupuesto.customer_address_id"
								dense
								small-chips
								deletable-chips
								:error-messages="errors.customer_address_id"
								outlined
								:items="domicilios"
							/>
						</v-col>
						<v-col cols="12" xl="4">
							<v-autocomplete
								label="Destination Address"
								v-model="presupuesto.destination_id"
								dense
								small-chips
								deletable-chips
								:error-messages="errors.destination_id"
								outlined
								:items="domicilios"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" xl="3">
							<v-text-field
								:error-messages="errors.importe_flete"
								type="number"
								step="0.01"
								label="Importe Flete"
								outlined
								dense
								v-model.number="presupuesto.importe_flete"
							/>
						</v-col>
						<v-col cols="12" xl="3">
							<v-text-field
								:error-messages="errors.importe_termografos"
								type="number"
								step="0.01"
								label="Importe Termógrafos"
								outlined
								dense
								v-model.number="presupuesto.importe_termografos"
							/>
						</v-col>
						<v-col cols="12" xl="6">
							<v-text-field
								:error-messages="errors.comprador"
								type="text"
								label="Comprador"
								outlined
								dense
								v-model="presupuesto.comprador"
							/>
						</v-col>
						<v-col>
							<v-autocomplete
								dense
								outlined
								clearable
								label="Usuario Responsable"
								v-model="presupuesto.user_id"
								:items="users"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<v-textarea
								outlined
								label="Observaciones"
								v-model="presupuesto.observaciones"
							/>
						</v-col>
					</v-row>
					<v-row v-if="modificado">
						<v-col>
							<v-btn color="primary" type="submit" :disabled="loader">
								Actualizar
							</v-btn>
							<v-btn color="error" class="ml-3" @click="cancelar">
								cancelar
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
			</v-form>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
	name: 'Datos',
	data: () => ({
		original: undefined
	}),
	computed: {
		...mapState({
			presupuesto: state => state.Budgets.resource.budget,
			warehouses: state => state.Budgets.resource.almacenes,
			clientes: state => state.Budgets.resource.clientes,
			errors: state => state.errors,
			addresses: state => state.Budgets.resource.domicilios,
			loader: state => state.Budgets.loader,
			users: state => state.Budgets.resource.users
		}),
		domicilios() {
			return this.addresses.filter(
				x => x.cliente_id == this.presupuesto.cliente_id
			);
		},
		modificado: function() {
			return JSON.stringify(this.presupuesto) !== JSON.stringify(this.original);
		}
	},
	watch: {
		presupuesto: function(val) {
			this.original = Object.assign({}, val);
		}
	},
	mounted() {
		this.original = Object.assign({}, this.presupuesto);
	},
	methods: {
		...mapActions('Budgets', ['detalles', 'updateBudget']),
		cancelar() {
			this.detalles(this.original.id);
		}
	}
};
</script>
