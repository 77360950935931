<!-- eslint-disable -->
<template>
	<v-card height="100%">
		<v-card-text>
			<v-row class="mt-3">
				<v-col cols="12" md="5">
					<v-autocomplete
						dense
						outlined
						clearable
						label="Filtrar por Usuario"
						v-model="paginado.user_id"
						:items="paginado.users"
					/>
				</v-col>
				<v-col cols="12" md="5">
					<v-autocomplete
						dense
						outlined
						clearable
						label="Filtrar por Estatus"
						v-model="paginado.status"
						:items="paginado.statuses"
					/>
				</v-col>
				<v-col>
					<v-btn color="success" @click="$store.dispatch('Reportes/nuevo')">
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</v-col>
			</v-row>
			<v-data-table
				dense
				mobile-breakpoint="0"
				:headers="headers"
				:items="items"
				class="elevation-1 mt-3"
				:items-per-page="paginado.perPage"
				hide-default-footer
			>
				<template v-slot:[`item.created_at`]="{ item }">
					{{ item.created_at | dateTime }}
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-btn
						icon
						color="success"
						v-if="item.actions.edit"
						@click="$store.dispatch('Reportes/editar', item.id)"
					>
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
					<v-btn
						icon
						color="primary"
						v-if="item.actions.info"
						@click="$store.dispatch('Reportes/historial', item.id)"
					>
						<v-icon>mdi-history</v-icon>
					</v-btn>
					<v-btn
						icon
						color="error"
						v-if="item.actions.delete"
						@click="eliminar(item)"
					>
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</template>
			</v-data-table>
			<Pagination :loader="loader" :items="items" :paginado="paginado" />
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Reportes',
	computed: {
		...mapState({
			headers: state => state.Reportes.headers,
			items: state => state.Reportes.collection,
			loader: state => state.Reportes.loader,
			paginado: state => state.Reportes.paginado
		}),
		actual() {
			return this.paginado.currentPage;
		},
		user_id() {
			return this.paginado.user_id;
		},
		status() {
			return this.paginado.status;
		}
	},
	mounted() {
		this.$store.dispatch('Reportes/getAll');
	},
	watch: {
		user_id: function() {
			this.$store.dispatch('Reportes/getAll');
		},
		status: function() {
			this.$store.dispatch('Reportes/getAll');
		}
	},
	methods: {
		eliminar(item) {
			let pregunta = `¿Deseas eliminar el reporte ${item.folio}?`,
				text = 'Esta acción no puede deshacerse';
			window
				.swal({
					title: pregunta,
					text: text,
					icon: 'warning',
					buttons: ['Cancelar', 'Confirmar'],
					dangerMode: true
				})
				.then(confirmado => {
					if (confirmado) {
						this.$store.dispatch('Reportes/eliminar', item.id);
					}
				});
		}
	}
};
</script>
