<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text v-if="proveedores">
      <v-expansion-panels multiple v-model="name">
        <Datos />
        <Productos v-if="$route.params.id" />
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="regresar">regresar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import Productos from "./PanelProducto";
  import Datos from "./PanelDatos";
  import { mapState } from "vuex";
  export default {
    name: "Details",
    components: { Datos, Productos },
    computed: {
      ...mapState({
        proveedores: (state) => state.Orders.proveedores,
        abiertos: (state) => state.Orders.abiertos,
      }),
      name: {
           get(){
             return this.abiertos
           },
           set(newName){
             return newName
           } 
        }
    },
    beforeMount() {
      this.$store.commit("setErrors", []);
      let id = this.$route.params.id;
      if (!this.proveedores) {
        if (id) {
          this.$store.dispatch("Orders/getResource", { id });
        } else {
          this.$store.dispatch("Orders/nueva");
        }
      }
    },
    methods: {
      regresar() {
        this.$router.push({ name: "orders" }).catch(() => {});
      },
    },
  };
</script>
