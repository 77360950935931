/** @format */

import router from "../../router";
const url = "FR/quality_controls";
const base = "qc";
const initialState = () => ({
  headers: [],
  collection: undefined,
  resource: undefined,
  loader: false,
  paginado: { currentPage: 1 },
  suppliers: undefined,
  species: undefined,
  panel: [],
  types: undefined,
  sites: undefined,
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setSites(state, payload) {
      state.sites = payload;
    },
    setPanel(state, payload = []) {
      state.panel = payload;
    },
    setSuppliers(state, payload) {
      state.suppliers = payload;
    },
    setSpecies(state, payload) {
      state.species = payload;
    },
    setInitialState(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
    setHeaders(state, payload) {
      state.headers = payload;
    },
    setCollection(state, payload) {
      state.collection = payload;
    },
    setResource(state, payload = {}) {
      state.resource = payload;
    },
    setLoader(state, payload = true) {
      state.loader = payload;
    },
    setPaginado(state, payload) {
      let paginado = payload;
      if (paginado.currentPage > paginado.lastPage) paginado.currentPage = 1;
      state.paginado = paginado;
    },
    setTypes(state, payload) {
      state.types = payload;
    },
  },
  actions: {
    async getAll({ commit, dispatch, state }) {
      commit("setCollection", []);
      commit("setLoader", true);
      window.axios
        .get(url, {
          params: {
            page: state.paginado.currentPage,
            supplier: state.paginado.supplier,
          },
        })
        .then((resp) => {
          if (resp.data.message)
            dispatch("message", resp.data.message, { root: true });
          commit("setCollection", resp.data.collection);
          commit("setHeaders", resp.data.headers);
          commit("setPaginado", resp.data.paginado);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async nuevo({ commit, dispatch }) {
      commit("setLoader");
      await window.axios
        .get(`${url}/create`)
        .then((resp) => {
          commit("setSuppliers", resp.data.suppliers);
          commit("setSpecies", resp.data.species);
          commit("setTypes", resp.data.types);
          commit("setSites", resp.data.sites);
          commit("setResource");
          commit("setPanel", [0]);
          router.push({ name: `${base}.create` }).catch(() => {});
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async sendResource({ commit, dispatch, state }) {
      commit("setLoader");
      commit("setErrors", {}, { root: true });
      let resource = state.resource;
      if (resource.id) {
        await window.axios
          .put(`${url}/${resource.id}`, resource)
          .then((resp) => {
            dispatch("message", resp.data.message, { root: true });
            dispatch("getResource", resource.id);
          })
          .catch((e) => dispatch("errores", e, { root: true }))
          .finally(() => commit("setLoader", false));
      } else {
        await window.axios
          .post(url, resource)
          .then((resp) => {
            dispatch("message", resp.data.message, { root: true });
            dispatch("getResource", resp.data.id);
          })
          .catch((e) => dispatch("errores", e, { root: true }))
          .finally(() => commit("setLoader", false));
      }
    },
    async getResource({ commit, dispatch }, id) {
      commit("setLoader");
      commit("setErrors", {}, { root: true });
      await window.axios
        .get(`${url}/${id}`)
        .then((resp) => {
          commit("setSuppliers", resp.data.suppliers);
          commit("setSpecies", resp.data.species);
          commit("setTypes", resp.data.types);
          commit("setSites", resp.data.sites);
          commit("setResource", resp.data.resource);
          commit("setPanel");
          router.push({ name: `${base}.edit`, params: { id } }).catch(() => {});
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async updateResource({ commit, dispatch, state }) {
      commit("setErrors", {}, { root: true });
      let resource = state.resource;
      resource.prods = resource.products.filter((x) => x.taken);
      window.axios
        .put(`${url}/updateResource/${resource.id}`, resource)
        .then((resp) => {
          dispatch("message", resp.data.message, { root: true });
          dispatch("getResource", resource.id);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
  },
};
