<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Contactos del Cliente
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card-text class="mt-3">
        <v-row>
          <v-col>
            <v-text-field
              dense
              outlined
              clearable
              label="Buscar"
              append-icon="mdi-magnify"
              v-model="search"
            />
          </v-col>
          <v-col>
            <v-btn color="success" @click="dialog = true">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          dense
          mobile-breakpoint="0"
          :headers="headers"
          :items="items"
          class="elevation-1"
          :loading="loader"
          :search.sync="search"
          hide-default-footer
          :items-per-page="items.length"
          :item-class="row_classes"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <template v-if="item.actions.restore">
              <v-btn
                icon
                color="success"
                :disabled="loader"
                @click="habilitar(item)"
              >
                <v-icon>mdi-delete-restore</v-icon>
              </v-btn>
            </template>
            <template v-else>
              <v-btn
                icon
                color="success"
                v-if="item.actions.edit"
                :disabled="loader"
                @click="editar(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                icon
                color="red"
                v-if="item.actions.delete"
                :disabled="loader"
                @click="deshabilitar(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </template>
          <template v-slot:[`item.telefono`]="{ item }">{{
            item.telefono | telefono
          }}</template>
        </v-data-table>
      </v-card-text>
    </v-expansion-panel-content>
    <ContactoForm
      :dialog="dialog"
      :contacto="contacto"
      v-if="dialog"
      @cancelar="cancelar"
    />
  </v-expansion-panel>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import ContactoForm from "./ContactoForm.vue";
  export default {
    name: "Contactos",
    components: { ContactoForm },
    data: () => ({
      headers: [
        { text: "Cargo", value: "cargo" },
        { text: "Nombre", value: "nombre" },
        { text: "Correo", value: "email" },
        { text: "Teléfono", value: "telefono" },
        { text: "Acciones", value: "actions", align: "end" },
      ],
      search: undefined,
      contacto: {},
      dialog: false,
    }),
    computed: {
      ...mapState({
        loader: (state) => state.Clientes.loader,
        items: (state) => state.Clientes.contactos,
      }),
    },
    methods: {
      ...mapActions("Clientes", ["disableContacto", "enableContacto"]),
      editar(item) {
        this.contacto = Object.assign({}, item);
        this.dialog = true;
      },
      cancelar() {
        this.contacto = {};
        this.dialog = false;
      },
      deshabilitar(item) {
        this.$swal({
          title: `¿ Deshabilitar a ${item.nombre} ?`,
          text: `No estara disponible `,
          icon: "question",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.disableContacto(item.id);
          }
        });
      },
      habilitar(item) {
        this.$swal({
          title: `¿ Habilitar a ${item.nombre} ?`,
          icon: "question",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.enableContacto(item.id);
          }
        });
      },
    },
  };
</script>
