<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-row>
    <v-col cols="12" md="auto">
      <v-text-field
        dense
        outlined
        clearable
        label="Anthracnose"
        v-model.number="resource.c_anthracnose"
        type="number"
        min="0"
        hint="Necrosis"
        :error-messages="errors.c_anthracnose"
      />
    </v-col>
    <v-col cols="12" md="auto">
      <v-text-field
        dense
        outlined
        clearable
        label="Bruising"
        v-model.number="resource.c_bruising"
        type="number"
        min="0"
        hint="Magulladuras"
        :error-messages="errors.c_bruising"
      />
    </v-col>
    <v-col cols="12" md="auto">
      <v-text-field
        dense
        outlined
        clearable
        label="Freezing Damage"
        v-model.number="resource.c_freezing"
        type="number"
        min="0"
        hint="Daño por Frio"
        :error-messages="errors.c_freezing"
      />
    </v-col>
    <v-col cols="12" md="auto">
      <v-text-field
        dense
        outlined
        clearable
        label="Decay"
        v-model.number="resource.c_decay"
        type="number"
        min="0"
        hint="Pudrición de la Pulpa"
        :error-messages="errors.c_decay"
      />
    </v-col>
    <v-col cols="12" md="auto">
      <v-text-field
        dense
        outlined
        clearable
        label="Insect Damage"
        v-model.number="resource.c_insect"
        type="number"
        min="0"
        hint="Daño por Insectos"
        :error-messages="errors.c_insect"
      />
    </v-col>
    <v-col cols="12" md="auto">
      <v-text-field
        dense
        outlined
        clearable
        label="Internal Breakdown"
        v-model.number="resource.c_internal"
        type="number"
        min="0"
        hint="Rompimiento de la Pulpa"
        :error-messages="errors.c_internal"
      />
    </v-col>
    
    <v-col cols="12" md="auto">
      <v-text-field
        dense
        outlined
        clearable
        label="Mold"
        v-model.number="resource.c_mold"
        type="number"
        min="0"
        hint="Pudrición por Hongos"
        :error-messages="errors.c_mold"
      />
    </v-col>
    <v-col cols="12" md="auto">
      <v-text-field
        dense
        outlined
        clearable
        label="Stem end Rot"
        v-model.number="resource.c_stem"
        type="number"
        min="0"
        hint="Pudrición del Pendunculo"
        :error-messages="errors.c_stem"
      />
    </v-col>
    <v-col cols="12" md="auto">
      <v-text-field
        dense
        outlined
        clearable
        label="Wounds"
        v-model.number="resource.c_wounds"
        type="number"
        min="0"
        hint="Heridas"
        :error-messages="errors.c_wounds"
      />
    </v-col>
    
    <v-col cols="12">
      <h4>Sum Condition: {{ score }}</h4>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
  export default {
    name: "Quality",
    props: {
      resource: {
        type: Object,
        required: true,
      },
    },
    computed: {
      score() {
        let total = 0;
        if (this.resource.c_skin) total += this.resource.c_skin;
        if (this.resource.c_wounds) total += this.resource.c_wounds;
        if (this.resource.c_stem) total += this.resource.c_stem;
        if (this.resource.c_anthracnose) total += this.resource.c_anthracnose;
        if (this.resource.c_bruising) total += this.resource.c_bruising;
        if (this.resource.c_freezing) total += this.resource.c_freezing;
        if (this.resource.c_decay) total += this.resource.c_decay;
        if (this.resource.c_insect) total += this.resource.c_insect;
        if (this.resource.c_internal) total += this.resource.c_internal;
        if (this.resource.c_mechanical) total += this.resource.c_mechanical;
        if (this.resource.c_mold) total += this.resource.c_mold;
        return total;
      },
      ...mapState({
        errors: state => state.errors
      })
    },
    mounted(){
      this.$store.commit('setErrors')
    }
  };
</script>
