<template>
  <v-footer
    color="primary"
    app
  >
    <span
      class="white--text"
    >{{ appName }} &copy; 2020</span>
  </v-footer>
</template>
<script>
import { mapState } from 'vuex';
export default {
	computed: {
		...mapState({
			appName: state => state.appName
		})
	}
};
</script>
