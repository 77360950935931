<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-form @submit.prevent="enviar" :disabled="loader">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="auto">
          <v-text-field
            dense
            outlined
            clearable
            label="Date"
            v-model="resource.date"
            type="date"
            :error-messages="errors.date"
          />
        </v-col>
        <v-col cols="12" md="auto">
          <v-autocomplete
            dense
            outlined
            clearable
            label="Pallet ID"
            v-model="resource.pallet_id"
            :items="pallets"
            :error-messages="errors.pallet_id"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            dense
            outlined
            clearable
            label="Product"
            v-model="producto"
            disabled
            :error-messages="errors.product"
          />
        </v-col>
        <v-col cols="12" md="auto">
          <v-text-field
            type="number"
            dense
            outlined
            clearable
            label="Quantity"
            v-model="resource.quantity"
            :error-messages="errors.quantity"
          />
        </v-col>
      </v-row>
      <v-row v-if="producto">
        <v-col cols="12" md="6">
          <h3 class="mb-5">QUALITY DEFECTS</h3>
          <quality :resource="resource" class="mb-4" />
        </v-col>
        <v-col cols="12" md="6">
          <h3 class="mb-5">CONDITION DEFECTS</h3>
          <condition :resource="resource" class="mb-4" />
        </v-col>
        <v-col cols="12">
          <v-textarea
            outlined
            label="Observations"
            v-model="resource.observations"
          />
        </v-col>
        <v-col cols="12" md="auto">
          <v-text-field
            type="number"
            dense
            outlined
            clearable
            label="Firmness"
            v-model="resource.firmness"
            :error-messages="errors.firmness"
            hint="Firmeza"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn color="success" type="submit" v-if="producto" :disabled="loader">
        save
      </v-btn>
      <v-btn color="error" @click="$emit('cancelar')" :disabled="loader">
        cancel
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import Quality from "./QualityFields.vue";
  import Condition from "./ConditionFields.vue";
  export default {
    name: "Formulario",
    components: { Quality, Condition },
    emits: ["cancelar"],
    data: () => ({
      resource: {
        pallet_id: undefined,
      },
    }),
    computed: {
      ...mapState({
        detalles: (state) => state.Embarques.detalles,
        loader: (state) => state.Embarques.loader,
        errors: (state) => state.errors,
      }),
      pallets() {
        return [...new Set(this.detalles.map((x) => x.pallet))].sort();
      },
      producto() {
        let encontrado = this.detalles.find(
          (x) => x.pallet == this.resource.pallet_id
        );
        return encontrado ? encontrado.producto : null;
      },
    },
    watch: {
      producto(val) {
        this.resource.product = val;
      },
    },
    methods: {
      ...mapActions("Embarques", ["addSample"]),
      enviar() {
        this.$store.commit("Embarques/setLoader", true);
        window.axios
          .post(`FR/addSample/${this.$route.params.id}`, this.resource)
          .then((resp) => {
            this.$store.dispatch("message", resp.data.message);
            this.$store.commit("Embarques/setSamples", resp.data.collection);
            this.$emit('cancelar')
          })
          .catch((e) => this.$store.dispatch("errores", e))
          .finally(() => this.$store.commit("Embarques/setLoader", false));
      },
    },
    mounted() {
      this.$store.commit("setErrors");
    },
  };
</script>
