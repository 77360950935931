<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-form @submit.prevent="$store.dispatch('Orders/getAll')">
        <v-row class="mt-3">
          <v-col cols="12" md="4">
            <v-autocomplete
              dense
              outlined
              clearable
              label="Filtrar por Proveedor"
              :items="paginado.proveedores"
              v-model="paginado.proveedor_id"
              type="search"
            />
          </v-col>
          <v-col>
            <v-text-field
              dense
              outlined
              clearable
              label="Buscar PO"
              v-model="paginado.search"
              placeholder="Solo colocar el número"
            />
          </v-col>
          <v-col>
            <v-btn color="primary" type="submit" :disabled="loader">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              color="success"
              class="ml-3"
              :disabled="loader"
              @click="$store.dispatch('Orders/nueva')"
              v-if="userCan('fr-purchases-create')"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        dense
        :items="items"
        :headers="headers"
        class="elevation-1"
        mobile-breakpoint="0"
        :items-per-page="paginado.perPage"
        hide-default-footer
      >
        <template v-slot:[`item.date_req`]="{ item }">
          {{ item.date_req | date }}
        </template>
        <template v-slot:[`item.order_date`]="{ item }">
          {{ item.order_date | date }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="success"
            :disabled="loader"
            @click="$store.dispatch('Orders/getResource', item)"
            v-if="userCan('fr-purchases-edit') && !item.compra_id"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            color="error"
            :disabled="loader"
            :href="item.pdf"
            target="_blank"
            v-if="userCan('fr-purchases-pdf')"
          >
            <v-icon>mdi-file-pdf</v-icon>
          </v-btn>
          <v-btn
            icon
            color="primary"
            v-if="!item.compra_id"
            @click="procesar(item)"
          >
            <v-icon>mdi-cogs</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <Pagination :loader="loader" :items="items" :paginado="paginado" />
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  export default {
    name: "Orders",
    data: () => ({
      search: undefined,
    }),
    computed: {
      ...mapState({
        headers: (state) => state.Orders.headers,
        loader: (state) => state.Orders.loader,
        items: (state) => state.Orders.collection,
        paginado: (state) => state.Orders.paginado,
      }),
      actual() {
        return this.paginado.currentPage;
      },
      proveedor() {
        return this.paginado.proveedor_id;
      },
    },
    mounted() {
      this.$store.dispatch("Orders/getAll");
    },
    watch: {
      actual: function() {
        this.$store.dispatch("Orders/getAll");
      },
      proveedor: function(val) {
        if (!val) this.$store.dispatch("Orders/getAll");
      },
    },
    methods: {
      ...mapActions("Orders", ["makePurchase"]),
      procesar(item){
        this.$swal({
          title: '¿ Procesar como compra ?',
          icon: "question",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.makePurchase(item.id)
          }
        });
      }
    },
  };
</script>
