<!-- eslint-disable -->
<template>
  <v-expansion-panel v-if="venta">
    <v-expansion-panel-header color="green lighten-4">
      Datos Venta
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="mt-4">
        <v-col cols="12" xl="5">
          Fecha Venta: <strong> {{ venta.fecha | date }}</strong
          ><br />
          Cliente: <strong>{{ venta.cliente }}</strong
          ><br />
          PO: <strong>{{ venta.po }}</strong
          ><br />
          Comprador: <strong>{{ venta.comprador }}</strong
          ><br />
          Domicilio:
          <p class="nl2br">
            <strong>{{ venta.address }}</strong>
          </p>
        </v-col>
        <v-col cols="12" xl="7">
          PU: <strong>{{ venta.pu }}</strong> <br />
          Fecha PU: <strong>{{ venta.fecha_pu | date }}</strong> <br />
          Almacen: <strong>{{ venta.warehouse }}</strong
          ><br /><br />
          <template v-if="venta.bol">
            Bol: <strong>{{ venta.bol }}</strong> <br />
            <v-btn color="error" @click="preguntar" v-if="userCan('fr-ventas-bol-delete')">Eliminar</v-btn>
            <v-btn
              color="primary"
              @click="descargar"
              v-if="venta.downloadBol"
              class="ml-3"
            >
              Descargar
            </v-btn>
          </template>
          <template v-else>
            <v-row>
              <v-col cols="12" xl="10">
                <v-file-input
                  v-model="file"
                  dense
                  outlined
                  accept="application/pdf"
                  placeholder="Seleccionar Bol"
                  prepend-icon="mdi-file-pdf"
                  label="Bol"
                  v-if="userCan('fr-ventas-bol')"
                />
              </v-col>
              <v-col cols="12" xl="2">
                <v-btn class="mr-2" color="success" @click="subir" v-if="file"
                  >Subir</v-btn
                >
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import { mapState, mapActions, mapMutations } from "vuex";
  export default {
    name: "PanelVenta",
    data: () => ({
      file: undefined,
    }),
    computed: {
      ...mapState({
        venta: (state) => state.Ventas.resource,
      }),
    },
    methods: {
      ...mapActions(["errores"]),
      ...mapMutations("Ventas", ["setResource"]),
      subir() {
        let formData = new FormData();
        formData.append("file", this.file);
        window.axios
          .post(`/FR/subirBol/${this.venta.id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((resp) => {
            window.swal(`Archivo ${resp.data.bol} subido con exito`, {
              icon: "success",
              timer: 1500,
            });
            this.setResource(resp.data);
            this.file = undefined;
          })
          .catch((e) => {
            this.errores(e);
            this.file = undefined;
          });
      },
      preguntar() {
        let pregunta = `¿Seguro de eliminar el bol?`;
        window
          .swal({
            title: pregunta,
            icon: "warning",
            buttons: ["Cancelar", "Confirmar"],
            dangerMode: true,
          })
          .then((r) => {
            if (r) {
              window.axios
                .post(`/FR/eliminarBol/${this.venta.id}`)
                .then((resp) => {
                  window.swal(`Archivo eliminado del sistema`, {
                    icon: "success",
                    timer: 1500,
                  });
                  this.setResource(resp.data);
                })
                .catch((e) => this.errores(e));
            }
          });
      },
      descargar() {
        window
          .axios({
            url: `/FR/descargarBol/${this.venta.id}`,
            method: "GET",
            responseType: "blob",
          })
          .then((response) => {
            let fileName = this.venta.bol,
              fileURL = window.URL.createObjectURL(new Blob([response.data])),
              fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .catch((e) => {
            window.swal("No se encuentra el archivo en este servidor", {
              icon: "error",
              timer: 1500,
            });
          });
      },
    },
  };
</script>
