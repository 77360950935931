var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"my-2"},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","items":_vm.items,"headers":_vm.headers,"mobile-breakpoint":"0","loading":_vm.loader,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("folio")(item.id))+" ")]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fecha))+" ")]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}},{key:"item.cajas",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numero")(item.cajas))+" ")]}}],null,true)}),_c('Pagination',{attrs:{"loader":_vm.loader,"items":_vm.items,"paginado":_vm.paginado}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"small":"","color":"primary","disabled":_vm.loader},on:{"click":_vm.getAll}},[_vm._v(" actualizar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }