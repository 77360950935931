<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-expansion-panels multiple v-if="cliente">
        <Datos />
        <Domicilios />
        <Ventas />
        <Nuevo />
        <Contactos />
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="$router.push({ name: 'clientes' })">
        regresar a clientes
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import Datos from "./datos.vue";
  import Domicilios from "./domicilios.vue";
  import Ventas from "./ventas";
  import Nuevo from "./PanelNuevo.vue";
  import Contactos from "./PanelContactos.vue";
  import { mapActions, mapState } from "vuex";
  export default {
    name: "Detalles",
    components: { Datos, Domicilios, Ventas, Nuevo, Contactos },
    computed: {
      ...mapState({
        cliente: (state) => state.Clientes.resource,
      }),
    },
    mounted() {
      if (!this.cliente) {
        let id = this.$route.params.id;
        this.detalles(id);
      }
    },
    methods: {
      ...mapActions("Clientes", ["detalles"]),
    },
  };
</script>
