<!-- eslint-disable -->
<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Ventas
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-row class="mt-3">
				<v-col cols="12" md="6">
					<v-autocomplete
						dense
						outlined
						clearable
						label="Filtrar por cliente"
						v-model="cliente"
						:items="clientes"
					/>
				</v-col>
			</v-row>
			<v-data-table
				dense
				:headers="headers"
				:items="items"
				class="elevation-1 mt-3"
				mobile-breakpoint="0"
				hide-default-footer
				:items-per-page="items.length"
			>
				<template v-slot:[`item.fecha`]="{ item }">
					{{ item.fecha | date }}
				</template>
				<template v-slot:[`item.importe`]="{ item }">
					{{ item.importe | moneda }}
				</template>
				<template v-slot:[`item.precio`]="{ item }">
					{{ item.precio | moneda }}
				</template>
			</v-data-table>
			<v-row class="mt-3">
				<v-col>Importe: {{ importe | moneda }}</v-col>
			</v-row>
			<v-btn small color="primary" class="mt-3" @click="descargarXls">
				<v-icon small>mdi-file-excel</v-icon>
			</v-btn>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>
<script>
import { mapState } from 'vuex';
export default {
	name: 'Ventas',
	data: () => ({
		headers: [
			{ text: 'Pallet', value: 'pallet_id' },
			{ text: 'Producto', value: 'producto' },
			{ text: 'Fecha', value: 'fecha' },
			{ text: 'Cliente', value: 'cliente' },
			{ text: 'PU', value: 'pu' },
			{ text: 'Cantidad', value: 'cantidad', align: 'end' },
			{ text: 'Precio U', value: 'precio', align: 'end' },
			{ text: 'Importe', value: 'importe', align: 'end' }
		],
		cliente: undefined
	}),
	computed: {
		...mapState({
			collection: state => state.Compras.ventas,
			id: state => state.Compras.resource.id,
			folio: state => state.Compras.resource.folio
		}),
		items() {
			if (this.cliente)
				return this.collection.filter(x => x.cliente == this.cliente);
			return this.collection;
		},
		clientes() {
			return [...new Set(this.collection.map(x => x.cliente))].sort();
		},
		importe() {
			return window._.sumBy(this.items, 'importe');
		}
	},
	methods: {
		descargarXls() {
			window
				.axios({
					url: `/FR/compras/downloadXls/${this.id}`,
					method: 'GET',
					responseType: 'blob'
				})
				.then(resp => {
					let fileName = `Compra ${this.folio}.xlsx`,
						fileURL = window.URL.createObjectURL(new Blob([resp.data])),
						fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute('download', fileName);
					document.body.appendChild(fileLink);
					fileLink.click();
				})
				.catch(() => {
					window.swal('Error al procesar,volver a intentar', {
						icon: 'error',
						timer: 1500
					});
				});
		}
	}
};
</script>
