import { render, staticRenderFns } from "./show.vue?vue&type=template&id=2f0e4bd4&scoped=true&"
import script from "./show.vue?vue&type=script&lang=js&"
export * from "./show.vue?vue&type=script&lang=js&"
import style0 from "./show.vue?vue&type=style&index=0&id=2f0e4bd4&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f0e4bd4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VDataTable})
