<!-- eslint-disable -->
<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Cargos Extra
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-data-table
				dense
				mobile-breakpoint="0"
				:headers="headers"
				:items="items"
				class="elevation-1 mt-2"
			>
				<!-- prettier-ignore -->
				<template v-slot:[`item.importe`]="{ item }">{{ item.importe | moneda }}</template>
				<!-- prettier-ignore -->
				<template v-slot:[`item.date`]="{ item }">{{ item.date | date }}</template>
			</v-data-table>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Charges',
	data: () => ({
		headers: [
			{ text: 'Fecha', value: 'date' },
			{ text: 'Concepto', value: 'concept' },
			{ text: 'Importe', value: 'importe', align: 'end' },
			{ text: 'Usuario', value: 'usuario' }
		]
	}),
	computed:{
		...mapState({
			items: state => state.Sucursales.charges
		})
	}
};
</script>
