<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-card>
    <v-card-title class="text-h5 grey lighten-2">
      {{ title }}
    </v-card-title>
    <v-form :disabled="loader" @submit.prevent="enviar">
      <v-card-text class="mt-2">
        <v-row>
          <v-col cols="12">
            <v-combobox
              dense
              outlined
              clearable
              label="Categoría"
              v-model="resource.category"
              :error-messages="errors.category"
              :items="categories"
            />
          </v-col>
          
          <v-col cols="12">
            <v-text-field
              dense
              outlined
              clearable
              label="Descripción"
              v-model="resource.description"
              :error-messages="errors.description"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              outlined
              clearable
              label="Peso Unidad"
              v-model="resource.unit_weight"
              :error-messages="errors.unit_weight"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
            type="number"
              dense
              outlined
              clearable
              label="Unidades X Pallet"
              v-model="resource.units_per_pallet"
              :error-messages="errors.units_per_pallet"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              outlined
              clearable
              label="Cantidad"
              v-model="resource.quantity"
              :error-messages="errors.quantity"
              type="number"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              outlined
              clearable
              label="Precio"
              v-model="resource.price"
              :error-messages="errors.price"
              type="number"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" type="submit" :disabled="loader">guardar</v-btn>
        <v-btn color="error" @click="$emit('cerrar')" :disabled="loader"
          >cancelar</v-btn
        >
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  export default {
    name: "PriceList",
    props: {
      resource: {
        type: Object,
        required: true,
      },
      categories: {
        type: Array,
        required: true,
      },
    },
    emits: ["cerrar", "actualizar"],
    computed: {
      ...mapState({
        errors: (state) => state.errors,
        loader: (state) => state.loader,
      }),
      title() {
        return this.resource.id ? "Editar" : "Agregar";
      },
    },
    methods: {
      ...mapMutations(["setErrors", "setLoader"]),
      ...mapActions(["errores", "message"]),
      enviar() {
        this.setErrors();
        this.setLoader();
        let url = "price_lists";
        if (this.resource.id) {
          window.axios
            .put(`${url}/${this.resource.id}`, this.resource)
            .then((resp) => {
              this.message(resp.data.message);
              this.$emit("actualizar");
            })
            .catch((e) => this.errores(e))
            .finally(() => this.setLoader(false));
        } else {
          window.axios
            .post(url, this.resource)
            .then((resp) => {
              this.message(resp.data.message);
              this.$emit("actualizar");
            })
            .catch((e) => this.errores(e))
            .finally(() => this.setLoader(false));
        }
      },
    },
    beforeMount() {
      this.setErrors();
    },
  };
</script>
