<!-- eslint-disable -->
<template>
	<v-expansion-panel-content>
		<v-card class="mt-3">
			<v-card-title>Historial del Pallet {{ item.pallet_id }}</v-card-title>
			<v-card-text>
				<v-expansion-panels multiple>
					<!-- Apartados -->
					<v-expansion-panel>
						<v-expansion-panel-header
							color="orange lighten-4"
							v-if="item.apartados > 0"
						>
							Apartados
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-data-table
								dense
								mobile-breakpoint="0"
								:headers="headers"
								class="elevation-1 mt-3"
							>
							</v-data-table>
						</v-expansion-panel-content>
					</v-expansion-panel>
					<!-- Vendidos -->
					<v-expansion-panel>
						<v-expansion-panel-header
							color="blue lighten-4"
							v-if="item.vendidos > 0"
						>
							Vendidos
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-data-table
								dense
								mobile-breakpoint="0"
								:headers="headers"
								class="elevation-1 mt-3"
							>
								dense mobile-breakpoint="0" :headers="headers"
								class="elevation-1 mt-3" >
							</v-data-table>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-card-text>
			<v-card-actions>
				<v-btn color="primary" @click="$emit('regresar')">regresar</v-btn>
			</v-card-actions>
		</v-card>
	</v-expansion-panel-content>
</template>

<script>
export default {
	name: 'Historial',
	props: {
		item: { type: Object, default: null }
	},
	data: () => ({
		headers: [
			{ text: 'Presupuesto', value: 'presupuesto' },
			{ text: 'Cliente', value: 'cliente' },
			{ text: 'PU', value: 'pu' },
			{ text: 'PO', value: 'po' },
			{ text: 'Cantidad', value: 'cantidad' },
			{ text: 'Precio', value: 'precio', align: 'end' },
			{ text: 'Importe', value: 'importe', align: 'end' }
		]
	})
};
</script>
