<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Historial de ventas
    </v-expansion-panel-header>
    <v-expansion-panel-content v-if="ventas.inicio">
      <v-card-text>
        <v-row class="mt-2">
          <v-col cols="12" md="3" lg="2">
            <v-text-field
              dense
              outlined
              label="Fecha Inicial"
              type="date"
              v-model="ventas.inicio"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="12" md="3" lg="2">
            <v-text-field
              dense
              outlined
              label="Fecha Final"
              type="date"
              v-model="ventas.fin"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="12" md="3" lg="1">
            <v-btn color="info" @click="buscar">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <v-row>
              <v-col cols="2">
                <v-switch v-model="grafico" :label="`Gráfica`"></v-switch>
              </v-col>
              <v-col cols="2">
                <v-switch v-model="tabla" :label="`Tabla`"></v-switch>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-3">
          <v-col cols="12" md="3" lg="2">
            Total de Ventas: {{ items.length }}
          </v-col>
          <v-col cols="12" md="3" lg="2">
            Total de Cajas: {{ cajas | numero }}
          </v-col>
          <v-col> Importe de las ventas: {{ importe | moneda }} </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="grafico">
        <pie-chart
          :chart-data="ventas.chart"
          :options="options"
          :height="300"
        />
      </v-card-text>
      <v-card-text v-if="tabla">
        <v-row>
          <v-col cols="12" xl="4" offset-lg="8">
            <v-container>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Busqueda"
                single-line
                hide-details
              />
            </v-container>
          </v-col>
        </v-row>
        <v-data-table
          dense
          :items="items"
          :headers="headers"
          class="elevation-1"
          :search.sync="search"
          mobile-breakpoint="0"
        >
          <template v-slot:[`item.id`]="{ item }">
            {{ item.id | folio }}
          </template>
          <template v-slot:[`item.fecha`]="{ item }">
            {{ item.fecha | date }}
          </template>
          <template v-slot:[`item.importe`]="{ item }">
            {{ item.importe | moneda }}
          </template>
          <template v-slot:[`item.cajas`]="{ item }">
            {{ item.cajas | numero }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import { mapMutations, mapState } from "vuex";
  import PieChart from "../../components/PieChart";
  export default {
    name: "Ventas",
    components: { PieChart },
    data: () => ({
      options: {
        maintainAspectRatio: false,
      },
      grafico: false,
      tabla: false,
      search: undefined,
      headers: [
        { text: "Folio", value: "folio" },
        { text: "Fecha", value: "fecha" },
        { text: "PU", value: "pu" },
        { text: "Expedientes", value: "expedientes" },
        { text: "Cajas", value: "cajas", align: "end" },
        { text: "Importe Producto", value: "importe", align: "end" },
        { text: "Facturado", value: "facturas", align: "center" },
        { text: "Nota", value: "nota" },
      ],
    }),
    computed: {
      ...mapState({
        ventas: (state) => state.Clientes.ventas,
      }),
      items() {
        return this.ventas.items;
      },
      importe() {
        return window._.sumBy(this.items, "importe");
      },
      cajas() {
        return window._.sumBy(this.items, "cajas");
      },
    },
    methods: {
      ...mapMutations("Clientes", ["setVentas"]),
      buscar() {
        this.ventas.items = [];
        let url = `FR/getTime/${this.$route.params.id}`;
        window.axios
          .get(url, {
            params: {
              inicio: this.ventas.inicio,
              fin: this.ventas.fin,
            },
          })
          .then((resp) => {
            this.setVentas(resp.data);
          });
      },
    },
  };
</script>
