<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-expansion-panels multiple v-model="panel">
        <Generales v-if="embarque.id" />
        <Pallets v-if="detalles.length" />
        <Archivos v-if="files.length && userCan('fr-embarques-files')" />
        <Ventas v-if="ventas.length && userCan('fr-embarques-ventas')" />
        <Gastos v-if="gastos && userCan('fr-embarques-gastos')" />
        <CQ v-if="userCan('fr-quality') && embarque.id" />
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="regresar">regresar a embarques</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapState, mapActions, mapMutations } from "vuex";
  import Generales from "./panelDatos";
  import Pallets from "./panelPallets";
  import Archivos from "./panelArchivos";
  import Ventas from "./panelVentas";
  import Gastos from "./panelGastos";
  import CQ from "./PanelCQ.vue";
  export default {
    name: "Detalles",
    components: { Generales, Pallets, Archivos, Ventas, Gastos, CQ },
    data: () => ({
      panel: [4],
    }),
    computed: {
      ...mapState({
        embarque: (state) => state.Embarques.resource,
        detalles: (state) => state.Embarques.detalles,
        files: (state) => state.Embarques.files,
        ventas: (state) => state.Embarques.ventas,
        gastos: (state) => state.Embarques.gastos,
      }),
    },
    mounted() {
      if (!this.embarque.id) {
        this.getEmbarque(this.$route.params.id);
      }
    },
    methods: {
      ...mapActions("Embarques", ["getEmbarque"]),
      regresar() {
        this.$router.push({ name: "embarques" });
      },
    },
  };
</script>
