<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card class="my-2">
    <v-card-text>
      <v-row>
        <v-col class="mt-2">
          <v-autocomplete
            dense
            outlined
            clearable
            small-chips
            label="Seleccione Artículo"
            v-model="supply"
            :items="articulos"
          />
        </v-col>
      </v-row>
      <v-data-table
        calculate-widths
        sortBy="expediente"
        :headers="computedHeaders"
        :items="disponibles"
        class="elevation-1"
        v-if="supply"
        mobile-breakpoint="0"
        hide-default-footer
        :items-per-page="disponibles.length"
      >
        <template v-slot:[`item.date`]="{ item }">
          {{ item.date | date }}
        </template>
        <template v-slot:[`item.price`]="{ item }">
          {{ item.price | moneda }}
        </template>
        <template v-slot:[`item.precio`]="{ item }">
          <v-text-field
            dense
            type="number"
            step="0.01"
            min="0"
            class="righted-input"
            v-model.number="item.precio"
            hide-details="auto"
          />
        </template>
        <template v-slot:[`item.seleccionados`]="{ item }">
          <v-text-field
            dense
            type="number"
            step="0.01"
            min="0"
            class="righted-input"
            v-model.number="item.seleccionados"
            hide-details="auto"
          />
        </template>
      </v-data-table>
      <v-row class="mt-3" v-if="listos.length">
        <v-col>
          <v-card>
            <v-card-title>Articulos seleccionados</v-card-title>
            <v-card-text>
              <v-data-table
                dense
                :headers="headerListos"
                :items="listos"
                class="elevation-1"
                hide-default-footer
                :items-per-page="listos.length"
                mobile-breakpoint="0"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn icon color="red" @click="eliminar(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <template v-slot:[`item.precio`]="{ item }">
                  {{ item.precio | moneda }}
                </template>
                <template v-slot:[`item.importe`]="{ item }">
                  {{ importe(item) | moneda }}
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-text> Importe: {{ importTotal | moneda }} </v-card-text>
            <v-card-actions>
              <v-btn color="success" @click="agregar" :disabled="loader"
                >agregar al presupuesto</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  export default {
    name: "CardSupplies",
    data: () => ({
      supply: undefined,
      headers: [
        { text: "Expediente", value: "expediente", hide: "smAndDown" },
        { text: "Pallet", value: "pallet_id" },
        { text: "Fecha", value: "date" },
        { text: "Disponibles", value: "disponibles", align: "end" },
        { text: "Cantidad", value: "seleccionados", align: "end" },
        { text: "Precio Compra", value: "price", align: "end" },
        { text: "Precio", value: "precio", align: "end" },
      ],
      headerListos: [
        { text: "Producto", value: "supply" },
        { text: "Pallet", value: "pallet_id" },
        { text: "Cantidad", value: "seleccionados", align: "end" },
        { text: "Precio", value: "precio", align: "end" },
        { text: "Importe", value: "importe", align: "end" },
        { text: "", value: "actions", align: "end" },
      ],
    }),
    computed: {
      ...mapState({
        supplies: (state) => state.Budgets.resource.supplies,
        loader: (state) => state.Budgets.resource.loader,
      }),
      articulos() {
        return [...new Set(this.supplies.map((x) => x.supply))].sort();
      },
      disponibles() {
        return this.supplies.filter((x) => x.supply == this.supply);
      },
      computedHeaders() {
        return this.headers.filter(
          (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
        );
      },
      listos: function() {
        return this.supplies.filter((x) => {
          return (
            x.seleccionados &&
            x.precio &&
            x.seleccionados <= x.disponibles &&
            x.seleccionados > 0 &&
            x.precio >= 0
          );
        });
      },
      importTotal() {
        return this.listos.reduce(function(accumulator, item) {
          let cantidad = parseFloat(item.seleccionados),
            precio = parseFloat(item.precio);
          return accumulator + precio * cantidad;
        }, 0);
      },
    },
    methods: {
      ...mapMutations("Budgets", ["setLoader"]),
      ...mapActions(["message", "errores"]),
      ...mapActions("Budgets", ["detalles"]),
      eliminar() {},
      agregar() {
        this.setLoader();
        let url = `FR/budgets/addSupply/${this.$route.params.id}`,
          items = this.listos;
        window.axios
          .post(url, { items })
          .then((resp) => {
            this.message(resp.data.message);
            this.detalles(this.$route.params.id);
            this.$emit('cancelar')
          })
          .catch((e) => {
            this.errores(e);
          })
          .finally(this.setLoader(false));
      },
      importe(item) {
        let importe = item.seleccionados * item.precio;
        return importe.toFixed(2);
      },
    },
  };
</script>
