<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-card height="97%" class="mb-1">
    <v-card-text>
      <v-row class="mb-1 primary white--text">
        <v-col cols="12"
          ><strong>{{ item.prod }}</strong></v-col
        >
        <v-col cols="12" md="6"
          >Label: <strong>{{ item.label }}</strong></v-col
        >
        <v-col cols="12" md="6" class="text-right"
          >Total: <strong>{{ total }}</strong></v-col
        >
      </v-row>
      <v-data-table
        dense
        :headers="headers"
        :items="item.fechas"
        class="elevation-1"
        hide-default-footer
        mobile-breakpoint="0"
      >
        <template v-slot:[`item.date`]="{ item }">
          {{ item.date | date }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: "Tarjeta",
    props: {
      item: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    data: () => ({
      headers: [
        { text: "Date", value: "date" },
        { text: "Boxes", value: "amount", align: "end" },
      ],
    }),
    computed: {
      total() {
        return window._.sumBy(this.item.fechas, "amount");
      },
    },
  };
</script>
