<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text v-if="cargado">
      <v-expansion-panels multiple>
        <Datos />
        <Pruebas />
        
        <Agregar v-if="budget.budget.finalizado == '0'" />
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions v-if="cargado">
      <v-btn color="primary" small @click="cancelar">Regresar</v-btn>
      <v-btn
        :color="btnColor"
        small
        @click="preguntar"
        v-if="budget.budget.finalizado == '0'"
        >{{ btnTitle }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapState, mapActions, mapMutations } from "vuex";
  import Datos from "./panelDatos";
  import Agregar from "./NewPanelAgregar.vue";
  import Pruebas from './NewPanelProducts.vue'
  export default {
    name: "Formulario",
    components: { Datos, Agregar,  Pruebas},
    computed: {
      ...mapState({
        budget: (state) => state.Budgets.resource,
        details: (state) => state.Budgets.resource.details,
        externos: (state) => state.Budgets.resource.PuItems,
      }),
      cargado() {
        return Object.keys(this.budget).length > 0;
      },
      btnTitle: function() {
        return this.details.length || this.externos.length
          ? "Finalizar Venta"
          : "Cancelar folio";
      },
      btnColor: function() {
        return this.details.length || this.externos.length
          ? "success"
          : "error";
      },
    },
    mounted() {
      if (!this.cargado) {
        this.detalles(this.$route.params.id);
      }
    },
    methods: {
      ...mapActions("Budgets", ["detalles", "finalizar"]),
      preguntar() {
        let pregunta =
            this.details.length || this.externos.length
              ? `¿Deseas finalizar la venta?`
              : `¿Deseas cancelar el folio ${this.budget.budget.folio}?`,
          text =
            this.details.length || this.externos.length
              ? `Nota:
               Al confirmar la venta no podrá editarla.
               El producto se restará de inventario.`
              : "El folio ya no podra ser utilizado";
        window
          .swal({
            title: pregunta,
            text: text,
            icon: "warning",
            buttons: ["Cancelar", "Confirmar"],
            dangerMode: true,
          })
          .then((confirmed) => {
            if (confirmed) {
              this.finalizar(this.$route.params.id);
            }
          });
      },
      cancelar() {
        this.$router.push({ name: "budgets" });
      },
    },
  };
</script>
