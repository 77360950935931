<!-- eslint-disable -->
<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Conceptos de la Factura
		</v-expansion-panel-header>
		<v-expansion-panel-content v-if="!action">
			<v-row>
				<v-col cols="12" md="4">
					<v-btn small color="success" class="mt-5" @click="nuevo" v-if="userCan('fr-facturas-addconcepto')">
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="12" xl="4" offset-xl="4">
					<v-container>
						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="Busqueda"
							single-line
							hide-details
						/>
					</v-container>
				</v-col>
			</v-row>
			<v-data-table
				dense
				:headers="headers"
				:items="details"
				class="elevation-1 mt-3"
				mobile-breakpoint="0"
				hide-default-footer
				:items-per-page="details.length"
			>
				<template v-slot:[`item.precio`]="{ item }">
					{{ item.precio | moneda }}
				</template>
				<template v-slot:[`item.importe`]="{ item }">
					{{ item.importe | moneda }}
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-btn icon color="red" @click="eliminar(item)" v-if="userCan('fr-facturas-removeconcepto')">
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</template>
			</v-data-table>
			<p class="negrita mt-3">Importe de la Factura: {{ importe | moneda }}</p>
		</v-expansion-panel-content>
		<v-expansion-panel-content v-else>
			<v-card class="mt-2">
				<v-form @submit.prevent="updateDetail(concepto)">
					<v-card-text>
						<v-row>
							<v-col>
								<v-text-field
									outlined
									dense
									type="text"
									label="Concpeto"
									v-model="concepto.concepto"
									:error-messages="errors.concepto"
									:disabled="tienePallet"
								/>
							</v-col>
							<v-col v-if="tienePallet">
								<v-text-field
									outlined
									dense
									type="number"
									label="Pallet ID"
									v-model.number="concepto.pallet"
									:error-messages="errors.pallet"
									disabled
								/>
							</v-col>
							<v-col>
								<v-text-field
									outlined
									dense
									type="number"
									label="Cantidad"
									v-model.number="concepto.cantidad"
									:error-messages="errors.cantidad"
									:disabled="tienePallet"
								/>
							</v-col>
							<v-col>
								<v-text-field
									outlined
									dense
									type="number"
									step="0.01"
									label="Precio Unitario"
									v-model.number="concepto.precio"
									:error-messages="errors.precio"
								/>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-btn small color="success" type="submit" :disabled="loader"
							>Guardar</v-btn
						>
						<v-btn small color="error" @click="cancelar">Cancelar</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
	name: 'Conceptos',
	data: () => ({
		action: false,
		concepto: undefined,
		search: undefined,
		headers: [
			{ text: 'Concepto', value: 'concepto' },
			{ text: 'Pallet ID', value: 'pallet' },
			{ text: 'Cantidad', value: 'cantidad', align: 'end' },
			{ text: 'Precio', value: 'precio', align: 'end' },
			{ text: 'Importe', value: 'importe', align: 'end' },
			{ text: 'Acciones', value: 'actions', align: 'end' }
		]
	}),
	computed: {
		...mapState({
			details: state => state.Facturas.detalles,
			errors: state => state.errors,
			loader: state => state.Facturas.loader
		}),
		importe() {
			return _.sumBy(this.details, 'importe');
		},
		editando() {
			return this.concepto.id ? true : false;
		},
		tienePallet() {
			return this.concepto.pallet ? true : false;
		}
	},
	watch: {
		details: function() {
			this.cancelar();
		}
	},
	methods: {
		...mapMutations(['setErrors']),
		...mapMutations('Facturas', ['setLoader']),
		...mapActions('Facturas', ['updateDetail', 'deleteDetail']),

		pregunta(concepto) {
			return concepto.pallet
				? `¿Deseas eliminar el pallet ${concepto.pallet} de la factura?`
				: `¿Deseas eliminar el concepto ${concepto.concepto} de la factura?`;
		},
		eliminar(item) {
			window
				.swal({
					title: this.pregunta(item),
					icon: 'warning',
					buttons: ['Cancelar', 'Confirmar'],
					dangerMode: true
				})
				.then(confirmado => {
					if (confirmado) {
						this.deleteDetail(item.id);
					}
				});
		},
		editar(item) {
			this.action = true;
			this.concepto = Object.assign({}, item);
		},
		nuevo() {
			this.concepto = {};
			this.action = true;
		},
		cancelar() {
			this.concepto = undefined;
			this.action = false;
			this.setErrors([]);
			this.setLoader(false);
		}
	}
};
</script>
