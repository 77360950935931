/** @format */
const base = "goodman";
const routes = [
  {
    path: "/Goodmans-schedules",
    name: base,
    component: require("@/views/factoraje/Index.vue").default,
    meta: {
      name: "Goodman's Schedules",
    },
  },
  {
    path: "/Goodmans-schedules/create",
    name: `${base}.create`,
    component: require("@/views/factoraje/Create.vue").default,
    meta: {
      name: "New Schedule",
    },
  },
  {
    path: "/Goodmans-schedules/details/:id",
    name: `${base}.show`,
    component: require("@/views/factoraje/Show.vue").default,
    meta: {
      name: "Schedule Details",
    },
  },
];
export default routes;
