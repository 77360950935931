<!-- eslint-disable -->
<template>
	<v-card height="100%" v-if="user.id">
		<v-card-text>
			Usuario: {{ user.full_name }} <br />
			Email: {{ user.email }}
			<v-row class="mt-3">
				<v-col cols="12" md="6" xl="4" v-for="modulo in modulos" :key="modulo">
					<v-card class="my-2" height="97%">
						<v-card-title primary-title> {{modulo!=='MENU'?'Módulo':''}} {{ modulo }} </v-card-title>
						<v-card-text>
							<v-checkbox
								class="mio"
								v-for="permiso in buscar(modulo)"
								:key="permiso.id"
								v-model="abilities"
								:label="permiso.description"
								:value="permiso.id"
							/>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-btn
				color="primary"
				:disabled="loader"
				@click="$store.dispatch('Users/syncAbilities')"
			>
				actualizar los permisos
			</v-btn>
			<v-btn color="error" @click="eliminarPermisos">Eliminar Todos los permisos</v-btn>
		</v-card-actions>
	</v-card>
</template>
<script>
import { mapState } from 'vuex';
export default {
	name: 'Abilities',
	computed: {
		...mapState({
			user: state => state.Users.resource,
			permisos: state => state.Users.permisos,
			loader: state => state.Users.loader
		}),
		modulos() {
			// if(!this.user.sucursal_id){
			// 	return ['MENU']
			// }
			let filtrados = this.permisos.abilities.filter(x=>x.modulo!=='MENU');
			return [...new Set(filtrados.map(x => x.modulo))].sort();
		},
		actuales: {
			get() {
				return this.permisos.actuales;
			},
			set(value) {
				this.$store.commit('Users/setAbilities', value);
			}
		}
	},
	mounted() {
		if (!this.user.id) {
			let id = this.$route.params.id;
			this.$store.dispatch('Users/getAbilities', { id });
		} else {
			this.abilities = this.permisos.actuales;
		}
	},
	methods: {
		buscar(modulo) {
			return this.permisos.abilities.filter(x => x.modulo == modulo);
		},
		quitarTodos() {
			this.$store.commit('Users/setAbilities', []);
		},
		eliminarPermisos(){
			this.$store.commit('Users/setAbilities', []);
			this.$store.dispatch('Users/syncAbilities')
		}
	},
	data: () => ({
		abilities: []
	}),
	watch: {
		abilities(value) {
			this.$store.commit('Users/setAbilities', value);
		}
	}
};
</script>
<style lang="scss" scoped>
::v-deep .v-input--selection-controls {
	margin-top: 1px !important;
	padding-top: 0px !important;
}
::v-deep .v-messages {
	display: none;
}
::v-deep .mio > .v-input__control > .v-input__slot > label {
	font-size: 14px !important;
}
</style>
