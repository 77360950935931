<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-dialog persistent v-model="dialog" max-width="600">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Add Product
      </v-card-title>
      <v-form @submit.prevent="enviar" :disabled="loader">
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12" md="6" xl="auto">
              <v-autocomplete
                dense
                outlined
                clearable
                label="Caliber"
                v-model="resource.caliber"
                :items="calibers"
                :error-messages="errors.caliber"
              />
            </v-col>
            <v-col cols="12" md="6" xl="auto">
              <v-autocomplete
                dense
                outlined
                clearable
                label="Type"
                v-model="resource.type"
                :items="types"
                :error-messages="errors.type"
              />
            </v-col>
            <v-col cols="12" md="6" xl="auto">
              <v-autocomplete
                dense
                outlined
                clearable
                label="Category"
                v-model="resource.category"
                :items="categories"
                :error-messages="errors.category"
              />
            </v-col>
            <v-col cols="12" md="6" xl="auto">
              <v-text-field
                type="number"
                dense
                outlined
                clearable
                label="Boxes"
                v-model="resource.boxes"
                :error-messages="errors.boxes"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="loader" color="success" type="submit">
            send
          </v-btn>
          <v-btn :disabled="loader" color="error" @click="$emit('cerrar')">
            cancel
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  export default {
    name: "AddProduct",
    props: {
      dialog: {
        type: Boolean,
      },
    },
    emits: ["cerrar"],
    data: () => ({
      calibers: ["32", "36", "40", "48", "60", "70", "84", "96"],
      types: [
        { text: "Conventional", value: "conv" },
        { text: "Organic", value: "org" },
      ],
      categories: [
        { text: "Cat 1", value: "#1" },
        { text: "Cat 2", value: "#2" },
      ],
      resource: {
        caliber: undefined,
        category: undefined,
        type: undefined,
      },
    }),
    computed: {
      ...mapState({
        errors: (state) => state.errors,
        loader: (state) => state.loader,
        quality: (state) => state.Qc.resource,
      }),
    },
    methods: {
      ...mapMutations(["setLoader", "setErrors"]),
      ...mapActions(["errores", "message"]),
      enviar() {
        this.setErrors();
        this.setLoader();
        window.axios
          .post(
            `FR/quality_controls/addAvocado/${this.$route.params.id}`,
            this.resource
          )
          .then((resp) => {
            this.message(resp.data.message);
            this.quality.products = resp.data.products;
            this.$emit("cerrar");
          })
          .catch((e) => this.errores(e))
          .finally(() => this.setLoader(false));
      },
    },
    mounted() {
      this.setErrors();
    },
  };
</script>
