<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Datos Artículo
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-form @submit.prevent="sendResource" :disabled="loader">
        <Fields />
        <v-card-actions v-if="modificado">
          <v-btn color="success" type="submit" :disabled="loader">
            actualizar
          </v-btn>
          <v-btn color="error" @click="cancelar">
            cancelar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  import Fields from "./Fields.vue";
  export default {
    name: "Resource",
    data: () => ({
      original: undefined,
    }),
    components: { Fields },
    computed: {
      ...mapState({
        errors: (state) => state.errors,
        resource: (state) => state.Supplies.resource,
        loader: (state) => state.Supplies.loader,
        units: (state) => state.Supplies.units,
      }),
      modificado() {
        return JSON.stringify(this.resource) !== JSON.stringify(this.original);
      },
    },
    methods: {
      ...mapActions("Supplies", ["nuevo", "getResource", "sendResource"]),
      ...mapMutations("Supplies", ["setResource"]),
      cancelar() {
        this.setResource(this.original);
      },
    },
    mounted() {
      this.original = Object.assign({}, this.resource);
    },
    watch: {
      resource(val) {
        this.original = Object.assign({}, val);
      },
    },
  };
</script>
