<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Gastos: <span class="mover">{{ importe | moneda }}</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content v-if="!gasto">
      <v-row>
        <v-col cols="12" md="4">
          <v-btn small color="success" class="mt-5" @click="nuevo">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :items="items"
        :headers="headers"
        class="elevation-1"
        :search.sync="search"
        mobile-breakpoint="0"
      >
        <template v-slot:[`item.archivo`]="{ item }">
          <div v-if="item.archivo.nombre">
            {{ item.archivo.nombre }}
            <v-btn
              icon
              color="success"
              :href="item.archivo.link"
              target="_blank"
              v-if="item.archivo.link"
            >
              <v-icon>mdi-cloud-download</v-icon>
            </v-btn>
          </div>
          <v-btn icon color="primary" v-else @click="editar(item)">
            <v-icon>mdi-attachment</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="red" @click="eliminar(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
      </v-data-table>
    </v-expansion-panel-content>
    <v-expansion-panel-content v-else class="mt-3">
      <v-card>
        <v-form @submit.prevent="enviar">
          <v-card-text>
            <v-row>
              <v-col cols="12" xl="2">
                <v-text-field
                  type="date"
                  label="Fecha del Gasto"
                  outlined
                  dense
                  v-model="gasto.fecha"
                  :error-messages="errors.fecha"
                  :disabled="gasto.id ? true : false"
                />
              </v-col>
              <v-col cols="12" xl="6">
                <v-file-input
                  v-model="file"
                  dense
                  outlined
                  placeholder="Seleccionar archivo"
                  prepend-icon="mdi-attachment"
                  label="Archivo del gasto"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xl="8">
                <v-text-field
                  type="text"
                  label="Concepto del Gasto"
                  outlined
                  dense
                  v-model="gasto.concepto"
                  :error-messages="errors.concepto"
                  :disabled="gasto.id ? true : false"
                />
              </v-col>
              <v-col cols="12" xl="2">
                <v-text-field
                  type="number"
                  step="0.01"
                  label="Importe"
                  outlined
                  dense
                  v-model.number="gasto.importe"
                  :error-messages="errors.importe"
                  :disabled="gasto.id ? true : false"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xl="10">
                <v-textarea
                  outlined
                  label="Observaciones"
                  v-model="gasto.observaciones"
                  :error-messages="errors.observaciones"
                  :disabled="gasto.id ? true : false"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn small color="success" type="submit" :disabled="loader"
              >guardar</v-btn
            >
            <v-btn small color="error" @click="cancelar" :disabled="loader"
              >cancelar</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  export default {
    name: "Gastos",
    data: () => ({
      headers: [
        { text: "Folio", value: "folio" },
        { text: "Fecha", value: "fecha" },
        { text: "Concepto", value: "concepto" },
        { text: "Observaciones", value: "observaciones" },
        { text: "Archivo", value: "archivo" },
        { text: "Importe", value: "importe", align: "end" },
        { text: "Acciones", value: "actions", align: "end" },
      ],
      search: undefined,
      gasto: undefined,
      file: null,
      //items: []
    }),
    computed: {
      ...mapState({
        errors: (state) => state.errors,
        loader: (state) => state.Compras.loader,
        items: (state) => state.Compras.gastos,
      }),
      importe() {
        return _.sumBy(this.items, "importe");
      },
    },
    watch: {
      items: function() {
        this.cancelar();
      },
    },
    methods: {
      ...mapActions("Compras", ["uploadGasto", "deleteGasto"]),
      ...mapMutations(["setErrors"]),
      nuevo() {
        this.gasto = {};
      },
      cancelar() {
        this.gasto = undefined;
        this.setErrors([]);
        this.file = undefined;
      },
      enviar() {
        let formData = new FormData();
        formData.append("file", this.file ? this.file : "");
        formData.append(
          "concepto",
          this.gasto.concepto ? this.gasto.concepto : ""
        );
        formData.append(
          "importe",
          this.gasto.importe ? this.gasto.importe : ""
        );
        formData.append(
          "observaciones",
          this.gasto.observaciones ? this.gasto.observaciones : ""
        );
        formData.append("fecha", this.gasto.fecha ? this.gasto.fecha : "");
        this.uploadGasto(formData);
      },
      eliminar(item) {
        let pregunta = `¿Eliminar el gasto ${item.concepto}?`;
        window
          .swal({
            title: pregunta,
            icon: "warning",
            buttons: ["Cancelar", "Confirmar"],
            dangerMode: true,
          })
          .then((confirmed) => {
            if (confirmed) {
              this.deleteGasto(item.id);
            }
          });
      },
      editar(item) {
        this.gasto = Object.assign({}, item);
      },
    },
  };
</script>
