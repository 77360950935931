<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="blue lighten-4">
      Invoices
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card-text>
        <v-data-table
          dense
          :items="items"
          :headers="headers"
          class="elevation-1"
          mobile-breakpoint="0"
          :items-per-page="items.length"
          hide-default-footer
          :item-class="rowColor"
        >
          <template v-slot:[`item.id`]="{ item }">
            {{ item.id | folio }}
          </template>
          <template v-slot:[`item.fecha`]="{ item }">
            {{ item.fecha | date }}
          </template>
          <template v-slot:[`item.importe`]="{ item }">
            {{ item.importe | moneda }}
          </template>
          <template v-slot:[`item.resta`]="{ item }">
            {{ item.resta | moneda }}
          </template>
          <template v-slot:[`item.pagos`]="{ item }">
            {{ (item.importe - item.resta) | moneda }}
          </template>
          <template v-slot:[`item.fecha_pago`]="{ item }">
            {{ item.fecha_pago | date }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    name: "Invoices",
    computed: {
      ...mapState({
        items: (state) => state.Goodman.invoices,
      }),
    },
    data: () => ({
      headers: [
        { text: "Invoice", value: "folio" },
        { text: "PU", value: "pu" },
        { text: "Fecha", value: "fecha" },
        { text: "Cliente", value: "cliente" },
        { text: "Importe", value: "importe", align: "end" },
        { text: "Pagos", value: "pagos", align: "center" },
        { text: "Resta", value: "resta", align: "end" },
        { text: "Referencia", value: "referencia" },
      ],
    }),
    methods: {
      rowColor(item) {
        let color = null;
        if (item.diasAtraso && item.diasAtraso < 11) color = "yellow lighten-5";
        if (item.diasAtraso && item.diasAtraso > 10) color = "red lighten-5";
        return color;
      },
    },
  };
</script>
