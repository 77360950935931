<!-- eslint-disable -->
<template>
	<v-expansion-panel-content>
		<v-card-title primary-title>
			Nuevo Pago
		</v-card-title>
		<v-form @submit.prevent="enviar">
			<v-card-text>
				<v-row class="mt-3">
					<v-col cols="12" md="3">
						<v-text-field
							dense
							outlined
							clearable
							label="Fecha"
							type="date"
							:max="today"
							v-model="pago.fecha"
							:error-messages="errors.fecha"
						/>
					</v-col>
					<v-col cols="12" md="3">
						<v-combobox
							dense
							outlined
							clearable
							label="Forma de Pago"
							v-model="pago.metodo_pago"
							:items="metodos"
							:error-messages="errors.metodo_pago"
						/>
					</v-col>
					<v-col cols="12" md="3">
						<v-text-field
							dense
							outlined
							clearable
							label="Referencia"
							v-model="pago.referencia"
							:error-messages="errors.referencia"
						/>
					</v-col>
					<v-col cols="12" md="3">
						<v-text-field
							dense
							outlined
							clearable
							label="Importe"
							type="number"
							step="0.01"
							v-model.number="pago.importe"
							:error-messages="errors.importe"
						/>
					</v-col>
					<v-col cols="12" xl="6">
						<v-file-input
							class="mt-5"
							v-model="file"
							dense
							outlined
							placeholder="Seleccionar archivo para subir"
							prepend-icon="mdi-attachment"
							label="Subir Archivo"
							accept=".pdf"
						/>
					</v-col>
					<v-col cols="12">
						<v-textarea
							outlined
							label="Observaciones"
							v-model="pago.observaciones"
						/>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-btn color="success" type="submit" :disabled="loader">guardar</v-btn>
				<v-btn color="error" @click="$emit('cancelar')" :disabled="loader"
					>cancelar</v-btn
				>
			</v-card-actions>
		</v-form>
	</v-expansion-panel-content>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Nuevo',
	props: {
		metodos: {
			type: Array,
			required: true
		}
	},
	data: () => ({
		pago: {},
		file: undefined
	}),
	mounted() {
		this.$store.commit('Compras/setLoader', false);
		this.$store.commit('setErrors', []);
	},
	computed: {
		...mapState({
			errors: state => state.errors,
			loader: state => state.Compras.loader,
			id: state => state.Compras.resource.id
		}),
		today() {
			return new Date().toISOString().split('T')[0];
		}
	},
	methods: {
		enviar() {
			let data = new FormData();
			data.append('fecha', this.pago.fecha ? this.pago.fecha : '');
			data.append(
				'metodo_pago',
				this.pago.metodo_pago ? this.pago.metodo_pago : ''
			);
			data.append(
				'referencia',
				this.pago.referencia ? this.pago.referencia : ''
			);
			data.append('importe', this.pago.importe ? this.pago.importe : '');
			data.append(
				'observaciones',
				this.pago.observaciones ? this.pago.observaciones : ''
			);
			if (this.file) {
				data.append('file', this.file);
			}
			this.$store.commit('Compras/setLoader', true);
			this.$store.commit('setErrors', []);
			window.axios
				.post(`FR/compras/pagos/nuevo/${this.id}`, data)
				.then(resp => {
					window.swal(resp.data.message, { icon: 'success', timer: 1500 });
					this.$store.commit('Compras/setPagos', resp.data.pagos);
					this.$emit('cancelar');
				})
				.catch(e => this.$store.dispatch('errores', e))
				.finally(() => this.$store.commit('Compras/setLoader', false));
		}
	}
};
</script>
