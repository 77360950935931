<!-- @format -->

<!-- eslint-disable -->
<template>
  <div v-if="user">
    <v-navigation-drawer v-model="drawer" app>
      <div class="text-center py-1">
        <img :src="require('./logo.png')" width="40%" alt="" />
      </div>
      <v-list dense shaped>
        <div class="d-xl-none">
          <v-list-item link :to="{ name: 'editMe' }">
            <v-list-item-action>
              <v-icon>mdi-account-box</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Mis Datos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <template v-if="menu.menu">
          <v-list-item
            v-for="(item, key) in menu.menu"
            :key="key"
            link
            :to="item.to"
          >
            <v-list-item-action>
              <v-icon>{{item.icon}}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
            <v-list-item-title>{{item.title}}</v-list-item-title>
          </v-list-item-content>
          </v-list-item>
        </template>
        <template v-if="menu.grupos">
          <v-list-item-group color="primary" v-for="grupo in menu.grupos" :key="grupo.name">
             <v-list-group :value="false" prepend-icon="mdi-folder">
            <template v-slot:activator>
              <v-list-item-title>{{grupo.name}}</v-list-item-title>
            </template>
           <v-list-item
            v-for="item in grupo.items"
            :key="item.title"
            link
            :to="{ name: item.to }"
          >
            <v-list-item-action>
              <v-icon>{{item.icon}}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
            <v-list-item-title>{{item.title}}</v-list-item-title>
          </v-list-item-content>
          </v-list-item>
          </v-list-group>
          </v-list-item-group>
        </template>
        <template v-if="menu.varios">
          <v-list-item
            v-for="item in menu.varios"
            :key="item.title"
            link
            :to="{ name: item.to }"
          >
            <v-list-item-action>
              <v-icon>{{item.icon}}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
            <v-list-item-title>{{item.title}}</v-list-item-title>
          </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="primary">
      <v-app-bar-nav-icon @click="drawer = !drawer" class="white--text" />
      <v-toolbar-title class="white--text" v-text="name"></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        color="white"
        link
        :to="{ name: 'editMe' }"
        class="d-none d-xl-flex"
      >
        <v-icon>mdi-account-box</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>
<script>
  import { mapState } from "vuex";
  export default {
    data: () => ({
      drawer: null,
      catalogos: undefined,
      otros: undefined,
      menu: {},
    }),
    computed: {
      ...mapState({
        user: (state) => state.Auth.user,
        inventario: (state) => state.Dashboard.inventario,
      }),
      name() {
        if (this.$route.params.nombre) return this.$route.params.nombre;
        return this.$route.meta ? this.$route.meta.name : "";
      },
      url() {
        let urlBase = process.env.VUE_APP_BASE_URL,
          url = urlBase.substring(0, urlBase.length - 4);
        return url + "log-viewer/logs";
      },
      isDevelopment() {
        return process.env.NODE_ENV === "development";
      },
    },
    mounted() {
      window.axios
        .get("FR/menu")
        .then((resp) => {
          this.menu = resp.data;
        })
        .catch((e) => this.$store.dispatch("errores", e));
    },
    methods: {
    },
  };
</script>
