<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Information
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-form @submit.prevent="updateResource" :disabled="loader">
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12" md="4">
              <strong style="font-size:1.1em;"
                >Schedule # {{ resource.schedule }}
              </strong>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                dense
                outlined
                clearable
                label="Date"
                type="date"
                v-model="resource.date"
                hide-details=""
              />
            </v-col>
            <v-col cols="12" md="4">
              <strong
                >Total of Invoices Sold: {{ resource.total | moneda }}</strong
              >
            </v-col>
            <v-col cols="12" md="4">
              <strong
                >Processing Fees:
                <span class="red--text">{{
                  resource.fees | moneda
                }}</span></strong
              >
            </v-col>
            <v-col cols="12" md="4">
              <strong
                >Required Reserve:
                <span class="red--text">{{
                  resource.reserve | moneda
                }}</span></strong
              >
            </v-col>
            <v-col cols="12" md="4">
              <strong
                >Expenses Deducted:
                <span class="red--text">{{
                  resource.deducted | moneda
                }}</span></strong
              >
            </v-col>
            <v-col cols="12" md="4">
              <strong
                >Proceeds Owed/Paid to Client:
                {{ resource.paid | moneda }}</strong
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-if="modificado">
          <v-btn color="success" :disabled="loader" type="submit">
            update
          </v-btn>
          <v-btn color="error" :disabled="loader" @click="cancelar">
            cancel
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  export default {
    name: "Information",
    computed: {
      ...mapState({
        resource: (state) => state.Goodman.resource,
        loader: (state) => state.Goodman.loader,
      }),
      modificado() {
        return JSON.stringify(this.original) !== JSON.stringify(this.resource);
      },
    },
    data: () => ({
      original: undefined,
    }),
    mounted() {
      this.original = Object.assign({}, this.resource);
    },
    watch: {
      resource(val) {
        this.original = Object.assign({}, val);
      },
    },
    methods: {
      ...mapActions("Goodman", ["showResource", "updateResource"]),
      cancelar() {
        this.showResource(this.$route.params.id);
      },
    },
  };
</script>
