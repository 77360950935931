<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="orange lighten-4">
      Files
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card-text>
        <v-row class="my-2">
          <v-col cols="12" xl="6">
            <v-file-input
              v-model="file"
              dense
              outlined
              placeholder="Select file"
              prepend-icon="mdi-attachment"
              label="File"
              accept="image/*,.pdf"
              hide-details="auto"
              :disabled="loader"
              :error-messages="errors.file"
            />
          </v-col>
          <v-col cols="12" xl="2">
            <v-btn color="success" :disabled="loader" @click="enviar()"
              >Upload</v-btn
            >
          </v-col>
          <v-col cols="12" xl="4">
            <v-text-field
              dense
              outlined
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              hide-details="auto"
              :disabled="loader"
            />
          </v-col>
        </v-row>
        <v-data-table
          dense
          mobile-breakpoint="0"
          :headers="headers"
          :items="items"
          hide-default-footer
          class="elevation-1"
          :items-per-page="items.length"
          :search.sync="search"
          :loading="loader"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              icon
              color="primary"
              v-if="item.actions.download"
              :href="item.actions.download"
              target="_blank"
            >
              <v-icon>mdi-cloud-download</v-icon>
            </v-btn>
            <v-btn icon color="error" @click="eliminar(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | dateTime }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  export default {
    name: "Files",
    data: () => ({
      headers: [
        { text: "File", value: "name" },
        { text: "Date / Time", value: "created_at" },
        { text: "User", value: "user" },
        { text: "Actions", value: "actions", align: "end" },
      ],
      file: undefined,
      search: undefined,
    }),
    computed: {
      ...mapState({
        items: (state) => state.Goodman.files,
        loader: (state) => state.loader,
        errors: (state) => state.errors,
      }),
    },
    methods: {
      ...mapMutations(["setLoader", "setErrors"]),
      ...mapActions(["errores", "message"]),
      enviar() {
        let formData = new FormData(),
          url = `FR/schedules/uploadFile/${this.$route.params.id}`;
        formData.append("file", this.file);
        this.setLoader();
        this.setErrors();
        window.axios
          .post(url, formData)
          .then((resp) => {
            this.message(resp.data.message);
            this.file = undefined;
            this.$store.commit("Goodman/setFiles", resp.data.files);
          })
          .catch((e) => this.errores(e))
          .finally(() => this.setLoader(false));
      },
      eliminar(item) {
        let pregunta = `Delete file ${item.name}?`,
          text = "This action can't be undone";
        this.$swal({
          title: pregunta,
          text: text,
          icon: "warning",
          showCancelButton: true,
        }).then((resp) => {
          if (resp.isConfirmed) {
            this.setLoader();
            this.setErrors();
            window.axios.delete(item.actions.delete)
            .then((resp) => {
            this.message(resp.data.message);
            this.file = undefined;
            this.$store.commit("Goodman/setFiles", resp.data.files);
          })
          .catch((e) => this.errores(e))
          .finally(() => this.setLoader(false));
          }
        });
      },
    },
  };
</script>
