<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text> Bienvenido {{ user.nombre }} </v-card-text>
    <v-card-text>
      <v-expansion-panels multiple v-model="panel">
        <NewInventario v-if="inventario" />
        <Ventas v-if="ventas.collection" />
        <Facturas v-if="facturas.collection" />
        <Pagos v-if="pagos.headers" />
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="getAll" v-if="ventas.collection">
        actualizar datos
      </v-btn>
      <v-btn color="success" @click="downloadExcel">
        <v-icon>mdi-file-excel</v-icon>
        Statement Texas
      </v-btn>
      <v-btn color="success" @click="downloadCa">
        <v-icon>mdi-file-excel</v-icon>
        Statement California
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import fileDownload from "js-file-download";
  import Ventas from "./../views/dashboard/ventas";
  import Facturas from "./../views/dashboard/facturas";
  import Pagos from "./../views/dashboard/pagos";
  import NewInventario from "@/views/dashboard/PanelNewInventario";
  export default {
    name: "Dashboard",
    components: {
    
      Ventas,
      Facturas,
      Pagos,
      NewInventario,
    },
    data: () => ({
      panel: [0],
    }),
    computed: {
      ...mapState({
        inventario: (state) => state.Dashboard.inventario,
        ventas: (state) => state.Dashboard.ventas,
        facturas: (state) => state.Dashboard.facturas,
        pagos: (state) => state.Dashboard.pagos,
        externos: (state) => state.Dashboard.externos,
        user: (state) => state.Auth.user,
      }),
    },
    mounted() {
      this.getAll();
    },
    methods: {
      ...mapActions("Dashboard", ["getAll"]),
      downloadExcel() {
        let url = `FR/getStatementAll`;
        let name = `Statement Texas.xlsx`;
        this.$swal({
          title: `¿ Descargar el Statement Texas ?`,
          text: "Esto puede tardar algunos minutos",
          icon: "warning",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            window.axios
              .get(url, {
                responseType: "blob",
              })
              .then((response) => {
                console.log(response.headers);
                fileDownload(response.data, name);
              });
          }
        });
      },
      downloadCa() {
        let url = "FR/getStatementCa";
        let name = `Statement California.xlsx`;
        this.$swal({
          title: `¿ Descargar el Statement California ?`,
          text: "Esto puede tardar algunos minutos",
          icon: "warning",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            window.axios
              .get(url, {
                responseType: "blob",
              })
              .then((response) => {
                console.log(response.headers);
                fileDownload(response.data, name);
              });
          }
        });
      },
    },
  };
</script>
