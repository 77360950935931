<!-- eslint-disable -->
<template>
	<v-card height="100%">
		<v-card-text>
			<v-expansion-panels multiple v-model="panel">
				<template v-if="warehouses">
					<PDF />
					<Fechas />
					<Busqueda />
					<Disponibles />
					<Apartados />
				</template>
			</v-expansion-panels>
		</v-card-text>
	</v-card>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import PDF from './pdf';
import Busqueda from './busqueda';
import Disponibles from './disponibles';
import Apartados from './apartados';
import Fechas from './fechas';
export default {
	name: 'Pallets',
	components: { PDF, Busqueda, Disponibles, Apartados, Fechas },
	data: () => ({
		panel: [0]
	}),
	computed: {
		...mapState({
			warehouses: state => state.Pallets.warehouses
		})
	},
	mounted() {
		if (!this.warehouses) {
			this.getAll();
		}
	},
	methods: {
		...mapActions('Pallets', ['getAll'])
	}
};
</script>
