<!-- eslint-disable -->
<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="amber lighten-4">
			Apartados
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-card class="mt-3">
				<v-card-text>
					<v-row>
						<v-col
							cols="12"
							md="6"
							xl="4"
							v-for="producto in productos"
							:key="producto"
						>
							<v-card class="mt-3" height="98%">
								<v-card-text>
									<div class="negrita">{{ producto }}</div>
									<div style="color:black;font-size:1.1em;">
										Apartados {{ detallesprodcto(producto) | numero }}
									</div>
									<div>
										<v-row>
											<v-col
												cols="12"
												sm="6"
												xl="4"
												v-for="pallet in armarPallets(producto)"
												:key="pallet.pallet"
											>
												<v-card class="mt-2" color="amber lighten-4">
													<v-card-text class="pallet">
														<div
															class="text-center"
															style="color:black;font-size: 1.1em;font-weight: bolder;"
														>
															{{ pallet.pallet }}
														</div>
														<div class="text-center" style="color:black;">
															Exp {{ pallet.expediente }}<br />
															Apartados {{ pallet.apartados }}
														</div>
													</v-card-text>
												</v-card>
											</v-col>
										</v-row>
									</div>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex';
export default {
	name: 'Apartados',
	computed: {
		...mapState({
			collection: state => state.Pallets.disponibles
		}),
		disponibles() {
			return this.collection.filter(x => x.apartados > 0);
		},
		productos() {
			return [...new Set(this.disponibles.map(x => x.referencia))].sort();
		}
	},
	methods: {
		detallesprodcto(producto) {
			let filtrados = this.disponibles.filter(x => {
					return x.referencia == producto;
				}),
				total = _.sumBy(filtrados, 'apartados');
			return total;
		},
		armarPallets(producto) {
			let items = [],
				filtrados = this.disponibles.filter(x => {
					return x.referencia == producto;
				}),
				pallets = [...new Set(filtrados.map(x => x.pallet))].sort();
			pallets.forEach(pallet => {
				let encontrados = filtrados.filter(x => x.pallet == pallet),
					obj = {
						pallet,
						expediente: encontrados[0].expediente,
						disponibles: _.sumBy(encontrados, 'disponibles'),
						apartados: _.sumBy(encontrados, 'apartados')
					};
				items.push(obj);
			});
			return items;
		}
	}
};
</script>
<style>
.pallet {
	font-size: 0.9em;
}
</style>
