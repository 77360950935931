/** @format */

import router from "../../router";
const url = "FR/embarques";
const initialState = () => ({
  headers: [],
  collection: [],
  resource: {},
  loader: false,
  detalles: [],
  files: [],
  ventas: [],
  gastos: undefined,
  paginado: { currentPage: 1 },
  samples: [],
  printQC: undefined,
  summaryQC: undefined,
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setsummaryQC(state, payload) {
      state.summaryQC = payload;
    },
    setprintQC(state, payload) {
      state.printQC = payload;
    },
    setSamples(state, payload) {
      state.samples = payload;
    },
    setInitialState(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
    setHeaders(state, payload) {
      state.headers = payload;
    },
    setCollection(state, payload) {
      state.collection = payload;
    },
    setResource(state, payload) {
      state.resource = payload;
    },
    setLoader(state, payload = true) {
      state.loader = payload;
    },
    setDetalles(state, payload) {
      state.detalles = payload;
    },
    setFiles(state, payload) {
      state.files = payload;
    },
    setVentas(state, payload) {
      state.ventas = payload;
    },
    setGastos(state, payload) {
      state.gastos = payload;
    },
    setPaginado(state, payload) {
      state.paginado = payload;
    },
  },
  actions: {
    async getAll({ commit, dispatch, state }) {
      commit("setCollection", []);
      commit("setLoader");
      window.axios
        .get(url, {
          params: {
            page: state.paginado.currentPage,
            search: state.paginado.search,
          },
        })
        .then((resp) => {
          commit("setCollection", resp.data.collection);
          commit("setHeaders", resp.data.headers);
          let paginado = resp.data.paginado;
          if (paginado.currentPage > paginado.lastPage)
            paginado.currentPage = 1;
          commit("setPaginado", paginado);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async getEmbarque({ commit, dispatch }, id) {
      commit("setErrors", [], { root: true });
      window.axios
        .get(`${url}/${id}`)
        .then((resp) => {
          commit("setResource", resp.data.resource);
          commit("setDetalles", resp.data.detalles);
          commit("setFiles", resp.data.files);
          commit("setVentas", resp.data.ventas);
          commit("setGastos", resp.data.gastos);
          commit("setprintQC", resp.data.printQC);
          router
            .push({ name: "embarque.details", params: { id } })
            .catch(() => {});
        })
        .catch((e) => dispatch("errores", e, { root: true }));
    },
    async procesar({ commit, state, dispatch }, id) {
      commit("setLoader", true);
      await window.axios
        .post(`FR/procesarEmbarque/${id}`)
        .then((resp) => {
          window.swal(resp.data.message, { icon: "success", timer: 1500 });
          let index = state.collection.findIndex((x) => x.id == id);
          state.collection.splice(index, 1, resp.data.actualizado);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async loadFile({ commit, state }, formData) {
      commit("setLoader", true);
      await window.axios
        .post(`FR/subirArchivo/${state.resource.id}`, formData)
        .then((resp) => {
          window.swal(resp.data.message, { icon: "success", timer: 1500 });
          let index = state.collection.findIndex(
            (x) => x.id == resp.data.resource.id
          );
          state.collection.splice(index, 1, resp.data.resource);
          commit("setFiles", resp.data.files);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async deleteFile({ commit, dispatch, state }, id) {
      commit("setLoader", true);
      await window.axios
        .delete(`FR/eliminar-archivo-embarque/${id}`)
        .then((resp) => {
          window.swal(resp.data.message, { icon: "success", timer: 1500 });
          commit("setFiles", resp.data.files);
          let index = state.collection.findIndex(
            (x) => x.id == resp.data.resource.id
          );
          state.collection.splice(index, 1, resp.data.resource);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async addGasto({ commit, dispatch, state }, gasto) {
      let ruta = `FR/addGasto/${state.resource.id}`;
      commit("setLoader", true);
      commit("setErrors", [], { root: true });
      await window.axios
        .post(ruta, gasto)
        .then((resp) => {
          window.swal(resp.data.message, { icon: "success", timer: 1500 });
          commit("setGastos", resp.data.gastos);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async deleteGasto({ commit, dispatch }, id) {
      commit("setLoader", true);
      await window.axios
        .delete(`FR/deleteGasto/${id}`)
        .then((resp) => {
          window.swal(resp.data.message, { icon: "success", timer: 1500 });
          commit("setGastos", resp.data.gastos);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async updateGasto({ commit, dispatch }, data) {
      let ruta = `FR/updateGasto/${data.id}`;
      commit("setLoader", true);
      commit("setErrors", [], { root: true });
      await window.axios
        .post(ruta, data.data)
        .then((resp) => {
          window.swal(resp.data.message, { icon: "success", timer: 1500 });
          commit("setGastos", resp.data.gastos);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async getSamples({ commit, dispatch, state }) {
      commit("setLoader");
      commit("setSamples", []);
      await window.axios
        .get(`FR/samples/${state.resource.id}`)
        .then((resp) => {
          commit("setSamples", resp.data.collection);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async getSummaryQC({ commit, dispatch, state }) {
      commit("setLoader");
      commit("setsummaryQC", undefined);
      await window.axios
        .get(`${url}/summaryQC/${state.resource.id}`)
        .then((resp) => {
          if (resp.data.message) {
            dispatch("message", resp.data.message, { root: true });
          }
          commit("setsummaryQC", resp.data);
        })
        .catch((e) => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async postSummaryQC({ commit, dispatch, state }) {
      commit("setLoader");
      commit("setErrors", {}, { root: true });
      let resource = state.summaryQC;
      if (resource.id) {
        await window.axios
          .put(`${url}/summaryQC/${resource.id}`, resource)
          .then((resp) => {
            if (resp.data.message)
              dispatch("message", resp.data.message, { root: true });
            commit("setsummaryQC", resp.data);
          })
          .catch((e) => dispatch("errores", e, { root: true }))
          .finally(() => commit("setLoader", false));
      } else {
        await window.axios
          .post(`${url}/summaryQC/${state.resource.id}`, resource)
          .then((resp) => {
            if (resp.data.message)
              dispatch("message", resp.data.message, { root: true });
            commit("setsummaryQC", resp.data);
          })
          .catch((e) => dispatch("errores", e, { root: true }))
          .finally(() => commit("setLoader", false));
      }
    },
  },
};
