<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        class="elevation-1 mt-3"
        hide-default-footer
      >
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: "Index",
    data: () => ({
      headers: [
        { text: "Empresa", value: "empresa" },
        { text: "N° de Poliza", value: "emitido" },
        { text: "Inicio Vigencia", value: "puntaje" },
        { text: "Fin de vigencia", value: "files", align: "end" },
        { text: "Importe", value: "files", align: "end" },
        { text: "Deducible", value: "files", align: "end" },
        { text: "Acciones", value: "actions", align: "end" },
      ],
      items: [],
    }),
  };
</script>
