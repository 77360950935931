<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card class="my-2">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="3" xl="2" class="mio">
          <strong>Total apartados:{{ apartados | numero }}</strong>
        </v-col>
        <v-col cols="12" md="3" xl="2" class="mio">
          <strong>Total disponibles: {{ disponibles | numero }}</strong>
        </v-col>
        <v-col cols="12" md="3" xl="2">
          <v-switch v-model="todos" label="Mostrar todos "></v-switch>
        </v-col>
        <v-col cols="12" md="3" xl="2">
          <v-switch v-model="fechas" label="Mostrar Fechas"></v-switch>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :items="items"
        :headers="headers"
        class="elevation-1"
        mobile-breakpoint="0"
        :loading="loader"
        :item-class="row_classes"
        :items-per-page="perPage"
        hide-default-footer
        v-if="!fechas"
      >
      </v-data-table>
      <v-row v-else>
        <v-col cols="12" md="4" xl="3" v-for="(card, index) in tarjetas" :key="index">
          <CardFechas :item="card" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn small color="primary" @click="actualizar">actualizar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import CardFechas from "./CardFechas.vue";
  export default {
    name: "Inventario",
    components: { CardFechas },
    data: () => ({
      collection: [],
      headers: [],
      todos: false,
      loader: false,
      fechas: false,
      tarjetas: [],
    }),
    beforeMount() {
      this.actualizar();
    },
    methods: {
      actualizar() {
        this.loader = true;
        this.headers = this.collection = this.tarjetas = [];
        window.axios
          .get("FR/new-inventario")
          .then((resp) => {
            this.collection = resp.data.items;
            this.headers = resp.data.headers;
            this.tarjetas = resp.data.fechas;
          })
          .finally(() => (this.loader = false));
      },
    },
    computed: {
      apartados() {
        return window._.sumBy(this.collection, "apartados");
      },
      disponibles() {
        return window._.sumBy(this.collection, "disponibles");
      },
      perPage() {
        return this.items.length;
      },
      items() {
        if (this.todos) return this.collection;
        return this.collection.filter((x) => x.disponibles > 0);
      },
    },
  };
</script>
<style scoped>
  .mio {
    margin-top: 16px;
    padding-top: 4px;
  }
</style>
