<!-- eslint-disable -->
<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Productos
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-data-table
				dense
				mobile-breakpoint="0"
				:headers="headers"
				:items="items"
				class="elevation-1 mt-2"
			>
				<!-- prettier-ignore -->
				<template v-slot:[`item.importe`]="{ item }">{{ item.importe | moneda }}</template>
				<!-- prettier-ignore -->
				<template v-slot:[`item.precio`]="{ item }">{{ item.precio | moneda }}</template>
			</v-data-table>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Products',
	data: () => ({
		headers: [
			{ text: 'Producto', value: 'producto' },
			{ text: 'Pallet ID', value: 'pallet_id' },
			{ text: 'Cantidad', value: 'cantidad', align: 'end' },
			{ text: 'Precio U', value: 'precio', align: 'end' },
			{ text: 'Importe', value: 'importe', align: 'end' }
		]
	}),
	computed: {
		...mapState({
			items: state => state.Sucursales.products
		})
	}
};
</script>
