<!-- @format -->

<!-- eslint-disable -->
<template>
  <div v-if="item.actions">
    <div v-if="item.actions.restore">
      <v-btn
        icon
        color="success"
        @click="$emit('restore', item)"
        :disabled="loader"
      >
        <v-icon>mdi-delete-restore</v-icon>
      </v-btn>
    </div>
    <div v-else>
      <v-btn
        icon
        color="primary"
        @click="information(item)"
        v-if="item.observations"
        :disabled="loader"
      >
        <v-icon>mdi-information</v-icon>
      </v-btn>
      <v-btn
        icon
        color="success"
        @click="$emit('editar', item)"
        v-if="item.actions.edit"
        :disabled="loader"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <slot></slot>
      <v-btn
        icon
        color="error"
        @click="$emit('eliminar', item)"
        v-if="item.actions.delete"
        :disabled="loader"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>
  </div>
  <div v-else>
    <slot></slot>
  </div>
</template>
<script>
  export default {
    name: "Actions",
    props: {
      item: {
        type: Object,
        default() {
          return {};
        },
      },
      loader: {
        type: Boolean,
      },
    },
    methods: {
      information(item) {
        this.$swal({
          title: "Observaciones",
          html: `${item.observations}`,
          confirmButtonColor: "#d33",
          confirmButtonText: "Cerrar",
        });
      },
    },
    emits: ["restore", "editar", "eliminar"],
  };
</script>
