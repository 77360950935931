<!-- eslint-disable -->
<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Busqueda
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-card class="mt-3">
				<v-card-text>
					<v-form @submit.prevent="searchPallet({ id: search })">
						<v-row>
							<v-col>
								<v-text-field
									outlined
									dense
									type="text"
									label="Pallet"
									v-model="search"
								/>
							</v-col>
							<v-col>
								<v-btn color="primary" type="submit" :disabled="loader">
									buscar
								</v-btn>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-card-text v-if="pallet">
					<v-card class="mt-3">
						<v-card-text>
							Total de cajas del pallet: {{ totalCajas }}
							<v-data-table
								dense
								:headers="headers"
								:items="trazabilidad"
								mobile-breakpoint="0"
							>
								<template v-slot:[`item.fecha`]="{ item }">
									{{ item.fecha | date }}
								</template>
							</v-data-table>
						</v-card-text>
					</v-card>
					<v-card class="mt-3">
						<v-card-text>
							Total de cajas vendidas: {{ vendidos }} <br />
							Importe: {{ importe | moneda }}
							<v-data-table
								dense
								:headers="headers2"
								:items="ventas"
								mobile-breakpoint="0"
							>
								<template v-slot:[`item.importe`]="{ item }">
									{{ item.importe | moneda }}
								</template>
								<template v-slot:[`item.precio`]="{ item }">
									{{ item.precio | moneda }}
								</template>
								<template v-slot:[`item.fecha`]="{ item }">
									{{ item.fecha | date }}
								</template>
							</v-data-table>
						</v-card-text>
					</v-card>
				</v-card-text>
			</v-card>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex';
export default {
	name: 'Busqueda',
	data: () => ({
		search: undefined,
		headers: [
			{ text: 'Producto', value: 'producto' },
			{ text: 'Huerta', value: 'huerta' },
			{ text: 'Lote', value: 'lote' },
			{ text: 'Fecha de corte', value: 'fecha' },
			{ text: 'Cantidad', value: 'cantidad', align: 'end' }
		],
		headers2: [
			{ text: 'Producto', value: 'producto' },
			{ text: 'Fecha', value: 'fecha' },
			{ text: 'Cliente', value: 'cliente' },
			{ text: 'Metodo de Pago', value: 'metodoPago' },
			{ text: 'Invoice ID', value: 'factura_id' },
			{ text: 'PU', value: 'pu' },
			{ text: 'Cantidad', value: 'cantidad', align: 'end' },
			{ text: 'Precio U', value: 'precio', align: 'end' },
			{ text: 'Importe', value: 'importe', align: 'end' }
		]
	}),
	computed: {
		...mapState({
			loader: state => state.Pallets.loader,
			pallet: state => state.Pallets.pallet
		}),
		trazabilidad() {
			let resultado = [],
				items = this.pallet.trazabilidad,
				lotes = [...new Set(items.map(x => x.lote))];
			lotes.forEach(lote => {
				let filtrados = items.filter(x => x.lote == lote),
					obj = Object.assign({}, filtrados[0]);
				obj.cantidad = _.sumBy(filtrados, 'cantidad');
				resultado.push(obj);
			});
			return resultado;
		},
		ventas() {
			let resultado = [],
				items = this.pallet.ventas,
				lotes = [...new Set(items.map(x => x.pu))];
			lotes.forEach(pu => {
				let filtrados = items.filter(x => x.pu == pu),
					obj = Object.assign({}, filtrados[0]);
				obj.cantidad = _.sumBy(filtrados, 'cajas');
				obj.importe = _.sumBy(filtrados, 'importe');
				resultado.push(obj);
			});
			return resultado;
		},
		totalCajas() {
			return _.sumBy(this.pallet.trazabilidad, 'cantidad');
		},
		vendidos() {
			return _.sumBy(this.pallet.ventas, 'cajas');
		},
		importe() {
			return _.sumBy(this.pallet.ventas, 'importe');
		}
	},
	methods: {
		...mapActions('Pallets', ['searchPallet'])
	}
};
</script>
