<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-expansion-panels multiple v-if="sale">
        <Sale />
        <Products />
        <Charges v-if="charges" />
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="primary"
        @click="$router.push({ name: 'california.sales' })"
      >
        regresar
      </v-btn>
      <v-btn color="success" v-if="sale && !sale.factura" @click="facturar">
        Facturar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import Sale from "./sale/PanelSale.vue";
  import Products from "./sale/PanelProducts.vue";
  import Charges from "./sale/PanelCharges.vue";
  export default {
    name: "Show",
    components: { Charges, Products, Sale },
    computed: {
      ...mapState({
        sale: (state) => state.Sucursales.sale,
        products: (state) => state.Sucursales.products,
        charges: (state) => state.Sucursales.charges,
      }),
    },
    beforeMount() {
      if (!this.sale) {
        this.getSale(this.$route.params.id);
      }
    },
    methods: {
      ...mapActions("Sucursales", ["getSale", "invoiceSale"]),
      facturar() {
        this.$swal({
          title: "¿Deseas hacer la factura de la venta?",
          text: "Esta accion no puede deshacerse",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.invoiceSale();
          }
        });
      },
    },
  };
</script>
