var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"green lighten-4"}},[_vm._v(" Busqueda ")]),_c('v-expansion-panel-content',[_c('v-card',{staticClass:"mt-3"},[_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.searchPallet({ id: _vm.search })}}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"text","label":"Pallet"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":_vm.loader}},[_vm._v(" buscar ")])],1)],1)],1)],1),(_vm.pallet)?_c('v-card-text',[_c('v-card',{staticClass:"mt-3"},[_c('v-card-text',[_vm._v(" Total de cajas del pallet: "+_vm._s(_vm.totalCajas)+" "),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.trazabilidad,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fecha))+" ")]}}],null,true)})],1)],1),_c('v-card',{staticClass:"mt-3"},[_c('v-card-text',[_vm._v(" Total de cajas vendidas: "+_vm._s(_vm.vendidos)+" "),_c('br'),_vm._v(" Importe: "+_vm._s(_vm._f("moneda")(_vm.importe))+" "),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers2,"items":_vm.ventas,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}},{key:"item.precio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.precio))+" ")]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fecha))+" ")]}}],null,true)})],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }