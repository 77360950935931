<!-- @format -->
<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-form @submit.prevent="getAll">
        <v-row class="my-3">
          <v-col cols="12" md="3">
            <v-autocomplete
              dense
              outlined
              clearable
              label="Filtrar por Cliente"
              :items="paginado.clientes"
              v-model="paginado.cliente_id"
            />
          </v-col>
          <v-col cols="6" md="2">
            <v-autocomplete
              dense
              outlined
              clearable
              label="Filtrar por Estatus"
              :items="estados"
              v-model="paginado.status"
            />
          </v-col>
          <v-col cols="6" md="2">
            <v-autocomplete
              dense
              outlined
              clearable
              label="Filtrar por Método"
              :items="paginado.metodos"
              v-model="paginado.metodo"
            />
          </v-col>
          <v-col cols="6" md="2">
            <v-text-field
              dense
              outlined
              clearable
              label="Buscar PU"
              v-model="paginado.search"
            />
          </v-col>
          <v-col cols="12" md="auto">
            <v-btn color="primary" type="submit" :disabled="loader">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn color="info" class="ml-3" @click="getAll">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn color="success" class="ml-3" @click="dialog = true">
              <v-icon>mdi-file-excel</v-icon>
            </v-btn>
            <v-btn color="success" class="ml-3" @click="dialogInvoice = true">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        dense
        :items="items"
        :headers="headers"
        class="elevation-1"
        mobile-breakpoint="0"
        :items-per-page="paginado.perPage"
        hide-default-footer
        :item-class="rowColor"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | folio }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.resta`]="{ item }">
          {{ item.resta | moneda }}
        </template>
        <template v-slot:[`item.pagos`]="{ item }">
          {{ (item.importe - item.resta) | moneda }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <actions
            :item="item"
            @editar="detalles(item)"
            @eliminar="eliminar(item)"
          >
            <v-btn
              icon
              color="red"
              :href="item.pdf"
              target="_blank"
              :disabled="loader"
            >
              <v-icon>mdi-file-pdf</v-icon>
            </v-btn>
            <v-btn
              icon
              color="primary"
              v-if="item.merge"
              :href="item.merge"
              target="_blank"
              :disabled="loader"
            >
              <v-icon>mdi-file-pdf</v-icon>
            </v-btn>
            <v-btn
              icon
              color="primary"
              v-if="item.merge2"
              :href="item.merge2"
              target="_blank"
              :disabled="loader"
            >
              <v-icon>mdi-file-pdf</v-icon>
            </v-btn>
            <v-btn
              icon
              color="info"
              v-if="item.actions.email"
              @click="email(item)"
            >
              <v-icon>mdi-email</v-icon>
            </v-btn>
          </actions>
        </template>
        <template v-slot:[`item.fecha_pago`]="{ item }">
          {{ item.fecha_pago | date }}
        </template>
      </v-data-table>
      <Pagination :loader="loader" :items="items" :paginado="paginado" />
    </v-card-text>
    <Excel
      v-if="dialog"
      :dialog="dialog"
      @cancelar="dialog = false"
      :clientes="paginado.clientes"
      :metodos="paginado.metodos"
    />
    <modal-email
      v-if="dialogEmail"
      :dialog="dialogEmail"
      @cancelar="dialogEmail = false"
      :item="invoice"
      :pdfTypes="pdfTypes"
      :url="invoice.urlEmail"
    />
    <NewInvoice
      v-if="dialogInvoice"
      :dialog="dialogInvoice"
      @cancelar="dialogInvoice = false"
      @actualizar="actualizar"
    />
  </v-card>
</template>

<script>
  import { mapState, mapActions, mapMutations } from "vuex";
  import Excel from "./ModalExcel.vue";
  import NewInvoice from "./ModalInvoice.vue";
  export default {
    name: "Facturas",
    components: {
      Excel,
      NewInvoice,
    },
    data: () => ({
      dialog: false,
      estados: [
        { text: "Pagada", value: "true" },
        { text: "Pendiente", value: "false" },
      ],
      dialogEmail: false,
      invoice: {},
      dialogInvoice: false,
    }),
    computed: {
      ...mapState({
        headers: (state) => state.Facturas.headers,
        items: (state) => state.Facturas.collection,
        paginado: (state) => state.Facturas.paginado,
        loader: (state) => state.Facturas.loader,
      }),
      actual() {
        return this.paginado.currentPage;
      },
      cliente_id() {
        return this.paginado.cliente_id;
      },
      metodo() {
        return this.paginado.metodo;
      },
      estado() {
        return this.paginado.status;
      },
      pdfTypes() {
        let types = ["Invoice"];
        if (this.invoice.merge || this.invoice.merge2) {
          types.push("Combinado");
        }
        return types;
      },
    },
    mounted() {
      this.getAll();
    },
    methods: {
      ...mapActions("Facturas", ["getAll", "getFactura", "deleteResource"]),
      detalles(item) {
        this.getFactura(item.id);
      },
      eliminar(item) {
        this.$swal({
          title: `¿ Eliminar el invoice ${item.folio} ?`,
          text: "Esta acción no puede deshacerse",
          icon: "question",
          showCancelButton: true,
        }).then((resp) => {
          if (resp.isConfirmed) {
            this.deleteResource(item.id);
          }
        });
      },
      rowColor(item) {
        let color = null;
        if (item.diasAtraso && item.diasAtraso < 11) color = "yellow lighten-5";
        if (item.diasAtraso && item.diasAtraso > 10) color = "red lighten-5";
        return color;
      },
      email(item) {
        this.dialogEmail = true;
        this.invoice = item;
      },
      actualizar() {
        this.getAll();
        this.dialogInvoice = false;
      },
    },
    watch: {
      actual: function() {
        this.getAll();
      },
      cliente_id: function() {
        this.getAll();
      },
      metodo: function() {
        this.getAll();
      },
      estado() {
        this.getAll();
      },
    },
  };
</script>
