<!-- @format -->

<!-- eslint-disable -->
<template>
  <v-card class="my-2">
    <v-card-text>
      <v-row>
        <v-col class="mt-2">
          <v-autocomplete
            clearable
            outlined
            dense
            small-chips
            label="Seleccionar Producto"
            :items="productos"
            v-model="producto"
          />
        </v-col>
      </v-row>

      <v-data-table
        calculate-widths
        sortBy="expediente"
        :headers="computedHeaders"
        :items="items"
        class="elevation-1"
        v-if="producto"
        mobile-breakpoint="0"
        hide-default-footer
        :items-per-page="items.length"
      >
        <template v-slot:[`item.seleccionados`]="{ item }">
          <v-text-field
            dense
            type="number"
            :max="item.disponibles"
            min="0"
            class="righted-input"
            v-model.number="item.seleccionados"
            hide-details="auto"
          />
        </template>
        <template v-slot:[`item.precio`]="{ item }">
          <v-text-field
            dense
            type="number"
            step="0.01"
            min="0"
            class="righted-input"
            v-model.number="item.precio"
            hide-details="auto"
          />
        </template>
        <template v-slot:[`item.fecha_minima`]="{ item }">
          {{ item.fecha_minima | date }}
        </template>
        <template v-slot:[`item.precioMexico`]="{ item }">
          {{ item.precioMexico | moneda }}
        </template>
      </v-data-table>
      <v-row class="mt-3">
        <v-col cols="12" v-if="listos.length">
          <v-card>
            <v-card-title>Aguacates FR seleccionados</v-card-title>
            <v-card-text>
              <v-data-table
                dense
                :headers="headerListos"
                :items="listos"
                class="elevation-1"
                hide-default-footer
                :items-per-page="perPage"
                mobile-breakpoint="0"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn icon color="red" @click="eliminar(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <template v-slot:[`item.precio`]="{ item }">
                  {{ item.precio | moneda }}
                </template>
                <template v-slot:[`item.importe`]="{ item }">
                  {{ importe(item) | moneda }}
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-text>
              Total de Pallets a agregar: {{ palletsTotal.toFixed(2) }} <br />
              Importe: {{ importTotal | moneda }}
            </v-card-text>
            <v-card-actions>
              <v-btn color="success" @click="agregar" :disabled="loader"
                >agregar al presupuesto</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  export default {
    name: "CardFR",
    data: () => ({
      producto: undefined,
      perPage: 50,
      headers: [
        { text: "Expediente", value: "expediente", hide: "smAndDown" },
        { text: "Pallet", value: "pallet" },
        { text: "Fecha", value: "fecha_minima" },
        { text: "Disponibles", value: "disponibles", align: "end" },
        { text: "Cajas a Vender", value: "seleccionados", align: "end" },
        { text: "Precio Mex", value: "precioMexico", align: "end" },
        { text: "Precio", value: "precio", align: "end" },
      ],
      headerListos: [
        { text: "Producto", value: "referencia" },
        { text: "Pallet", value: "pallet" },
        { text: "Cantidad", value: "seleccionados", align: "end" },
        { text: "Precio", value: "precio", align: "end" },
        { text: "Importe", value: "importe", align: "end" },
        { text: "", value: "actions", align: "end" },
      ],
    }),
    computed: {
      ...mapState({
        pallets: (state) => state.Budgets.resource.disponibles,
        loader: (state) => state.Budgets.loader,
      }),
      productos: function() {
        return [...new Set(this.pallets.map((x) => x.referencia))];
      },
      items: function() {
        return this.pallets.filter((x) => {
          return x.referencia == this.producto;
        });
      },
      computedHeaders() {
        return this.headers.filter(
          (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
        );
      },
      listos: function() {
        return this.pallets.filter((x) => {
          return (
            x.seleccionados &&
            x.precio &&
            x.seleccionados <= x.disponibles &&
            x.seleccionados > 0 &&
            x.precio >= 0
          );
        });
      },
      cajas: function() {
        return this.listos.reduce(function(accumulator, item) {
          return accumulator + parseFloat(item.seleccionados);
        }, 0);
      },
      palletsTotal: function() {
        let total = this.cajas / 80;
        return total;
      },
      importTotal: function() {
        return this.listos.reduce(function(accumulator, item) {
          let cantidad = parseFloat(item.seleccionados),
            precio = parseFloat(item.precio);
          return accumulator + precio * cantidad;
        }, 0);
      },
    },
    methods: {
      ...mapActions("Budgets", ["addProducto"]),
      agregar() {
        let data = { data: this.listos };
        this.addProducto(data);
      },
      eliminar(item) {
        item.seleccionados = undefined;
        item.precio = undefined;
      },
      importe(item) {
        let cantidad = parseFloat(item.seleccionados),
          precio = parseFloat(item.precio);
        return cantidad * precio;
      },
    },
  };
</script>
