<!-- eslint-disable -->
<template>
	<v-card height="100%">
		<v-card-text v-if="action">
			<v-form @submit.prevent="sendWarehouse(almacen)">
				<v-row>
					<v-col>
						{{ title }}
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" xl="4">
						<v-text-field
							outlined
							dense
							type="text"
							label="Nombre"
							v-model="almacen.name"
							:error-messages="errors.name"
						/>
					</v-col>
					<v-col cols="12" xl="4">
						<v-text-field
							outlined
							dense
							type="text"
							label="Teléfono"
							v-model="almacen.phone"
							:error-messages="errors.phone"
						/>
					</v-col>
					<v-col cols="12" xl="4">
						<v-text-field
							outlined
							dense
							type="text"
							label="FOB"
							v-model="almacen.fob"
							:error-messages="errors.fob"
						/>
					</v-col>
					<v-col cols="12" sm="6">
						<v-textarea
							outlined
							label="Domicilio"
							v-model="almacen.address"
							:error-messages="errors.address"
						/>
					</v-col>
					<v-col cols="12" sm="6">
						<v-textarea
							outlined
							label="Horario"
							v-model="almacen.timetable"
							:error-messages="errors.timetable"
						/>
					</v-col>
					<v-col>
						<v-btn color="primary" type="submit" :disabled="loader"
							>guardar</v-btn
						>
						<v-btn color="error" class="ml-3" @click="cancelar">cancelar</v-btn>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>
		<v-card-text v-else>
			<v-row>
				<v-col cols="12" md="4">
					<v-btn small color="success" class="mt-5" @click="nuevo" v-if="userCan('fr-warehouses-create')">
						<v-icon>mdi-plus</v-icon>
					</v-btn>
					<v-btn small color="info" class="mt-5 ml-3" @click="getAll">
						<v-icon>mdi-refresh</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="12" xl="4" offset-xl="4">
					<v-container>
						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="Busqueda"
							single-line
							hide-details
						/>
					</v-container>
				</v-col>
			</v-row>
			<v-data-table
				dense
				:items="items"
				:headers="headers"
				class="elevation-1"
				:search.sync="search"
				mobile-breakpoint="0"
			>
				<template v-slot:[`item.actions`]="{ item }">
					<v-btn icon color="success" @click="editar(item)" v-if="userCan('fr-warehouses-edit')">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
					<v-btn icon color="red" @click="eliminar(item)"  v-if="userCan('fr-warehouses-delete')">
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
	name: 'Warehouses',
	data: () => ({
		search: undefined,
		almacen: {}
	}),
	computed: {
		...mapState({
			headers: state => state.Warehouses.headers,
			items: state => state.Warehouses.collection,
			loader: state => state.Warehouses.loader,
			action: state => state.Warehouses.action,
			errors: state => state.errors
		}),
		title() {
			return this.almacen.id ? 'Editando Almacen' : 'Nuevo Almacen';
		}
	},
	mounted() {
		if (!this.items.length) this.getAll();
	},
	methods: {
		...mapActions('Warehouses', ['getAll', 'sendWarehouse', 'deleteWarehouse']),
		...mapMutations(['setErrors']),
		...mapMutations('Warehouses', ['setLoader', 'SET_ACTION']),
		editar(item) {
			this.SET_ACTION(true);
			this.almacen = Object.assign({}, item);
		},
		eliminar(item) {
			let pregunta = `¿Deseas eliminar el almacen ${item.name}?`;
			window
				.swal({
					title: pregunta,
					icon: 'warning',
					buttons: ['Cancelar', 'Confirmar'],
					dangerMode: true
				})
				.then(confirmado => {
					if (confirmado) {
						this.deleteWarehouse(item.id);
					}
				});
		},
		nuevo() {
			this.SET_ACTION(true);
			this.almacen = {};
		},
		cancelar() {
			this.setErrors([]);
			this.setLoader(false);
			this.SET_ACTION(false);
		}
	}
};
</script>
