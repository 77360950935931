<!-- eslint-disable -->
<template>
	<v-card height="100%">
		<v-card-text>
			<Formulario />
		</v-card-text>
	</v-card>
</template>

<script>
import Formulario from './formulario';
export default {
	components: { Formulario }
};
</script>
