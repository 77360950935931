/** @format */
const base ='qc'
const routes = [
  {
    path: "/Quality-Control",
    name: `${base}`,
    component: require("@/views/qc/Index.vue").default,
    meta: {
      name: "Quality Control",
    },
  },
  {
    path: "/Quality-Control/new",
    name: `${base}.create`,
    component: require("@/views/qc/Show.vue").default,
    meta: {
      name: "New Quality Control",
    },
  },
  {
    path: "/Quality-Control/details/:id",
    name: `${base}.edit`,
    component: require("@/views/qc/Show.vue").default,
    meta: {
      name: "Quality Control Details",
    },
  },
];
export default routes;
